<template>
    <AppWrapper activeTab="Payments">
        <div class="">
            <header class="pt-[72px] pb-[42px] flex justify-between items-center">
                <div class="">
                    <div class="text-heading-3 font-semibold text-grey-300">Payments</div>
                    <div class="text-body-2 text-grey-200">View and manage various payment methods in your register app.</div>
                </div>
            </header>

            <main class="grid grid-cols-1 gap-[20px]">
                <div class="flex justify-between border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]">
                    <div class="">
                        <div class="text-body-1 font-semibold">Cash Payment</div>
                        <p class="text-grey-200 text-body-2">You don't have cash payment setup yet. <span @click="handleOpenCashPaymentModal" class="text-[#1677FF] cursor-pointer">Set it up now</span> </p>
                    </div>

                    <ToggleSlider name="status" :isChecked="payments?.cash?.status" @on:change="updatePaymentStatus($event, 'cash', updatePaymentsHandler)" />  

                    <AppModal headerContent="Cash Payment setup" :openModal="isCashPaymentModalOpen" @close="handleOpenCashPaymentModal">
                        <form @submit.prevent="(event) => handlePaymentSubmit(event, 'cash', handleOpenCashPaymentModal)">
                            <div class="px-[28px] pt-[32px]">
                                <header class="text-body-1 text-grey-200">Please add your store's cash payment configurations.</header>
                                <div class="grid grid-cols-1 gap-[32px] mt-[32px]">
                                    <div class="">
                                        <DropdownField 
                                            label="Currency" 
                                            name="currency"
                                            :optionsData="[{name: 'Naira (₦)', value: 'NGN'}]" 
                                            :value="payments?.cash?.currency"
                                            @change="updatePaymentsHandler.handleChange"
                                        />
                                    </div>

                                    <!-- <InputField 
                                        label="Round to Nearest" 
                                        value="0.00" 
                                        prefixIcon="fa6-solid:naira-sign" 
                                        type="number" 
                                    /> -->

                                    <CheckboxInput label="Receive email Notifications for Cash Transactions." />
                                </div>
                            </div>

                            <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                                <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenCashPaymentModal" />
                                <ActionButton
                                    theme="primary" 
                                    class="w-[fit-content]" 
                                    :isSubmit="true" 
                                    :disable="loading" 
                                    :label="loading ? 'Loading...' : 'Save'" 
                                />
                            </footer>
                        </form>
                    </AppModal>
                </div>

                <div class="flex justify-between border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]">
                    <div class="">
                        <div class="text-body-1 font-semibold">Debit Card Payment</div>
                        <p class="text-grey-200 text-body-2">You don't have debit card payment setup yet. <span @click="handleOpenDebitCardModal" class="text-[#1677FF] cursor-pointer">Set it up now</span> </p>
                    </div>

                    <ToggleSlider :isChecked="payments?.debit?.status"  @on:change="updatePaymentStatus($event, 'debit', updatePaymentsHandler)" />

                    <AppModal headerContent="Debit Card Payment setup" :openModal="isDebitCardModalOpen" @close="handleOpenDebitCardModal">
                        <form @submit.prevent="(event) => handlePaymentSubmit(event, 'debit', handleOpenDebitCardModal)">
                            <div class="px-[28px] pt-[32px]">
                                <header class="text-body-1 text-grey-200">Please provide a list of POS machine details for accepting debit card payments at your store.</header>
                                <div class="debit-id items-center gap-[12px] mt-[32px]" v-for="(device, idx) in payments?.debit?.devices" :key="idx">
                                    <InputField 
                                        label="Machine ID/Name *" 
                                        type="text" 
                                        placeholder="Machine ID/Name" 
                                        name="machineName"
                                        :value="device?.machineName"
                                        @update:blur:value="updatePaymentsHandler.handleBlur"
                                        @update:value="handlePaymentValueChange($event, idx, 'debit', 'devices')"
                                    />

                                    <InputField 
                                        label="Manufacturer *" 
                                        type="text" 
                                        name="machineId"
                                        placeholder="Manufacturer" 
                                        :value="device?.machineId"
                                        @update:blur:value="updatePaymentsHandler.handleBlur"
                                        @update:value="handlePaymentValueChange($event, idx, 'debit', 'devices')"
                                    />

                                    <div class="bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content]" @click="deleteFromPaymentInputList('debit', idx, payments, updatePaymentsHandler, 'devices')">
                                        <Icon icon="ant-design:delete-filled" class="text-primary-300 w-[24px] h-[24px]" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px]">
                                    <ActionButton prefixIcon="ant-design:plus-outline" label="Add Machine" class="w-[fit-content] m-[auto]" theme="outlined" @click="() => addDebitDevice()" />
                                    <CheckboxInput label="Receive email Notifications for Cash Transactions." />
                                </div>
                            </div>

                            <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                                <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenDebitCardModal" />
                                <ActionButton
                                    theme="primary" 
                                    class="w-[fit-content]" 
                                    :isSubmit="true" 
                                    :disable="loading" 
                                    :label="loading ? 'Loading...' : 'Save'" 
                                />
                            </footer>
                        </form>
                    </AppModal>
                </div>

                <div class="flex justify-between border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]">
                    <div class="">
                        <div class="text-body-1 font-semibold">Bank Transfer payment</div>
                        <p class="text-grey-200 text-body-2">You don't have Bank Transfer payment setup yet. <span @click="handleOpenBankTransferModal" class="text-[#1677FF] cursor-pointer">Set it up now</span> </p>
                    </div>

                    <ToggleSlider :isChecked="payments?.bankTransfer?.status"  @on:change="updatePaymentStatus($event, 'bankTransfer', updatePaymentsHandler)" />

                    <AppModal headerContent="Bank Transfer payment setup" :openModal="isOpenBankModalOpen" @close="handleOpenBankTransferModal">
                        <form @submit.prevent="(event) => handlePaymentSubmit(event, 'bankTransfer', handleOpenBankTransferModal)">
                            <div class="px-[28px] pt-[32px]">
                                <header class="text-body-1 text-grey-200">Please provide a list of bank details for accepting transfer payments at your store.</header>
                                <div class="bank-transfer-input mt-[32px]" v-for="(account, idx) in payments?.bankTransfer?.accounts" :key="idx">
                                    <div class="">
                                        <div class="grid grid-cols-2 gap-[12px]">
                                            <InputField 
                                                label="Bank Account Name *" 
                                                type="text" 
                                                placeholder="Account name" 
                                                name="accountName"
                                                :value="account?.accountName"
                                                @update:blur:value="updatePaymentsHandler.handleBlur"
                                                @update:value="handlePaymentValueChange($event, idx, 'bankTransfer', 'accounts')"
                                            />

                                            <InputField 
                                                label="Bank Account Number *" 
                                                type="phone" 
                                                placeholder="080 123 456" 
                                                :value="account?.accountNumber"
                                                name="accountNumber"
                                                @update:blur:value="updatePaymentsHandler.handleBlur"
                                                @update:value="handlePaymentValueChange($event, idx, 'bankTransfer', 'accounts')"
                                            />
                                        </div>

                                        <div class="mt-[14px]">
                                            <DropdownField
                                                label="Select Bank" 
                                                name="accountProvider"
                                                :value="account?.accountProvider"
                                                :optionsData="banksDetailsData"
                                                @change="handlePaymentValueChange($event, idx, 'bankTransfer', 'accounts')"
                                            />
                                        </div>

                                    </div>

                                    <div class="bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content]" @click="deleteFromPaymentInputList('bankTransfer', idx, payments, updatePaymentsHandler, 'accounts')">
                                        <Icon icon="ant-design:delete-filled" class="text-primary-300 w-[24px] h-[24px]" />
                                    </div>
                                </div>
                                <div class="grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px]">
                                    <ActionButton prefixIcon="ant-design:plus-outline" label="Add Bank" class="w-[fit-content] m-[auto]" theme="outlined" @click="() => addBankAccount()" />
                                    <CheckboxInput label="Receive email Notifications for Bank Transfer Transactions." />
                                </div>
                            </div>

                            <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                                <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenBankTransferModal"/>
                                <ActionButton
                                    theme="primary" 
                                    class="w-[fit-content]" 
                                    :isSubmit="true" 
                                    :disable="loading" 
                                    :label="loading ? 'Loading...' : 'Save'" 
                                />
                            </footer>
                        </form>
                    </AppModal>
                </div>

                <div class="flex justify-between border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]">
                    <div class="">
                        <div class="text-body-1 font-semibold">QR Code Payment</div>
                        <p class="text-grey-200 text-body-2">You don't have QR code payment setup yet. <span @click="handleOpenQrCodeModal" class="text-[#1677FF] cursor-pointer">Set it up now</span> </p>
                    </div>

                    <ToggleSlider :isChecked="payments?.qrCode?.status" @on:change="updatePaymentStatus($event, 'qrCode', updatePaymentsHandler)" />

                    <AppModal headerContent="QR Code Payment Setup" :openModal="isQrCodeModalOpen" @click="handleOpenQrCodeModal" :defaultActionButton="true">
                        <div class="px-[28px] pt-[32px]">
                            <div class="">
                                <div class="font-semibold text-body-1">How it works:</div>
                                <p class="text-body-2 text-grey-200 mt-[8px]">Customers will be able to make payments by scanning a QR code displayed on your register App. This QR code will be generated automatically for each transaction and processed through the restaurant portal.</p>
                            </div>

                            <div class="mt-[32px]">
                                <div class="font-semibold text-body-1">Charges Applied:</div>
                                <p class="text-body-2 text-grey-200 mt-[8px]">All transactions processed through QR code payments will incur a convenience fee of 0.002% per transaction.</p>
                            </div>

                            <div class="mt-[32px]">
                                <div class="font-semibold text-body-1">Terms and Conditions:</div>
                                <p class="text-body-2 text-grey-200 mt-[8px]">By enabling QR code payments on your restaurant portal, you agree to apply the specified convenience fee to all transactions processed through QR code payments.</p>
                            </div>
                        </div>
                    </AppModal>
                </div>
            </main>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import ToggleSlider from '@/components/shared/toggle-slider/ToggleSlider.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';
import { usePaymentHelper } from './Payment.helper';
import { useStore } from 'vuex';

const isCashPaymentModalOpen = ref(false)
const isDebitCardModalOpen = ref(false)
const isOpenBankModalOpen = ref(false)
const isQrCodeModalOpen = ref(false)
const loading = ref(false)
const store = useStore()

const { payments, updatePaymentStatus, updatePaymentsHandler, addDebitDevice, handlePaymentValueChange, addBankAccount, deleteFromPaymentInputList, banksDetailsData } = usePaymentHelper(store);


const handleOpenCashPaymentModal = () => {
    isCashPaymentModalOpen.value = !isCashPaymentModalOpen.value;
};

const handleOpenDebitCardModal = () => {
    isDebitCardModalOpen.value = !isDebitCardModalOpen.value;
};

const handleOpenBankTransferModal = () => {
    isOpenBankModalOpen.value = !isOpenBankModalOpen.value;
};

const handleOpenQrCodeModal = () => {
    isQrCodeModalOpen.value = !isQrCodeModalOpen.value;
};

const handlePaymentSubmit = async (event: Event, paymentType: string, callback?: () => void) => {
    loading.value = true
    await updatePaymentsHandler.handleSubmit(event, paymentType)
    loading.value = false
    callback?.()
}

</script>

<style scoped lang="scss">
.debit-id {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
}

.bank-transfer-input {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 24px;
    align-items: center;
}
</style>