import { ref, watchEffect } from "vue";
import { sortBy } from "lodash";
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue'
import InvoiceService from "@/utility/services/invoice.service";


export const getInvoices = async({status = "", search="", since=0}) => {
    try {
        const response = await InvoiceService.getInvoiceRequest({ status, search, since });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const getInvoiceById = async({ invoiceId = "" }) => {
    try {
        const response = await InvoiceService.getInvoiceByIdRequest({ invoiceId });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export interface ActiveTab {
    status: string;
    count: number;
}

export interface SearchOptions {
    status: string;
    search: string;
    since: number;
}

const searchOptionsData = ref<SearchOptions>({
    status: '',
    search: '',
    since: 0
});

export interface InvoiceDetails {
    invoiceNumber: string | number | undefined;
    invoiceDate: string;
    amount: string;
    status: string;
    serviceFee: string;
    restaurantStoreName: string
    invoiceNotes: string
}

export const useInvoicesHelper = () => {

    const invoiceTabs = ref<any>([]);
    const search = ref();
    const activeTab: any = ref<ActiveTab>({
        status: "All",
        count: 0
    });
    const orderDetailsData = ref<InvoiceDetails>({
        invoiceNumber: '',
        invoiceDate: '',
        status: '',
        amount: '',
        serviceFee: '',
        restaurantStoreName: '',
        invoiceNotes: ''
    })
    const invoiceDetailsData = ref<InvoiceDetails>({
        invoiceNumber: '',
        invoiceDate: '',
        status: '',
        amount: '',
        serviceFee: '',
        restaurantStoreName: '',
        invoiceNotes: ''
    })

    const invoiceData = ref([]);

    const orderData = ref([
        { invoice: "INV-000032", date: "Aug 21, 2024 3:25 PM", service_fee: '₦3.02', paid: '₦1,250', status: 'Cancelled' },
        { invoice: "INV-000033", date: "Aug 21, 2024 3:25 PM", service_fee: '₦3.02', paid: '₦1,250', status: 'Cancelled' },
        { invoice: "INV-000034", date: "Sep 10, 2024 11:45 AM", service_fee: '₦3.02', paid: '₦980', status: 'Completed' },
        { invoice: "INV-000035", date: "Oct 5, 2024 6:30 PM",  service_fee: '₦3.02', paid: '₦1,750', status: 'Pending' },
        { invoice: "INV-000036", date: "Nov 15, 2024 9:15 AM", service_fee: '₦3.02', paid: '₦2,300', status: 'Completed' },
        { invoice: "INV-000037", date: "Dec 3, 2024 4:50 PM",  service_fee: '₦3.02', paid: '₦1,150', status: 'Cancelled' },
        { invoice: "INV-000038", date: "Jan 8, 2025 1:20 PM",  service_fee: '₦3.02', paid: '₦1,800', status: 'Completed' },
        { invoice: "INV-000039", date: "Feb 19, 2025 10:55 AM", service_fee: '₦3.02', paid: '₦2,050', status: 'Pending' },
        { invoice: "INV-000040", date: "Mar 7, 2025 8:40 PM",  service_fee: '₦3.02', paid: '₦1,500', status: 'Completed' },
        { invoice: "INV-000041", date: "Apr 25, 2025 5:05 PM", service_fee: '₦3.02', paid: '₦1,900', status: 'Cancelled' },
        { invoice: "INV-000042", date: "May 14, 2025 2:30 PM", service_fee: '₦3.02', paid: '₦2,200', status: 'Pending' }
    ]);

    const orderHeaders = ref([
        { name: "Invoice Number", value: "invoiceNumber" },
        { name: "Invoice Date", value: "invoiceDate" },
        { name: "Status", value: "statusCard" },
        { name: "Amount Paid", icon: 'ant-design:sort-ascending-outlined', value: "amount" },
        { name: "Service Fee", value: "serviceFee" },
    ]);

    const detailsHeader = ref([
        { name: "Items", value: "menuName" },
        { name: "Type", value: "type" },
        { name: "Category", value: "category" },
        { name: "Quantity", value: "quantity" },
        { name: "Price", value: "price" },
    ]);

    const detailsData = ref([
        { items: "Jollof Rice", type: "Size", category: '₦3.02', quantity: '2', price: 'N50.00' },
        { items: "Sweet Corn", type: "Size", category: '₦3.02', quantity: '10', price: 'N50.00' },
        { items: "Chicken Beaf", type: "Size", category: '₦3.02', quantity: '8', price: 'N50.00' },
        { items: "Locawaz", type: "Size",  category: '₦3.02', quantity: '16', price: 'N50.00' },
        { items: "Stick Stacks", type: "Size", category: '₦3.02', quantity: '1', price: 'N50.00' },
    ]);

    const onSearch = (event: any) => {
        searchOptionsData.value.search = event.target.value;
    };

    const onChangeData = (event: any) => {
        searchOptionsData.value.since = event.target.value;
    };

    const activateTab = (tab: ActiveTab) => {
        activeTab.value = tab;
        searchOptionsData.value.status = tab.status;
    };

    const searchDateOptions = ref( [
        { value: 0, name: ''},
        { value: 30, name: 'Last 30 days'},
        { value: 60, name: 'Last 60 days'},
        { value: 80, name: 'Last 80 days'}
    ]);

    watchEffect(() => {
        getInvoices({
            status: searchOptionsData.value.status, 
            search: searchOptionsData.value.search,
            since: searchOptionsData.value.since
        }).then((responseData) => {
            const { total, invoices, invoiceByStatus } = responseData;
            invoiceByStatus.push({ status: "All", count: total });
            invoiceData.value = invoices;
            invoiceTabs.value = sortBy<ActiveTab>(invoiceByStatus, ['status']);
        });
    });

    return {
        activateTab, 
        activeTab, 
        AppWrapper,
        invoiceTabs,
        onChangeData,
        onSearch,
        search,
        searchDateOptions,
        invoiceData: invoiceData,
        invoiceHeaders: orderHeaders.value,
        orderDetailsData,
        invoiceDetailsData,
        detailsHeader,
        detailsData
    }
}