import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';
import { getAccountProfile } from '@/views/settings/Settings.helper';

export interface ProfileState {
  profileData: any; 
  loading: boolean;
}

@Module({ namespaced: true })
export default class ProfileModule extends VuexModule implements ProfileState {
  profileData = null;
  loading = true;

  @MutationAction
  async fetchProfileData() {
    try {
      const responseData = await getAccountProfile();
      return {
        profileData: responseData,
        loading: false
      };
    } catch (error) {
      console.error('Error fetching profile data:', error);
      return {
        profileData: null,
        loading: false
      };
    }
  }
}
