<template>
    <header class="bg-primary-300 py-[40px] px-[34px] fixed w-[100%] top-0 flex gap-[48px] items-center">
        <div class="text-white flex gap-[10px] cursor-pointer" @click="router.back">
            <Icon icon="material-symbols:arrow-left-alt" width="24" height="24"/>
            <div>Go back</div>
        </div>
        <div class="bg-[#0000001F] flex items-center w-[fit-content] rounded-[8px] p-[2px] text-body-2">
            <div class="bg-white px-[24px] py-[8px] rounded-[8px]">Stores Management</div>
            <!-- <div class=" px-[24px] py-[8px] text-white">Report and Analysis</div> -->
        </div>
    </header>

    <CreateStoreForm :isOpen="isOpen" :handleOpenEditModal="handleOpenEditModal" :createStoreFormHandler="createStoreHandler" />

    <main class="px-[32px] mt-[180px]">
        <div class="flex items-center justify-between mt-[36px] mb-[32px]">
            <div class="font-semibold text-heading-3">Menu</div>
            <div class="flex items-center gap-[12px]">
                <InputField type="text" placeholder="Search" prefix-icon="ant-design:search-outlined" @change="() => {}" />
                <ActionButton 
                    :handleClick="() => handleOpenEditModal()"
                    theme="primary"
                    label="New Store"
                    class="w-[fit-content] m-[auto]"
                    prefixIcon="ant-design:plus-outlined"
                />
            </div>
        </div>

        <div class="">
            <TableField
                :headers="storesHeader"
                :dataItems="storesData() || []"
                :isPagination="true"
                :viewDetails="true"
                viewDetailsText="Visit Store"
                @handleViewDetailsClick="(data) => selectStore(data?.id)"
            />
        </div>
    </main>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import TableField from '@/components/shared/table/TableField.vue';
import { useStoreManagementHelper } from './StoreManagement.helper';
import { ref } from 'vue';
import CreateStoreForm from './CreateStoreForm.vue';
import { Icon } from '@iconify/vue';
import { useRouter } from 'vue-router';

const { storesHeader, storesData, createStoreHandler, selectStore } = useStoreManagementHelper()
const isOpen = ref(false);
const router = useRouter()

const handleOpenEditModal = () => {
    isOpen.value = !isOpen.value;
};

</script>

<style scoped>

</style>