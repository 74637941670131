import Chart, { ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';


export const doughnutChartHelper = ( doughnutChartData: any  ) => {
   
    let _doughnutChart = null;
    const config: ChartConfiguration<keyof ChartTypeRegistry, number[], string>  = {
        type: 'doughnut',
        data: doughnutChartData,
        options: {
            plugins: {
                legend: {
                    display: false
                }
            }
        }
    };

    const canvas = document.getElementById('doughnutChart') as HTMLCanvasElement;
    if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
            _doughnutChart = new Chart(ctx, config);
        }
    }

    return { doughnutChart: _doughnutChart, doughnutChartData }
}