<template>
    <AppWrapper activeTab="Orders">
        <div class="lg:py-[72px] py-[20px] lg:w-[800px] lg:m-[auto]">
            <div class="flex items-center gap-[6px] lg:text-body-1 text-body-2">
                <div class="flex items-center gap-[6px] text-grey-100 cursor-pointer" @click="router.back()">
                    <Icon icon="ant-design:shopping-outlined" />
                    <div>Orders</div>
                </div>
                <div>/</div>
                <div>{{ orderDetailData.orderNumber }}</div>
            </div>

            <div class="border-[1px] border-solid border-grey-50 rounded-[8px] lg:mt-[32px] mt-[12px]">
                <header class="flex items-center gap-[26px] lg:py-[24px] lg:px-[32px] p-[10px]">
                    <div class="">
                        <div class="order-image rounded-[8px]" :style="{ backgroundImage: `url(${orderDetailData?.imageUrl})`}"></div>
                    </div>
                    <div class="">
                        <div class="">
                            <StatusLabel :status="orderDetailData.status" />
                        </div>
                        <div class="font-semibold lg:text-heading-5 text-body-2 mt-[6px]">Order {{ orderDetailData.orderNumber }}</div>
                        <div class="lg:text-body-2 text-caption text-grey-100">Ordered on {{ orderDetailData.orderDate }}</div>
                    </div>
                </header>

                <div v-if="orderDetailData.status === 'Cancelled'" class="lg:text-body-2 text-caption p-[10px]">
                    Cancelled reason: {{orderDetailData?.cancelReason}}
                </div>
                <main class="">
                    <section class="">
                        <header class="flex items-center justify-between lg:py-[18px] lg:px-[24px] px-[10px] py-[14px] bg-bg-200 border-b-[1px] border-solid border-grey-50">
                            <div class="flex items-center gap-[10px] text-grey-200">
                                <div class=""><Icon icon="ant-design:shopping-cart-outlined" class="lg:w-[24px] lg:h-[24px]" /></div>
                                <div class="lg:text-body-1 text-body-2">Order Information</div>
                            </div>
                            <div class="text-grey-200 cursor-pointer" @click="handleShowOrderInformation"><Icon :icon="showOrderInfo ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="lg:w-[24px] lg:h-[24px]" /></div>
                        </header>
                        <main class="">
                            <div v-show="showOrderInfo" class="grid grid-cols-2 lg:px-[24px] px-[10px] py-[28px]">
                                <div class="">
                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Order Date</div>
                                        <div class="font-medium tlg:ext-body-1  text-caption mt-[4px]">{{ orderDetailData.orderDate }}</div>
                                    </div>

                                    <div class="mt-[24px]">
                                        <div class="text-grey-200 text-caption font-medium">Payment Option</div>
                                        <div class="font-medium lg:text-body-1 text-caption mt-[4px]">{{ orderDetailData.paymentType }}</div>
                                    </div>

                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium mt-[24px]">Processed By</div>
                                        <div class="font-medium lg:text-body-1 text-caption mt-[4px]">{{ orderDetailData?.cashier?.firstName }} {{ orderDetailData?.cashier?.lastName }}</div>
                                    </div>
                                </div>

                                <div class="">
                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Total Price</div>
                                        <div class="font-medium lg:text-body-1 text-caption mt-[4px]">{{ orderDetailData.totalPrice }}</div>
                                    </div>

                                    <div class="mt-[24px]">
                                        <div class="text-grey-200 text-caption font-medium">Order Channel</div>
                                        <div class="font-medium lg:text-body-1 text-caption mt-[4px]">{{ orderDetailData?.orderChannel }}</div>
                                    </div>

                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium mt-[24px]">Customer’s Name</div>
                                        <div class="font-medium lg:text-body-1 text-caption mt-[4px]">{{ orderDetailData?.customerFirstName }} {{ orderDetailData?.customerLastName }} ({{orderDetailData.customerPhone}})</div>
                                    </div>
                                </div>
                            </div>

                           
                            <div class="border-t-[1px] border-solid border-grey-50 lg:px-[32px] px-[10px] pb-[20px]">
                                <div class="flex gap-[32px] border-b-[1px] border-solid lg:mt-[24px]">
                                    <div
                                        v-for="(tab, index) in orderInformationTabs"
                                        :key="index"
                                        :class="['py-[12px] cursor-pointer lg:text-body-2 text-caption text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                                        @click="activateTab(index)"
                                    > {{ tab }} </div>
                                </div>

                                <div v-if="activeTab === 0">
                                    <div class="text-grey-200 lg:text-body-2 text-caption font-medium mt-[24px]">{{ orderDetailData?.orderDetails?.length }} product from Store</div>
                                    <div v-for="(item) in  orderDetailData?.orderDetails" 
                                        :key="item?.menuId"
                                        class="mt-[24px]">
                                        <div class="text-grey-300 lg:text-body-1 text-body-2 font-medium flex justify-between items-center">
                                            <div class="flex lg:gap-[24px] gap-[12px]">
                                                <div class="">{{ item.quantity }}x</div>
                                                <div class="lg:text-body-1 text-caption">{{ item.menuName }}</div>
                                            </div>
                                            <div class="lg:text-body-1 text-caption">{{ item.formatedPrice }}</div>
                                        </div>
                                        <div class="text-grey-200 lg:text-body-2 text-caption font-medium mt-[4px] lg:ml-[41px] ml-[29px]">{{ item.category }} | {{ item.name }}</div>
                                    </div>
                                </div>
                                <!-- <div v-else>
                                    delivery info
                                </div> -->
                            </div>
                            <div class="">
                                <div class="bg-bg-200 p-[32px] border-t-[3px] border-grey-50 border-dashed">
                                    <div class="font-semibold lg:text-heading-4 ">Summary</div>

                                    <div class="flex items-center justify-between mt-[10px]">
                                        <div class="lg:text-body-1 text-body-2 text-grey-200">Product Subtotal</div>
                                        <div class="lg:text-body-1 text-body-2 text-grey-300 font-medium">{{orderDetailData.subTotalPrice}}</div>
                                    </div>

                                    <div  v-for="(serviceFee) in orderDetailData?.serviceFees"
                                        :key="serviceFee?.name"
                                        class="flex items-center justify-between mt-[10px]">
                                        <div class="lg:text-body-1 text-caption text-grey-200">{{ serviceFee.name }}</div>
                                        <div class="text-body-1 text-grey-300 font-medium">{{ serviceFee.formattedValue }}</div>
                                    </div>
                                    
                                    <div class="flex items-center justify-between mt-[10px]">
                                        <div class="lg:text-body-1 text-body-2 text-grey-200">Total:</div>
                                        <div class="lg:text-body-1 text-body-2 text-grey-300 font-medium">{{orderDetailData.totalPrice}}</div>
                                    </div>
                                </div>

                                <!-- <div class="flex items-center justify-between border-t-[1px] border-solid border-grey-50 py-[20px] px-[32px]">
                                    <div class="text-body-2 font-medium text-grey-200">Order 1 of 84</div>
                                    <div class="flex items-center gap-[10px] font-medium border-[2px] border-solid border-grey-50 px-[16px] py-[11px] text-grey-200 rounded-[8px]">
                                        <div class="text-body-2">Next Order</div>
                                        <div class=""><Icon icon="ant-design:arrow-right-outlined" class="w-[24px] h-[24px]" /></div>
                                    </div>
                                </div> -->
                            </div>
                        </main>
                    </section>
                </main>
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useOrderHelper, getOrderById, OrderDetail, orderDetailInitialValues } from './Order.helper';
import { defineProps, watchEffect, ref } from 'vue'
import { Icon } from '@iconify/vue';
import StatusLabel from '@/components/shared/status-label/StatusLabel.vue'

const { AppWrapper, orderInformationTabs } = useOrderHelper()
const router = useRouter()

interface Props {
    id: string
}

const orderDetailData= ref<OrderDetail>({...orderDetailInitialValues});
const activeTab = ref(0);
const props = defineProps<Props>()
const showOrderInfo = ref(true)

const activateTab = (tab: number) => {
    activeTab.value = tab;
};

const handleShowOrderInformation = () => {
    showOrderInfo.value = !showOrderInfo.value
}

watchEffect(() => {
    getOrderById({ orderId: props.id}).then((responseData)=> {
        if (responseData) {
            orderDetailData.value = responseData as OrderDetail;
        }
    });
});

</script>

<style scoped>
.order-image {
    width: 92px;
    height: 92px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>