import { createStore } from "@/views/store-management-analysis/StoreManagement.helper"
import { useForm } from "./useForm"

export const useStoreFormHadler = () => {

    const createStoreHandler = useForm({
        initialValues: {},

        onSubmit: async (values: any, dataProps: any) => {
            console.log(values, dataProps.value)
            try {
                await createStore(values, dataProps.value.store).then(() => {
                    dataProps.value.callback?.();
                })
            } catch (error) {
                console.error(error)
            }
        }
    })

    return {createStoreHandler}
} 