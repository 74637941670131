<template>
    <div class="flex items-center gap-[16px] lg:text-body-2 text-overline w-[fit-content] m-[auto] my-[16px] py-[16px]">
        <div class="mr-[16px]">Total {{ props.paginationMode === 'server' ? props.totalItems : props.totalItems.length }} items</div>

        <Icon icon="ant-design:left-outlined" @click="prevPage" :disabled="currentPage === 1" class="mr-[8px]" />

        <template v-for="pageNumber in totalPages" :key="pageNumber">
            <div @click="gotoPage(pageNumber)" class="cursor-pointer" :class="{ 'border-[1px] border-solid border-primary-300 text-primary-300 px-[10px] py-[3px] rounded-[8px]': pageNumber === currentPage }">{{ pageNumber }}</div>
        </template>

        <Icon icon="ant-design:right-outlined" @click="nextPage" :disabled="currentPage === totalPages" class="ml-[8px]" />
        
        <select v-model="pageSize" @change="handlePageSizeChange" class="ml-[16px] bg-transparent outline-none">
            <option v-for="option in pageSizeOptions" :key="option" :value="option">{{ option }} / pages</option>
        </select>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, defineEmits, defineProps } from 'vue';
import { Icon } from '@iconify/vue';

interface Props {
    totalItems: any
    paginationMode: 'server' | 'client'
}

const props = defineProps<Props>()

const currentPage = ref(1);
const pageSize = ref(10);
const pageSizeOptions = [5, 10, 25, 50]; 
const totalPages = computed(() => Math.ceil((props.paginationMode === 'server' ? props.totalItems : props.totalItems.length) / pageSize.value));
const emit = defineEmits(['pageChange', 'pageSizeChange']);

const prevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        emitPageChange();
    }
};

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        emitPageChange();
    }
};

const gotoPage = (pageNumber: number) => {
    currentPage.value = pageNumber;
    emitPageChange();
};

const handlePageSizeChange = () => {
    currentPage.value = 1;
    emit('pageSizeChange', pageSize.value); 
    emitPageChange(); 
};

const emitPageChange = () => {
    emit('pageChange', currentPage.value);
};

</script>

<!-- 
<template>
    <div class="flex items-center gap-[16px] lg:text-body-2 text-overline w-[fit-content] m-[auto] my-[16px] py-[16px]">
        <div class="mr-[16px]">Total {{ totalItems }} items</div>

        <Icon icon="ant-design:left-outlined" @click="prevPage" :disabled="currentPage === 1" class="mr-[8px]" />

        <template v-for="pageNumber in totalPages" :key="pageNumber">
            <div @click="gotoPage(pageNumber)" class="cursor-pointer" :class="{ 'border-[1px] border-solid border-primary-300 text-primary-300 px-[10px] py-[3px] rounded-[8px]': pageNumber === currentPage }">{{ pageNumber }}</div>
        </template>

        <Icon icon="ant-design:right-outlined" @click="nextPage" :disabled="currentPage === totalPages" class="ml-[8px]" />
        
        <select v-model="pageSize" @change="handlePageSizeChange" class="ml-[16px] bg-transparent outline-none">
            <option v-for="option in pageSizeOptions" :key="option" :value="option">{{ option }} / pages</option>
        </select>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, watchEffect } from 'vue';
import { Icon } from '@iconify/vue';
import { getPagination } from './TablePagination.helper'

interface Props {
    totalItems: number
}

const props = defineProps<Props>()

const emit = defineEmits(['pageChange', 'pageSizeChange']);

const { 
    gotoPage, 
    handlePageSizeChange, 
    nextPage, 
    pageSizeOptions, 
    prevPage, 
    currentPage,
    pageSize,
    totalPages
} = getPagination({ totalItems: props.totalItems, emit })

watchEffect(() => {
    console.log(currentPage.value, "currentPage")
})

</script>

<style scoped>

</style> -->

