<template>
    <div>
        <AppModal headerContent="Create New Store" :openModal="isOpen" @close="handleOpenEditModal">
            <div class="">
                <form @submit.prevent="handleSubmitCreateStore">
                    <div class="p-[24px]">
                        <div class="grid grid-cols-2 gap-[20px] mt-[8px]">
                            <InputField
                                label="Store Name" 
                                type="text" 
                                name="name"
                                placeholder="Store Name" 
                                :value="createStoreFormHandler?.values?.name"
                                @update:blur:value="createStoreFormHandler.handleBlur"
                                @update:value="createStoreFormHandler.handleChange"
                            />
                            <InputField 
                                label="Email" 
                                type="email" 
                                placeholder="Email"
                                name="email"
                                :value="createStoreFormHandler?.values?.email"
                                @update:blur:value="createStoreFormHandler.handleBlur"
                                @update:value="createStoreFormHandler.handleChange"
                            />
                            <InputField 
                                label="Phone Number" 
                                type="tel" 
                                placeholder="080 123 456" 
                                name="phone"
                                :value="createStoreFormHandler?.values?.phone"
                                @update:blur:value="createStoreFormHandler.handleBlur"
                                @update:value="createStoreFormHandler.handleChange"
                            />
                            <InputField 
                                label="Established Date" 
                                type="date"
                                name="establishDate"
                                placeholder="Established Date" 
                                :value="createStoreFormHandler?.values?.establishDate"
                                @update:blur:value="createStoreFormHandler.handleBlur"
                                @update:value="createStoreFormHandler.handleChange"
                            />

                            <InputField 
                                label="State" 
                                type="text" 
                                name="address.state"
                                placeholder="" 
                                :value="createStoreFormHandler?.values?.address?.state"
                                @update:blur:value="createStoreFormHandler.handleBlur"
                                @update:value="createStoreFormHandler.handleChange"
                            />

                            <InputField 
                                label="LGA" 
                                type="text" 
                                placeholder="" 
                                name="address.lga"
                                :value="createStoreFormHandler?.values?.address?.lga"
                                @update:blur:value="createStoreFormHandler.handleBlur"
                                @update:value="createStoreFormHandler.handleChange"
                            />
                        </div>

                        <InputField 
                            label="Street Address" 
                            type="text" 
                            placeholder="Street Address"
                            name="address.street" 
                            class="mt-[32px]" 
                            :value="createStoreFormHandler?.values?.address?.street"
                            @update:blur:value="createStoreFormHandler.handleBlur"
                            @update:value="createStoreFormHandler.handleChange"
                        />

                        <div class="mt-[32px]">
                            <DropdownField
                                label="Business Status" 
                                name="businessStatus"
                                :value="createStoreFormHandler?.values?.businessStatus" 
                                :optionsData="businessStatusOptions"
                                @change="createStoreFormHandler.handleChange"
                            />
                        </div>

                        <InputField 
                            label="Restaurant Type" 
                            name="restaurantType" 
                            type="text" 
                            placeholder="Enter Restaurant Type" 
                            class="mt-[32px]" 
                            @keydown.enter.prevent="(event: Event) => addToInputList(event, createStoreFormHandler?.values, createStoreFormHandler)"
                            undertext='Tap "Enter Key" to add more restaurant type' 
                        />

                        <div class="flex items-center gap-[10px] mt-[8px]">
                            <div class="py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption" v-for="(rt, idx) in createStoreFormHandler?.values?.restaurantType" :key="idx">
                                <div class="">{{ rt }}</div>
                                <Icon icon="ant-design:close-outlined" width="12" height="12" @click="() => deleteFromInputList('restaurantType', idx, createStoreFormHandler?.values, createStoreFormHandler)" />
                            </div>
                        </div>

                        <!-- <InputField 
                            label="Service Operations" 
                            name="serviceOptions" 
                            type="text" 
                            placeholder="Enter Service Operations" 
                            class="mt-[32px]" 
                            @keydown.enter.prevent="(event: Event) => addToInputList(event, createStoreFormHandler?.values, createStoreFormHandler)"
                            undertext='Tap "Enter Key" to add more service options' 
                        />

                        <div class="flex items-center gap-[10px] mt-[8px]">
                            <div class="py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption" v-for="(so, idx) in createStoreFormHandler?.values.serviceOptions" :key="idx">
                                <div class="">{{ so }}</div>
                                <Icon icon="ant-design:close-outlined" width="12" height="12" @click="() => deleteFromInputList('serviceOptions', idx, createStoreFormHandler?.values, createStoreFormHandler)" />
                            </div>
                        </div>

                        <div class="grid grid-cols-2 gap-[20px] mt-[32px]">
                            <InputField 
                                label="Website" 
                                type="text" 
                                placeholder="Enter website Url" 
                                name="website"
                                :value="createStoreFormHandler?.values.website"
                                @update:blur:value="createStoreFormHandler.handleBlur"
                                @update:value="createStoreFormHandler.handleChange"
                            />

                            <InputField 
                                label="CAC" 
                                type="text" 
                                name="cacRegistrationNumber"
                                placeholder="Enter CAC"
                                :value="createStoreFormHandler?.values.cacRegistrationNumber"
                                @update:blur:value="createStoreFormHandler.handleBlur"
                                @update:value="createStoreFormHandler.handleChange"
                            />
                        </div> -->
                    </div>

                    <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                        <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenEditModal" />
                        <ActionButton
                            theme="primary" 
                            class="w-[fit-content]" 
                            :isSubmit="true" 
                            :disable="loading && true" 
                            :label="loading ? 'Loading...' : 'Save'" 
                        />
                    </footer>
                </form>
            </div>
        </AppModal>
    </div>
</template>

<script setup lang="ts">
import {  defineProps, ref } from 'vue'
import { addToInputList, deleteFromInputList } from '@/utility/helper/utils.helper';
import { useSettingsHelper } from '../settings/Settings.helper';
import AppModal from '@/components/shared/modal/AppModal.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import { Icon } from '@iconify/vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { useStore } from 'vuex';

interface Props {
    createStoreFormHandler: any
    isOpen: boolean
    handleOpenEditModal: () => void
}

const props = defineProps<Props>()

const { businessStatusOptions } = useSettingsHelper()
const loading = ref(false)
const store = useStore()

const dataProp = ref({
    callback: props.handleOpenEditModal,
    store: store
})

const  handleSubmitCreateStore = async (event: Event) => {
    loading.value = true
    await props.createStoreFormHandler?.handleSubmit(event, dataProp)
    loading.value = false
}
</script>
