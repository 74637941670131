import { handleLogin, handleLogout, handleResetPassword, handleRestNewPassword } from "@/views/auth-view/auth.helper";
import { useForm } from "./useForm";
import * as yup from 'yup';
import { useRouter } from "vue-router";
import { Store } from "vuex";

export const useAuthSession = (store?: Store<Record<string | number, unknown>> | any, callback?: any) => {
    const router = useRouter();

    const loginFormHandler = useForm({
        initialValues: {
            email: "",
            password: ""
        },

        validationSchema: yup.object().shape({
            email: yup.string().required('Email is required').email("Invalid email"),
            password: yup.string().required("This must be at least 8 characters").min(8, "This must be at least 8 characters")
        }),

        onSubmit: async (values: any) => {
            try {
                await handleLogin(store, values.email, values.password, () => router.push({name: "DashBoard"}))
            } catch (error) {
                console.error("Login error:", error);
            }
        }
    })

    const resetPasswordHandler = useForm({
        initialValues: { email: "" },

        validationSchema: yup.object().shape({
            email: yup.string().required('Email is required').email('Invalid email address')
        }),
        
        onSubmit: async (value: any) => {
            try {
                await handleResetPassword(value.email)
            } catch (error) {
                console.log(error);
            }
        } 
    })

    const resetNewPasswordFormHandler = useForm({
        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
    
        validationSchema: yup.object().shape({
            newPassword: yup.string().required("New password is required").min(8, "Password must be at least 8 characters"),
            confirmPassword: yup.string()
                .test('passwords-match', 'Passwords must match', function(value) {
                    const newPassword = this.resolve(yup.ref('newPassword'));
                    return !newPassword || newPassword === value;
                })
                .required('Password confirmation is required')
        }),
    
    
        onSubmit: async (values: any) => {

            try {
                await handleRestNewPassword(store, values.newPassword, callback).then((res) => {
                    if (res == undefined) return;
                    else callback?.();
                })
            } catch (error) {
                console.error("update account profile error:", error);
            }
        }
    })

    const logoutSession = async () => {
        try {
            await handleLogout(() => router.push({name: "Login"}))
        } catch (error) {
            console.error("logout error:", error);
        }
    }

    return { loginFormHandler, logoutSession, resetPasswordHandler, resetNewPasswordFormHandler };
}