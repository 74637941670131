import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-[20px]" }
const _hoisted_3 = { class: "flex justify-between border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]" }
const _hoisted_4 = { class: "px-[28px] pt-[32px]" }
const _hoisted_5 = { class: "grid grid-cols-1 gap-[32px] mt-[32px]" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }
const _hoisted_8 = { class: "flex justify-between border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]" }
const _hoisted_9 = { class: "px-[28px] pt-[32px]" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px]" }
const _hoisted_12 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }
const _hoisted_13 = { class: "flex justify-between border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]" }
const _hoisted_14 = { class: "px-[28px] pt-[32px]" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "grid grid-cols-2 gap-[12px]" }
const _hoisted_17 = { class: "mt-[14px]" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px]" }
const _hoisted_20 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }
const _hoisted_21 = { class: "flex justify-between border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]" }

import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import ToggleSlider from '@/components/shared/toggle-slider/ToggleSlider.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';
import { usePaymentHelper } from './Payment.helper';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentView',
  setup(__props) {

const isCashPaymentModalOpen = ref(false)
const isDebitCardModalOpen = ref(false)
const isOpenBankModalOpen = ref(false)
const isQrCodeModalOpen = ref(false)
const loading = ref(false)
const store = useStore()

const { payments, updatePaymentStatus, updatePaymentsHandler, addDebitDevice, handlePaymentValueChange, addBankAccount, deleteFromPaymentInputList, banksDetailsData } = usePaymentHelper(store);


const handleOpenCashPaymentModal = () => {
    isCashPaymentModalOpen.value = !isCashPaymentModalOpen.value;
};

const handleOpenDebitCardModal = () => {
    isDebitCardModalOpen.value = !isDebitCardModalOpen.value;
};

const handleOpenBankTransferModal = () => {
    isOpenBankModalOpen.value = !isOpenBankModalOpen.value;
};

const handleOpenQrCodeModal = () => {
    isQrCodeModalOpen.value = !isQrCodeModalOpen.value;
};

const handlePaymentSubmit = async (event: Event, paymentType: string, callback?: () => void) => {
    loading.value = true
    await updatePaymentsHandler.handleSubmit(event, paymentType)
    loading.value = false
    callback?.()
}


return (_ctx: any,_cache: any) => {
  const _component_CheckboxInput = _resolveComponent("CheckboxInput")!

  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "Payments" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[21] || (_cache[21] = _createElementVNode("header", { class: "pt-[72px] pb-[42px] flex justify-between items-center" }, [
          _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "text-heading-3 font-semibold text-grey-300" }, "Payments"),
            _createElementVNode("div", { class: "text-body-2 text-grey-200" }, "View and manage various payment methods in your register app.")
          ])
        ], -1)),
        _createElementVNode("main", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", { class: "" }, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-body-1 font-semibold" }, "Cash Payment", -1)),
              _createElementVNode("p", { class: "text-grey-200 text-body-2" }, [
                _cache[9] || (_cache[9] = _createTextVNode("You don't have cash payment setup yet. ")),
                _createElementVNode("span", {
                  onClick: handleOpenCashPaymentModal,
                  class: "text-[#1677FF] cursor-pointer"
                }, "Set it up now")
              ])
            ]),
            _createVNode(ToggleSlider, {
              name: "status",
              isChecked: _unref(payments)?.cash?.status,
              "onOn:change": _cache[0] || (_cache[0] = ($event: any) => (_unref(updatePaymentStatus)($event, 'cash', _unref(updatePaymentsHandler))))
            }, null, 8, ["isChecked"]),
            _createVNode(AppModal, {
              headerContent: "Cash Payment setup",
              openModal: isCashPaymentModalOpen.value,
              onClose: handleOpenCashPaymentModal
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _cache[1] || (_cache[1] = _withModifiers((event) => handlePaymentSubmit(event, 'cash', handleOpenCashPaymentModal), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _cache[11] || (_cache[11] = _createElementVNode("header", { class: "text-body-1 text-grey-200" }, "Please add your store's cash payment configurations.", -1)),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(DropdownField, {
                          label: "Currency",
                          name: "currency",
                          optionsData: [{name: 'Naira (₦)', value: 'NGN'}],
                          value: _unref(payments)?.cash?.currency,
                          onChange: _unref(updatePaymentsHandler).handleChange
                        }, null, 8, ["value", "onChange"])
                      ]),
                      _createVNode(_component_CheckboxInput, { label: "Receive email Notifications for Cash Transactions." })
                    ])
                  ]),
                  _createElementVNode("footer", _hoisted_7, [
                    _createVNode(ActionButton, {
                      label: "Cancel",
                      theme: "secondary",
                      class: "w-[fit-content]",
                      onClick: handleOpenCashPaymentModal
                    }),
                    _createVNode(ActionButton, {
                      theme: "primary",
                      class: "w-[fit-content]",
                      isSubmit: true,
                      disable: loading.value,
                      label: loading.value ? 'Loading...' : 'Save'
                    }, null, 8, ["disable", "label"])
                  ])
                ], 32)
              ]),
              _: 1
            }, 8, ["openModal"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", { class: "" }, [
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-body-1 font-semibold" }, "Debit Card Payment", -1)),
              _createElementVNode("p", { class: "text-grey-200 text-body-2" }, [
                _cache[12] || (_cache[12] = _createTextVNode("You don't have debit card payment setup yet. ")),
                _createElementVNode("span", {
                  onClick: handleOpenDebitCardModal,
                  class: "text-[#1677FF] cursor-pointer"
                }, "Set it up now")
              ])
            ]),
            _createVNode(ToggleSlider, {
              isChecked: _unref(payments)?.debit?.status,
              "onOn:change": _cache[2] || (_cache[2] = ($event: any) => (_unref(updatePaymentStatus)($event, 'debit', _unref(updatePaymentsHandler))))
            }, null, 8, ["isChecked"]),
            _createVNode(AppModal, {
              headerContent: "Debit Card Payment setup",
              openModal: isDebitCardModalOpen.value,
              onClose: handleOpenDebitCardModal
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _cache[4] || (_cache[4] = _withModifiers((event) => handlePaymentSubmit(event, 'debit', handleOpenDebitCardModal), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _cache[14] || (_cache[14] = _createElementVNode("header", { class: "text-body-1 text-grey-200" }, "Please provide a list of POS machine details for accepting debit card payments at your store.", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(payments)?.debit?.devices, (device, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "debit-id items-center gap-[12px] mt-[32px]",
                        key: idx
                      }, [
                        _createVNode(InputField, {
                          label: "Machine ID/Name *",
                          type: "text",
                          placeholder: "Machine ID/Name",
                          name: "machineName",
                          value: device?.machineName,
                          "onUpdate:blur:value": _unref(updatePaymentsHandler).handleBlur,
                          "onUpdate:value": ($event: any) => (_unref(handlePaymentValueChange)($event, idx, 'debit', 'devices'))
                        }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                        _createVNode(InputField, {
                          label: "Manufacturer *",
                          type: "text",
                          name: "machineId",
                          placeholder: "Manufacturer",
                          value: device?.machineId,
                          "onUpdate:blur:value": _unref(updatePaymentsHandler).handleBlur,
                          "onUpdate:value": ($event: any) => (_unref(handlePaymentValueChange)($event, idx, 'debit', 'devices'))
                        }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                        _createElementVNode("div", {
                          class: "bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content]",
                          onClick: ($event: any) => (_unref(deleteFromPaymentInputList)('debit', idx, _unref(payments), _unref(updatePaymentsHandler), 'devices'))
                        }, [
                          _createVNode(_unref(Icon), {
                            icon: "ant-design:delete-filled",
                            class: "text-primary-300 w-[24px] h-[24px]"
                          })
                        ], 8, _hoisted_10)
                      ]))
                    }), 128)),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(ActionButton, {
                        prefixIcon: "ant-design:plus-outline",
                        label: "Add Machine",
                        class: "w-[fit-content] m-[auto]",
                        theme: "outlined",
                        onClick: _cache[3] || (_cache[3] = () => _unref(addDebitDevice)())
                      }),
                      _createVNode(_component_CheckboxInput, { label: "Receive email Notifications for Cash Transactions." })
                    ])
                  ]),
                  _createElementVNode("footer", _hoisted_12, [
                    _createVNode(ActionButton, {
                      label: "Cancel",
                      theme: "secondary",
                      class: "w-[fit-content]",
                      onClick: handleOpenDebitCardModal
                    }),
                    _createVNode(ActionButton, {
                      theme: "primary",
                      class: "w-[fit-content]",
                      isSubmit: true,
                      disable: loading.value,
                      label: loading.value ? 'Loading...' : 'Save'
                    }, null, 8, ["disable", "label"])
                  ])
                ], 32)
              ]),
              _: 1
            }, 8, ["openModal"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", { class: "" }, [
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-body-1 font-semibold" }, "Bank Transfer payment", -1)),
              _createElementVNode("p", { class: "text-grey-200 text-body-2" }, [
                _cache[15] || (_cache[15] = _createTextVNode("You don't have Bank Transfer payment setup yet. ")),
                _createElementVNode("span", {
                  onClick: handleOpenBankTransferModal,
                  class: "text-[#1677FF] cursor-pointer"
                }, "Set it up now")
              ])
            ]),
            _createVNode(ToggleSlider, {
              isChecked: _unref(payments)?.bankTransfer?.status,
              "onOn:change": _cache[5] || (_cache[5] = ($event: any) => (_unref(updatePaymentStatus)($event, 'bankTransfer', _unref(updatePaymentsHandler))))
            }, null, 8, ["isChecked"]),
            _createVNode(AppModal, {
              headerContent: "Bank Transfer payment setup",
              openModal: isOpenBankModalOpen.value,
              onClose: handleOpenBankTransferModal
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _cache[7] || (_cache[7] = _withModifiers((event) => handlePaymentSubmit(event, 'bankTransfer', handleOpenBankTransferModal), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_14, [
                    _cache[17] || (_cache[17] = _createElementVNode("header", { class: "text-body-1 text-grey-200" }, "Please provide a list of bank details for accepting transfer payments at your store.", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(payments)?.bankTransfer?.accounts, (account, idx) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "bank-transfer-input mt-[32px]",
                        key: idx
                      }, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(InputField, {
                              label: "Bank Account Name *",
                              type: "text",
                              placeholder: "Account name",
                              name: "accountName",
                              value: account?.accountName,
                              "onUpdate:blur:value": _unref(updatePaymentsHandler).handleBlur,
                              "onUpdate:value": ($event: any) => (_unref(handlePaymentValueChange)($event, idx, 'bankTransfer', 'accounts'))
                            }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                            _createVNode(InputField, {
                              label: "Bank Account Number *",
                              type: "phone",
                              placeholder: "080 123 456",
                              value: account?.accountNumber,
                              name: "accountNumber",
                              "onUpdate:blur:value": _unref(updatePaymentsHandler).handleBlur,
                              "onUpdate:value": ($event: any) => (_unref(handlePaymentValueChange)($event, idx, 'bankTransfer', 'accounts'))
                            }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createVNode(DropdownField, {
                              label: "Select Bank",
                              name: "accountProvider",
                              value: account?.accountProvider,
                              optionsData: _unref(banksDetailsData),
                              onChange: ($event: any) => (_unref(handlePaymentValueChange)($event, idx, 'bankTransfer', 'accounts'))
                            }, null, 8, ["value", "optionsData", "onChange"])
                          ])
                        ]),
                        _createElementVNode("div", {
                          class: "bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content]",
                          onClick: ($event: any) => (_unref(deleteFromPaymentInputList)('bankTransfer', idx, _unref(payments), _unref(updatePaymentsHandler), 'accounts'))
                        }, [
                          _createVNode(_unref(Icon), {
                            icon: "ant-design:delete-filled",
                            class: "text-primary-300 w-[24px] h-[24px]"
                          })
                        ], 8, _hoisted_18)
                      ]))
                    }), 128)),
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(ActionButton, {
                        prefixIcon: "ant-design:plus-outline",
                        label: "Add Bank",
                        class: "w-[fit-content] m-[auto]",
                        theme: "outlined",
                        onClick: _cache[6] || (_cache[6] = () => _unref(addBankAccount)())
                      }),
                      _createVNode(_component_CheckboxInput, { label: "Receive email Notifications for Bank Transfer Transactions." })
                    ])
                  ]),
                  _createElementVNode("footer", _hoisted_20, [
                    _createVNode(ActionButton, {
                      label: "Cancel",
                      theme: "secondary",
                      class: "w-[fit-content]",
                      onClick: handleOpenBankTransferModal
                    }),
                    _createVNode(ActionButton, {
                      theme: "primary",
                      class: "w-[fit-content]",
                      isSubmit: true,
                      disable: loading.value,
                      label: loading.value ? 'Loading...' : 'Save'
                    }, null, 8, ["disable", "label"])
                  ])
                ], 32)
              ]),
              _: 1
            }, 8, ["openModal"])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", { class: "" }, [
              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "text-body-1 font-semibold" }, "QR Code Payment", -1)),
              _createElementVNode("p", { class: "text-grey-200 text-body-2" }, [
                _cache[18] || (_cache[18] = _createTextVNode("You don't have QR code payment setup yet. ")),
                _createElementVNode("span", {
                  onClick: handleOpenQrCodeModal,
                  class: "text-[#1677FF] cursor-pointer"
                }, "Set it up now")
              ])
            ]),
            _createVNode(ToggleSlider, {
              isChecked: _unref(payments)?.qrCode?.status,
              "onOn:change": _cache[8] || (_cache[8] = ($event: any) => (_unref(updatePaymentStatus)($event, 'qrCode', _unref(updatePaymentsHandler))))
            }, null, 8, ["isChecked"]),
            _createVNode(AppModal, {
              headerContent: "QR Code Payment Setup",
              openModal: isQrCodeModalOpen.value,
              onClick: handleOpenQrCodeModal,
              defaultActionButton: true
            }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createElementVNode("div", { class: "px-[28px] pt-[32px]" }, [
                  _createElementVNode("div", { class: "" }, [
                    _createElementVNode("div", { class: "font-semibold text-body-1" }, "How it works:"),
                    _createElementVNode("p", { class: "text-body-2 text-grey-200 mt-[8px]" }, "Customers will be able to make payments by scanning a QR code displayed on your register App. This QR code will be generated automatically for each transaction and processed through the restaurant portal.")
                  ]),
                  _createElementVNode("div", { class: "mt-[32px]" }, [
                    _createElementVNode("div", { class: "font-semibold text-body-1" }, "Charges Applied:"),
                    _createElementVNode("p", { class: "text-body-2 text-grey-200 mt-[8px]" }, "All transactions processed through QR code payments will incur a convenience fee of 0.002% per transaction.")
                  ]),
                  _createElementVNode("div", { class: "mt-[32px]" }, [
                    _createElementVNode("div", { class: "font-semibold text-body-1" }, "Terms and Conditions:"),
                    _createElementVNode("p", { class: "text-body-2 text-grey-200 mt-[8px]" }, "By enabling QR code payments on your restaurant portal, you agree to apply the specified convenience fee to all transactions processed through QR code payments.")
                  ])
                ], -1)
              ])),
              _: 1
            }, 8, ["openModal"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})