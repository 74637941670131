import { ref } from "vue";
import MenusService from "@/utility/services/menus.service";


export const menueHeaders = ref([
    { name: "Items", value: "entityCard" },
    { name: "Cost Price", value: "cost" },
    { name: "Selling Price", value: "price" },
    { name: "Total Quantity", value: "totalQuantity" },
    { name: "Remaining Quantity", value: "remainingQuantity" },
    { name: "Availability", value: "available" },
    { name: "Action", value: "action" },
]);

export const menuItemsData = ref([
    {
        name: "Pizza",
        description: 'A delightful blend of velvety pounded yam and flavorful Egusi soup.',
        imageUrl: `https://source.unsplash.com/400x300/?Pizza`,
        cost_price: 500,
        selling_price: 600,
        availability: '',
        action: 'Edit'
    }
])

export const getMenus = async({ search=""}) => {
    try {
        const response = await MenusService.getMenusRequest({ search });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const getMenuById = async(menuId: string) => {
    try {
        const response = await MenusService.getMenuByIdRequest(menuId);
        return response;
    } catch (error) {
        console.error(error)
    }
}