import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "lg:py-[76px] py-[30px]" }
const _hoisted_2 = { class: "lg:flex items-center justify-between" }
const _hoisted_3 = { class: "font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between" }
const _hoisted_4 = { class: "flex gap-[32px] border-b-[1px] border-solid mt-[24px]" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "my-[28px]"
}
const _hoisted_7 = {
  key: 1,
  class: "my-[28px]"
}
const _hoisted_8 = { class: "w-full" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "flex items-center gap-[6px] w-full px-[8px] bg-white" }
const _hoisted_11 = { class: "text-overline font-semibold text-grey-100 mt-[3px]" }
const _hoisted_12 = { class: "text-caption font-semibold text-grey-300" }
const _hoisted_13 = {
  key: 2,
  class: "my-[28px]"
}
const _hoisted_14 = { class: "lg:flex items-center justify-between gap-4 mb-4" }
const _hoisted_15 = { class: "text-heading-5 underline" }
const _hoisted_16 = { class: "lg:flex grid gap-[10px] lg:mt-0 mt-4" }
const _hoisted_17 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_18 = { class: "flex grid-cols-2" }
const _hoisted_19 = { class: "text-caption" }
const _hoisted_20 = { class: "font-semibold" }
const _hoisted_21 = { class: "text-overline" }
const _hoisted_22 = { class: "text-overline" }
const _hoisted_23 = { class: "text-overline" }
const _hoisted_24 = { class: "w-[800px] m-[auto] py-[72px]" }
const _hoisted_25 = { class: "" }
const _hoisted_26 = { class: "text-heading-3 flex items-center gap-[24px] mb-[28px]" }

import TableField from '@/components/shared/table/TableField.vue';
    import { useCustomersHelper, getUsers, getEmployeeSchedule, getAttendance } from './Teams.helper'
    import { ref, watchEffect, computed } from 'vue';
    import InputField from '@/components/shared/input-field/InputField.vue';
    import { SearchOptions } from '../menu/MenuView.vue';
    import ActionButton from '@/components/shared/action-button/ActionButton.vue';
    import { Icon } from '@iconify/vue';
    import NewMember from './NewMember.vue'
    import FullCalendar from '@fullcalendar/vue3';
    import dayGridPlugin from '@fullcalendar/daygrid'; 
    import interactionPlugin from '@fullcalendar/interaction'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
    import EntityCard from '@/components/shared/entity-card/EntityCard.vue';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'TeamsView',
  setup(__props) {

    const isOpen = ref(true)
    const teamTabs = ref([`Members (0)`, 'Schedules (0)', 'Clock-in/Out']);
    const showFilterMobile = ref(false);
    const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 
        'August', 'September', 'October', 'November', 'December'
    ];
    const monthsOptions = months.map((month, index) => ({ name: month, value: index + 1 }));
    const yearsOptions = years.map(year => ({ name: year.toString(), value: year }));
    const defaultEmployeeSchedule = ref([]); 
    const calendarRef: any = ref(null);
    const attendanceData = ref()
    const days: any = ref([]); 

    const queryParams = ref({
        search: '?editModal=true', 
    });
    
    const handleOpenEditModal = () => {
        isOpen.value = !isOpen.value;
    };

    const usersData: any = ref([]);

    const searchOptionsData = ref<SearchOptions>({
        search: '',
    });

    const { AppWrapper, activateTab, activeTab } = useCustomersHelper()
    const onSearch = (event: any) => {
        searchOptionsData.value.search = event.target.value;
    };

    const usersTableHeader = ref([
        {name: 'Member', value: 'entityCard'},
        {name: 'Role', icon: 'ant-design:sort-ascending-outlined', value: 'role'},
        {name: 'Status', value: 'status'},
        {name: 'Last Updated', value: 'lastUpdated'},
    ])

    const clockInOutTableHeader = ref([
        {name: 'Member', value: 'entityCard'},
        {name: 'Clock-In Time', value: 'clockIn'},
        {name: 'Clock-Out Time', value: 'clockOut'},
        {name: 'Total Hours', value: 'totalHours'},
    ])

    const usersTableValue = () => {
        return usersData.value?.users?.map((user: Record<string | number, unknown | any>) => {
            return {
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                imageUrl:`${user.avatar}?v=${new Date().getTime()}`,
                description: user.email,
                subDescription: user.phone,
                role: user.role || 'Not registered',
                status: user.status,
                lastUpdated: user.modifiedDate || '________'
            }
        })
    }

    const attendaceTableValue = () => {
        return attendanceData.value?.attendence?.map((a: Record<string | number, unknown | any>) => {
            return {
                name: `${a.firstName} ${a.lastName}`,
                imageUrl:`${a.avatar}?v=${new Date().getTime()}`,
                clockIn: a.clockIn,
                clockOut: a.clockOut || 'Not registered',
                totalHours: a.totalHours,
            }
        })
    }

    const selectedAttendanceMonth = ref(new Date().getMonth() + 1); 
    const selectedAttendanceYear = ref(new Date().getFullYear()); 

    const calendarScheduleEvents = () => {
        return defaultEmployeeSchedule.value;
    }

    watchEffect(() => {
        getEmployeeSchedule({}).then((response: any) => {
            defaultEmployeeSchedule.value = response.employeeSchedule;
        });
    });

    watchEffect(() => {
        getUsers({search: searchOptionsData.value.search})
        .then((response: any) => {
            usersData.value = response;
            teamTabs.value = [`Members (${response?.total})`, `Schedules (${defaultEmployeeSchedule.value.length})`, 'Clock-in/Out'];
        })
    })

    watchEffect(() => {
        getAttendance({year: "", month: "", date: ""})
        .then((response: any) => {
            attendanceData.value = response;
        })
    })

    const getDaysInMonth = (month: number, year: number) => {
        return new Date(year, month, 0).getDate();
    };

    const today = new Date();
    const selectedDay = ref(today.getDate()); 

    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    watchEffect(() => {
        const daysInSelectedMonth = getDaysInMonth(selectedAttendanceMonth.value, selectedAttendanceYear.value);
        days.value = Array.from({ length: daysInSelectedMonth }, (_, i) => ({
            name: (i + 1).toString(),
            value: (i + 1) 
        }));
    });

    watchEffect(() => {
        if (selectedDay.value > days.value.length) {
            selectedDay.value = days.value.length;
        }
    });

    const onAttendanceDayChange = (e: any) => {
        selectedDay.value = e.target.value; 
        fetchAttendance();
    };

    const onAttendanceMonthChange = (e: any) => {
        selectedAttendanceMonth.value = e.target.value;
        fetchAttendance(); 
    };

    const onAttendanceYearChange = (e: any) => {
        selectedAttendanceYear.value = e.target.value;
        fetchAttendance();
    };

    const fetchAttendance = () => {
        getAttendance({
            year: selectedAttendanceYear.value.toString(),
            month: selectedAttendanceMonth.value.toString(),
            date: selectedDay.value.toString(),
        })
        .then((response: any) => {
            attendanceData.value = response;
        });
    };

    const isMobile = ref(window.innerWidth < 768);

    window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 768;
    });

    const calendarView = computed(() => {
        return isMobile.value ? 'timeGridDay' : 'dayGridMonth';
    });

    const headerToolbarView = computed(() => {
        return isMobile.value ? {
            left: 'prev,next',
            center: 'title',
            right: ''
        } : 
        {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }; 
    })


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Teams" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", null, "Teams", -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden", showFilterMobile.value && 'bg-primary-300 text-white']),
              onClick: handleShowMobileFilter
            }, [
              _createVNode(_unref(Icon), {
                class: "",
                icon: "fluent:options-20-filled",
                width: "20",
                height: "20"
              })
            ], 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lg:flex items-center gap-[12px] grid lg:mt-0 mt-[15px]", !showFilterMobile.value ? 'hidden' : 'block' ])
          }, [
            _createVNode(InputField, {
              type: "text",
              placeholder: "Search",
              "prefix-icon": "ant-design:search-outlined",
              onChange: onSearch
            }),
            _createVNode(ActionButton, {
              theme: "primary",
              label: "New Member",
              class: "lg:w-[fit-content]",
              onClick: handleOpenEditModal
            })
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(teamTabs.value, (tab, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab) === index }]),
              onClick: ($event: any) => (_unref(activateTab)(index))
            }, _toDisplayString(tab), 11, _hoisted_5))
          }), 128))
        ]),
        (_unref(activeTab) == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(TableField, {
                headers: usersTableHeader.value,
                dataItems: usersTableValue() || [],
                isPagination: true,
                viewDetails: true,
                "edit-details": true,
                "uri-query": queryParams.value.search,
                routeData: {name: 'TeamDetails', paramId: 'id'}
              }, null, 8, ["headers", "dataItems", "uri-query"])
            ]))
          : _createCommentVNode("", true),
        (_unref(activeTab) == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("main", _hoisted_8, [
                _createVNode(_unref(FullCalendar), {
                  ref_key: "calendarRef",
                  ref: calendarRef,
                  options: {
                            timeZone: 'UTC',
                            themeSystem: 'bootstrapFontAwesome',
                            plugins: [ _unref(dayGridPlugin),  _unref(timeGridPlugin), _unref(interactionPlugin) ],
                            initialView: calendarView.value,
                            events: calendarScheduleEvents(),
                            slotEventOverlap: false,
                            headerToolbar: headerToolbarView.value
                        }
                }, {
                  eventContent: _withCtx((arg) => [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "bg-primary-300 w-[6px] h-[6px] rounded-full" }, null, -1)),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(arg.event.id), 1),
                        _createElementVNode("div", _hoisted_12, _toDisplayString(arg.event.title), 1)
                      ])
                    ])
                  ]),
                  _: 1
                }, 8, ["options"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_unref(activeTab) == 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, _toDisplayString(dayNames[new Date(selectedAttendanceYear.value, selectedAttendanceMonth.value - 1, selectedDay.value).getDay()]), 1),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(DropdownField, {
                    optionsData: days.value,
                    value: selectedDay.value.toString(),
                    onChange: onAttendanceDayChange
                  }, null, 8, ["optionsData", "value"]),
                  _createVNode(DropdownField, {
                    optionsData: _unref(monthsOptions),
                    value: selectedAttendanceMonth.value.toString(),
                    onChange: onAttendanceMonthChange
                  }, null, 8, ["optionsData", "value"]),
                  _createVNode(DropdownField, {
                    optionsData: _unref(yearsOptions),
                    value: selectedAttendanceYear.value.toString(),
                    onChange: onAttendanceYearChange
                  }, null, 8, ["optionsData", "value"])
                ])
              ]),
              _createElementVNode("div", null, [
                (!isMobile.value)
                  ? (_openBlock(), _createBlock(TableField, {
                      key: 0,
                      headers: clockInOutTableHeader.value,
                      dataItems: attendaceTableValue() || [],
                      isPagination: true
                    }, null, 8, ["headers", "dataItems"]))
                  : _createCommentVNode("", true),
                (isMobile.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attendaceTableValue(), (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.name,
                          class: "border-b p-4"
                        }, [
                          _createElementVNode("div", _hoisted_18, [
                            _createVNode(EntityCard, {
                              "image-url": item.imageUrl
                            }, null, 8, ["image-url"]),
                            _createElementVNode("div", _hoisted_19, [
                              _createElementVNode("div", _hoisted_20, _toDisplayString(item.name), 1),
                              _createElementVNode("div", _hoisted_21, "Clock-In: " + _toDisplayString(item.clockIn), 1),
                              _createElementVNode("div", _hoisted_22, "Clock-Out: " + _toDisplayString(item.clockOut || 'Not registered'), 1),
                              _createElementVNode("div", _hoisted_23, "Total Hours: " + _toDisplayString(item.totalHours), 1)
                            ])
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ], 512), [
        [_vShow, isOpen.value]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: handleOpenEditModal
            }, [
              _createVNode(_unref(Icon), { icon: "ant-design:arrow-left-outlined" })
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-semibold" }, "Create Member", -1))
          ])
        ]),
        _createVNode(NewMember, { handleOpenEditModal: handleOpenEditModal })
      ], 512), [
        [_vShow, !isOpen.value]
      ])
    ]),
    _: 1
  }))
}
}

})