import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lg:pt-[76px] pt-[30px]" }
const _hoisted_2 = { class: "lg:flex items-center justify-between" }
const _hoisted_3 = { class: "font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between" }
const _hoisted_4 = { class: "my-[28px] overflow-auto no-scrollbar" }

import TableField from '@/components/shared/table/TableField.vue';
    import { useCustomersHelper } from './Customers.helper'
    import InputField from '@/components/shared/input-field/InputField.vue';
    import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { ref } from 'vue';
import { Icon } from '@iconify/vue';
    
export default /*@__PURE__*/_defineComponent({
  __name: 'CustomersView',
  setup(__props) {

    const { AppWrapper, customersHeader, onSearch, searchDateOptions, onSearchDateChange, customersData, searchOptionsData } = useCustomersHelper()

    const showFilterMobile = ref(false);
    const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value
   

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Customers" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", null, "Customers", -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden", showFilterMobile.value && 'bg-primary-300 text-white']),
              onClick: handleShowMobileFilter
            }, [
              _createVNode(_unref(Icon), {
                class: "",
                icon: "fluent:options-20-filled",
                width: "20",
                height: "20"
              })
            ], 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lg:flex items-center gap-[12px] grid lg:mt-0 mt-[10px]", !showFilterMobile.value ? 'hidden' : 'block' ])
          }, [
            _createVNode(InputField, {
              type: "text",
              placeholder: "Search",
              "prefix-icon": "ant-design:search-outlined",
              onChange: _unref(onSearch)
            }, null, 8, ["onChange"]),
            _createVNode(DropdownField, {
              value: _unref(searchOptionsData).since.toString(),
              label: "",
              optionsData: _unref(searchDateOptions),
              onChange: _unref(onSearchDateChange)
            }, null, 8, ["value", "optionsData", "onChange"])
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(TableField, {
            headers: _unref(customersHeader),
            dataItems: _unref(customersData),
            isPagination: true,
            viewDetails: true,
            routeData: {name: 'CustomerDetails', paramId: 'customerId'},
            pagination: "client"
          }, null, 8, ["headers", "dataItems"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})