import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/icons/location.svg'


const _hoisted_1 = { class: "px-[24px] py-[32px]" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex items-center gap-2" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "text-body-2" }
const _hoisted_6 = { class: "text-body-2 text-grey-200" }
const _hoisted_7 = { class: "mt-[52px]" }
const _hoisted_8 = { class: "text-link font-medium text-body-2 text-center mt-[24px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';


interface Props {
    openStoreModal: boolean
    handleShowModal: () => void
    storesProfile: any
    selectedStoreId: any
    selectStore: any
    reloadApp: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SwitchStoresModal',
  props: {
    openStoreModal: { type: Boolean },
    handleShowModal: { type: Function },
    storesProfile: {},
    selectedStoreId: {},
    selectStore: {},
    reloadApp: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(AppModal, {
    headerContent: "Registered stores",
    openModal: _ctx.openStoreModal,
    onClose: _ctx.handleShowModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-body-2" }, "Select your preferred store to access information, team details, menu, and more.", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storesProfile, (store, idx) => {
          return (_openBlock(), _createElementBlock("div", {
            key: idx,
            onClick: ($event: any) => (_ctx.selectStore(store.id)),
            class: _normalizeClass(['flex items-center bg-bg-100 justify-between rounded-[8px] px-[16px] py-[14px] mt-[16px] border-[1px]', 
                { 'border-primary-300': _ctx.selectedStoreId === store.id, 'border-grey-50': _ctx.selectedStoreId !== store.id }])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _cache[0] || (_cache[0] = _createElementVNode("img", {
                src: _imports_0,
                width: "42",
                height: "42"
              }, null, -1)),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(store.name), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(store?.address?.street) + " " + _toDisplayString(store?.address?.lga) + " " + _toDisplayString(store?.address?.city) + " " + _toDisplayString(store?.address?.country), 1)
              ])
            ]),
            _createVNode(_component_RadioButton, {
              isChecked: _ctx.selectedStoreId === store.id
            }, null, 8, ["isChecked"])
          ], 10, _hoisted_2))
        }), 128)),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(ActionButton, {
            theme: "primary",
            label: "Continue",
            onClick: _ctx.reloadApp
          }, null, 8, ["onClick"]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_link, { to: { name:'StoreManagement'} }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("Store Management & Analysis")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["openModal", "onClose"]))
}
}

})