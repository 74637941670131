import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "grid grid-cols-2 auth_responsive" }
const _hoisted_2 = { class: "bg-[#F5F5F5] h-screen p-[42px] relative" }
const _hoisted_3 = { class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" }
const _hoisted_4 = { class: "w-[fit-content] mt-[114px] mx-[auto]" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "text-heading-3 text-center mt-[42px] capitalize w-[600px] m-[auto]" }
const _hoisted_7 = { class: "flex gap-8 justify-center items-center mt-[42px]" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "relative" }


import StandaloneLogo from '../../shared/standalone-logo/StandaloneLogo.vue'
import { useAuthCarousel } from './AuthWrapper.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthWrapper',
  props: {
  activePage: {
    type: String as () => ('login' | 'forget-password' | 'reset-done'), 
    default: 'login',
  }
},
  setup(__props) {



const images = [
  require("@/assets/images/backgrounds/auth-image.svg"),
  require("@/assets/images/backgrounds/auth-image-2.svg"),
  require("@/assets/images/backgrounds/auth-image-3.svg")
];

const texts = [
  "manage sales, inventory, and transactions in one place",
  "Optimize your business operations seamlessly",
  "Take full control with a simple tap – easy management at your fingertips"
];

const { activeIndex, currentImage, currentText, goTo } = useAuthCarousel(images, texts);
const dots = Array.from({ length: images.length });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(StandaloneLogo),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            width: "500",
            height: "500",
            src: _unref(currentImage)
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(currentText)), 1),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dots), (dot, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["h-[18px] w-[18px] bg-[#D9D9D966] rounded-full", { 'bg-primary-300': index === _unref(activeIndex) }]),
              onClick: ($event: any) => (_unref(goTo)(index))
            }, null, 10, _hoisted_8))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})