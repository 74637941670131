import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item-card flex items-center gap-[12px]" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "item-info" }
const _hoisted_4 = { class: "lg:text-body-1 text-body-2" }
const _hoisted_5 = { class: "text-grey-200 lg:text-body-2 mt-[4px]" }
const _hoisted_6 = { class: "text-grey-200 lg:text-body-2 mt-[4px]" }

import { VNode } from 'vue';

interface Props {
    name?: string | VNode,
    description?: string | VNode,
    subDescription?: string | VNode
    imageUrl: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EntityCard',
  props: {
    name: {},
    description: {},
    subDescription: {},
    imageUrl: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "entity-image rounded-[8px]",
        style: _normalizeStyle({ backgroundImage: `url(${_ctx.imageUrl || 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fstatic.vecteezy.com%2Fsystem%2Fresources%2Fpreviews%2F012%2F942%2F784%2Foriginal%2Fbroken-image-icon-isolated-on-a-white-background-no-image-symbol-for-web-and-mobile-apps-free-vector.jpg&f=1&nofb=1&ipt=0efb585a47c7ab7be84190f0536e38fb85b80a8b0f60743a72f7b35e0c329281&ipo=images'})`})
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.name), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.description), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.subDescription), 1)
    ])
  ]))
}
}

})