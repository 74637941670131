import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/images/icons/location.svg'
import _imports_1 from '@/assets/images/images/avatar-1.svg'


const _hoisted_1 = { class: "text-caption w-[140px] overflow-y-hidden text-ellipsis" }
const _hoisted_2 = { class: "text-body-2 font-semibold text-grey-200 w-[120px] text-ellipsis whitespace-nowrap overflow-hidden" }
const _hoisted_3 = { class: "text-caption text-bg-100 mt-[32px] px-[16px]" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "flex items-center gap-2" }
const _hoisted_6 = { class: "text-caption w-[140px] overflow-y-hidden text-ellipsis text-bg-100" }
const _hoisted_7 = { class: "text-body-2 font-medium" }
const _hoisted_8 = { class: "text-caption" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "" }

import { Icon } from '@iconify/vue'
import { sideBarContents } from './SideNavbar.helper'
import { onMounted, ref, watch } from 'vue';
import { useAuthSession } from "@/utility/composables/useAuthSession";
import { computed } from 'vue';
import { useStore } from 'vuex';

interface Props {
    activeTabContent: string
    showModal?: () => void
    handleOpenCreateStoreModal?: () => void
    mobileCollapse?: boolean
    handleToggleMobileCollapse?: () => void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SideNavbar',
  props: {
    activeTabContent: {},
    showModal: { type: Function },
    handleOpenCreateStoreModal: { type: Function },
    mobileCollapse: { type: Boolean },
    handleToggleMobileCollapse: { type: Function }
  },
  setup(__props: any) {

const store = useStore();
const profileData = computed(() => store.state.profile.profileData);
const storeProfile = computed(() => store.state.StoreProfileModule.storeProfile);

const role = computed(() => profileData.value?.appRoles.find((role: any) => role.appName === 'FoodCart-Restaurant')?.role || '');

const { logoutSession } = useAuthSession()

const profileDropdown = ref(false)

const prop = __props

const isCollapse = ref(false)

const handleToggleCollapse = () => {
    isCollapse.value = !isCollapse.value
    localStorage.setItem('globalSideNavbarCollapsed', `${isCollapse.value}`)
}

onMounted(() => {
    const isCollapsed = localStorage.getItem('globalSideNavbarCollapsed')
    if (isCollapsed !== null) {
        isCollapse.value = !prop.mobileCollapse && isCollapsed === 'true' 
    }
})

watch(() => localStorage.getItem('globalSideNavbarCollapsed'), (newVal) => {
    if (newVal !== null) {
        isCollapse.value = newVal === 'true'
    }
})

onMounted(() => {
    store.dispatch('profile/fetchProfileData');
})

onMounted(() => {
    store.dispatch('StoreProfileModule/fetchStoreDataData');
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["h-screen bg-primary-300 py-[24px] side-navbar", [
            isCollapse.value ? 'w-[fit-content] px-[9px]' : 'px-[32px]',
            _ctx.mobileCollapse && 'px-[8px]',
            !_ctx.mobileCollapse && 'px-[32px]',
        ]])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["cursor-pointer responsive_sidebar_header lg:block lg:px-[0px] px-[16px]", !_ctx.mobileCollapse && 'hidden'])
    }, [
      (!_ctx.mobileCollapse)
        ? (_openBlock(), _createBlock(_unref(Icon), {
            key: 0,
            class: _normalizeClass(["text-bg-100 cursor-pointer", { 'w-[fit-content] m-[auto]': isCollapse.value }]),
            icon: !isCollapse.value ? 'ant-design:menu-fold-outlined' : 'ant-design:menu-unfold-outlined',
            width: "24",
            height: "24",
            onClick: handleToggleCollapse
          }, null, 8, ["icon", "class"]))
        : _createCommentVNode("", true),
      _createVNode(_unref(Icon), {
        else: "",
        class: "cursor-pointer text-bg-100 mt-[27px] lg:hidden",
        icon: "ant-design:menu-unfold-outlined",
        width: "24",
        height: "24",
        onClick: _ctx.handleToggleMobileCollapse
      }, null, 8, ["onClick"]),
      _createElementVNode("header", {
        class: _normalizeClass(["lg:flex hidden items-center bg-bg-100 justify-between rounded-[8px] px-[16px] py-[14px] mt-[32px]", { 'w-[fit-content] px-[4px] py-[8px] m-[auto]': isCollapse.value }])
      }, [
        _createElementVNode("div", {
          class: "flex items-center gap-2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args)))
        }, [
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_0,
            width: "42",
            height: "42"
          }, null, -1)),
          _withDirectives(_createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(storeProfile.value?.name), 1),
            _createTextVNode(" " + _toDisplayString(storeProfile.value?.address?.street) + ", " + _toDisplayString(storeProfile.value?.address?.lga) + " " + _toDisplayString(storeProfile.value?.address?.city), 1)
          ], 512), [
            [_vShow, !isCollapse.value]
          ])
        ]),
        _withDirectives(_createVNode(_unref(Icon), {
          class: "font-semibold",
          icon: "ant-design:plus-circle-filled",
          width: "24",
          height: "24",
          onClick: _ctx.handleOpenCreateStoreModal
        }, null, 8, ["onClick"]), [
          [_vShow, !isCollapse.value]
        ])
      ], 2)
    ], 2),
    _createElementVNode("main", {
      class: _normalizeClass(["lg:mt-[32px] mt-[16px] overflow-auto no-scrollbar mb-[30px] pb-[50px] lg:block", !_ctx.mobileCollapse && 'hidden'])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sideBarContents), (content, headerContent) => {
        return (_openBlock(), _createElementBlock("div", { key: headerContent }, [
          _createElementVNode("header", _hoisted_3, _toDisplayString(!isCollapse.value ? headerContent : ''), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(content, (contentData, idx) => {
            return (_openBlock(), _createElementBlock("div", { key: idx }, [
              _createVNode(_component_router_link, {
                to: { path: contentData.linkTo}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["flex items-center gap-3 text-bg-100 mt-[12px] px-[16px] py-[12px] rounded-[8px] cursor-pointer", { 'bg-bg-300': contentData.name == _ctx.activeTabContent, 'w-[fit-content] px-[16px] py-[12px] m-[auto]': isCollapse.value }])
                  }, [
                    _createVNode(_unref(Icon), {
                      class: "",
                      icon: contentData.icon,
                      width: "20",
                      height: "20"
                    }, null, 8, ["icon"]),
                    _withDirectives(_createElementVNode("div", { class: "text-body-2" }, _toDisplayString(contentData.name), 513), [
                      [_vShow, !isCollapse.value]
                    ])
                  ], 2)
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ]))
      }), 128))
    ], 2),
    _createElementVNode("footer", {
      class: _normalizeClass(["lg:block", !_ctx.mobileCollapse && 'hidden'])
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["profile rounded-[8px] px-[12px] py-[6px] mt-[32px]", { 'w-[fit-content] px-[4px] py-[6px] m-[auto]': isCollapse.value }])
        }, [
          _createElementVNode("span", {
            class: "flex items-center justify-between cursor-pointer",
            onClick: _cache[1] || (_cache[1] = () => profileDropdown.value = !profileDropdown.value)
          }, [
            _createElementVNode("div", _hoisted_5, [
              _cache[4] || (_cache[4] = _createElementVNode("img", {
                src: _imports_1,
                width: "40",
                height: "40"
              }, null, -1)),
              _withDirectives(_createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(profileData.value?.firstName) + " " + _toDisplayString(profileData.value?.lastName), 1),
                _createElementVNode("div", _hoisted_8, _toDisplayString(role.value), 1)
              ], 512), [
                [_vShow, !isCollapse.value]
              ])
            ]),
            _withDirectives(_createVNode(_unref(Icon), {
              class: "text-bg-100 text-bg-100 font-semibold cursor-pointer",
              icon: "ant-design:ellipsis-outlined",
              width: "20",
              height: "20"
            }, null, 512), [
              [_vShow, !isCollapse.value]
            ])
          ]),
          (profileDropdown.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["profile-dropdown p-[10px] bg-white w-[100%] rounded-[8px] grid gap-[10px]", { 'w-[fit-content] px-[6px] py-[6px] ml-[-6.5px] mt-[20px]': isCollapse.value }])
              }, [
                _createVNode(_component_router_link, { to: { path:'/settings'} }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["text-body-2 text-grey-200 font-medium flex gap-[8px] items-center py-[10px] px-[16px]", { 'w-[fit-content] px-[10px] py-[10px] m-[auto]': isCollapse.value }])
                    }, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:smile-filled",
                          width: "17",
                          height: "20"
                        })
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_10, "Profile", 512), [
                        [_vShow, !isCollapse.value]
                      ])
                    ], 2)
                  ]),
                  _: 1
                }),
                _createVNode(_component_router_link, { to: "/settings?q=storeDetails" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["text-body-2 text-grey-200 font-medium flex gap-[8px] items-center py-[10px] px-[16px]", { 'w-[fit-content] px-[10px] py-[10px] m-[auto]': isCollapse.value }])
                    }, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:setting-filled",
                          width: "17",
                          height: "20"
                        })
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_12, "Settings", 512), [
                        [_vShow, !isCollapse.value]
                      ])
                    ], 2)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", {
                  class: _normalizeClass(["text-body-2 text-grey-200 font-medium flex gap-[8px] text-white items-center py-[10px] px-[16px] rounded-[8px] bg-primary-300 cursor-pointer", { 'w-[fit-content] px-[10px] py-[10px] m-[auto]': isCollapse.value }]),
                  onClick: _cache[2] || (_cache[2] = () => _unref(logoutSession)())
                }, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_unref(Icon), {
                      icon: "ant-design:login-outlined",
                      width: "17",
                      height: "20"
                    })
                  ]),
                  _withDirectives(_createElementVNode("div", _hoisted_14, "Logout", 512), [
                    [_vShow, !isCollapse.value]
                  ])
                ], 2)
              ], 2))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ], 2)
  ], 2))
}
}

})