import ApiService from "./api.service"
import { storeId } from "./auth.service"

export default class UserService {

    static async createUserRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post(`users/${storeId()}`, {...values})
        return response.data.data
    }

    static async updateUserRequest(values: Record<string | number, unknown>, userId: string): Promise<unknown> {
        const response = await ApiService.put(`users/${storeId()}/${userId}`, {...values})
        return response.data.data
    }
    
    static async getUsersRequest({search = '', page = 1, sortBy='', sortDirection = -1, limit=100}): Promise<unknown> {
        const response = await ApiService.get(`users/${storeId()}/?search=${search}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}&limit=${limit}`)
        return response.data.data
    }

    static async getUserIdRequest({ userId = "" }): Promise<unknown> {
        const response = await ApiService.get(`users/${storeId()}/${userId}`)
        return response.data.data
    }

    static async getEmployeeScheduleRequest({ year = "", month = "" }): Promise<unknown> {
        const response = await ApiService.get(`store/${storeId()}/schedule?year=${year}&month=${month}`)
        return response.data.data
    }

    static async getAttendenceRequest({ year = "", month = "", date = "" }): Promise<unknown> {
        const response = await ApiService.get(`store/${storeId()}/attendence?year=${year}&month=${month}&date=${date}`)
        return response.data.data
    }
}