import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "flex gap-[32px] border-b-[1px] border-solid" }
const _hoisted_3 = ["onClick"]

import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
    import SalesReport from './SalesReport.vue';
    import CustomerReport from './CustomerReport.vue';
    import { useReportHelper } from './Report.helper';

    
export default /*@__PURE__*/_defineComponent({
  __name: 'ReportView',
  setup(__props) {

    const { reportTabs, activateTab, activeTab  } = useReportHelper();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "Reports" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("header", { class: "pt-[72px] flex justify-between items-center" }, [
          _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "text-heading-3 font-semibold text-grey-300" }, "Reports and Analysis")
          ])
        ], -1)),
        _createElementVNode("main", null, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(reportTabs), (tab, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab) === index }]),
                onClick: ($event: any) => (_unref(activateTab)(index))
              }, _toDisplayString(tab), 11, _hoisted_3))
            }), 128))
          ]),
          (_unref(activeTab) == 0)
            ? (_openBlock(), _createBlock(SalesReport, { key: 0 }))
            : _createCommentVNode("", true),
          (_unref(activeTab) == 1)
            ? (_openBlock(), _createBlock(CustomerReport, { key: 1 }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})