import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-[72px]" }
const _hoisted_2 = { class: "flex items-center gap-[6px] text-body-1" }
const _hoisted_3 = { class: "flex justify-between items-center mt-[32px]" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "mt-[32px]" }

import { ref, watchEffect } from "vue";
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue'

import { Icon } from '@iconify/vue';
import { getDashboardBestItems } from '../helper/Dashboard.helper';
import TableField from '@/components/shared/table/TableField.vue';
// import { bestSellersTabs, bestSellingTableHeaders } from './BestSellingTableModule.helper'

import { useRouter } from 'vue-router';
export interface SearchOptions {
    search: string;
    since: number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BestSelling',
  setup(__props) {

const router = useRouter()

const bestSellingTableHeaders = ref([
    { name: "Items", value: "entityCard" },
    { name: "Orders", icon: 'ant-design:sort-ascending-outlined', value: "count" },
    { name: "Channel", value: "orderChannel" },
    { name: "Category", value: "category" },
]);
const bestSellingItemsData = ref();
const search = ref();

const searchOptionsData = ref<SearchOptions>({
    search: '',
    since: 0
});

const orderSearchDateOptions = ref( [
    { value: 0, name: ''},
    { value: 30, name: 'Last 30 days'},
    { value: 60, name: 'Last 60 days'},
    { value: 80, name: 'Last 80 days'}
]);

const onSearch = (event: any) => {
    searchOptionsData.value.search = event.target.value;
};

const onChangeData = (event: any) => {
    searchOptionsData.value.since = event.target.value;
};

watchEffect(() => {
    getDashboardBestItems({
        search: searchOptionsData.value.search,
        since: searchOptionsData.value.since
    }).then((responseData: any) => {
        bestSellingItemsData.value = responseData.map((item: any) => {
            return {
                ...item,
                name: item.menuName
            }
        });
    });

})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "Dashboard" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "flex items-center gap-[6px] text-grey-100 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).back()))
          }, [
            _createVNode(_unref(Icon), { icon: "ant-design:appstore-outlined" }),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, "Dashboard", -1))
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", null, "/", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("div", null, "Best selling items", -1))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(InputField, {
              type: "text",
              placeholder: "Search",
              value: search.value,
              "prefix-icon": "ant-design:search-outlined",
              onChange: onSearch
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(DropdownField, {
              defaultOptionLabel: "Select",
              optionsData: orderSearchDateOptions.value,
              onChange: onChangeData
            }, null, 8, ["optionsData"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(TableField, {
            headers: bestSellingTableHeaders.value,
            dataItems: bestSellingItemsData.value,
            isPagination: true,
            viewDetails: false
          }, null, 8, ["headers", "dataItems"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})