<template>
    <div class="mt-[32px]">
        <div class="text-grey-200">Manage your store details and working hour below.</div>
        <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]">
            <header class="flex items-center justify-between">
                <div class="font-semibold text-sub-heading">Store Information</div>
                <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] py-[11px] w-[fit-content] text-body-2 cursor-pointer" @click="handleOpenEditModal">Update</div>
            </header>

            <div class="grid grid-cols-3 gap-[24px]">
                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Store Name</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[120px] rounded mt-[4px]' : loading}">
                        {{ storeProfileData?.name }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Email</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[200px] rounded mt-[4px]' : loading}">
                        {{ storeProfileData?.email }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Phone Number</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[140px] rounded mt-[4px]' : loading}">
                        {{ storeProfileData?.phone }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Established Date</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[160px] rounded mt-[4px]' : loading}">
                        {{ storeProfileData?.establishDate }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Store Address</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[350px] rounded mt-[4px]' : loading}" >
                        <div v-if="!loading">
                            {{ storeAddress() }}
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Business Status</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[160px] rounded mt-[4px]' : loading}">
                        {{ storeProfileData?.businessStatus }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Restaurant Type</div>
                    <div class="flex gap-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[200px] rounded mt-[4px]' : loading}">
                        <div class="font-medium text-body-1 mt-[4px]" v-for="(restaurantType, index) in storeProfileData.restaurantType" :key="index">
                            {{ restaurantType  }}<template v-if="index !== storeProfileData.restaurantType.length - 1">,</template>
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Service Options</div>
                    <div class="flex gap-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[150px] rounded mt-[4px]' : loading}">
                        <div class="font-medium text-body-1 mt-[4px]" v-for="(serviceOption, index) in storeProfileData.serviceOptions" :key="index">
                            {{ serviceOption }}<template v-if="index !== storeProfileData.serviceOptions.length - 1">,</template>
                        </div>
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Website</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[300px] rounded mt-[4px]' : loading}">
                        {{ storeProfileData.website == '' ? 'N/A' : storeProfileData.website }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Device Activation Code</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[300px] rounded mt-[4px]' : loading}">
                        {{ storeProfileData.deviceRegistrationCode }}
                    </div>
                </div>

            </div>

            <AppModal headerContent="Update Store information" :openModal="isOpen" @close="handleOpenEditModal">
                <div class="">
                    <form @submit.prevent="handleStoreDetailSubmit($event)">
                        <div class="p-[24px]">
                            <div class="grid grid-cols-2 gap-[20px] my-[8px] mb-[8px]">
                                <InputField 
                                    label="Store Name" 
                                    type="text" 
                                    name="name"
                                    placeholder="Store Name" 
                                    :value="storeProfileData?.name"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />
                                <InputField 
                                    label="Email" 
                                    type="email" 
                                    placeholder="Email"
                                    name="email"
                                    :value="storeProfileData?.email"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />
                                <InputField 
                                    label="Phone Number" 
                                    type="tel" 
                                    placeholder="080 123 456" 
                                    name="phone"
                                    :value="storeProfileData?.phone"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />
                                <InputField 
                                    label="Established Date" 
                                    type="date"
                                    name="establishDate"
                                    placeholder="Established Date" 
                                    :value="storeProfileData?.establishDate"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />

                                <InputField 
                                    label="Street Address" 
                                    type="text" 
                                    placeholder="Street Address"
                                    name="address.street" 
                                    :value="storeProfileData?.address?.street"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />

                                <InputField 
                                    label="City " 
                                    type="text" 
                                    placeholder="City Address"
                                    name="address.city" 
                                    :value="storeProfileData?.address?.city"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />

                                <InputField 
                                    label="LGA" 
                                    type="text" 
                                    placeholder="" 
                                    name="address.lga"
                                    :value="storeProfileData?.address?.lga"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />

                                <InputField 
                                    label="State" 
                                    type="text" 
                                    name="address.state"
                                    placeholder="" 
                                    :value="storeProfileData?.address?.state"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />
                                
                            </div>
                            
                            <InputField 
                                label="Country Address" 
                                type="text" 
                                placeholder="Country Address"
                                name="address.country" 
                                :value="storeProfileData?.address?.country"
                                @update:blur:value="updateProfileFormHandler.handleBlur"
                                @update:value="updateProfileFormHandler.handleChange"
                            />

                            <div class="mt-[16px]">
                                <DropdownField 
                                    label="Business Status" 
                                    name="businessStatus"
                                    :value="storeProfileData.businessStatus" 
                                    :optionsData="businessStatusOptions"
                                    @change="updateProfileFormHandler.handleChange"
                                />
                            </div>

                            <InputField 
                                label="Restaurant Type" 
                                name="restaurantType" 
                                type="text" 
                                placeholder="Enter Restaurant Type" 
                                class="mt-[16px]" 
                                @keydown.enter.prevent="(event) => addToInputList(event, storeProfileData, updateProfileFormHandler)"
                                undertext='Tap "Enter Key" to add more restaurant type' 
                            />

                            <div class="flex items-center gap-[10px] mt-[8px]">
                                <div class="py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption" v-for="(rt, idx) in storeProfileData.restaurantType" :key="idx">
                                    <div class="">{{ rt }}</div>
                                    <Icon icon="ant-design:close-outlined" width="12" height="12" @click="() => deleteFromInputList('restaurantType', idx, storeProfileData, updateProfileFormHandler)" />
                                </div>
                            </div>

                            <InputField 
                                label="Service Operations" 
                                name="serviceOptions" 
                                type="text" 
                                placeholder="Enter Service Operations" 
                                class="mt-[32px]" 
                                @keydown.enter.prevent="(event) => addToInputList(event, storeProfileData, updateProfileFormHandler)"
                                undertext='Tap "Enter Key" to add more service options' 
                            />

                            <div class="flex items-center gap-[10px] mt-[8px]">
                                <div class="py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption" v-for="(so, idx) in storeProfileData.serviceOptions" :key="idx">
                                    <div class="">{{ so }}</div>
                                    <Icon icon="ant-design:close-outlined" width="12" height="12" @click="() => deleteFromInputList('serviceOptions', idx, storeProfileData, updateProfileFormHandler)" />
                                </div>
                            </div>

                            <div class="grid grid-cols-2 gap-[20px] mt-[32px]">
                                <InputField 
                                    label="Website" 
                                    type="text" 
                                    placeholder="Enter website Url" 
                                    name="website"
                                    :value="storeProfileData.website"
                                    @update:blur:value="updateProfileFormHandler.handleBlur"
                                    @update:value="updateProfileFormHandler.handleChange"
                                />
                              
                            </div>
                        </div>

                        <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                            <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenEditModal" />
                            <ActionButton
                                theme="primary" 
                                class="w-[fit-content]" 
                                :isSubmit="true" 
                                :disable="updateProfileFormHandler.form.loading && true" 
                                :label="updateProfileFormHandler.form.loading ? 'Loading...' : 'Save'" 
                            />
                        </footer>
                    </form>
                </div>
            </AppModal>
        </div>

        <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]">
            <header class="flex items-center justify-between">
                <div class="font-semibold text-sub-heading">Working Hours</div>
                <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] py-[11px] w-[fit-content] text-body-2 cursor-pointer" @click="handleOpenWorkingHoursModal">Update</div>
            </header>

            <div class="text-grey-200 mt-[16px]">
                <TableField
                    :headers="scheduleHeader" 
                    :dataItems="scheduleData" 
                />
            </div>

            <AppModal headerContent="Update work Hours" :openModal="isOpenWorkingHoursModal" @close="handleOpenWorkingHoursModal">
                <form @submit.prevent="updateWorkingHoursFormHandler.handleSubmit">
                    <div class="py-[32px] px-[28px]">
                        <div class="border-[1px] border-solid border-grey-50 p-[14px] rounded-[8px] mt-[12px]" v-for="(storeHour, idx) in workingHoursData?.storeHours" :key="idx">
                            <div class="flex items-center justify-between" :class="{ 'opacity-50': !storeHour.status }">
                                <div class="flex gap-[6px] items-center capitalize">
                                    <ToggleSlider name="status" :isChecked="storeHour.status" @on:change="(event) => handleWorkingHoursChange(event, idx)" :label="idx"/>
                                    <div class="text-grey-100 text-body-2" v-if="!openWorkHourUpdate[idx]">{{ formatTimeToAmOrPm(storeHour.open) }} - {{ formatTimeToAmOrPm(storeHour.close) }}</div>
                                </div>
                                <Icon :icon="!openWorkHourUpdate[idx] ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="text-primary-300 cursor-pointer" :class="{ 'text-primary-300 cursor-not-allowed': !storeHour.status }" @click="storeHour.status && handleOpenWorkHourUpdateModal(idx)" />
                            </div>
                            <div class="" v-if="openWorkHourUpdate[idx] && storeHour.status">
                                <div class="work-hours-card items-center gap-[20px] mt-[16px]">
                                    <InputField 
                                        label="Start Time" 
                                        type="time" 
                                        name="open"
                                        prefixIcon="ant-design:clock-circle-outlined"
                                        placeholder="" 
                                        :value="storeHour.open"
                                        ref="openInputFields"
                                        @update:blur:value="updateWorkingHoursFormHandler.handleBlur"
                                        @update:value="handleWorkingHoursChange($event, idx)"
                                    />

                                    <InputField 
                                        label="End Time" 
                                        type="time" 
                                        name="close"
                                        prefixIcon="ant-design:clock-circle-outlined"
                                        placeholder="" 
                                        :value="storeHour.close"
                                        ref="closeInputFields"
                                        @update:blur:value="updateWorkingHoursFormHandler.handleBlur"
                                        @update:value="handleWorkingHoursChange($event, idx)"
                                    />

                                    <div class="text-primary-300 bg-bg-200 py-[10px] px-[10px] rounded-[4px] mt-[24px] w-[fit-content]" @click="clearInputFields(idx)">
                                        <Icon icon="ant-design:clear-outlined" width="24" height="24" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                        <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenWorkingHoursModal"/>
                        <ActionButton
                            theme="primary" 
                            class="w-[fit-content]" 
                            :isSubmit="true" 
                            :disable="updateWorkingHoursFormHandler.form.loading && true" 
                            :label="updateWorkingHoursFormHandler.form.loading ? 'Loading...' : 'Save'" 
                        />
                    </footer>
                </form>
            </AppModal>
        </div>
    </div>
</template>

<script setup lang="ts">
import TableField from '@/components/shared/table/TableField.vue';
import { getStoreProfile, useSettingsHelper, useStoreDetailsHelper } from './Settings.helper'
import { ref, watch, watchEffect } from 'vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { useSettingsHandler } from '@/utility/composables/useSettingsRequestsHandler';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ToggleSlider from '@/components/shared/toggle-slider/ToggleSlider.vue';
import { Icon } from '@iconify/vue';
import { addToInputList, deleteFromInputList, formatTimeToAmOrPm } from '@/utility/helper/utils.helper';
import { useStore } from 'vuex';


const storeProfileData: Record<string | number, any> = ref({});
const loading = ref(true);
const isOpen = ref(false);
const isOpenWorkingHoursModal = ref(false);
const openWorkHourUpdate = ref<boolean[]>([]);
const store = useStore()

const storeAddress = () => {
    let address = ""
    if (storeProfileData.value?.address?.street) {
        let street = storeProfileData.value?.address?.street;
        let city = storeProfileData.value?.address?.city ? `${storeProfileData.value?.address?.city} ` : '';
        let lga = storeProfileData.value?.address?.lga ? `${storeProfileData.value?.address?.lga}. ` : '';
        let state = storeProfileData.value?.address?.state ? `${storeProfileData.value?.address?.state} state ` : '';
        let postalCode = storeProfileData.value?.address?.postalCode ? `${storeProfileData.value?.address?.postalCode} ` : '';
        let country = storeProfileData.value?.address?.country ? `${storeProfileData.value?.address?.country}` : '';
        address = `${street}, ${city}${lga}${state}${postalCode}${country}`; 
    }
    return address;
}

const handleOpenWorkHourUpdateModal = (idx: number) => {
    if (openWorkHourUpdate.value.length <= idx) {
        openWorkHourUpdate.value[idx] = false;
    }
    openWorkHourUpdate.value[idx] = !openWorkHourUpdate.value[idx];
};

const handleOpenEditModal = () => {
    isOpen.value = !isOpen.value;
};

const handleOpenWorkingHoursModal = () => {
    isOpenWorkingHoursModal.value = !isOpenWorkingHoursModal.value;
};

const { businessStatusOptions } = useSettingsHelper()
const { updateProfileFormHandler, updateWorkingHoursFormHandler } = useSettingsHandler(store)
const { workingHoursData, scheduleHeader, scheduleData } = useStoreDetailsHelper();


const handleStoreDetailSubmit = async (event: any) => {
    loading.value = true;
    await updateProfileFormHandler.handleSubmit(event);
    storeProfileData.value = updateProfileFormHandler.values;
    handleOpenEditModal();
    loading.value = false;
}

watchEffect(() => {
    getStoreProfile().then((responseData) => {
        storeProfileData.value = responseData;
        loading.value = false;
    })
})

watch(storeProfileData, (newStoreProfileData) => {
    updateProfileFormHandler.setValues(newStoreProfileData);
});

const handleWorkingHoursChange = (event: any, idx: any) => {
    const { name, value, checked } = event.target;
    const updatedStoreHours = { ...workingHoursData.value.storeHours };
    const updatedDay = { ...(updatedStoreHours[idx] || {}) };

    name === 'status' ? updatedDay[name] = checked : updatedDay[name] = value;

    updatedStoreHours[idx] = updatedDay;
    workingHoursData.value.storeHours = updatedStoreHours; 
    updateWorkingHoursFormHandler.setValues(workingHoursData.value)
};

const clearInputFields = (idx: string | number) => {
    const updatedStoreHours = { ...workingHoursData.value.storeHours };
    const updatedDay = { ...(updatedStoreHours[idx] || {}) }; 

    updatedDay.open = '';
    updatedDay.close = '';

    updatedStoreHours[idx] = updatedDay;
    workingHoursData.value.storeHours = updatedStoreHours; 
    updateWorkingHoursFormHandler.setValues(workingHoursData.value)
};

</script>

<style scoped>
.work-hours-card {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
}
</style>