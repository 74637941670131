import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
}
const _hoisted_2 = { class: "text-body-2 text-medium text-error mt-[4px]" }
const _hoisted_3 = {
  key: 1,
  class: "w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
}
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "text-heading-1 font-semibold text-grey-300 flex gap-[20px] items-center" }
const _hoisted_6 = { class: "bg-[#D5FFD4] p-[2px] w-[fit-content] rounded-[50%]" }

import AuthWrapper from "@/components/app/auth-wrapper/AuthWrapper.vue"
import ActionButton from "@/components/shared/action-button/ActionButton.vue"
import InputField from "@/components/shared/input-field/InputField.vue"
import { useAuthSession } from "@/utility/composables/useAuthSession";
import { Icon } from '@iconify/vue'
import { useRouter } from "vue-router"


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPassword',
  setup(__props) {

const router = useRouter()
const { resetPasswordHandler } = useAuthSession()


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthWrapper, null, {
    default: _withCtx(() => [
      (!_unref(resetPasswordHandler).form.res)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[1] || (_cache[1] = _createElementVNode("header", { class: "" }, [
              _createElementVNode("div", { class: "text-heading-1 font-semibold text-grey-300" }, "Reset password"),
              _createElementVNode("div", { class: "text-sub-heading text-grey-200" }, "Please enter your email to request a password reset.")
            ], -1)),
            _createElementVNode("form", {
              onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_unref(resetPasswordHandler).handleSubmit && _unref(resetPasswordHandler).handleSubmit(...args)), ["prevent"]))
            }, [
              _createVNode(InputField, {
                class: "mt-[50px]",
                placeholder: "Email",
                type: "email",
                prefixIcon: "ant-design:mail-outlined",
                name: "email",
                value: _unref(resetPasswordHandler).values.email,
                "onUpdate:blur:value": _unref(resetPasswordHandler).handleBlur,
                "onUpdate:value": _unref(resetPasswordHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(resetPasswordHandler).errors.email), 1),
              _createVNode(ActionButton, {
                class: "mt-[24px]",
                theme: "primary",
                disable: _unref(resetPasswordHandler).form.loading && true,
                label: _unref(resetPasswordHandler).form.loading ? 'Loading...' : 'Reset Password',
                isSubmit: true
              }, null, 8, ["disable", "label"]),
              _createVNode(ActionButton, {
                class: "mt-[19px]",
                label: "Back to login",
                theme: "linked",
                handleClick: _unref(router).back
              }, null, 8, ["handleClick"])
            ], 32)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("header", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[2] || (_cache[2] = _createElementVNode("span", null, "Email Sent!", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_unref(Icon), {
                    class: "text-success",
                    icon: "ant-design:check-circle-filled",
                    width: "28",
                    height: "28"
                  })
                ])
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-sub-heading text-grey-200" }, "We have sent you an email with reset instructions", -1))
            ]),
            _createVNode(ActionButton, {
              class: "mt-[48px]",
              label: "Go to login",
              theme: "primary",
              handleClick: () => _unref(router).push('/auth/login')
            }, null, 8, ["handleClick"])
          ]))
    ]),
    _: 1
  }))
}
}

})