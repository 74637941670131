<template>
    <AppWrapper activeTab="Customers">
        <div class="lg:pt-[76px] pt-[30px]">
            <div class="lg:flex items-center justify-between">
                <div class="font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between">
                    <div>Customers</div>
                    <div class="border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden" :class="showFilterMobile && 'bg-primary-300 text-white'" @click="handleShowMobileFilter"><Icon class="" icon="fluent:options-20-filled" width="20" height="20" /></div>
                </div>

                <div class="lg:flex items-center gap-[12px] grid lg:mt-0 mt-[10px]" :class="!showFilterMobile ? 'hidden' : 'block' ">
                    <InputField type="text" placeholder="Search" prefix-icon="ant-design:search-outlined" @change="onSearch"  />
                    <DropdownField :value="searchOptionsData.since.toString()" label=""  :optionsData="searchDateOptions" @change="onSearchDateChange" />
                </div>
            </div>

            <div class="my-[28px] overflow-auto no-scrollbar">
                <TableField 
                    :headers="customersHeader" 
                    :dataItems="customersData" 
                    :isPagination="true" 
                    :viewDetails="true"
                    :routeData="{name: 'CustomerDetails', paramId: 'customerId'}"
                    pagination="client" 
                />
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
    import TableField from '@/components/shared/table/TableField.vue';
    import { useCustomersHelper } from './Customers.helper'
    import InputField from '@/components/shared/input-field/InputField.vue';
    import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { ref } from 'vue';
import { Icon } from '@iconify/vue';
    const { AppWrapper, customersHeader, onSearch, searchDateOptions, onSearchDateChange, customersData, searchOptionsData } = useCustomersHelper()

    const showFilterMobile = ref(false);
    const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value
   
</script>

<style scoped>


</style>