<template>
  <AuthWrapper>
    <div class="w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" v-if="!isResetPassword">
      <form @submit.prevent="handleSubmitPassword($event)">
        <header class="">
          <div class="text-heading-1 font-semibold text-grey-300">Set new password</div>
          <div class="text-sub-heading text-grey-200">Don't use variation of an old password or any personal information.</div>
        </header>

        <InputField 
          class="mt-[50px]" 
          placeholder="New password" 
          type="password" 
          name="newPassword"
          prefixIcon="ant-design:lock-outlined"
          :value="resetNewPasswordFormHandler.values.newPassword"
          @update:blur:value="resetNewPasswordFormHandler.handleBlur"
          @update:value="resetNewPasswordFormHandler.handleChange"
        />

        <div class="text-body-2 text-medium text-error mt-[4px]" v-if="resetNewPasswordFormHandler.errors.newPassword"> {{ resetNewPasswordFormHandler.errors.newPassword }}</div>

        <InputField 
          class="mt-[24px]"
          placeholder="Confirm password"
          type="password" 
          name="confirmPassword"
          prefixIcon="ant-design:lock-outlined"
          :value="resetNewPasswordFormHandler.values.confirmPassword"
          @update:blur:value="resetNewPasswordFormHandler.handleBlur"
          @update:value="resetNewPasswordFormHandler.handleChange"
        />

        <div class="text-body-2 text-medium text-error mt-[4px]" v-if="resetNewPasswordFormHandler.errors.confirmPassword"> {{ resetNewPasswordFormHandler.errors.confirmPassword }}</div>

        <ActionButton 
          class="mt-[24px]" 
          :label="loading ? 'loading...' : 'Reset Password'" 
          theme="primary" 
          :disable="loading"
          :isSubmit="true"
        />

        <ActionButton
          class="mt-[19px]" 
          label="Back to login" 
          theme="linked" 
          :handleClick="router.back" 
        />
      </form>
    </div>

    <div class="w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" v-else>
      <header class="">
        <div class="text-heading-1 font-semibold text-grey-300 flex gap-[20px] items-center">
          <span>All Done!</span>
          <div class="bg-[#D5FFD4] p-[2px] w-[fit-content] rounded-[50%]">
            <Icon class="text-success" icon="ant-design:check-circle-filled" width="28" height="28" /> 
          </div>
        </div>
        <div class="text-sub-heading text-grey-200">Your password has been successfully changed. You can now proceed to the login page with your new password.</div>
      </header>

      <ActionButton 
        class="mt-[48px]"
        label="Go to login"
        theme="primary"
        :handleClick="() => router.push('/auth/login')"
      />
    </div>
  </AuthWrapper>

</template>

<script setup lang='ts'>
import AuthWrapper from "@/components/app/auth-wrapper/AuthWrapper.vue"
import ActionButton from "@/components/shared/action-button/ActionButton.vue"
import InputField from "@/components/shared/input-field/InputField.vue"
import { Icon } from '@iconify/vue'
import { ref } from "vue";
import { useRouter } from "vue-router"
import { useStore } from 'vuex'
import { useAuthSession } from "@/utility/composables/useAuthSession";

const router = useRouter()
const isResetPassword = ref(false)
const store = useStore();
const loading = ref(false);

const handleIsResetPassword = () => {
  return isResetPassword.value = true
}

const { resetNewPasswordFormHandler } = useAuthSession(store, handleIsResetPassword)

const handleSubmitPassword = async (event: any) => {
  loading.value = true;
  await resetNewPasswordFormHandler.handleSubmit(event)
  loading.value = false;
}

</script>

<style lang="">

</style>