import ApiService from "./api.service";
import { storeId } from "./auth.service";

export default class SetupCheckListService {

    static async setupChecklistRequest(): Promise<any> {
        try {
            const response = await ApiService.get(`store/${storeId()}/checklist`);
            return response.data.data;
        } catch (error) {
            console.error(error)
        }
    }
}