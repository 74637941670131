<template>
    <AppWrapper activeTab="Dashboard">
        <header class="lg:pt-[72px] pt-[30px] pb-[42px] lg:flex justify-between items-center dashboard_responsive_head">
            <div class="flex justify-between items-center">
                <div class="">
                    <div class="lg:text-heading-3 text-heading-5 font-semibold text-grey-300 dashboard_responsive_header">Hi {{ profileData?.firstName }},</div>
                    <div class="text-body-2 text-grey-200 dashboard_responsive_sub_header">Welcome to your dashboard</div>
                </div>

                <div class="border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden" :class="showFilterMobile && 'bg-primary-300 text-white'" @click="handleShowMobileFilter"><Icon class="" icon="fluent:options-20-filled" width="20" height="20" /></div>
            </div>

            <div class="lg:flex gap-[32px] lg:mt-0 mt-[8px] lg:border-b-[1px] border-solid dashboard_responsive_tab" :class="!showFilterMobile ? 'hidden' : 'block' ">
                <div
                    v-for="(tab) in filterTabs"
                    :key="tab?.since"
                    :class="['py-[12px] cursor-pointer lg:text-body-2 text-caption text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab.since === tab.since }]"
                    @click="activateTab(tab)"
                > {{ tab?.label }} </div>
            </div>
        </header>

        <div class="charts">
            <div class="dashboard flex gap-[20px]">
                <div class="border-[1px] border-solid border-grey-50 p-[20px] rounded-[8px] w-[fit-content]">
                    <div class="text-grey-300 lg:text-body-1 text-body-2 font-medium">Store Traffic</div>
                    <div class="lg:text-body-2 text-caption text-grey-200 font-regular">Track the number of customer who visited your store.</div>

                    <div class="dashboard-bar-chart mt-[31px]" v-if="customersOverview?.[1]?.customersNo != 0">
                        <canvas id="barChart" class="bar-chart"></canvas>
                    </div>

                    <div class="dashboard-bar-chart lg:mt-[31px] " v-else>
                        <img src="@/assets/images/images/empty-state.svg" class="mx-[auto]" width="185" height="152" />
                        <div class="text-center text-grey-100 text-body-2 mt-[40px] px-[82px] pb-[20px]">This space will feature a pie chart showcasing your order distribution once data is available.</div>
                    </div>
                </div>

                <div v-if="doughnutChartData" class="dashboard-dougnut-chart border-solid border-[1px] border-grey-50 p-[20px] rounded-[8px] max-w-[400px]">
                    <div class="lg:text-body-1 text-body-2 font-medium mb-[30px]">
                        Most used channel
                    </div>

                    <div class="mt-[50px] w-[300px]"  v-if="!doughnutChartData?.labels?.length">
                        <img src="@/assets/images/images/empty-state.svg" class="mx-[auto]" width="185" height="152" />
                        <div class="text-center text-grey-100 text-body-2 mt-[40px]">This space will feature a pie chart showcasing your order distribution once data is available.</div>
                    </div>

                    <div class="px-[75px]" :class="doughnutChartData?.labels?.length == 0 && 'hidden'">
                        <canvas id="doughnutChart" class="doughnut-chart"></canvas> 
                    </div>

                    <ul class="legend-list text-caption font-medium" v-if="doughnutChartData?.labels?.length">
                        <li v-for="(label, index) in doughnutChartData.labels" :key="index">
                            <span :style="{ backgroundColor: doughnutChartData.datasets[0].backgroundColor[index] }"></span>
                            {{ label }}
                        </li>
                    </ul>
                </div>



                <div class="grid grid-cols-3 gap-[20px] min-w-[845px]">
                    <div v-for="(customer, index) in customersOverview" :key="index" class="text-center py-[23px] whitespace-nowrap border-[1px] border-solid border-grey-50 rounded-[8px] w-[270px] relative">
                        <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                            <div class="flex gap-[8px] items-center justify-center">
                                <Icon :style="{color: customer.color}" :icon="customer.icon" width="24" height="24" />
                                <div class="lg:text-body-2 text-caption font-medium text-grey-300">{{ customer.type }}</div>
                            </div>
                            <div :class="['text-caption', {'text-success': customer.percAmount > 0, 'text-error': customer.percAmount < 0 }]">{{ customer.percAmount }}%</div>
                            <div class="mt-[16px] font-semibold lg:text-heading-5 text-body-1">{{ customer.customersNo }}</div>
                            <div class="border-[1px] border-solid border-grey-50 h-[8px] mt-[8px] rounded-[8px] w-[114px] m-[auto]">
                                <div :style="{background: `${customer.color}`, width: `${customer.progressPerc}%`}" class="h-[6px] rounded-[8px]"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class=" mt-[42px] responsive_dashboard_best_selling">
            <div class="flex justify-between items-center">
                <div class="">
                    <div class="lg:text-heading-5 font-semibold text-grey-300 responsive_dashboard_best_selling_header">Best selling Items</div>
                    <div class="lg:text-body-2 text-overline text-grey-200 responsive_dashboard_best_selling_sub_header">Know your best selling items and maintain it.</div>
                </div>
                <router-link :to="{ name: 'BestSelling' }">
                    <div class="flex text-grey-200 items-center gap-[10px] lg:py-[11px] lg:px-[16px] py-[5px] px-[6px] border-[1px] border-solid border-grey-50 rounded-[10px] cursor-pointer responsive_dashboard_best_selling_button">
                        <div class="font-medium text-grey-300 lg:text-body-2 text-overline responsive_dashboard_best_selling_button_text">All Items</div>
                        <Icon icon="la:long-arrow-alt-right" width="24" height="24" class="w-[16px] h-[16px]" />
                    </div>
                </router-link>
            </div>
            
            <div v-if="!customersItemsOverview.length" class="bar-chart-alt flex w-[100%] border-[1px] mt-[30px] h-[12px] bg-bg-200"></div>
            <div  v-if="!customersItemsOverview.length">
                <div class=" mt-[10px] flex gap-[10px] items-center">
                    <div class="w-[10px] h-[10px] bg-bg-300 rounded-[50%]"></div>
                    <div class="">{{ 0 }}</div>
                </div>
                <div class="ml-[20px] text-grey-200 text-body-2">No available data</div>
            </div>

            <div v-if="customersItemsOverview.length"  class="w-[100%] h-[12px] border-[1px] mt-[30px] flex"> 
                <div v-for="(chartData, index) in customersItemsOverview" :key="index" :style="{background: `${chartData.color}`, width: `${chartData.progressPerc}%`}" class="h-[10px]">
                </div>
            </div>

            <div v-if="customersItemsOverview.length" class="bar-chart-alt flex lg:gap-[90px] w-[100%]">
                <div v-for="(chartData, index) in customersItemsOverview" :key="index">
                    <div class=" mt-[10px] flex gap-[10px] items-center">
                        <div :style="{background: `${chartData.color}`}" class="w-[10px] h-[10px] rounded-[50%]"></div>
                        <div class="lg:text-body-2 text-overline">{{ chartData.count }}</div>
                    </div>
                    <div class="ml-[20px] text-grey-200 lg:text-body-2 text-overline">{{ chartData.item }}</div>
                </div>
            </div>
        </div>

        <div class="mt-[42px]">
            <div class="flex justify-between items-center">
                <div class="">
                    <div class="lg:text-heading-5 font-semibold text-grey-300">Recent orders</div>
                    <div class="lg:text-body-2 text-overline text-grey-200">See your items that are sold recently.</div>
                </div>

                <router-link :to="{ name: 'Orders' }">
                    <div class="flex text-grey-200 items-center gap-[10px] lg:py-[11px] lg:px-[16px] py-[5px] px-[6px] border-[1px] border-solid border-grey-50 rounded-[10px] cursor-pointer">
                        <div class="font-medium text-grey-300 lg:text-body-2 text-overline">All orders</div>
                        <Icon icon="la:long-arrow-alt-right" width="24" height="24" class="w-[16px] h-[16px]" />
                    </div>
                </router-link>
            </div>
            
            <div class="my-[28px] text-caption">
                <TableField 
                    :headers="filteredOrderHeaders" 
                    :dataItems="storeData" 
                    :isPagination="true"
                    :viewDetails="true"
                    :routeData="{name: 'OrderDetails', paramId: 'orderId'}"
                />
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue';
import { useDashboardHelper } from './helper/Dashboard.helper'
import TableField from '@/components/shared/table/TableField.vue';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';

const { doughnutChartData, filterTabs, activateTab, customersOverview, customersItemsOverview, activeTab, AppWrapper, headers, storeData } = useDashboardHelper()
const store = useStore();
const profileData = computed(() => store.state.profile.profileData);
const showFilterMobile = ref(false);
const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value

const isMobile = ref(window.innerWidth < 768);

const filteredOrderHeaders = computed(() => {
    if (!headers) return [];
    if (isMobile.value) {
        return headers.filter(header => !['orderDate', 'paymentMethod'].includes(header.value));
    }
    return headers;
});

window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768;
});

</script>

<style scoped lang="scss">

.charts {
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar {
        display: none;
    }

    .dashboard {

        .doughnut-chart {
            width: 250px;
            height: 150px;

            @media (max-width: 1040px) {
                width: 150px
            }
        }

        .legend-list {
            // position: absolute;
            bottom: 0;
            left: 0;
            padding: 0;
            margin: 30px auto;
            list-style: none;
            width: fit-content;
            display: grid;
            grid-template-columns: auto 1fr;
            text-align: center;
            gap: 10px;
            align-items: center;
        }

        .legend-list li {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 5px;
        }

        .legend-list span {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
        }

        .dashboard-bar-chart {
            width: 575px;
        }

    }
}

</style>