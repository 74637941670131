import { Store } from "vuex";
import { useForm } from "./useForm";
import { createUser, updateUser } from "@/views/teams/Teams.helper";

export const useUserFormHandler = (userId?: string, userData?: any, store?: Store<any> | any, callback?: () => void) => {
    const createUserFormHandler = useForm({
        initialValues: {},

        onSubmit: async (values: Record<string | number, unknown | any>) => {
            console.log({values});
            try {
                const updatedValues = {
                    ...userData?.value,
                    // avatar: values.avatar,
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phone: values.phone,
                    role: values.role,
                    nin: values?.nin,
                    gender: values.gender,
                    status: values.status,
                    imageData: values?.imageData,
                    pinCode: values?.pinCode,
                    employeeCardNumber: values?.employeeCardNumber,
                    finance: {
                        ...userData?.value.finance,
                        bankName: values?.finance?.bankName || userData?.value.finance?.bankName,
                        accountNumber: values.finance?.accountNumber || userData?.value.finance?.accountNumber,
                        accountName: values.finance?.accountName ||  userData?.value.finance?.accountName,
                        tax: values.finance?.tax || userData?.value.finance?.tax,
                        salary: values.finance?.salary || userData?.value.finance?.salary
                    },
                    address: {
                        ...userData?.value?.address,
                        street: values?.address?.street || userData?.value?.address?.street,
                        city: values?.address?.city || userData?.value?.address?.city,
                        state: values?.address?.state || userData?.value?.address?.state,
                        postalCode: values?.address?.postalCode || userData?.value?.address?.postalCode,
                        country: values?.address?.country || userData?.value?.address?.country
                    },
                    schedule: {
                        ...values?.schedule
                    },
                }

                delete updatedValues.id;
                delete updatedValues._id;
                delete updatedValues.status;
                delete updatedValues.roles;
                delete updatedValues.modifiedDate;
                delete updatedValues.createdDate;
                
                updatedValues?.schedule && Object.keys(updatedValues.schedule).forEach(key => {
                    updatedValues.schedule[key]?.period.forEach((scheduleItem: any) => {
                        delete scheduleItem._id;
                        delete scheduleItem.duration;
                        delete scheduleItem.shift;
                    })
                })

                const createUserValues = {
                    avatar: values?.avatar,
                    email: values?.email,
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    phone: values?.phone,
                    nin: values?.nin,
                    role: values?.role,
                    gender: values.gender,
                    imageData: values.imageData,
                    finance: {
                        bankName: values.finance?.bankName,
                        accountNumber: values.finance?.accountNumber,
                        accountName: values.finance?.accountName,
                        tax: parseInt(values.finance?.tax, 10) || 0  ,
                        salary:  parseInt(values.finance?.salary, 10) || 0
                    },
                    address: {
                        street: values?.address?.street,
                        city: values?.address?.city,
                        state: values?.address?.state,
                        postalCode: values?.address?.postalCode,
                        country: values?.address?.country
                    },
                    schedule: {
                        ...values.schedule
                    },
                }
                userId 
                ? await updateUser(updatedValues, userId, store) 
                .then((res:  any) => {
                    if (res) return callback?.();
                    else return;
                })
                : await createUser(createUserValues, store).then((res: any) => {
                    if (res == undefined) return callback?.();
                    else return;
                });

            } catch (error) {
                console.error(`${userId ? 'cannot update user. error:' : 'cannot create user. error:' }`, error);
            }
        }
    })
 
    return { createUserFormHandler }
}