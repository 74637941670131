import { Store } from "vuex";
import ApiService from "./api.service"
import { storeId } from "./auth.service"

export default class PaymentService {
    
    static async getPaymentsRequest(): Promise<unknown> {
        try {
            const response = await ApiService.get(`store/${storeId()}/payments`);
            return response.data.data;
        } catch (error) {
            console.error('Unhandled get payment Error', error)
        }
    }

    static async updatePaymentsRequest(values: Record<string | number, unknown>, store: Store<any>): Promise<unknown> {
        try {
            const response = await ApiService.put(`store/${storeId()}/payments`, values);
            store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Payment Updated', x_message: `Updated ${values.type} payment account successfully`, duration: 5000 });
            return response;
        } catch (error: any) {
            console.error('Unhandled update cash payment Error', error)
            store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error updating payment', x_message: `${error?.response.data.error}`, duration: 5000 })
        }
    }
}