import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex gap-[32px] border-b-[1px] border-solid mt-[24px]" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "mt-[24px]"
}
const _hoisted_4 = { class: "flex items-center gap-[10px] mt-[8px]" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "mt-[24px] grid grid-cols-2 gap-[24px]" }
const _hoisted_7 = { class: "mt-[24px]" }
const _hoisted_8 = { class: "flex gap-[32px]" }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px] border-grey-50" }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px] border-grey-50" }
const _hoisted_15 = { class: "border-t-[1px] border-solid border-grey-50 mt-[52px]" }
const _hoisted_16 = { class: "flex w-[fit-content] ml-[auto] gap-[12px]" }

import InputField from '@/components/shared/input-field/InputField.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
import { useMenuFormHelper } from './MenuForm.helper'
import { Icon } from '@iconify/vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { useMenuFormHandler } from '@/utility/composables/useMenuFormHandler'
import { computed, ref } from 'vue';
import UploadImage from '@/components/shared/upload-image/UploadImage.vue';
import { addToInputList, deleteFromInputList, uploadFile } from '@/utility/helper/utils.helper';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import { useStore } from 'vuex';

interface Props {
    handleAddMenuItems?: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuForm',
  props: {
    handleAddMenuItems: {}
  },
  setup(__props: any) {

const props = __props
const imageData = ref();
const store = useStore()
const { activateTab, activeTab, tabs, categoriesData } = useMenuFormHelper()
const { createMenuFormHandler } = useMenuFormHandler('', store, props.handleAddMenuItems)
const menuData = ref(createMenuFormHandler.values);

const imageDataFromEmitedBase64 = (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    imageData.value = imageDataParts?.[1];
}

const categoriesOptions = computed(() => {
    if (!categoriesData.value || !categoriesData.value.categories) {
        return [];
    }

    return categoriesData.value.categories.map((category: any) => ({
        name: category.category,
        value: category.id
    }));
});

const handleCategoryChange = (event: any) => {
    const { value } = event.target
    const categoryData =  categoriesData.value.categories.find((category: any) => value == category.id);

    const newCategoryData = {
        ...menuData.value,
        category: categoryData.category,
        categoryId: categoryData.id
    }

    createMenuFormHandler.setValues(newCategoryData);
}

const addMenuTypeHandler = (menuType: string) => {
    const newMenuType = menuData.value?.[menuType] == undefined 
    ? [{name: '', price: ''}] 
    : [...menuData.value?.[menuType], {name: '', price: ''}]
    const newMenuTypeData = {
        ...menuData.value,
        [menuType]: newMenuType
    }

    menuData.value[menuType] = newMenuTypeData
    createMenuFormHandler.setValues(newMenuTypeData);
}

const handleChangeMenuTypeValue = (event: any, index: any, menuType: string) => {
    const { name, value } = event.target;
    console.log({event});
    
    const updatedSizesData = [ ...menuData.value?.[menuType] ]
    const updatedSize = {...menuData.value?.[menuType]?.[index]}

    updatedSize[name] = value;

    updatedSizesData[index] = updatedSize;
    menuData.value[menuType] = updatedSizesData;

    createMenuFormHandler.setValues(menuData.value);
}


const handleCreateMenuSubmit = async (event: Event) => {
    event.preventDefault();

    createMenuFormHandler.values.imageData = imageData.value;

    await createMenuFormHandler.handleSubmit(event);
    menuData.value = createMenuFormHandler.values;
    // props.handleAddMenuItems()
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (handleCreateMenuSubmit($event)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabs), (tab, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab) === index }]),
          onClick: ($event: any) => (_unref(activateTab)(index))
        }, _toDisplayString(tab), 11, _hoisted_2))
      }), 128))
    ]),
    (_unref(activeTab) === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(InputField, {
            label: "Name",
            placeholder: "Name",
            type: "text",
            name: "name",
            value: _unref(createMenuFormHandler).values.name,
            "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
            "onUpdate:value": _unref(createMenuFormHandler).handleChange
          }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
          _createVNode(TextArea, {
            label: "Description",
            placeholder: "Description",
            class: "mt-[24px]",
            name: "description",
            value: _unref(createMenuFormHandler).values.description,
            "onUpdate:value": _unref(createMenuFormHandler).handleChange
          }, null, 8, ["value", "onUpdate:value"]),
          _createVNode(UploadImage, {
            name: "imageData",
            onBase64ImgCode: imageDataFromEmitedBase64,
            "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
            "onUpdate:value": _unref(createMenuFormHandler).handleFileChange
          }, null, 8, ["onUpdate:blur:value", "onUpdate:value"]),
          _createVNode(DropdownField, {
            optionsData: categoriesOptions.value,
            "default-option-label": "Select",
            label: "Category",
            class: "",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (handleCategoryChange($event)))
          }, null, 8, ["optionsData"]),
          _createVNode(InputField, {
            label: "Add Ingredients",
            placeholder: "Ingredients Name",
            type: "text",
            name: "ingredients",
            class: "mt-[24px]",
            onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers((event) => _unref(addToInputList)(event, menuData.value, _unref(createMenuFormHandler)), ["prevent"]), ["enter"])),
            undertext: "Tap \"Enter Key\" to add more restaurant type"
          }),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuData.value?.ingredients, (ingredient, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption",
                key: idx
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(ingredient), 1),
                _createVNode(_unref(Icon), {
                  icon: "ant-design:close-outlined",
                  width: "12",
                  height: "12",
                  onClick: () => _unref(deleteFromInputList)('ingredients', idx, menuData.value, _unref(createMenuFormHandler))
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(InputField, {
              label: "Cost Price",
              prefixIcon: "fa6-solid:naira-sign",
              type: "number",
              name: "cost",
              value: _unref(createMenuFormHandler).values.cost == '' ? '0.00' : _unref(createMenuFormHandler).values.cost,
              "onUpdate:value": _unref(createMenuFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:value"]),
            _createVNode(InputField, {
              label: "Selling Price",
              prefixIcon: "fa6-solid:naira-sign",
              type: "number",
              name: "price",
              value: _unref(createMenuFormHandler).values.price == '' ? '0.00' : _unref(createMenuFormHandler).values.price,
              "onUpdate:value": _unref(createMenuFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:value"]),
            _createVNode(InputField, {
              label: "Quantity",
              type: "number",
              name: "totalQuantity",
              value: _unref(createMenuFormHandler).values.totalQuantity,
              "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
              "onUpdate:value": _unref(createMenuFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
            _createVNode(InputField, {
              label: "Remaining Quantity",
              type: "number",
              name: "remainingQuantity",
              value: _unref(createMenuFormHandler).values.remainingQuantity,
              "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
              "onUpdate:value": _unref(createMenuFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "text-body-2 text-grey-200 mb-[7px] inline-block" }, "Avalability", -1)),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(RadioButton, {
                value: "true",
                name: "isAvailable",
                onChange: _unref(createMenuFormHandler).handleChange
              }, null, 8, ["onChange"]),
              _createVNode(RadioButton, {
                value: "false",
                name: "isAvailable",
                onChange: _unref(createMenuFormHandler).handleChange
              }, null, 8, ["onChange"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(activeTab) === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuData.value.sizes, (size, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "sizes items-center gap-[12px] mt-[32px]",
              key: idx
            }, [
              _createVNode(InputField, {
                label: "Name",
                type: "text",
                placeholder: "Name",
                name: "name",
                value: size.name,
                "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                "onUpdate:value": ($event: any) => (handleChangeMenuTypeValue($event, idx, 'sizes'))
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Price",
                type: "number",
                placeholder: "Price",
                name: "price",
                value: size.price,
                "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                "onUpdate:value": ($event: any) => (handleChangeMenuTypeValue($event, idx, 'sizes'))
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createElementVNode("div", {
                class: "bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content] cursor-pointer",
                onClick: ($event: any) => (_unref(deleteFromInputList)('sizes', idx, menuData.value, _unref(createMenuFormHandler)))
              }, [
                _createVNode(_unref(Icon), {
                  icon: "ant-design:delete-filled",
                  class: "text-primary-300 w-[24px] h-[24px]"
                })
              ], 8, _hoisted_10)
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(ActionButton, {
              prefixIcon: "ant-design:plus-outline",
              label: "Add size",
              class: "w-[fit-content] m-[auto]",
              theme: "outlined",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (addMenuTypeHandler('sizes')))
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(activeTab) === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuData.value.extras, (extra, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "sizes items-center gap-[12px] mt-[32px]",
              key: idx
            }, [
              _createVNode(InputField, {
                label: "Name",
                type: "text",
                placeholder: "Name",
                name: "name",
                value: extra.name,
                "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                "onUpdate:value": ($event: any) => (handleChangeMenuTypeValue($event, idx, 'extras'))
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Price",
                type: "number",
                placeholder: "Price",
                name: "price",
                value: extra.price,
                "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                "onUpdate:value": ($event: any) => (handleChangeMenuTypeValue($event, idx, 'extras'))
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createElementVNode("div", {
                class: "bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content] cursor-pointer",
                onClick: ($event: any) => (_unref(deleteFromInputList)('extras', idx, menuData.value, _unref(createMenuFormHandler)))
              }, [
                _createVNode(_unref(Icon), {
                  icon: "ant-design:delete-filled",
                  class: "text-primary-300 w-[24px] h-[24px]"
                })
              ], 8, _hoisted_13)
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(ActionButton, {
              prefixIcon: "ant-design:plus-outline",
              label: "Add Extra",
              class: "w-[fit-content] m-[auto]",
              theme: "outlined",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (addMenuTypeHandler('extras')))
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(ActionButton, {
          theme: "secondary",
          label: "Clear form",
          class: "w-[fit-content] mt-[22px]",
          prefixIcon: "ant-design:delete-outlined"
        }),
        _createVNode(ActionButton, {
          theme: "primary",
          label: "Save",
          class: "w-[fit-content] mt-[22px]",
          isSubmit: true
        })
      ])
    ])
  ], 32))
}
}

})