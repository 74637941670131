import StoreService from "@/utility/services/store.service"
import { ref, watchEffect } from "vue";

export const useAppWrapperHelper = () => {
    const storesProfile = ref();

    const getStores = async () => {
        try {
            const response = await StoreService.getStoresRequest();
            return response;
        } catch (error) {
            console.error(error)
        }
    }

    watchEffect(() => {

        getStores().then((res: any) => {
            if (res) {
                const { stores } = res;
                storesProfile.value = stores
            }
        })
    })
    
    return { storesProfile, getStores }
}
