import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/images/empty-state.svg'


const _hoisted_1 = { class: "overflow-auto no-scrollbar border-[1px] border-solid border-grey-50 rounded-[8px] p-[1px] responsive_table" }
const _hoisted_2 = { class: "min-w-full divide-y divide-gray-200" }
const _hoisted_3 = { class: "bg-bg-200" }
const _hoisted_4 = { class: "flex items-center justify-between" }
const _hoisted_5 = {
  key: 0,
  class: "px-[20px] py-[17px] text-left text-xs font-medium text-gray-400 capitalize tracking-wider"
}
const _hoisted_6 = {
  key: 1,
  class: "px-[20px] py-[17px] text-left text-xs font-medium text-gray-400 capitalize tracking-wider"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["colspan"]
const _hoisted_9 = {
  key: 0,
  class: "bg-white divide-y divide-gray-200"
}
const _hoisted_10 = {
  key: 2,
  class: "w-fit"
}
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "text-caption text-grey-300" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "text-caption text-grey-300" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "text-caption text-grey-300" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "text-caption text-grey-300" }
const _hoisted_19 = {
  key: 0,
  class: ""
}
const _hoisted_20 = { class: "text-caption text-grey-300" }
const _hoisted_21 = { class: "p-[10px] bg-bg-200 w-[fit-content] rounded-[4px] cursor-pointer" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "text-body-1" }
const _hoisted_24 = { class: "text-grey-200 text-body-3 mt-[4px]" }
const _hoisted_25 = {
  key: 0,
  class: "p-[20px]"
}
const _hoisted_26 = { class: "text-caption border-[1px] border-solid w-[fit-content] rounded-[8px] px-[10px] py-[5px] m-[auto]" }
const _hoisted_27 = { key: 0 }
const _hoisted_28 = {
  key: 1,
  class: "p-[20px]"
}
const _hoisted_29 = { class: "text-caption border-[1px] border-solid w-[fit-content] rounded-[8px] px-[10px] py-[5px] m-[auto]" }
const _hoisted_30 = {
  key: 0,
  class: "text-center my-[50px]"
}
const _hoisted_31 = {
  key: 0,
  class: "lg:block"
}

import { computed, ref, watchEffect, watch } from 'vue';
  import { Icon } from '@iconify/vue';
  import { Header, DataItem, RouteData } from './TableField.helper';
  import EntityCard from '@/components/shared/entity-card/EntityCard.vue';
  import TablePagination from '../pagination/TablePagination.vue';
  import StatusLabel from '@/components/shared/status-label/StatusLabel.vue';
  import ToggleSlider from '../toggle-slider/ToggleSlider.vue';
  import TableLoader from '../table-loader/TableLoader.vue';
  
  interface Props {
    headers: Header[]
    dataItems: DataItem[] | any
    isPagination?: boolean
    routeData?: RouteData
    viewDetails?: boolean
    viewDetailsText?: string
    handleViewDetailsClick?: () => void
    currentPage?: number
    totalItems?: number
    loading?: boolean
    editDetails?: boolean
    editDetailsText?: string
    uriQuery?: string
    pagination?: 'client' | 'server'

  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'TableField',
  props: {
    headers: {},
    dataItems: {},
    isPagination: { type: Boolean },
    routeData: {},
    viewDetails: { type: Boolean },
    viewDetailsText: {},
    handleViewDetailsClick: { type: Function },
    currentPage: {},
    totalItems: {},
    loading: { type: Boolean },
    editDetails: { type: Boolean },
    editDetailsText: {},
    uriQuery: {},
    pagination: {}
  },
  emits: ['currentPage', 'pageSize', 'handleToogleChange', 'openModal', 'handleViewDetailsClick'],
  setup(__props: any, { emit: __emit }) {

  const props = __props;

  const pagination = ref(props.pagination || 'client');
  
  const currentPage = ref(1);
  const pageSize = ref(10);
  const emit = __emit;
  
  watchEffect(() => {
    emit('currentPage', currentPage.value);
    emit('pageSize', pageSize.value);
    // console.log({ rrI: props.totalItems });
  });
  
  const paginatedDataItems = computed(() => {
    const startIndex = (currentPage.value - 1) * pageSize.value;
    const endIndex = startIndex + pageSize.value;
    return props.dataItems?.slice(startIndex, endIndex);
  });
  
  const handlePageChange = (page: number) => {
    console.log('Page change:', page);
    props.pagination === 'server' ? null : currentPage.value = page;
    emit('currentPage', page);
  };
  
  watch(() => props.currentPage, (newPage) => {
    console.log('Prop currentPage changed:', newPage);
    currentPage.value = newPage || 1;
  });
  
  const handlePageSizeChange = (size: number) => {
    pageSize.value = size;
    currentPage.value = 1;
  };

  
return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", _hoisted_3, [
          _createElementVNode("tr", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
              return (_openBlock(), _createElementBlock("th", {
                key: index,
                class: "px-[20px] py-[17px] text-left lg:text-body-2 text-caption font-medium text-gray-500 capitalize tracking-wider"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, _toDisplayString(header.name), 1),
                  (header?.icon)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        icon: header?.icon,
                        class: "ml-1",
                        width: "24",
                        height: "24"
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
                ])
              ]))
            }), 128)),
            (_ctx.viewDetails)
              ? (_openBlock(), _createElementBlock("th", _hoisted_5, _cache[1] || (_cache[1] = [
                  _createElementVNode("div", { class: "" }, null, -1)
                ])))
              : _createCommentVNode("", true),
            (_ctx.editDetails)
              ? (_openBlock(), _createElementBlock("th", _hoisted_6, _cache[2] || (_cache[2] = [
                  _createElementVNode("div", { class: "" }, null, -1)
                ])))
              : _createCommentVNode("", true)
          ]),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                _createElementVNode("td", {
                  colspan: _ctx.headers.length + (_ctx.viewDetails ? 1 : 0)
                }, [
                  _createVNode(TableLoader, { loading: _ctx.loading }, null, 8, ["loading"])
                ], 8, _hoisted_8)
              ]))
            : _createCommentVNode("", true)
        ]),
        (!_ctx.loading)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paginatedDataItems.value, (data, index) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: index,
                  class: "hover:bg-gray-50 lg:text-body-2 text-caption"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, hIndex) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: hIndex,
                      class: "p-[20px]"
                    }, [
                      (header.value === 'entityCard')
                        ? (_openBlock(), _createBlock(EntityCard, {
                            key: 0,
                            name: data.name,
                            description: data.description,
                            "image-url": data.imageUrl,
                            "sub-description": data.subDescription
                          }, null, 8, ["name", "description", "image-url", "sub-description"]))
                        : _createCommentVNode("", true),
                      (header.value === 'statusCard')
                        ? (_openBlock(), _createBlock(StatusLabel, {
                            key: 1,
                            status: data.status
                          }, null, 8, ["status"]))
                        : _createCommentVNode("", true),
                      (header.value === 'OrderDetails')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data?.orderDetails, (details, idx) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: idx,
                                class: _normalizeClass({'border-b border-gray-200 pb-5 mb-3': idx < data.orderDetails.length - 1})
                              }, [
                                _createElementVNode("div", _hoisted_11, [
                                  _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-grey-200 text-caption font-semibold" }, "Menu Name: ", -1)),
                                  _createElementVNode("span", _hoisted_12, _toDisplayString(details?.menuName), 1)
                                ]),
                                _createElementVNode("div", _hoisted_13, [
                                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-grey-200 text-caption font-semibold" }, "Category: ", -1)),
                                  _createElementVNode("span", _hoisted_14, _toDisplayString(details?.category), 1)
                                ]),
                                _createElementVNode("div", _hoisted_15, [
                                  _cache[5] || (_cache[5] = _createElementVNode("span", { class: "text-grey-200 text-caption font-semibold" }, "Quantity: ", -1)),
                                  _createElementVNode("span", _hoisted_16, _toDisplayString(details?.quantity), 1)
                                ]),
                                _createElementVNode("div", _hoisted_17, [
                                  _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-grey-200 text-caption font-semibold" }, "Price: ", -1)),
                                  _createElementVNode("span", _hoisted_18, "₦" + _toDisplayString(details?.price.toLocaleString()), 1)
                                ]),
                                (details?.note)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-grey-200 text-caption font-semibold" }, "Note: ", -1)),
                                      _createElementVNode("span", _hoisted_20, _toDisplayString(details?.note || 'N/A'), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 2))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true),
                      (header.value === 'available')
                        ? (_openBlock(), _createBlock(ToggleSlider, {
                            key: 3,
                            name: "status",
                            isChecked: data.isAvailable,
                            "onOn:change": ($event: any) => (_ctx.$emit('handleToogleChange', data))
                          }, null, 8, ["isChecked", "onOn:change"]))
                        : _createCommentVNode("", true),
                      (header.value === 'action')
                        ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 4,
                            to: { name: _ctx.routeData?.name, params: { id: data?.[_ctx.routeData?.paramId as any] }}
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_21, [
                                _createVNode(_unref(Icon), {
                                  icon: "ant-design:edit-outlined",
                                  class: "ml-1 text-grey-200",
                                  width: "20",
                                  height: "20"
                                })
                              ])
                            ]),
                            _: 2
                          }, 1032, ["to"]))
                        : _createCommentVNode("", true),
                      (header.value === 'action-callback')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 5,
                            class: "p-[10px] bg-bg-200 w-[fit-content] rounded-[4px] cursor-pointer",
                            onClick: ($event: any) => (_ctx.$emit('openModal', data))
                          }, [
                            _createVNode(_unref(Icon), {
                              icon: "ant-design:edit-outlined",
                              class: "ml-1 text-grey-200",
                              width: "20",
                              height: "20"
                            })
                          ], 8, _hoisted_22))
                        : _createCommentVNode("", true),
                      (header.value === 'orderEntity')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                            _createElementVNode("h5", _hoisted_23, _toDisplayString(data.orderNumber), 1),
                            _createElementVNode("p", _hoisted_24, _toDisplayString(data.orderDate), 1)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                            _createTextVNode(_toDisplayString(data?.[header.value as any]), 1)
                          ], 64))
                    ]))
                  }), 128)),
                  (_ctx.editDetails)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_25, [
                        _createVNode(_component_router_link, {
                          to: { 
                                name: `${_ctx.routeData?.name}`, 
                                params: { id: data?.[_ctx.routeData?.paramId as any] }, 
                                query: { search: _ctx.uriQuery } 
                            },
                          onClick: ($event: any) => (_ctx.$emit('handleViewDetailsClick', data))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_26, [
                              (_ctx.editDetailsText)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.editDetailsText), 1))
                                : (_openBlock(), _createBlock(_unref(Icon), {
                                    key: 1,
                                    icon: "ant-design:edit-outlined",
                                    class: "ml-1 text-grey-200",
                                    width: "20",
                                    height: "20"
                                  }))
                            ])
                          ]),
                          _: 2
                        }, 1032, ["to", "onClick"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.viewDetails)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_28, [
                        _createVNode(_component_router_link, {
                          to: { name:  _ctx.routeData?.name, params: { id: data?.[_ctx.routeData?.paramId as any] }},
                          onClick: ($event: any) => (_ctx.$emit('handleViewDetailsClick', data))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.viewDetailsText || 'View Details'), 1)
                          ]),
                          _: 2
                        }, 1032, ["to", "onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.loading && _ctx.dataItems?.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_30, _cache[8] || (_cache[8] = [
            _createElementVNode("img", {
              src: _imports_0,
              class: "mx-[auto]",
              width: "185",
              height: "152"
            }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isPagination && _ctx.dataItems?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
          _createVNode(TablePagination, {
            paginationMode: pagination.value as 'client' as 'server',
            currentPage: currentPage.value,
            "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((currentPage).value = $event)),
            totalItems: pagination.value === 'server' ? _ctx.totalItems : _ctx.dataItems || 0,
            pageSize: pageSize.value,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange
          }, null, 8, ["paginationMode", "currentPage", "totalItems", "pageSize"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})