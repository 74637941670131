import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logos/food-cart.svg'


const _hoisted_1 = ["width", "height"]




export default /*@__PURE__*/_defineComponent({
  __name: 'StandaloneLogo',
  props: {
  width: Number,
  height: Number
},
  setup(__props) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("img", {
      width: __props.width,
      height: __props.height,
      src: _imports_0
    }, null, 8, _hoisted_1)
  ]))
}
}

})