<template>
    <AppWrapper activeTab="Customers">
        <div class="lg:py-[72px] py-[30px] lg:w-[800px] lg:m-[auto]">
            <div class="flex items-center gap-[6px] lg:text-body-1 text-body-2">
                <div class="flex items-center gap-[6px] text-grey-100 cursor-pointer" @click="router.back()">
                    <Icon icon="ant-design:shopping-outlined" />
                    <div>Customer</div>
                </div>
                <div>/</div>
                <div>{{ customerDetailsData?.customerInfo?.firstName }} {{ customerDetailsData?.customerInfo?.lastName }}</div>
            </div>

            <div class="border-[1px] border-solid border-grey-50 rounded-[8px] lg:mt-[32px] mt-[20px]">
                <header class="lg:py-[24px] lg:px-[32px] p-[10px]">
                    <div class="flex items-center justify-between">
                        <div class="font-semibold lg:text-heading-5 lg:mt-[6px]">Customer Details</div>
                    </div>
                </header>

                <main class="">
                    <section class="">
                        <main>
                            <div class="lg:px-[32px] px-[10px] lg:py-[28px] py-[]">
                                <div class="grid lg:grid-cols-2 gap-[24px]">
                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Name</div>
                                        <div class="font-medium lg:text-body-1 text-body-2 mt-[4px]">{{ customerDetailsData?.customerInfo?.firstName }} {{ customerDetailsData?.customerInfo?.lastName }} </div>
                                    </div>

                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Email</div>
                                        <div class="font-medium lg:text-body-1 text-body-2 mt-[4px]">{{ customerDetailsData?.customerInfo?.email }}</div>
                                    </div>

                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Phone Number</div>
                                        <div class="font-medium lg:text-body-1 text-body-2 mt-[4px]">{{ customerDetailsData?.customerInfo?.phone }}</div>
                                    </div>

                                    
                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Registered On</div>
                                        <div class="font-medium lg:text-body-1 text-body-2 mt-[4px]">{{ customerDetailsData?.customerInfo?.registeredDate }}</div>
                                    </div>
                                    
                                    
                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Address</div>
                                        <div class="font-medium lg:text-body-1 text-body-2 mt-[4px]">{{ customerDetailsData?.customerInfo?.address?.street }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="grid lg:grid-cols-2 gap-[24px] lg:px-[32px] px-[10px] pb-[24px] lg:mt-0 mt-[20px]">
                                <div class="flex items-center gap-[16px] px-[20px] py-[15px] bg-[#CFFFE0] rounded-[8px]">
                                    <div class="">
                                        <ImageCard :imageUrl="require('@/assets/images/icons/rocket.svg')" width="42" height="42" />
                                    </div>
                                    <div class="lg:text-body-2 text-caption">
                                        <div class="text-grey-200">Most Ordered Item <span class="font-semibold">· {{ customerDetailsData?.favoriteMenu?.orderCount }} times</span></div>
                                        <div class="font-semibold">{{ customerDetailsData?.favoriteMenu?.favoriteFood }}</div>
                                    </div>
                                </div>

                                <div class="flex items-center gap-[16px] px-[20px] py-[15px] bg-[#FFF4D0] rounded-[8px]">
                                    <div class="">
                                        <ImageCard :imageUrl="require('@/assets/images/icons/beach-restaurant.svg')" width="42" height="42" />
                                    </div>
                                    <div class="lg:text-body-2 text-caption">
                                        <div class="text-grey-200">Most Used Channel <span class="font-semibold">· {{ customerDetailsData?.favoriteChannel?.orderCount }} times</span></div>
                                        <div class="font-semibold">{{ customerDetailsData?.favoriteChannel?.orderChannel }}</div>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="border-t-[1px] border-solid border-grey-50 lg:px-[32px] px-[10px] pb-[20px]">
                                <div class="flex gap-[32px] border-b-[1px] border-solid mt-[24px] overflow-auto no-scrollbar">
                                    <div
                                        v-for="(tab) in orderTabsCustomers"
                                        :key="tab?.status"
                                        :class="['py-[12px] cursor-pointer lg:text-body-2 text-caption lg:whitespace-none whitespace-nowrap text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeCustTab.status === tab.status }]"
                                        @click="activateTab(tab)"
                                    > {{ tab?.status }} ({{ tab?.orderCount }}) </div>
                                </div>

                                <div class="mt-[24px] overflow-auto no-scrollbar">
                                    <TableField 
                                        :headers="customOrderHeaders" 
                                        :dataItems="customOrderData" 
                                        :isPagination="true" 
                                        pagination="client"
                                    />
                                </div>
                            </div>

                            <!-- <div class="flex items-center justify-between border-t-[1px] border-solid border-grey-50 py-[20px] px-[32px]">
                                <div class="text-body-2 font-medium text-grey-200">Order 1 of 84</div>
                                <div class="flex items-center gap-[10px] font-medium border-[2px] border-solid border-grey-50 px-[16px] py-[11px] text-grey-200 rounded-[8px]">
                                    <div class="text-body-2">Next Customer</div>
                                    <div class=""><Icon icon="ant-design:arrow-right-outlined" class="w-[24px] h-[24px]" /></div>
                                </div>
                            </div> -->
                        </main>
                    </section>
                </main>
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
    import { useRouter } from 'vue-router';
    import { useCustomersHelper, getCustomerById, getCustomerOrders } from './Customers.helper';
    import { defineProps, watchEffect, ref } from 'vue'
    import { Icon } from '@iconify/vue';
    import { sortBy, sum } from "lodash";

    import TableField from '@/components/shared/table/TableField.vue';
    import ImageCard from '@/components/shared/image/ImageCard.vue';

    import { useOrderHelper, ActiveTab } from '../Orders/Order.helper';

    const { AppWrapper, customerDetailsData } = useCustomersHelper()
    const { customOrderHeaders, orderTabsCustomers, customOrderData } = useOrderHelper()

    const router = useRouter()

    interface Props {
        id: string
    }

    export interface SearchOptions {
        status: string;
    }

    const custSearchOptionsData = ref<SearchOptions>({
        status: ''
    });

    const props = defineProps<Props>()
    
    const activeCustTab = ref<ActiveTab>({
        status: "All",
        orderCount: 0
    });

    const activateTab = (tab: ActiveTab) => {
        activeCustTab.value = tab;
        custSearchOptionsData.value.status = tab.status;
    };

    watchEffect(() => {
        getCustomerById({customerId: props.id}).then((responseData: any)=> {
            if (responseData) {
                customerDetailsData.value = responseData;
            }
        });
        getCustomerOrders({customerId: props.id, status: custSearchOptionsData.value.status}).then((responseData: any)=> {
            if (responseData) {
                const { orderStatus, orders } = responseData;
                const totalOrderCount = sum(orderStatus.map((item: { orderCount: number; }) => item.orderCount));
                orderStatus.push({ status: "All", orderCount: totalOrderCount });
                orderTabsCustomers.value = sortBy(orderStatus, ['status']);
                customOrderData.value = orders.map((item: any) => {
                    return {
                        ...item,
                        name: item.menuName,
                        description: `Category: ${item.category}`,
                        subDescription: `Qty: ${item.quantity} Channel: ${item.orderChannel}`,
                    }
                });
            }
        });
    });
</script>

<style scoped>
.order-image {
    width: 92px;
    height: 92px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>