<template>
    <AppWrapper activeTab="Settings">
        <div class="">
            <header class="pt-[72px] pb-[24px] flex justify-between items-center">
                <div class="">
                    <div class="text-heading-3 font-semibold text-grey-300">Settings</div>
                    <div class="text-body-2 text-grey-200">View and manage settings related to your store.</div>
                </div>
            </header>

            <main class="">
                <div class="flex gap-[32px] border-b-[1px] border-solid">
                    <div
                        v-for="(tab, index) in settingsTabs"
                        :key="index"
                        :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                        @click="activateTab(index)"
                    > {{ tab }} </div>
                </div>

                <ProfileSettings v-if="activeTab == 0" />

                <StoreDetails v-if="activeTab == 1" />

                <div v-if="activeTab == null" class="mt-[32px]">
                    <div class="text-grey-200">Team Properties</div>
                    <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]">
                        <header class="flex items-center justify-between">
                            <div class="">
                                <div class="font-semibold text-sub-heading">Team Properties</div>
                                <div class="text-grey-200 text-caption">Define, manage, and update team roles and permissions.</div>
                            </div>
                            <ActionButton label="Add new role" theme="primary" class="w-[fit-content]" />
                        </header>
                    </div>

                    <div class="team-properties border-[1px] border-solid border-grey-50 rounded-[8px] mt-[20px]">
                        <header class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                            <div class="py-[17px] px-[20px]">Actions</div>
                            <div class="py-[17px] px-[40px] text-center">Admin</div>
                            <div class="py-[17px] px-[40px] text-center">Manager</div>
                            <div class="py-[17px] px-[40px] text-center">Cashier</div>
                            <div class="py-[17px] px-[20px] text-center"><Icon icon="ant-design:ellipsis-outlined" class="w-[24px] h-[24px]" /></div>
                        </header>

                        <header class="flex items-center justify-between py-[18px] px-[20px] bg-bg-200 mt-[12px]">
                            <div class="flex items-center gap-[10px] text-grey-200">
                                <div class=""><Icon icon="ant-design:profile-outlined" class="w-[24px] h-[24px]" /></div>
                                <div class="text-grey-300 font-semibold">Menu management</div>
                            </div>
                            <div class="text-grey-200 cursor-pointer" @click="handleMenuManagement"><Icon :icon="isMenuManagement ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="w-[18px] h-[18px]" /></div>
                        </header>

                        <div class="" v-if="isMenuManagement">
                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Add New Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Edit Existing Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Delete Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Set Menu Item Availability</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header">
                                <div class="py-[17px] px-[20px]">Customization Sizes and Extra</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>
                        </div>

                        <!-- Second accordion -->

                        <header class="flex items-center justify-between py-[18px] px-[20px] bg-bg-200 mt-[12px]">
                            <div class="flex items-center gap-[10px] text-grey-200">
                                <div class=""><Icon icon="ant-design:idcard-outlined" class="w-[24px] h-[24px]" /></div>
                                <div class="text-grey-300 font-semibold">Team management</div>
                            </div>
                            <div class="text-grey-200 cursor-pointer" @click="handleTemaManagement"><Icon :icon="isTeamManagement ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="w-[18px] h-[18px]" /></div>
                        </header>

                        <div class="" v-if="isTeamManagement">
                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Add New Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Edit Existing Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Delete Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Set Menu Item Availability</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header">
                                <div class="py-[17px] px-[20px]">Customization Sizes and Extra</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>
                        </div>

                        <!-- Third accordion -->

                        <header class="flex items-center justify-between py-[18px] px-[20px] bg-bg-200 mt-[12px]">
                            <div class="flex items-center gap-[10px] text-grey-200">
                                <div class=""><Icon icon="ant-design:setting-outlined" class="w-[24px] h-[24px]" /></div>
                                <div class="text-grey-300 font-semibold">App settings</div>
                            </div>
                            <div class="text-grey-200 cursor-pointer" @click="handleAppSettings"><Icon :icon="isAppSettings ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="w-[18px] h-[18px]" /></div>
                        </header>

                        <div class="" v-if="isAppSettings">
                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Add New Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Edit Existing Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Delete Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Set Menu Item Availability</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header">
                                <div class="py-[17px] px-[20px]">Customization Sizes and Extra</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>
                        </div>

                        <!-- Fourth accordion -->

                        <header class="flex items-center justify-between py-[18px] px-[20px] bg-bg-200 mt-[12px]">
                            <div class="flex items-center gap-[10px] text-grey-200">
                                <div class=""><Icon icon="ant-design:file-outlined" class="w-[24px] h-[24px]" /></div>
                                <div class="text-grey-300 font-semibold">Invoice management</div>
                            </div>
                            <div class="text-grey-200 cursor-pointer" @click="handleInvoice"><Icon :icon="isInvoiceManagement ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="w-[18px] h-[18px]" /></div>
                        </header>

                        <div class="" v-if="isInvoiceManagement">
                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Add New Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Edit Existing Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Delete Menu Item</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Set Menu Item Availability</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>

                            <div class="team-properties-table-header border-b-[1px] border-solid border-grey-50">
                                <div class="py-[17px] px-[20px]">Customization Sizes and Extra</div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[40px]"><CheckboxInput /></div>
                                <div class="py-[17px] px-[20px]"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <AdditionalChargesFees v-if="activeTab == 2" />

                <MicrositePersonalization v-if="activeTab == 3" />

                <DeliveryOption v-if="activeTab == 4" />

                <PayoutAccount v-if="activeTab == 5" />
            </main>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
import { useSettingsHelper } from './Settings.helper'
import { Icon } from '@iconify/vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import CheckboxInput from '@/components/shared/checkbox-input/CheckboxInput.vue';
import { ref, watchEffect } from 'vue';
import StoreDetails from './StoreDetails.vue';
import PayoutAccount from './PayoutAccount.vue';
import ProfileSettings from './ProfileSettings.vue';
import MicrositePersonalization from './MicrositePersonalization.vue';
import AdditionalChargesFees from './AdditionalChargesFees.vue';
import DeliveryOption from './DeliveryOption.vue';

const { settingsTabs, activateTab, activeTab } = useSettingsHelper()
const isMenuManagement = ref(true)
const isTeamManagement = ref(false)
const isAppSettings = ref(false)
const isInvoiceManagement = ref(false)

const url = window.location.href;
const params = new URLSearchParams(new URL(url).search);
const qValue = params.get('q');

const handleMenuManagement = () => {
    isMenuManagement.value = !isMenuManagement.value
}

const handleTemaManagement = () => {
    isTeamManagement.value = !isTeamManagement.value
}

const handleAppSettings = () => {
    isAppSettings.value = !isAppSettings.value;
};

const handleInvoice = () => {
    isInvoiceManagement.value = !isInvoiceManagement.value;
};

watchEffect(() => {
    switch (qValue) {
        case 'storeDetails':
            return activeTab.value = 1;
        case 'deliveryOptions':
            return activeTab.value = 4;
        case 'payoutAccount':
            return activeTab.value = 5;
        case 'additionalCharges':
            return  activeTab.value = 2
        default:
            return activeTab.value = 0
    }
})

</script>

<style scoped lang="scss">
.team-properties {

    &-table-header {
        display: grid;
        grid-template-columns: 1fr repeat(3, 8%) auto;
    }
}
</style>