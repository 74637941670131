import { ref, onMounted, onUnmounted } from 'vue';

export const useAuthCarousel = (images: string[], texts: string[]) => {
  const activeIndex = ref(0);
  const currentImage = ref(images[activeIndex.value]);
  const currentText = ref(texts[activeIndex.value]);

  let interval: any = null;

  const goTo = (index: number) => {
    activeIndex.value = index;
    updateContent();
  }

  const updateContent = () => {
    currentImage.value = images[activeIndex.value];
    currentText.value = texts[activeIndex.value];
  }

  onMounted(() => {
    interval = setInterval(() => {
      activeIndex.value = (activeIndex.value + 1) % images.length;
      updateContent();
    }, 8000);
  });

  onUnmounted(() => {
    if (interval) {
      clearInterval(interval);
    }
  });

  return { activeIndex, currentImage, currentText, goTo };
}
