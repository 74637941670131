import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] login_responsive" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "hidden login_responsive_logo" }
const _hoisted_4 = { class: "text-body-2 text-medium text-error mt-[4px]" }
const _hoisted_5 = { class: "text-body-2 text-medium text-error mt-[4px]" }

import AuthWrapper from "@/components/app/auth-wrapper/AuthWrapper.vue";
import ActionButton from "@/components/shared/action-button/ActionButton.vue";
import InputField from "@/components/shared/input-field/InputField.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useAuthSession } from "@/utility/composables/useAuthSession";
import { useStore } from "vuex";
import StandaloneLogo from "@/components/shared/standalone-logo/StandaloneLogo.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginView',
  setup(__props) {

const router = useRouter();
const showPassword = ref(false);
const store = useStore();
const { loginFormHandler } = useAuthSession(store)
const handleShowPassword = () => showPassword.value = !showPassword.value;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthWrapper, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(StandaloneLogo)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-heading-1 font-semibold text-grey-300 login_responsive_header" }, "Welcome back!", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-sub-heading text-grey-200 login_responsive_sub_header" }, "Please sign in to continue.", -1))
        ]),
        _createElementVNode("form", {
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_unref(loginFormHandler).handleSubmit && _unref(loginFormHandler).handleSubmit(...args)), ["prevent"]))
        }, [
          _createVNode(InputField, {
            class: "mt-[50px]",
            placeholder: "Email",
            type: "email",
            prefixIcon: "ant-design:mail-outlined",
            name: "email",
            value: _unref(loginFormHandler).values.email,
            "onUpdate:blur:value": _unref(loginFormHandler).handleBlur,
            "onUpdate:value": _unref(loginFormHandler).handleChange
          }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(loginFormHandler).errors.email), 1),
          _createVNode(InputField, {
            class: "mt-[24px]",
            placeholder: "Password",
            type: showPassword.value ? 'text' : 'password',
            prefixIcon: "ant-design:lock-outlined",
            suffixIcon: showPassword.value ? 'ant-design:eye-invisible-outlined' : 'ant-design:eye-outlined',
            handleClick: handleShowPassword,
            name: "password",
            value: _unref(loginFormHandler).values.password,
            "onUpdate:blur:value": _unref(loginFormHandler).handleBlur,
            "onUpdate:value": _unref(loginFormHandler).handleChange
          }, null, 8, ["type", "suffixIcon", "value", "onUpdate:blur:value", "onUpdate:value"]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(loginFormHandler).errors.password), 1),
          _createVNode(ActionButton, {
            disable: _unref(loginFormHandler).form.loading && true,
            class: "mt-[24px]",
            label: _unref(loginFormHandler).form.loading ? 'Loading...' : 'Sign In',
            theme: "primary",
            isSubmit: true
          }, null, 8, ["disable", "label"]),
          _createVNode(ActionButton, {
            class: "mt-[19px]",
            label: "Forget Password",
            theme: "linked",
            handleClick: () => _unref(router).push('/auth/reset_password')
          }, null, 8, ["handleClick"])
        ], 32)
      ])
    ]),
    _: 1
  }))
}
}

})