import MenusService from "@/utility/services/menus.service";
import { ref, watchEffect } from "vue";
import { getMenuById } from "../Menu.helper";
import { useMenuFormHandler } from "@/utility/composables/useMenuFormHandler";
import { Store } from "vuex";

export const updateMenu = async (values: Record<string | number, unknown>, menuId: string, store: any, callback?: () => void) => {
    try {
        await MenusService.updateMenuRequest(values, menuId);
        callback?.();
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Menu updated', x_message: `Updated "${values.name}" menu successfully`, duration: 5000 });
    } catch (error: any) {
        console.error(error)
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error updating menu', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
}

export const getCategories = async () => {
    try {
        const response = await MenusService.getCategoriesRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const useMenuHelper = (menuId: string, store: Store<any>) => {
    const tabs = ref(['Details', 'Sizes', 'Extras']);
    const activeTab = ref(0);
    const categoriesData = ref()
    const { createMenuFormHandler } = useMenuFormHandler(menuId, store);
    const menuData = ref(createMenuFormHandler.values);

    const activateTab = (index: number) => {
        activeTab.value = index;
    };

    watchEffect(() => {
        getCategories().then((response) => {
            categoriesData.value = response;
        })
    })

    watchEffect(() => {
        getMenuById(menuId).then((response) => {
            menuData.value = response;
        })
    })

    return { tabs, activateTab, activeTab, categoriesData, menuData, createMenuFormHandler }
}