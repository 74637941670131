<template>
    <AppWrapper activeTab="Reports">
        <div class="">
            <header class="pt-[72px] flex justify-between items-center">
                <div class="">
                    <div class="text-heading-3 font-semibold text-grey-300">Reports and Analysis</div>
                </div>
            </header>
            <main>
                <div class="flex gap-[32px] border-b-[1px] border-solid">
                    <div
                        v-for="(tab, index) in reportTabs"
                        :key="index"
                        :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                        @click="activateTab(index)"
                    > {{ tab }} </div>
                </div>
                <SalesReport v-if="activeTab == 0" />
                <CustomerReport v-if="activeTab == 1" />
            </main>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
    import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
    import SalesReport from './SalesReport.vue';
    import CustomerReport from './CustomerReport.vue';
    import { useReportHelper } from './Report.helper';

    const { reportTabs, activateTab, activeTab  } = useReportHelper();
</script>

<style scoped lang="scss">
</style>