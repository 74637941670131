<template>
  <div class="grid grid-cols-2 auth_responsive">
    <div class="bg-[#F5F5F5] h-screen p-[42px] relative">
      <StandaloneLogo />

      <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
        <div class="w-[fit-content] mt-[114px] mx-[auto]">
          <img width="500" height="500" :src="currentImage" />
        </div>

        <div class="text-heading-3 text-center mt-[42px] capitalize w-[600px] m-[auto]">
          {{ currentText }}
        </div>

        <div class="flex gap-8 justify-center items-center mt-[42px]">
          <div v-for="(dot, index) in dots" :key="index" 
            class="h-[18px] w-[18px] bg-[#D9D9D966] rounded-full" 
            :class="{ 'bg-primary-300': index === activeIndex }"
            @click="goTo(index)">
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <slot></slot> 
    </div>
  </div>
</template>

<script setup lang='ts'>
import { defineProps } from 'vue';
import StandaloneLogo from '../../shared/standalone-logo/StandaloneLogo.vue'
import { useAuthCarousel } from './AuthWrapper.helper';

defineProps({
  activePage: {
    type: String as () => ('login' | 'forget-password' | 'reset-done'), 
    default: 'login',
  }
})

const images = [
  require("@/assets/images/backgrounds/auth-image.svg"),
  require("@/assets/images/backgrounds/auth-image-2.svg"),
  require("@/assets/images/backgrounds/auth-image-3.svg")
];

const texts = [
  "manage sales, inventory, and transactions in one place",
  "Optimize your business operations seamlessly",
  "Take full control with a simple tap – easy management at your fingertips"
];

const { activeIndex, currentImage, currentText, goTo } = useAuthCarousel(images, texts);
const dots = Array.from({ length: images.length });

</script>

<style lang="scss" scoped>
  .carousel-item {
    opacity: 0;
    transition: opacity 0.5s ease; /* Adjust timing and easing function as needed */
  }

  .carousel-item.active {
    opacity: 1;
  }

  @media (max-width: 1040px) {
    .auth_responsive {
      grid-template-columns: 1fr;
        
      .h-screen {
        display: none;
      }
    }
  }
</style>
