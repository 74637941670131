<template>
    <div class="mt-[32px]">
        <div class="card">
            <div>
                <DataTable ref="customerDataTable" :value="data" sortMode="multiple" removableSort
                    v-model:filters="filters"
                    v-model:expandedRows="expandedRows"
                    :globalFilterFields="['registrationDate', 'firstName', 'lastName', 'phoneNumber', 'totalOrders' ]"
                    filterDisplay="row"
                    paginator 
                    :rows="25" 
                    resizableColumns 
                    columnResizeMode="fit"
                    showGridlines
                    :rowsPerPageOptions="[10, 25, 50]"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    stripedRows 
                    dataKey="customerId"
                    tableStyle="min-width: 50rem">
                    <template #header>
                        <span class="text-xl font-bold">Customer Report</span>
                        <!-- <div style="text-align:left">
                            <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header" @update:modelValue="onToggle"
                                display="chip" placeholder="Select Columns" />
                        </div> -->
                        <span class="flex justify-end">
                            <!-- <Button text icon="pi pi-plus" label="Expand All" @click="expandAll" />
                            <Button text icon="pi pi-minus" label="Collapse All" @click="collapseAll" /> -->
                            <IconField>
                                <InputText v-model="filters['global'].value" placeholder="Keyword Search" /> 
                                <InputIcon class="p-iconfield">
                                    <i class="pi pi-search" />
                                </InputIcon>
                            </IconField> &nbsp;&nbsp;&nbsp;
                            <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                        </span>
                    </template>
                    <template #empty>No customer data found. </template>
                    <template #loading>Loading Customer data. Please wait. </template>
                    <Column field="registrationDate" sortable header="Registration  Date" style="min-width: 13rem"></Column>
                    <Column field="firstName" header="First Name" sortable>
                        <template #body="{ data }">
                            {{ data.firstName }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by first name" />
                        </template>
                    </Column>
                    <Column field="lastName" header="Last Name" sortable>
                        <template #body="{ data }">
                            {{ data.lastName }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by last name" />
                        </template>
                    </Column>
                    <Column field="phoneNumber" sortable header="Phone Number">
                        <template #body="{ data }">
                            {{ data.phoneNumber }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by phone" />
                        </template>
                    </Column>
                    <Column field="totalOrders" sortable header="Total Orders">
                        <template #body="{ data }">
                            {{ data.totalOrders }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" @input="filterCallback()" placeholder="Search by number of orders" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>  
    </div>
</template>

<script setup lang="ts">
    import { useStore } from 'vuex';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';    
    import IconField from 'primevue/iconfield';    
    import InputIcon from 'primevue/inputicon';
    import Button from 'primevue/button';
    import Tag from 'primevue/tag';
    import Select from 'primevue/select';
    import MultiSelect from 'primevue/multiselect';
    import { useCustomerReportHelper } from './Report.helper'
    import { formatPrice } from '../../utility/helper/utils.helper'
    const store = useStore();
    const { onToggle, columns, selectedColumns, expandAll, collapseAll, onRowCollapse, onRowExpand, loading, data, expandedRows, filters, statuses, getSeverity, customerDataTable, exportCSV } =  useCustomerReportHelper(store);
</script>

<style scoped>

</style>