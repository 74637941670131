<template>
    <AppWrapper activeTab="Teams">
        <div class="lg:py-[76px] py-[30px]" v-show="isOpen">
            <div class="lg:flex items-center justify-between">
                <div class="font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between">
                    <div>Teams</div>
                    <div class="border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden" :class="showFilterMobile && 'bg-primary-300 text-white'" @click="handleShowMobileFilter"><Icon class="" icon="fluent:options-20-filled" width="20" height="20" /></div>
                </div>
                <div class="lg:flex items-center gap-[12px] grid lg:mt-0 mt-[15px]" :class="!showFilterMobile ? 'hidden' : 'block' ">
                    <InputField type="text" placeholder="Search" prefix-icon="ant-design:search-outlined" @change="onSearch"  />
                    <ActionButton theme="primary" label="New Member" class="lg:w-[fit-content]" @click="handleOpenEditModal" />
                </div>
            </div>

            <div class="flex gap-[32px] border-b-[1px] border-solid mt-[24px]">
                <div
                    v-for="(tab, index) in teamTabs"
                    :key="index"
                    :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                    @click="activateTab(index)"
                > {{ tab }} </div>
            </div>

            <div class="my-[28px]" v-if="activeTab == 0">
                <TableField
                    :headers="usersTableHeader"
                    :dataItems="usersTableValue() || []"
                    :isPagination="true"
                    :viewDetails="true"
                    :edit-details="true"
                    :uri-query="queryParams.search"
                    :routeData="{name: 'TeamDetails', paramId: 'id'}"
                />
            </div>

            <div class="my-[28px]" v-if="activeTab == 1">
                <main class="w-full">
                    <FullCalendar
                        ref="calendarRef"
                        :options="{
                            timeZone: 'UTC',
                            themeSystem: 'bootstrapFontAwesome',
                            plugins: [ dayGridPlugin,  timeGridPlugin, interactionPlugin ],
                            initialView: calendarView,
                            events: calendarScheduleEvents(),
                            slotEventOverlap: false,
                            headerToolbar: headerToolbarView
                        }"
                    >
                        <template v-slot:eventContent='arg'>
                            <div class="">
                                <div class="flex items-center gap-[6px] w-full px-[8px] bg-white">
                                    <div class="bg-primary-300 w-[6px] h-[6px] rounded-full"></div>
                                    <div class="text-overline font-semibold text-grey-100 mt-[3px]">{{ arg.event.id }}</div>
                                    <div class="text-caption font-semibold text-grey-300">{{ arg.event.title }}</div>
                                </div>
                            </div>
                        </template>
                    </FullCalendar>
                </main>
            </div>


            <div class="my-[28px]" v-if="activeTab == 2">
                <div class="lg:flex items-center justify-between gap-4 mb-4">
                    <div class="text-heading-5 underline">{{ dayNames[new Date(selectedAttendanceYear, selectedAttendanceMonth - 1, selectedDay).getDay()] }}</div> <!-- Display the selected day as text -->

                    <div class="lg:flex grid gap-[10px] lg:mt-0 mt-4">
                        <DropdownField 
                            :optionsData="days"
                            :value="selectedDay.toString()"
                            @change="onAttendanceDayChange"
                        />
                        <DropdownField 
                            :optionsData="monthsOptions"
                            :value="selectedAttendanceMonth.toString()"
                            @change="onAttendanceMonthChange"
                        />
                        <DropdownField 
                            :optionsData="yearsOptions"
                            :value="selectedAttendanceYear.toString()"
                            @change="onAttendanceYearChange"
                        />
                    </div>
                </div>

                <div >
                    <TableField
                        v-if="!isMobile"
                        :headers="clockInOutTableHeader"
                        :dataItems="attendaceTableValue() || []"
                        :isPagination="true"
                    />

                    <div class="flex flex-col" v-if="isMobile">
                        <div v-for="item in attendaceTableValue()" :key="item.name" class="border-b p-4">
                            <div class="flex grid-cols-2">
                                <EntityCard :image-url="item.imageUrl"  />
                                <div class="text-caption">
                                    <div class="font-semibold">{{ item.name }}</div>
                                    <div class="text-overline">Clock-In: {{ item.clockIn }}</div>
                                    <div class="text-overline">Clock-Out: {{ item.clockOut || 'Not registered' }}</div>
                                    <div class="text-overline">Total Hours: {{ item.totalHours }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="!isOpen" class="w-[800px] m-[auto] py-[72px]">
            <div class="">
                <div class="text-heading-3 flex items-center gap-[24px] mb-[28px]">
                    <div class="cursor-pointer" @click="handleOpenEditModal">
                        <Icon icon="ant-design:arrow-left-outlined" />
                    </div>

                    <div class="font-semibold">Create Member</div>
                </div>
            </div>

            <NewMember :handleOpenEditModal="handleOpenEditModal" />
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
    import TableField from '@/components/shared/table/TableField.vue';
    import { useCustomersHelper, getUsers, getEmployeeSchedule, getAttendance } from './Teams.helper'
    import { ref, watchEffect, computed } from 'vue';
    import InputField from '@/components/shared/input-field/InputField.vue';
    import { SearchOptions } from '../menu/MenuView.vue';
    import ActionButton from '@/components/shared/action-button/ActionButton.vue';
    import { Icon } from '@iconify/vue';
    import NewMember from './NewMember.vue'
    import FullCalendar from '@fullcalendar/vue3';
    import dayGridPlugin from '@fullcalendar/daygrid'; 
    import interactionPlugin from '@fullcalendar/interaction'
    import timeGridPlugin from '@fullcalendar/timegrid'
    import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
    import EntityCard from '@/components/shared/entity-card/EntityCard.vue';

    const isOpen = ref(true)
    const teamTabs = ref([`Members (0)`, 'Schedules (0)', 'Clock-in/Out']);
    const showFilterMobile = ref(false);
    const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value
    const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() + i);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 
        'August', 'September', 'October', 'November', 'December'
    ];
    const monthsOptions = months.map((month, index) => ({ name: month, value: index + 1 }));
    const yearsOptions = years.map(year => ({ name: year.toString(), value: year }));
    const defaultEmployeeSchedule = ref([]); 
    const calendarRef: any = ref(null);
    const attendanceData = ref()
    const days: any = ref([]); 

    const queryParams = ref({
        search: '?editModal=true', 
    });
    
    const handleOpenEditModal = () => {
        isOpen.value = !isOpen.value;
    };

    const usersData: any = ref([]);

    const searchOptionsData = ref<SearchOptions>({
        search: '',
    });

    const { AppWrapper, activateTab, activeTab } = useCustomersHelper()
    const onSearch = (event: any) => {
        searchOptionsData.value.search = event.target.value;
    };

    const usersTableHeader = ref([
        {name: 'Member', value: 'entityCard'},
        {name: 'Role', icon: 'ant-design:sort-ascending-outlined', value: 'role'},
        {name: 'Status', value: 'status'},
        {name: 'Last Updated', value: 'lastUpdated'},
    ])

    const clockInOutTableHeader = ref([
        {name: 'Member', value: 'entityCard'},
        {name: 'Clock-In Time', value: 'clockIn'},
        {name: 'Clock-Out Time', value: 'clockOut'},
        {name: 'Total Hours', value: 'totalHours'},
    ])

    const usersTableValue = () => {
        return usersData.value?.users?.map((user: Record<string | number, unknown | any>) => {
            return {
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                imageUrl:`${user.avatar}?v=${new Date().getTime()}`,
                description: user.email,
                subDescription: user.phone,
                role: user.role || 'Not registered',
                status: user.status,
                lastUpdated: user.modifiedDate || '________'
            }
        })
    }

    const attendaceTableValue = () => {
        return attendanceData.value?.attendence?.map((a: Record<string | number, unknown | any>) => {
            return {
                name: `${a.firstName} ${a.lastName}`,
                imageUrl:`${a.avatar}?v=${new Date().getTime()}`,
                clockIn: a.clockIn,
                clockOut: a.clockOut || 'Not registered',
                totalHours: a.totalHours,
            }
        })
    }

    const selectedAttendanceMonth = ref(new Date().getMonth() + 1); 
    const selectedAttendanceYear = ref(new Date().getFullYear()); 

    const calendarScheduleEvents = () => {
        return defaultEmployeeSchedule.value;
    }

    watchEffect(() => {
        getEmployeeSchedule({}).then((response: any) => {
            defaultEmployeeSchedule.value = response.employeeSchedule;
        });
    });

    watchEffect(() => {
        getUsers({search: searchOptionsData.value.search})
        .then((response: any) => {
            usersData.value = response;
            teamTabs.value = [`Members (${response?.total})`, `Schedules (${defaultEmployeeSchedule.value.length})`, 'Clock-in/Out'];
        })
    })

    watchEffect(() => {
        getAttendance({year: "", month: "", date: ""})
        .then((response: any) => {
            attendanceData.value = response;
        })
    })

    const getDaysInMonth = (month: number, year: number) => {
        return new Date(year, month, 0).getDate();
    };

    const today = new Date();
    const selectedDay = ref(today.getDate()); 

    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    watchEffect(() => {
        const daysInSelectedMonth = getDaysInMonth(selectedAttendanceMonth.value, selectedAttendanceYear.value);
        days.value = Array.from({ length: daysInSelectedMonth }, (_, i) => ({
            name: (i + 1).toString(),
            value: (i + 1) 
        }));
    });

    watchEffect(() => {
        if (selectedDay.value > days.value.length) {
            selectedDay.value = days.value.length;
        }
    });

    const onAttendanceDayChange = (e: any) => {
        selectedDay.value = e.target.value; 
        fetchAttendance();
    };

    const onAttendanceMonthChange = (e: any) => {
        selectedAttendanceMonth.value = e.target.value;
        fetchAttendance(); 
    };

    const onAttendanceYearChange = (e: any) => {
        selectedAttendanceYear.value = e.target.value;
        fetchAttendance();
    };

    const fetchAttendance = () => {
        getAttendance({
            year: selectedAttendanceYear.value.toString(),
            month: selectedAttendanceMonth.value.toString(),
            date: selectedDay.value.toString(),
        })
        .then((response: any) => {
            attendanceData.value = response;
        });
    };

    const isMobile = ref(window.innerWidth < 768);

    window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth < 768;
    });

    const calendarView = computed(() => {
        return isMobile.value ? 'timeGridDay' : 'dayGridMonth';
    });

    const headerToolbarView = computed(() => {
        return isMobile.value ? {
            left: 'prev,next',
            center: 'title',
            right: ''
        } : 
        {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
        }; 
    })

</script>