import { createMenu } from "@/views/menu/menu-form/MenuForm.helper";
import { useForm } from "./useForm";
import { updateMenu } from "@/views/menu/update-menu/UpdateMenu.helper";
import { Store } from "vuex";
import { useRouter } from "vue-router";

export const useMenuFormHandler = (menuId?: string, store?: Store<any>, callback?: any) => {
    const router = useRouter()
    const createMenuFormHandler = useForm({
        initialValues: {},

        onSubmit: async (values: any) => {
            try {
                const updatedValues = { ...values };
                delete updatedValues.id;
                delete updatedValues._id;
                delete updatedValues.status;
                delete updatedValues.image;

                menuId ? await updateMenu({...updatedValues}, menuId as string, store, () => router.back()) 
                : await createMenu({...values}, store)
                .then(() => {
                    callback?.()
                })
            } catch (error) {
                console.error("cannot create menu. error:", error);
            }
        }
    })
 
    return { createMenuFormHandler }
}