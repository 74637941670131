<template>
    <div class="fixed top-4 right-4 z-50 space-y-4">
        <div v-if="showToast" :class="toastXStyleType(theme)" class="px-[20px] py-[16px] rounded-[8px] max-w-[500px]">
            <div class="flex justify-between items-center">
                <div class="flex gap-[10px] items-center">
                    <Icon :icon="toastXIconType(theme)" width="20" height="20" />
                    <div class="text-grey-200 text-body-2 font-semibold">{{ x_title || 'Alert Title' }}</div>
                </div>
                <Icon icon="ant-design:close-outlined" width="12" height="12" class="text-grey-200 font-semibold" @click="closeToast" />
            </div>

            <div class="text-grey-200 mt-[4px] text-caption ml-[28px] mr-[25px]">{{ x_message || 'Interactively monetize corporate alignments and fully tested niche markets.' }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const showToast = computed(() => store.state.ToastX?.showToast);
const theme = computed(() => store.state.ToastX.theme);
const x_title = computed(() => store.state.ToastX.x_title);
const x_message = computed(() => store.state.ToastX.x_message);

const closeToast = () => {
  store.commit('ToastX/hideToast');
};


const toastXIconType: any = (theme: string) => {
    switch (theme) {
        case 'success':
            return 'ant-design:check-circle-filled';
        case 'error':
            return 'ant-design:close-circle-filled';
        case 'info':
            return 'ant-design:info-circle-filled';
        case 'warning':
            return 'ant-design:exclamation-circle-filled'
    }
}

const toastXStyleType = (theme: string) =>  {
    switch (theme) {
        case 'success':
            return 'bg-[#F6FFED] text-[#52C41A] border-[1px] border-solid border-[#B7EB8F]'
        case 'warning':
            return 'bg-[#FFFBE6] text-[#FAAD14] border-[1px] border-solid border-[#FFE58F]'
        case 'info':
            return 'bg-[#E6F4FF] text-[#1677FF] border-[1px] border-solid border-[#91CAFF]'  
        case 'error':
            return 'bg-[#FFF1F0] text-[#FF4D4F] border-[1px] border-solid border-[#FFA39E]'
    }
}
</script>

<style>

</style>