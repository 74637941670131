import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "text-grey-200 text-caption" }
const _hoisted_6 = { class: "text-grey-200 text-caption" }
const _hoisted_7 = { class: "px-[28px] pt-[32px]" }
const _hoisted_8 = { class: "grid grid-cols-2 gap-[10px] mt-[32px]" }
const _hoisted_9 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }

import { ref, watch, watchEffect } from 'vue';
import { getBankDetails, getPayoutAccountProfile } from './Settings.helper';
import AppModal from '@/components/shared/modal/AppModal.vue';
import { PayoutAccount } from '@/utility/services/settings.service';
import { useSettingsHandler } from '@/utility/composables/useSettingsRequestsHandler';
import InputField from '@/components/shared/input-field/InputField.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PayoutAccount',
  setup(__props) {

const payoutData = ref<PayoutAccount>()
const loading = ref(false);
const isPayoutUpdateModal = ref(false);
const banksDetailsData = ref()
const { updatePayoutAccountFormHandler } = useSettingsHandler()

const handleShowPayoutUpdatemodal = () => {
    isPayoutUpdateModal.value = !isPayoutUpdateModal.value;
};

const handleOpenPayoutUpdateModalSubmit = async (event: unknown) => {
    loading.value = true;
    await updatePayoutAccountFormHandler.handleSubmit(event);
    payoutData.value = updatePayoutAccountFormHandler.values;
    handleShowPayoutUpdatemodal();
    loading.value = false;
}

const handleBankChange = (event: any) => {
    const { value } = event.target
    const bankData =  banksDetailsData.value.find((bankD: any) => value == bankD.name);
    const newPayoutData = {
        ...payoutData.value, 
        accountProvider: bankData.name, 
        accountProviderCode: bankData.code
    }
    updatePayoutAccountFormHandler.setValues(newPayoutData);
}

watchEffect(() => {
    loading.value = true;
    getPayoutAccountProfile().then((response) => {
        payoutData.value = response;
        loading.value = false;
    })
})

watchEffect(() => {
    getBankDetails().then((response) => {
        banksDetailsData.value = response;
    })
})

watch(payoutData, (newPayoutData) => {
    updatePayoutAccountFormHandler.setValues(newPayoutData);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-grey-200" }, "Manage your payout account below.", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["font-semibold text-sub-heading", {'animate-pulse bg-slate-100 h-[22px] w-[250px] rounded' : loading.value}])
          }, _toDisplayString(payoutData.value?.accountName), 3),
          _createElementVNode("div", _hoisted_5, [
            _cache[2] || (_cache[2] = _createTextVNode("Account No: ")),
            _createElementVNode("span", {
              class: _normalizeClass({'animate-pulse bg-slate-100 h-[16px] w-[60px] rounded inline-block mt-[4px]' : loading.value})
            }, _toDisplayString(payoutData.value?.accountNumber), 3)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[3] || (_cache[3] = _createTextVNode("Bank Name: ")),
            _createElementVNode("span", {
              class: _normalizeClass({'animate-pulse bg-slate-100 h-[16px] w-[60px] rounded inline-block' : loading.value})
            }, _toDisplayString(payoutData.value?.accountProvider), 3)
          ])
        ]),
        _createElementVNode("div", {
          class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] py-[11px] w-[fit-content] text-body-2 cursor-pointer",
          onClick: handleShowPayoutUpdatemodal
        }, "Update")
      ])
    ]),
    _createVNode(AppModal, {
      headerContent: "Update Payout Account",
      openModal: isPayoutUpdateModal.value,
      onClose: handleShowPayoutUpdatemodal
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleOpenPayoutUpdateModalSubmit($event)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(DropdownField, {
              label: "Select Bank",
              name: "accountProvider",
              value: payoutData.value?.accountProvider,
              optionsData: banksDetailsData.value,
              onChange: _cache[0] || (_cache[0] = ($event: any) => (handleBankChange($event)))
            }, null, 8, ["value", "optionsData"]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(InputField, {
                label: "Account Name",
                type: "text",
                value: payoutData.value?.accountName,
                name: "accountName",
                "onUpdate:blur:value": _unref(updatePayoutAccountFormHandler).handleBlur,
                "onUpdate:value": _unref(updatePayoutAccountFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createVNode(InputField, {
                label: "Account Number",
                type: "number",
                value: payoutData.value?.accountNumber,
                name: "accountNumber",
                "onUpdate:blur:value": _unref(updatePayoutAccountFormHandler).handleBlur,
                "onUpdate:value": _unref(updatePayoutAccountFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
            ])
          ]),
          _createElementVNode("footer", _hoisted_9, [
            _createVNode(ActionButton, {
              label: "Cancel",
              theme: "secondary",
              class: "w-[fit-content]",
              onClick: handleShowPayoutUpdatemodal
            }),
            _createVNode(ActionButton, {
              theme: "primary",
              class: "w-[fit-content]",
              isSubmit: true,
              disable: loading.value && true,
              label: loading.value ? 'Loading...' : 'Save'
            }, null, 8, ["disable", "label"])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["openModal"])
  ]))
}
}

})