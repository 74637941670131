import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
}
const _hoisted_2 = {
  key: 0,
  class: "text-body-2 text-medium text-error mt-[4px]"
}
const _hoisted_3 = {
  key: 1,
  class: "text-body-2 text-medium text-error mt-[4px]"
}
const _hoisted_4 = {
  key: 1,
  class: "w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]"
}
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "text-heading-1 font-semibold text-grey-300 flex gap-[20px] items-center" }
const _hoisted_7 = { class: "bg-[#D5FFD4] p-[2px] w-[fit-content] rounded-[50%]" }

import AuthWrapper from "@/components/app/auth-wrapper/AuthWrapper.vue"
import ActionButton from "@/components/shared/action-button/ActionButton.vue"
import InputField from "@/components/shared/input-field/InputField.vue"
import { Icon } from '@iconify/vue'
import { ref } from "vue";
import { useRouter } from "vue-router"
import { useStore } from 'vuex'
import { useAuthSession } from "@/utility/composables/useAuthSession";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePassword',
  setup(__props) {

const router = useRouter()
const isResetPassword = ref(false)
const store = useStore();
const loading = ref(false);

const handleIsResetPassword = () => {
  return isResetPassword.value = true
}

const { resetNewPasswordFormHandler } = useAuthSession(store, handleIsResetPassword)

const handleSubmitPassword = async (event: any) => {
  loading.value = true;
  await resetNewPasswordFormHandler.handleSubmit(event)
  loading.value = false;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthWrapper, null, {
    default: _withCtx(() => [
      (!isResetPassword.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("form", {
              onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleSubmitPassword($event)), ["prevent"]))
            }, [
              _cache[1] || (_cache[1] = _createElementVNode("header", { class: "" }, [
                _createElementVNode("div", { class: "text-heading-1 font-semibold text-grey-300" }, "Set new password"),
                _createElementVNode("div", { class: "text-sub-heading text-grey-200" }, "Don't use variation of an old password or any personal information.")
              ], -1)),
              _createVNode(InputField, {
                class: "mt-[50px]",
                placeholder: "New password",
                type: "password",
                name: "newPassword",
                prefixIcon: "ant-design:lock-outlined",
                value: _unref(resetNewPasswordFormHandler).values.newPassword,
                "onUpdate:blur:value": _unref(resetNewPasswordFormHandler).handleBlur,
                "onUpdate:value": _unref(resetNewPasswordFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              (_unref(resetNewPasswordFormHandler).errors.newPassword)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(resetNewPasswordFormHandler).errors.newPassword), 1))
                : _createCommentVNode("", true),
              _createVNode(InputField, {
                class: "mt-[24px]",
                placeholder: "Confirm password",
                type: "password",
                name: "confirmPassword",
                prefixIcon: "ant-design:lock-outlined",
                value: _unref(resetNewPasswordFormHandler).values.confirmPassword,
                "onUpdate:blur:value": _unref(resetNewPasswordFormHandler).handleBlur,
                "onUpdate:value": _unref(resetNewPasswordFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              (_unref(resetNewPasswordFormHandler).errors.confirmPassword)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(resetNewPasswordFormHandler).errors.confirmPassword), 1))
                : _createCommentVNode("", true),
              _createVNode(ActionButton, {
                class: "mt-[24px]",
                label: loading.value ? 'loading...' : 'Reset Password',
                theme: "primary",
                disable: loading.value,
                isSubmit: true
              }, null, 8, ["label", "disable"]),
              _createVNode(ActionButton, {
                class: "mt-[19px]",
                label: "Back to login",
                theme: "linked",
                handleClick: _unref(router).back
              }, null, 8, ["handleClick"])
            ], 32)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("header", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[2] || (_cache[2] = _createElementVNode("span", null, "All Done!", -1)),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(Icon), {
                    class: "text-success",
                    icon: "ant-design:check-circle-filled",
                    width: "28",
                    height: "28"
                  })
                ])
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-sub-heading text-grey-200" }, "Your password has been successfully changed. You can now proceed to the login page with your new password.", -1))
            ]),
            _createVNode(ActionButton, {
              class: "mt-[48px]",
              label: "Go to login",
              theme: "primary",
              handleClick: () => _unref(router).push('/auth/login')
            }, null, 8, ["handleClick"])
          ]))
    ]),
    _: 1
  }))
}
}

})