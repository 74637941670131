import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "flex justify-end" }

import { useStore } from 'vuex';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';    
    import IconField from 'primevue/iconfield';    
    import InputIcon from 'primevue/inputicon';
    import Button from 'primevue/button';
    import Tag from 'primevue/tag';
    import Select from 'primevue/select';
    import MultiSelect from 'primevue/multiselect';
    import { useCustomerReportHelper } from './Report.helper'
    import { formatPrice } from '../../utility/helper/utils.helper'
    
export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerReport',
  setup(__props) {

    const store = useStore();
    const { onToggle, columns, selectedColumns, expandAll, collapseAll, onRowCollapse, onRowExpand, loading, data, expandedRows, filters, statuses, getSeverity, customerDataTable, exportCSV } =  useCustomerReportHelper(store);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createVNode(_unref(DataTable), {
          ref_key: "customerDataTable",
          ref: customerDataTable,
          value: _unref(data),
          sortMode: "multiple",
          removableSort: "",
          filters: _unref(filters),
          "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => (_isRef(filters) ? (filters).value = $event : null)),
          expandedRows: _unref(expandedRows),
          "onUpdate:expandedRows": _cache[3] || (_cache[3] = ($event: any) => (_isRef(expandedRows) ? (expandedRows).value = $event : null)),
          globalFilterFields: ['registrationDate', 'firstName', 'lastName', 'phoneNumber', 'totalOrders' ],
          filterDisplay: "row",
          paginator: "",
          rows: 25,
          resizableColumns: "",
          columnResizeMode: "fit",
          showGridlines: "",
          rowsPerPageOptions: [10, 25, 50],
          paginatorTemplate: "RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink",
          currentPageReportTemplate: "{first} to {last} of {totalRecords}",
          stripedRows: "",
          dataKey: "customerId",
          tableStyle: "min-width: 50rem"
        }, {
          header: _withCtx(() => [
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "text-xl font-bold" }, "Customer Report", -1)),
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_unref(IconField), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(InputText), {
                    modelValue: _unref(filters)['global'].value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(filters)['global'].value) = $event)),
                    placeholder: "Keyword Search"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_unref(InputIcon), { class: "p-iconfield" }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createElementVNode("i", { class: "pi pi-search" }, null, -1)
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _cache[5] || (_cache[5] = _createTextVNode("     ")),
              _createVNode(_unref(Button), {
                icon: "pi pi-external-link",
                label: "Export",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(exportCSV)($event)))
              })
            ])
          ]),
          empty: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("No customer data found. ")
          ])),
          loading: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("Loading Customer data. Please wait. ")
          ])),
          default: _withCtx(() => [
            _createVNode(_unref(Column), {
              field: "registrationDate",
              sortable: "",
              header: "Registration  Date",
              style: {"min-width":"13rem"}
            }),
            _createVNode(_unref(Column), {
              field: "firstName",
              header: "First Name",
              sortable: ""
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.firstName), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_unref(InputText), {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  type: "text",
                  onInput: ($event: any) => (filterCallback()),
                  placeholder: "Search by first name"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), {
              field: "lastName",
              header: "Last Name",
              sortable: ""
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.lastName), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_unref(InputText), {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  type: "text",
                  onInput: ($event: any) => (filterCallback()),
                  placeholder: "Search by last name"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), {
              field: "phoneNumber",
              sortable: "",
              header: "Phone Number"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.phoneNumber), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_unref(InputText), {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  type: "text",
                  onInput: ($event: any) => (filterCallback()),
                  placeholder: "Search by phone"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
              ]),
              _: 1
            }),
            _createVNode(_unref(Column), {
              field: "totalOrders",
              sortable: "",
              header: "Total Orders"
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.totalOrders), 1)
              ]),
              filter: _withCtx(({ filterModel, filterCallback }) => [
                _createVNode(_unref(InputText), {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                  type: "text",
                  onInput: ($event: any) => (filterCallback()),
                  placeholder: "Search by number of orders"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onInput"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "filters", "expandedRows"])
      ])
    ])
  ]))
}
}

})