import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]" }
const _hoisted_3 = { class: "mb-[32px]" }
const _hoisted_4 = { class: "flex items-center justify-between gap-[12px]" }
const _hoisted_5 = { class: "flex items-center gap-[10px]" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "p-[24px]" }
const _hoisted_8 = { class: "mt-[24px]" }
const _hoisted_9 = { class: "flex gap-[32px]" }
const _hoisted_10 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import { useAdditionalChargesSettingsHelper } from './Settings.helper';
import TableField from '@/components/shared/table/TableField.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AdditionalChargesFees',
  setup(__props) {

const { additionalChargesData, handleOpenEditModal, isOpen, loading, handleOpenPayoutUpdateModalSubmit, chargesFeesHeader, chargesFeesData, updateAdditionalChargesFormHandler } = useAdditionalChargesSettingsHelper()

// const handleChargesFeesChange = (event: any, fieldType: string) => {
//     const { name, value } = event.target;

//     // Spread the existing array to avoid mutating the original data
//     const newChargesFeesData = [ ...additionalChargesData.value?.serviceFees ];
//     let foundItem = false;

//     // Iterate over each object in the array
//     newChargesFeesData.forEach((item, index) => {
//         // Check if the current item's name matches the event's name
//         if (item.name === name) {
//             // Update the name of the matched item
//             item.name = value;
//             foundItem = true;
//         }
//         // Check if the current item's value matches the event's value
//         if (item.value === name) {
//             // Update the value of the matched item
//             item.value = value;
//             foundItem = true;
//         }
//     });

//     // If no item with matching name or value was found, add a new item
//     if (!foundItem) {
//         // Push a new object with the provided name and value
//         if (fieldType === 'name') {
//             newChargesFeesData.push({ name: value, value: '' }); // Initialize value as empty string
//         } else if (fieldType === 'value') {
//             newChargesFeesData.push({ name: '', value: value }); // Initialize name as empty string
//         }
//     }

//     console.log(newChargesFeesData);

//     // Update the state with the modified data
//     // additionalChargesData.value[chargesFeesType] = newChargesFeesData;
//     // updateAdditionalChargesFormHandler.setValues(additionalChargesData.value);
// }



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-grey-200" }, "Manage your store additional fees and charges below.", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-semibold text-heading-4" }, "Additional Charges and Fees", -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(InputField, {
                type: "text",
                placeholder: "Search",
                "prefix-icon": "ant-design:search-outlined"
              }),
              _createVNode(ActionButton, {
                handleClick: _unref(handleOpenEditModal),
                theme: "primary",
                label: "New Charge",
                class: "w-[fit-content] m-[auto]"
              }, null, 8, ["handleClick"])
            ])
          ])
        ]),
        _createVNode(TableField, {
          headers: _unref(chargesFeesHeader),
          dataItems: _unref(chargesFeesData)(),
          isPagination: true,
          onOpenModal: _cache[0] || (_cache[0] = (data) => _unref(handleOpenEditModal)(data))
        }, null, 8, ["headers", "dataItems"])
      ])
    ]),
    _createVNode(AppModal, {
      headerContent: "New Charges/Fee",
      openModal: _unref(isOpen),
      onClose: _unref(handleOpenEditModal)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(handleOpenPayoutUpdateModalSubmit)($event)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(InputField, {
                label: "Name",
                type: "text",
                name: "name",
                value: _unref(updateAdditionalChargesFormHandler).values.name,
                placeholder: "Enter charge/fee name",
                "onUpdate:value": _unref(updateAdditionalChargesFormHandler).handleChange
              }, null, 8, ["value", "onUpdate:value"]),
              _createElementVNode("div", _hoisted_8, [
                _cache[4] || (_cache[4] = _createElementVNode("label", { class: "text-body-2 text-grey-200 mb-[7px] inline-block" }, "Avalability", -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(RadioButton, {
                    value: "Amount",
                    name: "type",
                    onChange: _unref(updateAdditionalChargesFormHandler).handleChange
                  }, null, 8, ["onChange"]),
                  _createVNode(RadioButton, {
                    value: "Percentage",
                    name: "type",
                    onChange: _unref(updateAdditionalChargesFormHandler).handleChange
                  }, null, 8, ["onChange"])
                ])
              ]),
              _createVNode(InputField, {
                label: "Value",
                type: "number",
                name: "value",
                class: "mt-[24px]",
                prefixIcon: _unref(updateAdditionalChargesFormHandler).values.type == 'Amount' ? 'fa6-solid:naira-sign' : 'ant-design:percentage-outlined',
                value: _unref(updateAdditionalChargesFormHandler).values.value,
                placeholder: _unref(updateAdditionalChargesFormHandler).values.type == 'Amount' ? 'Enter Amount' : 'Enter Percentage',
                "onUpdate:value": _unref(updateAdditionalChargesFormHandler).handleChange
              }, null, 8, ["prefixIcon", "value", "placeholder", "onUpdate:value"])
            ]),
            _createElementVNode("footer", _hoisted_10, [
              _createVNode(ActionButton, {
                label: "Cancel",
                theme: "secondary",
                class: "w-[fit-content]",
                onClick: _unref(handleOpenEditModal)
              }, null, 8, ["onClick"]),
              _createVNode(ActionButton, {
                theme: "primary",
                class: "w-[fit-content]",
                isSubmit: true,
                disable: _unref(loading) && true,
                label: _unref(loading) ? 'Loading...' : 'Save'
              }, null, 8, ["disable", "label"])
            ])
          ], 32)
        ])
      ]),
      _: 1
    }, 8, ["openModal", "onClose"])
  ], 64))
}
}

})