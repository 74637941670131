import { ref } from 'vue';
import ApiService from './api.service';

interface User {
    email: string
    password: string
    roles: string
}

export const storeId = () => localStorage.getItem('storeId');
export default class AuthService {
    static user = ref<User | null>(null);

    static async loginRequest(email: string, password: string): Promise<any> {
        const response = await ApiService.post('auth/login', {
            email,
            password,
        })
        return response
    }

    static async resetPasswordRequest(email: string): Promise<any> {
        const response = await ApiService.post('auth/request-password-reset', { email })
        return response
    }

    static async setNewPasswordRequest(newPassword: string, token: string): Promise<any> {
        const response = await ApiService.post('auth/reset-password', { newPassword, token })
        return response
    }

    static async logoutRequest(): Promise<any> {
        const response = await ApiService.post('auth/logout', {})
        return response
    }
}