<template>
    <div class="flex gap-[8px] items-center">
        <label class="custom-checkbox">
            <input type="checkbox" :checked="isChecked" @change="toggleCheckbox">
            <div class="checkbox"></div>
        </label>
        <div class="text-body-2 text-grey-300">{{ label }}</div>
    </div>
</template>

<script setup lang="ts">
import { ref, defineProps, VNode } from 'vue';

interface Props {
    label?: VNode | string
}

defineProps<Props>()

const isChecked = ref(false);

const toggleCheckbox = () => {
    isChecked.value = !isChecked.value;
};
</script>

<style scoped lang="scss">

.custom-checkbox {
    display: inline-block;
    position: relative;
    cursor: pointer;

    input[type="checkbox"] {
        display: none;
    }

    .checkbox {
        width: 24px;
        height: 24px;
        border: 3px solid #313131cc;
        background-color: transparent;
        position: relative;
        border-radius: 4px;
    }

    input[type="checkbox"]:checked + .checkbox {
        background-color: #ED3237;
        border: 1px solid transparent;
    }

    input[type="checkbox"]:checked + .checkbox::before {
        content: '';
        width: 6px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }
}
</style>
