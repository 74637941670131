<template>
  <AuthWrapper>
    <div class="w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] login_responsive">
      
      <header class="">
        <div class="hidden login_responsive_logo"><StandaloneLogo /></div>
        <div class="text-heading-1 font-semibold text-grey-300 login_responsive_header">Welcome back!</div>
        <div class="text-sub-heading text-grey-200 login_responsive_sub_header">Please sign in to continue.</div>
      </header>

      <form @submit.prevent="loginFormHandler.handleSubmit">
        <InputField
          class="mt-[50px]"
          placeholder="Email"
          type="email"
          prefixIcon="ant-design:mail-outlined"
          name="email"
          :value="loginFormHandler.values.email"
          @update:blur:value="loginFormHandler.handleBlur"
          @update:value="loginFormHandler.handleChange"
        />
        <div class="text-body-2 text-medium text-error mt-[4px]"> {{ loginFormHandler.errors.email }}</div>
          
        <InputField
          class="mt-[24px]"
          placeholder="Password"
          :type="showPassword ? 'text' : 'password'"
          prefixIcon="ant-design:lock-outlined"
          :suffixIcon="showPassword ? 'ant-design:eye-invisible-outlined' : 'ant-design:eye-outlined'"
          :handleClick="handleShowPassword"
          name="password"
          :value="loginFormHandler.values.password"
          @update:blur:value="loginFormHandler.handleBlur"
          @update:value="loginFormHandler.handleChange"
        />
        <div class="text-body-2 text-medium text-error mt-[4px]"> {{ loginFormHandler.errors.password }}</div>

        <ActionButton :disable="loginFormHandler.form.loading && true" class="mt-[24px]" :label="loginFormHandler.form.loading ? 'Loading...' : 'Sign In'" theme="primary" :isSubmit="true" />
        <ActionButton class="mt-[19px]" label="Forget Password" theme="linked" :handleClick="() => router.push('/auth/reset_password')" /> 
      </form>
    </div>
  </AuthWrapper>
</template>

<script setup lang='ts'>
import AuthWrapper from "@/components/app/auth-wrapper/AuthWrapper.vue";
import ActionButton from "@/components/shared/action-button/ActionButton.vue";
import InputField from "@/components/shared/input-field/InputField.vue";
import { useRouter } from "vue-router";
import { ref } from "vue";
import { useAuthSession } from "@/utility/composables/useAuthSession";
import { useStore } from "vuex";
import StandaloneLogo from "@/components/shared/standalone-logo/StandaloneLogo.vue";

const router = useRouter();
const showPassword = ref(false);
const store = useStore();
const { loginFormHandler } = useAuthSession(store)
const handleShowPassword = () => showPassword.value = !showPassword.value;

</script>

<style lang="scss" scoped>
  @media (max-width: 1040px) {
    .login_responsive {
      width: 90%;
      // position: relative;
      margin: 0;
      transform: translate(-50%, 20%);

      &_logo {
        display: block;
      }

      &_header {
        font-size: 28px;
        margin-top: 30px
      }

      &_sub_header {
        font-size: 14px;
      }
    }
  }
</style>
