import { ref, watchEffect } from "vue";
import CustomerService from "@/utility/services/customer.service";

import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue'

export interface address {
    street: string | undefined;
    city: string | undefined;
    state: string;
    lga: string;
}
export interface favoriteMenu {
    favoriteFood: string; 
    orderCount: number;
}

export interface favoriteChannel {
    orderChannel: string; 
    orderCount: number;
}
export interface customerInfo {
    lastName: string | number | undefined;
    firstName: string | number | undefined;
    email: string;
    phone: string;
    totalOrders: string;
    registeredDate: string;
    address: address;
}

export interface CustomerDetails {
    customerInfo: customerInfo | undefined;
    favoriteMenu: favoriteMenu;
    favoriteChannel: favoriteChannel;
}

export interface SearchOptions {
    search: string;
    since: number;
}

export const getCustomers = async({search="", since=0}) => {
    try {
        const response = await CustomerService.getCustomerRequest({  search, since });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const getCustomerById = async({customerId=""}) => {
    try {
        const response = await CustomerService.getCustomerIdRequest({  customerId });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const getCustomerOrders = async({ customerId= "", status="" }) => {
    try {
        const response = await CustomerService.getCustomerOrdersRequest({  customerId, status });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const useCustomersHelper = () => {
    const orderInformationTabs = ref(['Customer Order', 'Delivery Info']);
    const activeTab = ref(0);

    const activateTab = (index: number) => {
    activeTab.value = index;
    };

    const customerDetailsData = ref<CustomerDetails>({
        customerInfo: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            totalOrders: '',
            registeredDate: '',
            address: {
                street: "",
                city: "",
                state: "",
                lga: "",
            },
        },
        favoriteChannel: {
            orderChannel: "",
            orderCount: 0
        },
        favoriteMenu: {
            favoriteFood: "",
            orderCount: 0
        }
    })

    const customersData = ref([]);
    
    const searchDateOptions = ref( [
        { value: 0, name: 'Today'},
        { value: 1, name: 'Yesterday'},
        { value: 7, name: 'Last Week'},
        { value: 30, name: 'Last 30 days'},
        { value: 60, name: 'Last 60 days'},
        { value: 80, name: 'Last 80 days'}
    ]);

    const customersHeader = ref([
        { name: "First Name", value: "firstName" },
        { name: "Last Name", value: "lastName" },
        { name: "Email", icon: 'ant-design:sort-ascending-outlined', value: "email" },
        { name: "Phone", value: "phoneNumber" },
        { name: "Total Order", value: "totalOrders" },
        { name: "Registration Date", value: "registrationDate" },
    ]);



    const searchOptionsData = ref<SearchOptions>({
        search: '',
        since: 0
    });

    const onSearch = (event: any) => {
        searchOptionsData.value.search = event.target.value;
    };
    const onSearchDateChange = (event: any) => {
        searchOptionsData.value.since = event.target.value;
    };

    watchEffect(() => {
        getCustomers({
            search: searchOptionsData.value.search,
            since: searchOptionsData.value.since,
        }).then((responseData: any) => {
            customersData.value = responseData.customers || [];
        });
    });


    return {
        activateTab, 
        activeTab, 
        AppWrapper,
        customersData,
        customersHeader: customersHeader.value,
        orderInformationTabs,
        onSearch,
        onSearchDateChange,
        searchDateOptions,
        customerDetailsData,
        searchOptionsData
    }
}