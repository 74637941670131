import ApiService from './api.service';
import { storeId } from './auth.service';


export default class InvoiceService {

    static async getInvoiceRequest({search = '', page = '', sortBy='', sortDirection = 1, limit=100, since=0, status = '' } ): Promise<any> {
        try {
            const _status = status === "All" ? "": status;
            const response = await ApiService.get(`store/${storeId()}/invoice?search=${search}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}&limit=${limit}&since=${since}&status=${_status}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getInvoiceByIdRequest({ invoiceId = '' } ): Promise<any> {
        try {
            const response = await ApiService.get(`store/${storeId()}/invoice/${invoiceId}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

}