<template>
    <AppWrapper activeTab="Invoices">
        <div class="py-[72px] w-[800px] m-[auto]">
            <div class="flex items-center gap-[6px] text-body-1">
                <div class="flex items-center gap-[6px] text-grey-100 cursor-pointer" @click="router.back()">
                    <Icon icon="ant-design:shopping-outlined" />
                    <div>Invoice</div>
                </div>
                <div>/</div>
                <div>{{ invoiceDetailsData.invoiceNumber }}</div>
            </div>

            <div class="border-[1px] border-solid border-grey-50 rounded-[8px] mt-[32px]">
                <header class="py-[24px] px-[32px]">
                    <div class="flex items-center justify-between">
                        <div class="font-semibold text-heading-5 mt-[6px]">Invoice #{{ invoiceDetailsData.invoiceNumber }}</div>
                        <div class="">
                            <StatusLabel :status="invoiceDetailsData.status" />
                        </div>
                    </div>
                </header>

                <div 
                    class="border-t-[1px] border-solid border-grey-50 flex items-center gap-[10px] py-[20px] px-[32px]" 
                    :style="{
                        borderBottom: invoiceDetailsData.status == 'Cancelled' ? '1px solid #D2452E' : '1px solid #52C41A',
                        backgroundColor: invoiceDetailsData.status == 'Cancelled' ? '#FFF1F04D' : '#F6FFED4D'
                    }">
                    <div :class="invoiceDetailsData.status == 'Cancelled' ? 'text-error' : 'text-success'">
                        <Icon :icon="invoiceDetailsData.status == 'Cancelled' ? 'ant-design:close-circle-outlined' : 'ant-design:check-circle-outlined'" class="w-[24px] h-[24px]" />
                    </div>
                    <div class="text-capitalize text-body-1 font-medium">Invoice {{ invoiceDetailsData.status }} on {{ invoiceDetailsData.invoiceDate }}</div>    
                </div>


                <main class="">
                    <section class="">
                        <main>
                            <div class=" px-[32px] py-[28px]">
                                <div class="grid grid-cols-2 gap-[24px]">
                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Order Date</div>
                                        <div class="font-medium text-body-1 mt-[4px]">{{ invoiceDetailsData.invoiceDate }}</div>
                                    </div>

                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Order Channel</div>
                                        <div class="font-medium text-body-1 mt-[4px]">{{ orderChannel}}</div>
                                    </div>

                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">From</div>
                                        <div class="font-medium text-body-1 mt-[4px]">{{invoiceDetailsData.restaurantStoreName}}</div>
                                        <!-- <div class="text-grey-200 text-caption font-medium">No 32, opposite Zara mall, mainland</div> -->
                                    </div>

                                    
                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">To</div>
                                        <div class="font-medium text-body-1 mt-[4px]">{{customer?.name}}</div>
                                    </div>

                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Phone Number</div>
                                        <div class="font-medium text-body-1 mt-[4px]">{{customer.phone}}</div>
                                    </div>
                                    
                                    
                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium">Processed By</div>
                                        <div class="font-medium text-body-1 mt-[4px]">{{cashier?.name || "Staff"}}</div>
                                    </div>

                                    <div class="">
                                        <div class="text-grey-200 text-caption font-medium ">Invoice Notes</div>
                                        <div class="font-medium text-body-1 mt-[4px]">{{ invoiceDetailsData.invoiceNotes }}</div>
                                    </div>
                                </div>

                                <header class="flex items-center justify-between py-[18px] px-[24px] bg-bg-200 rounded-[8px] mt-[24px]">
                                    <div class="flex items-center gap-[10px] text-grey-200">
                                        <div class="text-body-2">Other Charges</div>
                                    </div>
                                    <div class="text-grey-200 cursor-pointer" @click="handleShowOrderInformation"><Icon :icon="showOrderInfo ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="w-[24px] h-[24px]" /></div>
                                </header>

                                <div class="">
                                    <div v-show="showOrderInfo" class="">
                                        <div class="grid grid-cols-2 px-[24px] py-[28px] gap-[24px]">
                                            <div class="">
                                                <div class="text-grey-200 text-caption font-medium">Service Fee</div>
                                                <div class="font-medium text-body-1 mt-[4px]">{{ invoiceDetailsData.serviceFee }}</div>
                                            </div>

                                            <!-- <div class="">
                                                <div class="text-grey-200 text-caption font-medium">Paper Bag</div>
                                                <div class="font-medium text-body-1 mt-[4px]">₦5.00</div>
                                            </div>

                                            <div class="">
                                                <div class="text-grey-200 text-caption font-medium">Tray Fee</div>
                                                <div class="font-medium text-body-1 mt-[4px]">₦0.00</div>
                                            </div>

                                            <div class="">
                                                <div class="text-grey-200 text-caption font-medium">Delivery</div>
                                                <div class="font-medium text-body-1 mt-[4px]">₦0.00</div>
                                            </div>

                                            <div class="">
                                                <div class="text-grey-200 text-caption font-medium">Service Fee</div>
                                                <div class="font-medium text-body-1 mt-[4px]">{{ invoiceDetailsData.serviceFee }}</div>
                                            </div>

                                            <div class="">
                                                <div class="text-grey-200 text-caption font-medium ">Next Day Payout fee</div>
                                                <div class="font-medium text-body-1 mt-[4px]">{{ invoiceDetailsData.amount }}</div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="border-t-[1px] border-solid border-grey-50 px-[32px] pb-[20px]">
                                <div class="pt-[32px] pb-[24px] text-body-2 font-medium">Items</div>
                                <TableField
                                    :headers="detailsHeader" 
                                    :dataItems="detailsData" 
                                />
                            </div>

                            <!-- <div class="grid grid-cols-2 gap-[24px] py-[20px] px-[32px] border-t-[1px] border-solid border-">
                                <div class="flex items-center gap-[10px] border-solid border-[1px] border-grey-50 justify-center py-[12px] rounded-[8px]">
                                    <div class="">
                                        <Icon icon="ant-design:file-pdf-outlined" class="w-[24px] h-[24px]" />
                                    </div>
                                    <div class="text-body-2">Download Receipt</div>
                                </div>

                                <div class="flex items-center gap-[10px] border-solid border-[1px] border-grey-50 justify-center py-[12px] rounded-[8px]">
                                    <div class="">
                                        <Icon icon="ant-design:check-outlined" class="w-[24px] h-[24px]" />
                                    </div>
                                    <div class="text-body-2">Mark invoice as...</div>
                                </div>
                            </div> -->
                        </main>
                    </section>
                </main>
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useInvoicesHelper, getInvoiceById } from './Invoices.helper';
import { defineProps, watchEffect, ref } from 'vue'
import { Icon } from '@iconify/vue';
import StatusLabel from '@/components/shared/status-label/StatusLabel.vue'
import TableField from '@/components/shared/table/TableField.vue';

const { AppWrapper, invoiceDetailsData, detailsHeader } = useInvoicesHelper()
const router = useRouter()

let orders :any = [];
let orderChannel = ""
let customer: any = {};
let cashier: any = {};
const detailsData = ref([])


interface Props {
    id: string
}
const props = defineProps<Props>()

const showOrderInfo = ref(false)

const handleShowOrderInformation = () => {
    showOrderInfo.value = !showOrderInfo.value
}

watchEffect(() => {
    getInvoiceById({ invoiceId: props.id}).then((responseData)=> {
        if (responseData) {
            invoiceDetailsData.value = responseData;
            orders = responseData.orders;
            orderChannel = orders[0]?.orderChannel;
            customer = orders[0]?.customer;
            cashier = orders[0]?.cashier;
            detailsData.value = orders[0].orderDetails;
        }
    });
});


</script>

<style scoped>
.order-image {
    width: 92px;
    height: 92px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>