<template>
    <div class="mt-[32px]">
        <div class="text-grey-200">Manage your payout account below.</div>
        <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]">
            <header class="flex items-center justify-between">
                <div class="">
                    <div class="font-semibold text-sub-heading" :class="{'animate-pulse bg-slate-100 h-[22px] w-[250px] rounded' : loading}">{{ payoutData?.accountName }}</div>
                    <div class="text-grey-200 text-caption ">Account No: <span :class="{'animate-pulse bg-slate-100 h-[16px] w-[60px] rounded inline-block mt-[4px]' : loading}">{{ payoutData?.accountNumber }}</span></div>
                    <div class="text-grey-200 text-caption">Bank Name: <span :class="{'animate-pulse bg-slate-100 h-[16px] w-[60px] rounded inline-block' : loading}">{{ payoutData?.accountProvider }}</span></div>
                </div>
                <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] py-[11px] w-[fit-content] text-body-2 cursor-pointer" @click="handleShowPayoutUpdatemodal">Update</div>
            </header>
        </div>

        <AppModal headerContent="Update Payout Account" :openModal="isPayoutUpdateModal" @close="handleShowPayoutUpdatemodal">
            <form @submit.prevent="handleOpenPayoutUpdateModalSubmit($event)">
                <div class="px-[28px] pt-[32px]">
                    <!-- <InputField 
                        label="Select Bank" 
                        type="text" 
                        :value="payoutData?.accountProvider"
                        name="accountProvider"
                        @update:blur:value="updatePayoutAccountFormHandler.handleBlur"
                        @update:value="updatePayoutAccountFormHandler.handleChange"
                    /> -->

                    <DropdownField
                        label="Select Bank" 
                        name="accountProvider"
                        :value="payoutData?.accountProvider"
                        :optionsData="banksDetailsData"
                        @change="handleBankChange($event)"
                    />

                    <div class="grid grid-cols-2 gap-[10px] mt-[32px]">
                        <InputField 
                            label="Account Name" 
                            type="text" 
                            :value="payoutData?.accountName"
                            name="accountName"
                            @update:blur:value="updatePayoutAccountFormHandler.handleBlur"
                            @update:value="updatePayoutAccountFormHandler.handleChange"
                        />

                        <InputField
                            label="Account Number" 
                            type="number" 
                            :value="payoutData?.accountNumber"
                            name="accountNumber"
                            @update:blur:value="updatePayoutAccountFormHandler.handleBlur"
                            @update:value="updatePayoutAccountFormHandler.handleChange"
                        />
                    </div>
                </div>
                
                <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                    <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleShowPayoutUpdatemodal" />
                    <ActionButton
                        theme="primary" 
                        class="w-[fit-content]" 
                        :isSubmit="true" 
                        :disable="loading && true" 
                        :label="loading ? 'Loading...' : 'Save'" 
                    />
                </footer>
            </form>
        </AppModal>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, watchEffect } from 'vue';
import { getBankDetails, getPayoutAccountProfile } from './Settings.helper';
import AppModal from '@/components/shared/modal/AppModal.vue';
import { PayoutAccount } from '@/utility/services/settings.service';
import { useSettingsHandler } from '@/utility/composables/useSettingsRequestsHandler';
import InputField from '@/components/shared/input-field/InputField.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';

const payoutData = ref<PayoutAccount>()
const loading = ref(false);
const isPayoutUpdateModal = ref(false);
const banksDetailsData = ref()
const { updatePayoutAccountFormHandler } = useSettingsHandler()

const handleShowPayoutUpdatemodal = () => {
    isPayoutUpdateModal.value = !isPayoutUpdateModal.value;
};

const handleOpenPayoutUpdateModalSubmit = async (event: unknown) => {
    loading.value = true;
    await updatePayoutAccountFormHandler.handleSubmit(event);
    payoutData.value = updatePayoutAccountFormHandler.values;
    handleShowPayoutUpdatemodal();
    loading.value = false;
}

const handleBankChange = (event: any) => {
    const { value } = event.target
    const bankData =  banksDetailsData.value.find((bankD: any) => value == bankD.name);
    const newPayoutData = {
        ...payoutData.value, 
        accountProvider: bankData.name, 
        accountProviderCode: bankData.code
    }
    updatePayoutAccountFormHandler.setValues(newPayoutData);
}

watchEffect(() => {
    loading.value = true;
    getPayoutAccountProfile().then((response) => {
        payoutData.value = response;
        loading.value = false;
    })
})

watchEffect(() => {
    getBankDetails().then((response) => {
        banksDetailsData.value = response;
    })
})

watch(payoutData, (newPayoutData) => {
    updatePayoutAccountFormHandler.setValues(newPayoutData);
});

</script>

<style scoped>

</style>