<template>
  <div :class="['app-wrapper', { 'mobile-collapse': mobileCollapse }]">
    <div class="app-wrapper-left">
      <SideNavbar 
        :handleToggleMobileCollapse="() => handleToggleMobileCollapse()" 
        :mobileCollapse="mobileCollapse" 
        :activeTabContent="activeTab" 
        :showModal="handleShowModal" 
        :storesProfile="storesProfile" 
        :handleOpenCreateStoreModal="handleOpenCreateStoreModal" 
      />
    </div>

    <div :class="['app-wrapper-right', { 'hidden': mobileCollapse }, 'no-scrollbar lg:px-[32px] px-[10px]']">
      <Icon class="cursor-pointer mt-[27px] lg:hidden" icon="ant-design:menu-unfold-outlined" width="24" height="24" @click="handleToggleMobileCollapse" />
      <slot></slot>
    </div>
  </div>

  <CreateStoreForm
    :isOpen="isOpen" 
    :handleOpenEditModal="handleOpenCreateStoreModal" 
    :createStoreFormHandler="createStoreHandler" 
  />

  <SwitchStoresModal 
    :openStoreModal="openStoreModal" 
    :handleShowModal="handleShowModal" 
    :storesProfile="storesProfile" 
    :selectedStoreId="selectedStoreId" 
    :selectStore="selectStore" 
    :reloadApp="reloadApp" 
  />

  <div class="absolute bg-primary-300 rounded-[50%] lg:w-[54px] lg:h-[54px] w-[34px] h-[32px] bottom-[40px] right-[40px] shadow-md hover:shadow-lg animate-bounce-slow cursor-pointer" @click="handleShowModal">
    <Icon icon="mynaui:arrow-left-right" class="absolute text-white left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] lg:w-[32px] lg:h-[32px]" />
  </div>
</template>

<script setup lang="ts">
import SideNavbar from '@/components/shared/side-navbar/SideNavbar.vue';
import { defineProps, ref, watch, watchEffect } from 'vue';
import { useAppWrapperHelper } from './AppWrapper.helper';
import SwitchStoresModal from './components/SwitchStoresModal.vue'
import { useStoreManagementHelper } from '@/views/store-management-analysis/StoreManagement.helper';
import CreateStoreForm from '@/views/store-management-analysis/CreateStoreForm.vue';
import { Icon } from '@iconify/vue';

interface Props {
  activeTab: string;
}

defineProps<Props>();

const { storesProfile } = useAppWrapperHelper();
const openStoreModal = ref(false)
const selectedStoreId = ref<string | null>(null);
const isOpen = ref(false);
const { createStoreHandler } = useStoreManagementHelper();

const mobileCollapse = ref(false);
const handleToggleMobileCollapse = () => mobileCollapse.value = !mobileCollapse.value;

const handleShowModal = () => {
  openStoreModal.value = !openStoreModal.value;
}

const reloadApp = () => {
  window.location.reload()
}

const selectStore = (storeId: string) => {
  selectedStoreId.value = storeId;
  localStorage.setItem('storeId', storeId);
};

const handleOpenCreateStoreModal = () => {
    isOpen.value = !isOpen.value;
};

watch(selectedStoreId, (newStoreId) => {
  if (newStoreId) {
    localStorage.setItem('storeId', newStoreId);
  }
});

watchEffect(() => {
  const currentStoreId = localStorage.getItem('storeId');
  if (currentStoreId) {
    selectedStoreId.value = currentStoreId;
  } else if (storesProfile.value.length > 0) {
    selectedStoreId.value = storesProfile.value[0].id;
  }
});
</script>

<style scoped lang="scss">
.app-wrapper {
  display: grid;
  grid-template-columns: auto 1fr;

  &.mobile-collapse {
    grid-template-columns: 1fr;
  }

  &-right {
    height: 100vh;
    overflow-y: scroll;
  }

  &-right.hidden {
    display: none;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animate-bounce-slow {
  animation: bounce 2s infinite ease-in-out;
}

.transition-width {
  transition: width 0.5s ease-in-out; 
}
</style>
