import { getSetupCheckList } from '@/views/setup-guides/SetupGuide.helper';
import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';

export interface SetupGuideCheckListState {
  setupCheckListData: any;
  loading: boolean;
}

@Module({ namespaced: true })
export default class SetupGuideCheckListModule extends VuexModule implements SetupGuideCheckListState {
  setupCheckListData = null;
  loading = true;

  @MutationAction
  async fetchsetupCheckListData() {
    try {
      const responseData = await getSetupCheckList();
      return {
        setupCheckListData: responseData,
        loading: false
      };
    } catch (error) {
      console.error('Error fetching setupCheckList data:', error);
      return {
        setupCheckListData: null,
        loading: false
      };
    }
  }
}
