<template>
    <div class="flex items-center gap-[10px]">
        <label class="custom-radio ">
            <input type="radio" :name="name" :value="value" :checked="isChecked" @change="$emit('on:change', $event)" />
            <div class="outer-circle"></div>
            <div class="inner-circle"></div>
        </label>
        <label class="text-capitalize">{{ value }}</label>
    </div>
</template>
  
<script setup lang="ts">
import { ref, defineProps } from 'vue';

const isActive = ref(false);

interface Props {
    name?: string
    value?: string
    label?: string
    isChecked?: boolean
}

defineProps<Props>()

const toggleRadio = () => {
    isActive.value = !isActive.value;
};
</script>

<style scoped lang="scss">
.custom-radio {
    display: inline-block;
    position: relative;
    cursor: pointer;

    input[type="radio"] {
        display: none;
    }

    .outer-circle {
        width: 18px;
        height: 18px;
        border: 3px solid #ccc;
        border-radius: 50%;
        position: relative;
    }

    .inner-circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: background-color 0.3s ease;
        display: none;
    }

    input[type="radio"]:checked + .outer-circle {
        border-color: #ED3237;
    }

    input[type="radio"]:checked + .outer-circle + .inner-circle {
        display: block;
        background-color: #ED3237;
    }
}
</style>
