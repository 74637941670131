import ApiService from './api.service';
import { storeId } from './auth.service';


export default class DashboardService {

    static async getDashboardRequest({since = 365}): Promise<unknown> {
        try {
            const { data } = await ApiService.get(`store/${storeId()}/dashboard?since=${since}`)
            return data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getDashboardBestItemsRequest({search = "", since = 0}): Promise<unknown> {
        try {
            const { data } = await ApiService.get(`store/${storeId()}/dashboard/best-items?search=${search}&since=${since}`)
            return data.data
        } catch (error) {
            console.log(error)
        }
    }

}