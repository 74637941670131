<template>
    <AppWrapper activeTab="Invoices">
        <div class="lg:pt-[76px] pt-[30px]">
            <div class="lg:flex items-center justify-between">
                <div class="font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between">
                    <div>Invoices</div>
                    <div class="border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden" :class="showFilterMobile && 'bg-primary-300 text-white'" @click="handleShowMobileFilter"><Icon class="" icon="fluent:options-20-filled" width="20" height="20" /></div>
                </div>

                <div class="lg:flex items-center gap-[12px] grid lg:mt-0 mt-[10px]" :class="!showFilterMobile ? 'hidden' : 'block' ">
                    <InputField type="text" placeholder="Search" prefix-icon="ant-design:search-outlined"  :value="search"  @change="onSearch"  />
                    <DropdownField defaultOptionLabel="Select"  :optionsData="searchDateOptions" @change="onChangeData" />
                </div>
            </div>

            <div class="flex gap-[32px] border-b-[1px] border-solid mt-[24px]">
                <div
                    v-for="(tab) in invoiceTabs"
                    :key="tab?.status"
                    :class="['py-[12px] cursor-pointer lg:text-body-2 text-caption text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab.status === tab.status}]"
                    @click="activateTab(tab)"
                    > {{ tab?.status }} ({{ tab?.count }}) </div>
            </div>

            <div class="my-[28px] overflow-auto no-scrollbar">
                <TableField 
                    :headers="invoiceHeaders" 
                    :dataItems="invoiceData" 
                    :isPagination="true" 
                    :viewDetails="true"
                    :routeData="{name: 'InvoiceDetails', paramId: 'invoicePaymentId'}"
                />
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import TableField from '@/components/shared/table/TableField.vue';
import { useInvoicesHelper } from './Invoices.helper'
import InputField from '@/components/shared/input-field/InputField.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';

const showFilterMobile = ref(false);
const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value

const { AppWrapper, 
    activateTab, 
    invoiceData, 
    invoiceHeaders, 
    invoiceTabs, 
    activeTab,  
    onChangeData,
    search,
    searchDateOptions,
    onSearch,  } = useInvoicesHelper()

</script>

<style scoped>

</style>./Invoices.helper