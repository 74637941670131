import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lg:pt-[76px] pt-[30px]" }
const _hoisted_2 = { class: "lg:flex items-center justify-between" }
const _hoisted_3 = { class: "font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between" }
const _hoisted_4 = { class: "flex gap-[32px] border-b-[1px] border-solid mt-[24px]" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "my-[28px] overflow-auto no-scrollbar" }

import TableField from '@/components/shared/table/TableField.vue';
import { useInvoicesHelper } from './Invoices.helper'
import InputField from '@/components/shared/input-field/InputField.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { Icon } from '@iconify/vue';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicesView',
  setup(__props) {

const showFilterMobile = ref(false);
const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value

const { AppWrapper, 
    activateTab, 
    invoiceData, 
    invoiceHeaders, 
    invoiceTabs, 
    activeTab,  
    onChangeData,
    search,
    searchDateOptions,
    onSearch,  } = useInvoicesHelper()


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Invoices" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", null, "Invoices", -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden", showFilterMobile.value && 'bg-primary-300 text-white']),
              onClick: handleShowMobileFilter
            }, [
              _createVNode(_unref(Icon), {
                class: "",
                icon: "fluent:options-20-filled",
                width: "20",
                height: "20"
              })
            ], 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lg:flex items-center gap-[12px] grid lg:mt-0 mt-[10px]", !showFilterMobile.value ? 'hidden' : 'block' ])
          }, [
            _createVNode(InputField, {
              type: "text",
              placeholder: "Search",
              "prefix-icon": "ant-design:search-outlined",
              value: _unref(search),
              onChange: _unref(onSearch)
            }, null, 8, ["value", "onChange"]),
            _createVNode(DropdownField, {
              defaultOptionLabel: "Select",
              optionsData: _unref(searchDateOptions),
              onChange: _unref(onChangeData)
            }, null, 8, ["optionsData", "onChange"])
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(invoiceTabs), (tab) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tab?.status,
              class: _normalizeClass(['py-[12px] cursor-pointer lg:text-body-2 text-caption text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab).status === tab.status}]),
              onClick: ($event: any) => (_unref(activateTab)(tab))
            }, _toDisplayString(tab?.status) + " (" + _toDisplayString(tab?.count) + ") ", 11, _hoisted_5))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(TableField, {
            headers: _unref(invoiceHeaders),
            dataItems: _unref(invoiceData),
            isPagination: true,
            viewDetails: true,
            routeData: {name: 'InvoiceDetails', paramId: 'invoicePaymentId'}
          }, null, 8, ["headers", "dataItems"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})