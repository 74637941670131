import Chart, { ChartConfiguration, ChartTypeRegistry, GridLineOptions } from 'chart.js/auto';

interface BarChartData {
    labels: string[];
    datasets: Array<{
        data: number[];
        backgroundColor?: string | CanvasGradient;
        barPercentage?: number;
        categoryPercentage?: number;
        barThickness?: number;
    }>;
}

export const barChartrHelper = (barChartData: BarChartData) => {
    let _barChart = null;
    const isLargeDataset = barChartData.labels.length > 12;
    const config: ChartConfiguration<keyof ChartTypeRegistry, number[], string> = {
        type: 'bar',
        data: {
            ...barChartData,
            datasets: barChartData.datasets.map((dataset: BarChartData['datasets'][number]) => ({
                ...dataset,
                barPercentage: isLargeDataset ? 0.4 : undefined, // Adjust bar width if more than 15 bars
                categoryPercentage: isLargeDataset ? 0.5 : undefined, // Adjust space between bars if more than 15 bars
                barThickness: isLargeDataset ? 20 : undefined // Set a fixed bar thickness if more than 15 bars
            }))
        },
        options: {
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            return context.raw + ' customers'; // Only show the number of customers
                        }
                    }
                }
            },

            scales: {
                x: {
                    stacked: true,
                    beginAtZero: false,
                    ticks: {
                        stepSize: 1,
                        maxRotation: isLargeDataset ? 45 : 0, // Adjust rotation based on number of bars
                        minRotation: isLargeDataset ? 45 : 0, // Ensure slant when bars are more than 15
                        autoSkip: false,
                        color: '#00000059'
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0)', 
                        drawBorder: false, 
                        borderDash: [5, 5] as [number, number] 
                    } as unknown as GridLineOptions
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                    ticks: {
                        stepSize: 50,
                        color: '#00000059'
                    },
                    grid: {
                        drawBorder: false 
                    } as unknown as GridLineOptions 
                }
            }
        }
    };
    const createLinearGradient = (ctx: CanvasRenderingContext2D, startColor: string, endColor: string) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
        gradient.addColorStop(0, startColor);
        gradient.addColorStop(1, endColor);
        return gradient;
    }
    const canvas = document.getElementById('barChart') as HTMLCanvasElement;
    const ctx = canvas?.getContext('2d');
    if (ctx) {
        // Apply gradient to progressData
        if (barChartData && barChartData?.datasets) {
            barChartData.datasets[0].backgroundColor = createLinearGradient(ctx, '#FFE1DF', '#ED3237') as CanvasGradient;
        }
        _barChart = new Chart(ctx, config);
    }
    return { barChart: _barChart, barChartData };
};