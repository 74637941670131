import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fixed top-4 right-4 z-50 space-y-4" }
const _hoisted_2 = { class: "flex justify-between items-center" }
const _hoisted_3 = { class: "flex gap-[10px] items-center" }
const _hoisted_4 = { class: "text-grey-200 text-body-2 font-semibold" }
const _hoisted_5 = { class: "text-grey-200 mt-[4px] text-caption ml-[28px] mr-[25px]" }

import { Icon } from '@iconify/vue'
import { computed } from 'vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'ToastX',
  setup(__props) {

const store = useStore();

const showToast = computed(() => store.state.ToastX?.showToast);
const theme = computed(() => store.state.ToastX.theme);
const x_title = computed(() => store.state.ToastX.x_title);
const x_message = computed(() => store.state.ToastX.x_message);

const closeToast = () => {
  store.commit('ToastX/hideToast');
};


const toastXIconType: any = (theme: string) => {
    switch (theme) {
        case 'success':
            return 'ant-design:check-circle-filled';
        case 'error':
            return 'ant-design:close-circle-filled';
        case 'info':
            return 'ant-design:info-circle-filled';
        case 'warning':
            return 'ant-design:exclamation-circle-filled'
    }
}

const toastXStyleType = (theme: string) =>  {
    switch (theme) {
        case 'success':
            return 'bg-[#F6FFED] text-[#52C41A] border-[1px] border-solid border-[#B7EB8F]'
        case 'warning':
            return 'bg-[#FFFBE6] text-[#FAAD14] border-[1px] border-solid border-[#FFE58F]'
        case 'info':
            return 'bg-[#E6F4FF] text-[#1677FF] border-[1px] border-solid border-[#91CAFF]'  
        case 'error':
            return 'bg-[#FFF1F0] text-[#FF4D4F] border-[1px] border-solid border-[#FFA39E]'
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (showToast.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([toastXStyleType(theme.value), "px-[20px] py-[16px] rounded-[8px] max-w-[500px]"])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(Icon), {
                icon: toastXIconType(theme.value),
                width: "20",
                height: "20"
              }, null, 8, ["icon"]),
              _createElementVNode("div", _hoisted_4, _toDisplayString(x_title.value || 'Alert Title'), 1)
            ]),
            _createVNode(_unref(Icon), {
              icon: "ant-design:close-outlined",
              width: "12",
              height: "12",
              class: "text-grey-200 font-semibold",
              onClick: closeToast
            })
          ]),
          _createElementVNode("div", _hoisted_5, _toDisplayString(x_message.value || 'Interactively monetize corporate alignments and fully tested niche markets.'), 1)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})