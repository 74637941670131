<template>
    <AppWrapper activeTab="">
        <div class="relative">
            <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[100%] text-center">
                <div class="text-[68px] text-black font-bold">404</div>
                <div class="text-[35px] text-black font-medium my-[13px]">Hmm, page not found</div>
                <div class="text-grey-100">This page doesn't exist or was removed, we suggest you return back to <br /> dashboard</div>
                <ActionButton :handleClick="() => router.push('/')" theme="primary" label="Go to dashboard" class="w-[fit-content] m-[auto] mt-[22px]" prefixIcon="material-symbols:arrow-left-alt"/>
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import AppWrapper from './components/app/app-wrapper/AppWrapper.vue'
import ActionButton from './components/shared/action-button/ActionButton.vue';

const  router = useRouter()
</script>

<style scoped>

</style>