<template>
    <div 
        class="h-screen bg-primary-300 py-[24px] side-navbar" 
        :class="[
            isCollapse ? 'w-[fit-content] px-[9px]' : 'px-[32px]',
            mobileCollapse && 'px-[8px]',
            !mobileCollapse && 'px-[32px]',
        ]">
        <div class="cursor-pointer responsive_sidebar_header lg:block lg:px-[0px] px-[16px]" :class="!mobileCollapse && 'hidden'">
            <Icon v-if="!mobileCollapse" class="text-bg-100 cursor-pointer" :icon="!isCollapse ? 'ant-design:menu-fold-outlined' : 'ant-design:menu-unfold-outlined'" width="24" height="24" @click="handleToggleCollapse" :class="{ 'w-[fit-content] m-[auto]': isCollapse }" />
            <Icon else class="cursor-pointer text-bg-100 mt-[27px] lg:hidden" icon="ant-design:menu-unfold-outlined" width="24" height="24" @click="handleToggleMobileCollapse" />
            <header class="lg:flex hidden items-center bg-bg-100 justify-between rounded-[8px] px-[16px] py-[14px] mt-[32px]" :class="{ 'w-[fit-content] px-[4px] py-[8px] m-[auto]': isCollapse }">
                <div class="flex items-center gap-2" @click="showModal">
                    <img src="@/assets/images/icons/location.svg" width="42" height="42" />
                    <div class="text-caption w-[140px] overflow-y-hidden text-ellipsis" v-show="!isCollapse">
                        <div class="text-body-2 font-semibold text-grey-200 w-[120px] text-ellipsis whitespace-nowrap overflow-hidden">{{ storeProfile?.name}}</div>
                        {{ storeProfile?.address?.street }}, {{ storeProfile?.address?.lga }} {{ storeProfile?.address?.city }}
                    </div>
                </div>
                <Icon class="font-semibold" icon="ant-design:plus-circle-filled" width="24" height="24" v-show="!isCollapse" @click="handleOpenCreateStoreModal" />
            </header>
        </div>

        <main class="lg:mt-[32px] mt-[16px] overflow-auto no-scrollbar mb-[30px] pb-[50px] lg:block" :class="!mobileCollapse && 'hidden'">
            <div v-for="(content, headerContent) in sideBarContents" :key="headerContent">
                <header class="text-caption text-bg-100 mt-[32px] px-[16px]">{{ !isCollapse ? headerContent : '' }}</header>
                <div v-for="(contentData, idx) in content" :key="idx">
                    <router-link :to="{ path: contentData.linkTo}">
                        <div class="flex items-center gap-3 text-bg-100 mt-[12px] px-[16px] py-[12px] rounded-[8px] cursor-pointer" :class="{ 'bg-bg-300': contentData.name == activeTabContent, 'w-[fit-content] px-[16px] py-[12px] m-[auto]': isCollapse }">
                            <Icon class="" :icon="contentData.icon" width="20" height="20" />
                            <div class="text-body-2" v-show="!isCollapse">{{ contentData.name }}</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </main>
        <footer class="lg:block" :class="!mobileCollapse && 'hidden'">
            <div class="">
                <div class="profile rounded-[8px] px-[12px] py-[6px] mt-[32px]" :class="{ 'w-[fit-content] px-[4px] py-[6px] m-[auto]': isCollapse }">
                    <span class="flex items-center justify-between cursor-pointer" @click="() => profileDropdown = !profileDropdown">
                        <div class="flex items-center gap-2">
                            <img src="@/assets/images/images/avatar-1.svg" width="40" height="40" />
                            <div class="text-caption w-[140px] overflow-y-hidden text-ellipsis text-bg-100" v-show="!isCollapse">
                                <div class="text-body-2 font-medium">{{ profileData?.firstName }} {{ profileData?.lastName }}</div>
                                <div class="text-caption">{{role}}</div>
                            </div>
                        </div>

                        <Icon class="text-bg-100 text-bg-100 font-semibold cursor-pointer" icon="ant-design:ellipsis-outlined" width="20" height="20" v-show="!isCollapse" />
                    </span>

                    <div class="profile-dropdown p-[10px] bg-white w-[100%] rounded-[8px] grid gap-[10px]" v-if="profileDropdown" :class="{ 'w-[fit-content] px-[6px] py-[6px] ml-[-6.5px] mt-[20px]': isCollapse }">
                        <router-link :to="{ path:'/settings'}">
                            <div class="text-body-2 text-grey-200 font-medium flex gap-[8px] items-center py-[10px] px-[16px]"  :class="{ 'w-[fit-content] px-[10px] py-[10px] m-[auto]': isCollapse }">
                                <div class=""><Icon icon="ant-design:smile-filled" width="17" height="20" /></div>
                                <div class="" v-show="!isCollapse">Profile</div>
                            </div>
                        </router-link>

                        <router-link to="/settings?q=storeDetails">
                            <div class="text-body-2 text-grey-200 font-medium flex gap-[8px] items-center py-[10px] px-[16px]" :class="{ 'w-[fit-content] px-[10px] py-[10px] m-[auto]': isCollapse }">
                                <div class=""><Icon icon="ant-design:setting-filled" width="17" height="20" /></div>
                                <div class="" v-show="!isCollapse">Settings</div>
                            </div>
                        </router-link>

                        <div class="text-body-2 text-grey-200 font-medium flex gap-[8px] text-white items-center py-[10px] px-[16px] rounded-[8px] bg-primary-300 cursor-pointer"  :class="{ 'w-[fit-content] px-[10px] py-[10px] m-[auto]': isCollapse }" @click="() => logoutSession()">
                            <div class=""><Icon icon="ant-design:login-outlined" width="17" height="20" /></div>
                            <div class="" v-show="!isCollapse">Logout</div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { sideBarContents } from './SideNavbar.helper'
import { defineProps, onMounted, ref, watch } from 'vue';
import { useAuthSession } from "@/utility/composables/useAuthSession";
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const profileData = computed(() => store.state.profile.profileData);
const storeProfile = computed(() => store.state.StoreProfileModule.storeProfile);

const role = computed(() => profileData.value?.appRoles.find((role: any) => role.appName === 'FoodCart-Restaurant')?.role || '');

const { logoutSession } = useAuthSession()

interface Props {
    activeTabContent: string
    showModal?: () => void
    handleOpenCreateStoreModal?: () => void
    mobileCollapse?: boolean
    handleToggleMobileCollapse?: () => void
}

const profileDropdown = ref(false)

const prop = defineProps<Props>()

const isCollapse = ref(false)

const handleToggleCollapse = () => {
    isCollapse.value = !isCollapse.value
    localStorage.setItem('globalSideNavbarCollapsed', `${isCollapse.value}`)
}

onMounted(() => {
    const isCollapsed = localStorage.getItem('globalSideNavbarCollapsed')
    if (isCollapsed !== null) {
        isCollapse.value = !prop.mobileCollapse && isCollapsed === 'true' 
    }
})

watch(() => localStorage.getItem('globalSideNavbarCollapsed'), (newVal) => {
    if (newVal !== null) {
        isCollapse.value = newVal === 'true'
    }
})

onMounted(() => {
    store.dispatch('profile/fetchProfileData');
})

onMounted(() => {
    store.dispatch('StoreProfileModule/fetchStoreDataData');
})

</script>

<style lang="scss" scoped>
.side-navbar {
    display: grid;
    grid-template-rows: 0fr 1fr 0fr;

    @media (max-width: 1024px) {
        padding: 0 8px;
    }
}

.profile {
    position: relative;

    &-dropdown {
        position: absolute;
        left: 0;
        top: -180px;
    }
}

</style>
