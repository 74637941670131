import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex items-center gap-[16px] lg:text-body-2 text-overline w-[fit-content] m-[auto] my-[16px] py-[16px]" }
const _hoisted_2 = { class: "mr-[16px]" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["value"]

import { ref, computed } from 'vue';
import { Icon } from '@iconify/vue';

interface Props {
    totalItems: any
    paginationMode: 'server' | 'client'
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TablePagination',
  props: {
    totalItems: {},
    paginationMode: {}
  },
  emits: ['pageChange', 'pageSizeChange'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const currentPage = ref(1);
const pageSize = ref(10);
const pageSizeOptions = [5, 10, 25, 50]; 
const totalPages = computed(() => Math.ceil((props.paginationMode === 'server' ? props.totalItems : props.totalItems.length) / pageSize.value));
const emit = __emit;

const prevPage = () => {
    if (currentPage.value > 1) {
        currentPage.value--;
        emitPageChange();
    }
};

const nextPage = () => {
    if (currentPage.value < totalPages.value) {
        currentPage.value++;
        emitPageChange();
    }
};

const gotoPage = (pageNumber: number) => {
    currentPage.value = pageNumber;
    emitPageChange();
};

const handlePageSizeChange = () => {
    currentPage.value = 1;
    emit('pageSizeChange', pageSize.value); 
    emitPageChange(); 
};

const emitPageChange = () => {
    emit('pageChange', currentPage.value);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, "Total " + _toDisplayString(props.paginationMode === 'server' ? props.totalItems : props.totalItems.length) + " items", 1),
    _createVNode(_unref(Icon), {
      icon: "ant-design:left-outlined",
      onClick: prevPage,
      disabled: currentPage.value === 1,
      class: "mr-[8px]"
    }, null, 8, ["disabled"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(totalPages.value, (pageNumber) => {
      return (_openBlock(), _createElementBlock("div", {
        key: pageNumber,
        onClick: ($event: any) => (gotoPage(pageNumber)),
        class: _normalizeClass(["cursor-pointer", { 'border-[1px] border-solid border-primary-300 text-primary-300 px-[10px] py-[3px] rounded-[8px]': pageNumber === currentPage.value }])
      }, _toDisplayString(pageNumber), 11, _hoisted_3))
    }), 128)),
    _createVNode(_unref(Icon), {
      icon: "ant-design:right-outlined",
      onClick: nextPage,
      disabled: currentPage.value === totalPages.value,
      class: "ml-[8px]"
    }, null, 8, ["disabled"]),
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((pageSize).value = $event)),
      onChange: handlePageSizeChange,
      class: "ml-[16px] bg-transparent outline-none"
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(pageSizeOptions, (option) => {
        return _createElementVNode("option", {
          key: option,
          value: option
        }, _toDisplayString(option) + " / pages", 9, _hoisted_4)
      }), 64))
    ], 544), [
      [_vModelSelect, pageSize.value]
    ])
  ]))
}
}

})