<template>
    <AppWrapper activeTab="Dashboard">
        <!-- 
            ------- ToDo ----- 
            >> Create a custom BreadCrumbs Component dedicated to this project
            >> Replace Breadcrumbs Component with div below
        -->
        <div class="py-[72px]">
            <div class="flex items-center gap-[6px] text-body-1">
                <div class="flex items-center gap-[6px] text-grey-100 cursor-pointer" @click="router.back()">
                    <Icon icon="ant-design:appstore-outlined" />
                    <div>Dashboard</div>
                </div>
                <div>/</div>
                <div>Best selling items</div>
            </div>

            <div class="flex justify-between items-center mt-[32px]">
                <div class="">
                    <InputField type="text" 
                        placeholder="Search"
                        :value="search" 
                        prefix-icon="ant-design:search-outlined" 
                        @change="onSearch"  />
                </div>
                <div class="">
                    <DropdownField defaultOptionLabel="Select" 
                    :optionsData="orderSearchDateOptions" @change="onChangeData"
                     />
                </div>
            </div>

            <!-- <div class="flex gap-[32px] border-b-[1px] border-solid mt-[24px]">
                <div
                    v-for="(tab, index) in bestSellersTabs"
                    :key="index"
                    :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                    @click="activateTab(index)"
                > {{ tab }} </div>
            </div> -->
            <div class="mt-[32px]">
                <TableField :headers="bestSellingTableHeaders" :dataItems="bestSellingItemsData" :isPagination="true" :viewDetails="false" />
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue'

import { Icon } from '@iconify/vue';
import { getDashboardBestItems } from '../helper/Dashboard.helper';
import TableField from '@/components/shared/table/TableField.vue';
// import { bestSellersTabs, bestSellingTableHeaders } from './BestSellingTableModule.helper'

import { useRouter } from 'vue-router';
const router = useRouter()

const bestSellingTableHeaders = ref([
    { name: "Items", value: "entityCard" },
    { name: "Orders", icon: 'ant-design:sort-ascending-outlined', value: "count" },
    { name: "Channel", value: "orderChannel" },
    { name: "Category", value: "category" },
]);
export interface SearchOptions {
    search: string;
    since: number;
}

const bestSellingItemsData = ref();
const search = ref();

const searchOptionsData = ref<SearchOptions>({
    search: '',
    since: 0
});

const orderSearchDateOptions = ref( [
    { value: 0, name: ''},
    { value: 30, name: 'Last 30 days'},
    { value: 60, name: 'Last 60 days'},
    { value: 80, name: 'Last 80 days'}
]);

const onSearch = (event: any) => {
    searchOptionsData.value.search = event.target.value;
};

const onChangeData = (event: any) => {
    searchOptionsData.value.since = event.target.value;
};

watchEffect(() => {
    getDashboardBestItems({
        search: searchOptionsData.value.search,
        since: searchOptionsData.value.since
    }).then((responseData: any) => {
        bestSellingItemsData.value = responseData.map((item: any) => {
            return {
                ...item,
                name: item.menuName
            }
        });
    });

})

</script>

<style scoped>

</style>