import SetupCheckListService from "@/utility/services/setup-check.service";
import { ref, watchEffect } from "vue";

export const getSetupCheckList = async () => {
    try {
        const response = await SetupCheckListService.setupChecklistRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const useSetupGuideHelper = () => {
    const setupCheckListData = ref();

    watchEffect(() => {
        getSetupCheckList().then((response) => {
            setupCheckListData.value = response;
            console.log(setupCheckListData.value);
        })
    })

    return { setupCheckListData };
}