import MenusService from "@/utility/services/menus.service";
import { ref, watchEffect } from "vue";

export const createMenu = async (values: any, store: any) => {
    try {
        await MenusService.createMenuRequest(values);
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Menu created', x_message: `${values.name} menu was created successfully`, duration: 5000 });
    } catch (error: any) {
        console.error(error)
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error creating menu', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
}

export const getCategories = async () => {
    try {
        const response = await MenusService.getCategoriesRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const useMenuFormHelper = () => {
    const tabs = ref(['Details', 'Sizes', 'Extras']);
    const activeTab = ref(0);
    const categoriesData = ref()

    const activateTab = (index: number) => {
        activeTab.value = index;
    };

    watchEffect(() => {
        getCategories().then((response) => {
            categoriesData.value = response;
        })
    })

    return { tabs, activateTab, activeTab, categoriesData }
}