import ApiService from './api.service';
import { storeId } from './auth.service';

export default class MenusService {

    static async getMenusRequest({search = '', page = '', sortBy='', sortDirection = 1, limit=300, category=""} ): Promise<any> {
        const response = await ApiService.get(`store/${storeId()}/menus?search=${search}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}&limit=${limit}&category=${category}`)
        return response.data.data
    }

    static async getMenuByIdRequest(menuId: string): Promise<unknown> {
        const response = await ApiService.get(`store/${storeId()}/menus/${menuId}`)
        return response.data.data
    }

    static async updateMenuRequest(value: Record<string | number, unknown>, menuId: string): Promise<unknown> {
        const response = await ApiService.put(`store/${storeId()}/menus/${menuId}`, {...value})
        return response.data.data
    }

    static async createMenuRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post(`store/${storeId()}/menus`, {...values})
        return response.data.data
    }

    static async getCategoriesRequest(): Promise<unknown> {
        const response = await ApiService.get('setup/category')
        return response.data.data
    }

    static async getOrdersCategoriesRequest(): Promise<unknown> {
        const response = await ApiService.get(`store/${storeId()}/category`)
        return response.data.data
    }
}