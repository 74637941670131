import { ref, watchEffect } from "vue";
import OrdersService from "@/utility/services/orders.service";
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue'
import { sortBy, sum } from "lodash";
export interface OrderDetails {
    orderId: string | number | undefined;
    orderNumber: string;
    orderDate: string;
    orderChannel: string;
    totalPrice: string;
    status: string;
}

export interface CustomerOrderDetails {
    orderId: string | number | undefined;
    orderNumber: string;
    orderDate: string;
    orderChannel: string;
    category: string;
    menuName: string;
    menuImageUrl: string;
    totalPrice: string;
    quantity: number;
    status: string;
}

export interface OrderDetail {
    orderId: string | number | undefined
    orderNumber: string
    orderDate: string
    orderChannel: string
    subTotalPrice: string
    totalPrice: string
    status: string
    paymentType: string
    customerFirstName: string
    customerLastName: string
    customerPhone: string
    customerName: string
    cashier: Record<string | number, any>
    serviceFees: any
    orderDetails: any
    cancelReason: string,
    imageUrl: string
}

export const orderDetailInitialValues = {
    imageUrl: '',
    orderId: '',
    orderNumber: '',
    orderDate: '',
    orderChannel: '',
    totalPrice: '',
    subTotalPrice: '',
    status: '',
    paymentType: '',
    customerFirstName: '',
    customerLastName: '',
    customerPhone: '',
    customerName: '',
    orderDetails: [],
    serviceFees: [],
    cancelReason: '',
    cashier: {
        firstName: '',
        lastName: '',
    }
}

export interface SearchDateOptions {
    value: string | number | undefined;
    name: string;
}

export interface ActiveTab {
    status: string;
    orderCount: number;
}

export interface SearchOptions {
    status: string;
    search: string;
    since: number;
    startDate: string
    endDate: string
    cashier: string
    category: string
    paymentMethod: string
}

const searchOptionsData = ref<SearchOptions>({
    status: '',
    search: '',
    since: 0,
    startDate: '',
    endDate: '',
    cashier: '',
    category: '',
    paymentMethod: ''

});



export const getOrders = async({status = "", search="", cashier = "", category = "", paymentMethod = "", page=1, limit=10, startDate='', endDate='', since=0 }) => {
    try {
        const response = await OrdersService.getOrdersRequest({ status, search, cashier, category, paymentMethod, page, limit, startDate, endDate, since });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const getCategories = async () => {
    try {
        const response = await MenusService.getOrdersCategoriesRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const getCashiers = async () => {
    try {
        const response = await OrdersService.getCashiersRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const getPayments = async () => {
    try {
        const response = await PaymentService.getPaymentsRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const getOrderById = async({ orderId = "" }) => {
    try {
        const response = await OrdersService.getOrderByIdRequest({orderId});
        return response;
    } catch (error) {
        console.error(error)
    }
}

import { Ref } from "vue";
import MenusService from "@/utility/services/menus.service";
import PaymentService from "@/utility/services/payment.service";

export const useOrderHelper = (pageSize?: Ref<number>, currentPage?: Ref<number>) => { 
    
    const orderTabsCustomers = ref<any>([]);
    const orderData = ref([]);
    const totalOrders = ref();
    const customOrderData = ref([]);
    const search = ref();
    const orderTabs = ref<any>([]);
    const orderInformationTabs = ref(['Customer Order']);
    const loading = ref<boolean>(true)

    const activeTab: any = ref<ActiveTab>({
        status: "All",
        orderCount: 0 
    });
    
    const onSearch = (event: any) => {
        searchOptionsData.value.search = event.target.value;
    };

    const onChangeData = (event: any) => {
        loading.value = true
        searchOptionsData.value.since = event.target.value;
    };

    const onChangeStartDate = (event: any) => {
        loading.value = true
        searchOptionsData.value.startDate = event.target.value;
    };

    const onChangeEndDate = (event: any) => {
        loading.value = true
        searchOptionsData.value.endDate = event.target.value;
    };

    const onChangeCategoryData = (event: any) => {
        loading.value = true
        searchOptionsData.value.category = event.target.value;
    };

    const onChangeCashierData = (event: any) => {
        loading.value = true
        searchOptionsData.value.cashier = event.target.value;
    };

    const onChangePaymentMethodData = (event: any) => {
        loading.value = true
        searchOptionsData.value.paymentMethod = event.target.value;
    };

    
    watchEffect(() => {
        getOrders({
            status: searchOptionsData.value.status, 
            search: searchOptionsData.value.search,
            since: searchOptionsData.value.since,
            cashier: searchOptionsData.value.cashier,
            category: searchOptionsData.value.category,
            paymentMethod: searchOptionsData.value.paymentMethod,
            limit: pageSize?.value,
            page: currentPage?.value,
            startDate: searchOptionsData.value.startDate,
            endDate: searchOptionsData.value.endDate,
        }).then((responseData) => {
            const { orders, ordersByStatus, total } = responseData;
            const totalOrderCount = sum(ordersByStatus.map((item: { orderCount: number; }) => item.orderCount));
            ordersByStatus.push({ status: "All", orderCount: totalOrderCount });
            orderData.value = orders;
            totalOrders.value = total;
            orderTabs.value = sortBy<ActiveTab>(ordersByStatus, ['status']);
            loading.value = false
        });
        
    });
    
    const activateTab = (tab: ActiveTab) => {
        activeTab.value = tab;
        searchOptionsData.value.status = tab.status;
    };

    const orderDetailsData = ref<OrderDetails>({
        orderId: '',
        orderNumber: '',
        orderDate: '',
        orderChannel: '',
        totalPrice: '',
        status: ''
    })

    const orderSearchDateOptions = ref( [
        { value: 0, name: 'Today'},
        { value: 1, name: 'Yesterday'},
        { value: 7, name: 'Last Week'},
        { value: 30, name: 'Last 30 days'},
        { value: 60, name: 'Last 60 days'},
        { value: 80, name: 'Last 80 days'}
    ]);

    const orderHeaders = ref([
        { name: "Order Details", value: "OrderDetails" },
        { name: "Order Date", icon: 'ant-design:sort-ascending-outlined', value: "orderDate" },
        { name: "Customer's name", value: "customer" },
        { name: "Payment Method", value: "paymentMethod" },
        { name: "Cashier", value: "cashier" },
        { name: "Total Price", value: "totalPrice" },
        { name: "Status", value: "statusCard" },
    ]);

    const customOrderHeaders = ref([
        { name: "Order Number", value: "orderEntity" },
        { name: "Order Details", value: "entityCard" },
        { name: "Order Channel", value: "orderChannel" },
        { name: "Order Details", value: "OrderDetails" },
        { name: "Status", value: "statusCard" },
        { name: "Total Price", value: "totalPrice" },
    ]);

    const customerOrderDetailsData = ref<CustomerOrderDetails>({
        orderId: '',
        orderNumber: '',
        menuName: '',
        category: '',
        orderDate: '',
        orderChannel: '',
        quantity: 0,
        totalPrice: '',
        status: '',
        menuImageUrl: ""
    });

    return {
        AppWrapper,
        orderTabs,
        orderData,
        orderHeaders,
        orderDetailsData,
        orderInformationTabs,
        orderSearchDateOptions,
        search,
        onSearch,
        onChangeData,
        activateTab,
        activeTab,
        customOrderData,
        orderTabsCustomers,
        customOrderHeaders,
        customerOrderDetailsData,
        searchOptionsData,
        onChangeCategoryData,
        onChangeCashierData,
        onChangePaymentMethodData,
        totalOrders,
        onChangeStartDate,
        onChangeEndDate,
        loading
    }
}
