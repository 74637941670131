import { createStore } from 'vuex';
import profile from './modules/Profile';
import setupGuide from './modules/SetupGuideCheckList'
import ToastX from './modules/ToastX';
import StoreProfileModule from './modules/storeProfile';

export default createStore({
  modules: {
    profile,
    setupGuide,
    ToastX,
    StoreProfileModule
  }
});
