import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-[16px]" }
const _hoisted_2 = { class: "flex items-center gap-[10px] text-[#1677FF] font-semibold cursor-pointer" }
const _hoisted_3 = { class: "w-[fit-content]" }
const _hoisted_4 = ["name", "value"]

import { Icon } from '@iconify/vue';
  import { ref, watchEffect } from 'vue';
  import ImageCard from '../image/ImageCard.vue';
  
  interface Props {
    name?: string | number | any
    value?: string | number | any
    imageUrl?: string
  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'UploadProfileImage',
  props: {
    name: {},
    value: {},
    imageUrl: {}
  },
  emits: ['base64ImgCode', 'update:value', 'update:blur:value'],
  setup(__props: any, { emit: __emit }) {

  const fileInput = ref<HTMLInputElement | null>(null);
  const previewImageUrl = ref<string | null | any>(null);
  const dummyImage = ref('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fconferenceoeh.com%2Fwp-content%2Fuploads%2Fprofile-pic-dummy.png&f=1&nofb=1&ipt=0240de22da1ceb5dd6f69ef26d82a4bcee544f1349c8f87b51289b4067856e67&ipo=images')
  const emit = __emit;

  
  
  const openFilePicker = () => {
    if (fileInput.value) {
      fileInput.value.click();
    }
  };
  
  const handleFileChange = (event: Event) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          previewImageUrl.value = reader.result;
          emit('base64ImgCode', previewImageUrl.value);
        }
      };
      reader.readAsDataURL(files[0]);
    }
  };

  watchEffect(() => {
    previewImageUrl.value
  })


  
  
return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ImageCard, {
      imageUrl: !previewImageUrl.value ? _ctx.imageUrl || dummyImage.value : previewImageUrl.value,
      width: "86",
      height: "86"
    }, null, 8, ["imageUrl"]),
    _createElementVNode("div", {
      class: "text-center whitespace-nowrap text-body-2 font-medium",
      onClick: openFilePicker
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(Icon), { icon: "ant-design:picture-outlined" })
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "" }, "Upload profile image", -1))
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-grey-200 mt-[4px]" }, "PNG or JPEG (Max. File Size: 2MB)", -1))
    ]),
    _createElementVNode("input", {
      type: "file",
      name: _ctx.name,
      value: _ctx.value,
      onChange: handleFileChange,
      accept: "image/*",
      style: {"display":"none"},
      ref_key: "fileInput",
      ref: fileInput,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:blur:value', $event)))
    }, null, 40, _hoisted_4)
  ]))
}
}

})