import ApiService from './api.service';
import { storeId } from './auth.service';

export interface StoreProfile {
    name: string
    email: string
    phone: number
    address: Record<string | number, any>
    restaurantType: Array<string | number>
    website: string
    establishDate: Date
    businessStatus: string
    serviceOptions: Array<string | number>,
    deviceRegistrationCode?: number
} 

export interface PayoutAccount {
    accountName: string
    accountNumber: number
    accountProvider: string
}

export interface MicrositeAccount {
    logo?: string
    cover?: string
    name: string
    description: string,
    logoImageData: string,
    coverImageData: string,
}
export default class SettingsService {

    static async accountProfileRequest(): Promise<unknown> {
        try {
            const response = await ApiService.get('account/profile')
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async storeProfileRequest(): Promise<unknown> {
        try {
            const response = await ApiService.get(`store/${storeId()}/profile`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async updateStoreProfileRequest(values: StoreProfile): Promise<unknown> {
        const response = await ApiService.put(`store/${storeId()}/profile`, {...values})
        return response.data.data;
    }

    static async updatePayoutAccountRequest(values: PayoutAccount): Promise<unknown> {
        try {
            const response = await ApiService.put(`store/${storeId()}/payout`, {...values})
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getBankDetailsRequest(): Promise<unknown> {
        try {
            const response = await ApiService.get('setup/banks')
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async updateAccountProfileAvatarRequest(avatar: string): Promise<unknown> {
        try {
            const response = await ApiService.put('account/avatar', { avatar })
            return response
        } catch (error) {
            console.log(error)
        }
    }

    static async updateAccountProfileRequest(firstName: string, lastName: string, email: string, phone: number, address: Record<string | number, any>): Promise<unknown> {
        const response = await ApiService.put('account/profile', {
            firstName,
            lastName,
            email,
            phone,
            address
        });
        return response;
    }

    static async changeAccountPasswordRequest(currentPassword: string, newPassword: string): Promise<unknown> {
        try {
            const response = await ApiService.post('account/change-password', {
                currentPassword,
                newPassword
            })
            return response
        } catch (error) {
            console.log(error)
        }
    }

    static async payoutAccountRequest(): Promise<unknown> {
        try {
            const response = await ApiService.get(`store/${storeId()}/payout`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async micrositePersonalizationRequest(): Promise<unknown> {
        try {
            const response = await ApiService.get(`store/${storeId()}/microsite`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async updateMicrositePersonalizationRequest(values: MicrositeAccount): Promise<unknown> {
        try {
            const response = await ApiService.put(`store/${storeId()}/microsite`, {...values})
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async additionalChargesRequest(): Promise<unknown> {
        try {
            const response = await ApiService.get(`store/${storeId()}/fees`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async workingHoursRequest(): Promise<unknown> {
        try {
            const response = await ApiService.get(`store/${storeId()}/hours`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async updateAdditionalChargesRequest(values: any): Promise<unknown> {
        try {
            const response = await ApiService.put(`store/${storeId()}/fees`, values)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async updateWorkingHoursRequest(values: any): Promise<unknown> {
        try {
            const response = await ApiService.put(`store/${storeId()}/hours`, {...values})
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }
}