import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "modal-overlay" }
const _hoisted_2 = { class: "modal-content max-h-[90vh] overflow-auto relative no-scrollbar" }
const _hoisted_3 = { class: "flex items-center justify-between border-b-[1px] border-solid border-grey-50 p-[24px] fixed w-[700px] bg-white z-[1]" }
const _hoisted_4 = { class: "font-semibold text-sub-heading" }
const _hoisted_5 = { class: "mt-[60px]" }
const _hoisted_6 = {
  key: 0,
  class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]"
}

import { Icon } from '@iconify/vue';
import { VNode } from 'vue';
import ActionButton from '../action-button/ActionButton.vue';

interface Props {
    headerContent: VNode | string
    openModal: boolean 
    defaultActionButton?: boolean
    actionBtnHandleClick?: () => void
    isSubmit?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppModal',
  props: {
    headerContent: {},
    openModal: { type: Boolean },
    defaultActionButton: { type: Boolean },
    actionBtnHandleClick: { type: Function },
    isSubmit: { type: Boolean }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emits = __emit;

const closeModal = () => {
    emits('close');
};


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", _hoisted_3, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.headerContent), 1),
        _createElementVNode("button", { onClick: closeModal }, [
          _createVNode(_unref(Icon), { icon: "ant-design:close-outlined" })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.defaultActionButton)
        ? (_openBlock(), _createElementBlock("footer", _hoisted_6, [
            _createVNode(ActionButton, {
              label: "Cancel",
              theme: "secondary",
              class: "w-[fit-content]"
            }),
            _createVNode(ActionButton, {
              label: "Save",
              theme: "primary",
              class: "w-[fit-content]",
              type: _ctx.isSubmit,
              handleClick: _ctx.actionBtnHandleClick
            }, null, 8, ["type", "handleClick"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 512)), [
    [_vShow, props.openModal]
  ])
}
}

})