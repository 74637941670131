import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-primary-300 py-[40px] px-[34px] fixed w-[100%] top-0 flex gap-[48px] items-center" }
const _hoisted_2 = { class: "px-[32px] mt-[180px]" }
const _hoisted_3 = { class: "flex items-center justify-between mt-[36px] mb-[32px]" }
const _hoisted_4 = { class: "flex items-center gap-[12px]" }
const _hoisted_5 = { class: "" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import TableField from '@/components/shared/table/TableField.vue';
import { useStoreManagementHelper } from './StoreManagement.helper';
import { ref } from 'vue';
import CreateStoreForm from './CreateStoreForm.vue';
import { Icon } from '@iconify/vue';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'StoreManagement',
  setup(__props) {

const { storesHeader, storesData, createStoreHandler, selectStore } = useStoreManagementHelper()
const isOpen = ref(false);
const router = useRouter()

const handleOpenEditModal = () => {
    isOpen.value = !isOpen.value;
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", {
        class: "text-white flex gap-[10px] cursor-pointer",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(router).back && _unref(router).back(...args)))
      }, [
        _createVNode(_unref(Icon), {
          icon: "material-symbols:arrow-left-alt",
          width: "24",
          height: "24"
        }),
        _cache[2] || (_cache[2] = _createElementVNode("div", null, "Go back", -1))
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "bg-[#0000001F] flex items-center w-[fit-content] rounded-[8px] p-[2px] text-body-2" }, [
        _createElementVNode("div", { class: "bg-white px-[24px] py-[8px] rounded-[8px]" }, "Stores Management")
      ], -1))
    ]),
    _createVNode(CreateStoreForm, {
      isOpen: isOpen.value,
      handleOpenEditModal: handleOpenEditModal,
      createStoreFormHandler: _unref(createStoreHandler)
    }, null, 8, ["isOpen", "createStoreFormHandler"]),
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "font-semibold text-heading-3" }, "Menu", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(InputField, {
            type: "text",
            placeholder: "Search",
            "prefix-icon": "ant-design:search-outlined",
            onChange: () => {}
          }),
          _createVNode(ActionButton, {
            handleClick: () => handleOpenEditModal(),
            theme: "primary",
            label: "New Store",
            class: "w-[fit-content] m-[auto]",
            prefixIcon: "ant-design:plus-outlined"
          }, null, 8, ["handleClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(TableField, {
          headers: _unref(storesHeader),
          dataItems: _unref(storesData)() || [],
          isPagination: true,
          viewDetails: true,
          viewDetailsText: "Visit Store",
          onHandleViewDetailsClick: _cache[1] || (_cache[1] = (data) => _unref(selectStore)(data?.id))
        }, null, 8, ["headers", "dataItems"])
      ])
    ])
  ], 64))
}
}

})