import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-wrapper-left" }

import SideNavbar from '@/components/shared/side-navbar/SideNavbar.vue';
import { ref, watch, watchEffect } from 'vue';
import { useAppWrapperHelper } from './AppWrapper.helper';
import SwitchStoresModal from './components/SwitchStoresModal.vue'
import { useStoreManagementHelper } from '@/views/store-management-analysis/StoreManagement.helper';
import CreateStoreForm from '@/views/store-management-analysis/CreateStoreForm.vue';
import { Icon } from '@iconify/vue';

interface Props {
  activeTab: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppWrapper',
  props: {
    activeTab: {}
  },
  setup(__props: any) {



const { storesProfile } = useAppWrapperHelper();
const openStoreModal = ref(false)
const selectedStoreId = ref<string | null>(null);
const isOpen = ref(false);
const { createStoreHandler } = useStoreManagementHelper();

const mobileCollapse = ref(false);
const handleToggleMobileCollapse = () => mobileCollapse.value = !mobileCollapse.value;

const handleShowModal = () => {
  openStoreModal.value = !openStoreModal.value;
}

const reloadApp = () => {
  window.location.reload()
}

const selectStore = (storeId: string) => {
  selectedStoreId.value = storeId;
  localStorage.setItem('storeId', storeId);
};

const handleOpenCreateStoreModal = () => {
    isOpen.value = !isOpen.value;
};

watch(selectedStoreId, (newStoreId) => {
  if (newStoreId) {
    localStorage.setItem('storeId', newStoreId);
  }
});

watchEffect(() => {
  const currentStoreId = localStorage.getItem('storeId');
  if (currentStoreId) {
    selectedStoreId.value = currentStoreId;
  } else if (storesProfile.value.length > 0) {
    selectedStoreId.value = storesProfile.value[0].id;
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['app-wrapper', { 'mobile-collapse': mobileCollapse.value }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(SideNavbar, {
          handleToggleMobileCollapse: () => handleToggleMobileCollapse(),
          mobileCollapse: mobileCollapse.value,
          activeTabContent: _ctx.activeTab,
          showModal: handleShowModal,
          storesProfile: _unref(storesProfile),
          handleOpenCreateStoreModal: handleOpenCreateStoreModal
        }, null, 8, ["handleToggleMobileCollapse", "mobileCollapse", "activeTabContent", "storesProfile"])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(['app-wrapper-right', { 'hidden': mobileCollapse.value }, 'no-scrollbar lg:px-[32px] px-[10px]'])
      }, [
        _createVNode(_unref(Icon), {
          class: "cursor-pointer mt-[27px] lg:hidden",
          icon: "ant-design:menu-unfold-outlined",
          width: "24",
          height: "24",
          onClick: handleToggleMobileCollapse
        }),
        _renderSlot(_ctx.$slots, "default")
      ], 2)
    ], 2),
    _createVNode(CreateStoreForm, {
      isOpen: isOpen.value,
      handleOpenEditModal: handleOpenCreateStoreModal,
      createStoreFormHandler: _unref(createStoreHandler)
    }, null, 8, ["isOpen", "createStoreFormHandler"]),
    _createVNode(SwitchStoresModal, {
      openStoreModal: openStoreModal.value,
      handleShowModal: handleShowModal,
      storesProfile: _unref(storesProfile),
      selectedStoreId: selectedStoreId.value,
      selectStore: selectStore,
      reloadApp: reloadApp
    }, null, 8, ["openStoreModal", "storesProfile", "selectedStoreId"]),
    _createElementVNode("div", {
      class: "absolute bg-primary-300 rounded-[50%] lg:w-[54px] lg:h-[54px] w-[34px] h-[32px] bottom-[40px] right-[40px] shadow-md hover:shadow-lg animate-bounce-slow cursor-pointer",
      onClick: handleShowModal
    }, [
      _createVNode(_unref(Icon), {
        icon: "mynaui:arrow-left-right",
        class: "absolute text-white left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] lg:w-[32px] lg:h-[32px]"
      })
    ])
  ], 64))
}
}

})