import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "mt-[24px] border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]" }
const _hoisted_3 = { class: "relative border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]" }
const _hoisted_4 = { class: "grid grid-cols-3 gap-[24px]" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "px-[28px] pt-[32px]" }
const _hoisted_16 = { class: "grid grid-cols-2 gap-[24px] debit-id items-center gap-[12px] mt-[32px]" }
const _hoisted_17 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }
const _hoisted_18 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]" }
const _hoisted_19 = { class: "px-[28px]" }
const _hoisted_20 = { class: "grid grid-cols-1 gap-[20px] debit-id items-center gap-[12px] mt-[95px]" }
const _hoisted_21 = {
  key: 0,
  class: "text-body-2 text-medium text-error mt-[4px]"
}
const _hoisted_22 = {
  key: 1,
  class: "text-body-2 text-medium text-error mt-[4px]"
}
const _hoisted_23 = {
  key: 2,
  class: "text-body-2 text-medium text-error mt-[4px]"
}
const _hoisted_24 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }

import { getAccountProfile, updateAccountAvatar } from './Settings.helper';
import { watchEffect, ref, watch } from 'vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import { useSettingsHandler } from '@/utility/composables/useSettingsRequestsHandler';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import UploadProfileImage from '@/components/shared/upload-profile-image/UploadProfileImage.vue';
import { useStore } from 'vuex';


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileSettings',
  setup(__props) {

const loading = ref(false);
const isProfileUpdateModal = ref(false)
const isChangePasswordModal = ref(false)
const showPassword = ref(false);
const store = useStore()

const profileData: Record<string | number, any> = ref({});

const handleShowPassword = () => showPassword.value = !showPassword.value;

const imageDataFromEmitedBase64 = async (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    const avatar = imageDataParts?.[1];
    await updateAccountAvatar(avatar, store);
}

const handleOpenChangePasswordUpdateModal = () => {
    isChangePasswordModal.value = !isChangePasswordModal.value;
};

const handleOpenProfileUpdatemodal = () => {
    isProfileUpdateModal.value = !isProfileUpdateModal.value;
};

const { accountProfileFormHandler, changePasswordFormHandler } = useSettingsHandler(store);

const handleOpenProfileUpdateModalSubmit = async (event: any) => {
    loading.value = true;
    await accountProfileFormHandler.handleSubmit(event);
    profileData.value = accountProfileFormHandler.values;
    handleOpenProfileUpdatemodal();
    loading.value = false;
}

const handleOpenChangePasswordModalSubmit = async (event: any) => {
    await changePasswordFormHandler.handleSubmit(event);
    handleOpenChangePasswordUpdateModal();
}

watchEffect(() => {
    getAccountProfile().then((responseData) => {
        profileData.value = responseData;
    })
})

watch(profileData, (newProfileData) => {
    accountProfileFormHandler.setValues(newProfileData);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-grey-200" }, "Manage your profile and personal information below.", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(UploadProfileImage, {
        imageUrl: profileData.value?.avatar,
        name: "logo",
        onBase64ImgCode: imageDataFromEmitedBase64,
        "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
        "onUpdate:value": _unref(accountProfileFormHandler).handleFileChange
      }, null, 8, ["imageUrl", "onUpdate:blur:value", "onUpdate:value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("header", { class: "flex items-center justify-between" }, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-semibold text-sub-heading" }, "Personal Information", -1)),
        _createElementVNode("div", {
          class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] text-body-2 py-[11px] w-[fit-content] cursor-pointer",
          onClick: handleOpenProfileUpdatemodal
        }, "Update")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "First Name", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.firstName), 3)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Last Name", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.lastName), 3)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Email", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[200px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.email), 3)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Phone Number", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[120px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.phone), 3)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Address", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[160px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.address?.street), 3)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "City", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[120px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.address?.city), 3)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "LGA", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[120px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.address?.lga), 3)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "State", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.address?.state), 3)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Country", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.address?.country), 3)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Postal Code", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(profileData.value?.address?.postalCode), 3)
        ])
      ]),
      _createVNode(AppModal, {
        headerContent: "Update Personal information",
        openModal: isProfileUpdateModal.value,
        onClose: handleOpenProfileUpdatemodal
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (handleOpenProfileUpdateModalSubmit($event)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_15, [
              _cache[13] || (_cache[13] = _createElementVNode("header", { class: "text-body-2 text-grey-200" }, "Please note that you can only update your phone number. For any other changes to your information, please contact us.", -1)),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(InputField, {
                  label: "First Name",
                  type: "text",
                  placeholder: "First Name",
                  name: "firstName",
                  value: profileData.value?.firstName,
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Last Name",
                  type: "text",
                  placeholder: "Last Name",
                  value: profileData.value?.lastName,
                  name: "lastName",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Email",
                  type: "text",
                  placeholder: "Email",
                  value: profileData.value?.email,
                  name: "email",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Phone Number",
                  type: "text",
                  placeholder: "Phone Number",
                  value: profileData.value?.phone,
                  name: "phone",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Address - Street",
                  type: "text",
                  placeholder: "Street",
                  value: profileData.value?.address?.street,
                  name: "address.street",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Address - City",
                  type: "text",
                  placeholder: "City",
                  value: profileData.value?.address?.city,
                  name: "address.city",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Address - LGA",
                  type: "text",
                  placeholder: "LGA",
                  value: profileData.value?.address?.lga,
                  name: "address.lga",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Address - State",
                  type: "text",
                  placeholder: "State",
                  value: profileData.value?.address?.state,
                  name: "address.state",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Address - Postal Code",
                  type: "text",
                  placeholder: "Postal Code",
                  value: profileData.value?.address?.postalCode,
                  name: "address.postalCode",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Address - Country",
                  type: "text",
                  placeholder: "Country",
                  value: profileData.value?.address?.country,
                  name: "address.country",
                  "onUpdate:blur:value": _unref(accountProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(accountProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
              ])
            ]),
            _createElementVNode("footer", _hoisted_17, [
              _createVNode(ActionButton, {
                theme: "secondary",
                class: "w-[fit-content]",
                label: "Cancel",
                onClick: handleOpenProfileUpdatemodal
              }),
              _createVNode(ActionButton, {
                theme: "primary",
                class: "w-[fit-content]",
                isSubmit: true,
                disable: loading.value && true,
                label: loading.value ? 'Loading...' : 'Save'
              }, null, 8, ["disable", "label"])
            ])
          ], 32)
        ]),
        _: 1
      }, 8, ["openModal"])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("header", { class: "flex items-center justify-between" }, [
        _cache[14] || (_cache[14] = _createElementVNode("div", { class: "font-semibold text-sub-heading" }, "Change Passwords", -1)),
        _createElementVNode("div", {
          class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] text-body-2 py-[11px] w-[fit-content] cursor-pointer",
          onClick: handleOpenChangePasswordUpdateModal
        }, "Change")
      ]),
      _cache[15] || (_cache[15] = _createElementVNode("div", { class: "text-grey-200 mt-[10px] text-body-2" }, "Update your password from your old one", -1)),
      _createVNode(AppModal, {
        headerContent: "Change password",
        openModal: isChangePasswordModal.value,
        onClose: handleOpenChangePasswordUpdateModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (handleOpenChangePasswordModalSubmit($event)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(InputField, {
                  placeholder: "Old password",
                  prefixIcon: "ant-design:lock-outlined",
                  name: "currentPassword",
                  value: _unref(changePasswordFormHandler).values.currentPassword,
                  suffixIcon: showPassword.value ? 'ant-design:eye-invisible-outlined' : 'ant-design:eye-outlined',
                  handleClick: handleShowPassword,
                  type: showPassword.value ? 'text' : 'password',
                  "onUpdate:blur:value": _unref(changePasswordFormHandler).handleBlur,
                  "onUpdate:value": _unref(changePasswordFormHandler).handleChange
                }, null, 8, ["value", "suffixIcon", "type", "onUpdate:blur:value", "onUpdate:value"]),
                (_unref(changePasswordFormHandler).errors.currentPassword)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_unref(changePasswordFormHandler).errors.currentPassword), 1))
                  : _createCommentVNode("", true),
                _createVNode(InputField, {
                  placeholder: "New password",
                  type: "password",
                  name: "newPassword",
                  value: _unref(changePasswordFormHandler).values.newPassword,
                  prefixIcon: "ant-design:lock-outlined",
                  "onUpdate:blur:value": _unref(changePasswordFormHandler).handleBlur,
                  "onUpdate:value": _unref(changePasswordFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                (_unref(changePasswordFormHandler).errors.newPassword)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_unref(changePasswordFormHandler).errors.newPassword), 1))
                  : _createCommentVNode("", true),
                _createVNode(InputField, {
                  placeholder: "Confirm password",
                  type: "password",
                  name: "confirmPassword",
                  value: _unref(changePasswordFormHandler).values.confirmPassword,
                  prefixIcon: "ant-design:lock-outlined",
                  "onUpdate:blur:value": _unref(changePasswordFormHandler).handleBlur,
                  "onUpdate:value": _unref(changePasswordFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                (_unref(changePasswordFormHandler).errors.confirmPassword)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_unref(changePasswordFormHandler).errors.confirmPassword), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("footer", _hoisted_24, [
              _createVNode(ActionButton, {
                label: "Cancel",
                theme: "secondary",
                class: "w-[fit-content]",
                onClick: handleOpenChangePasswordUpdateModal
              }),
              _createVNode(ActionButton, {
                theme: "primary",
                class: "w-[fit-content]",
                isSubmit: true,
                disable: loading.value && true,
                label: loading.value ? 'Loading...' : 'Save'
              }, null, 8, ["disable", "label"])
            ])
          ], 32)
        ]),
        _: 1
      }, 8, ["openModal"])
    ])
  ]))
}
}

})