import "primeicons/primeicons.css";

import { createApp } from 'vue';
import '@/utility/styles/tailwind.css';
import App from './App.vue';
import router from './router';
import store from './utility/store/index';
import { isTokenExpired } from './utility/helper/utils.helper';
import PrimeVue from 'primevue/config';
import foodcart from './utility/presets/FoodCartTheme';

const app = createApp(App);

const checkTokenValidity = () => {
  const token = localStorage.getItem('token');
  if (token && isTokenExpired(token)) {
    localStorage.removeItem('token');
    router.push({ name: 'Login' });
  }
}

setInterval(checkTokenValidity, 60000);

checkTokenValidity();

app.use(PrimeVue, {
  theme: {
    preset: foodcart,
    options: {
      prefix: 'p',
      darkModeSelector:false || 'none',
      cssLayer: false,
    }
}
});

app.use(router).use(store).mount('#app');
