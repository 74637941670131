<template>
    <AppWrapper activeTab="Teams">
        <div class="py-[72px] w-[800px] m-[auto]" v-show="!isOpen">
            <div class="flex items-center gap-[6px] text-body-1">
                <div class="flex items-center gap-[6px] text-grey-100 cursor-pointer" @click="router.back()">
                    <Icon icon="ant-design:shopping-outlined" />
                    <div>Teams</div>
                </div>
                <div>/</div>
                <div>{{ `${userData?.firstName} ${userData?.lastName}` }}</div>
            </div>

            <div class="border-[1px] border-solid border-grey-50 rounded-[8px] mt-[32px]">
                <header class="flex justify-between gap-[26px] py-[24px] px-[32px]">
                    <div class="flex items-center gap-[26px]">
                        <div class="">
                            <div class="order-image rounded-[8px]" :style="{ backgroundImage: `url(${userData?.avatar}?v=${new Date().getTime()})`}"></div>
                        </div>
                        <div class="">
                            <div class="">
                                <StatusLabel :status="userData?.status" />
                            </div>
                            <div class="font-semibold text-heading-5 mt-[6px]">{{ `${userData?.firstName} ${userData?.lastName}` }}</div>
                            <div class="text-body-2 text-grey-100">Added on {{ userData?.createdDate }}</div>
                        </div>
                    </div>
                    <div class="">
                         <div class="order-image rounded-[8px]" :style="{ backgroundImage: `url(${userData?.qrCode}?v=${new Date().getTime()})`}"></div>
                    </div>
                    <div class="flex items-center gap-[10px] border-solid border-[1px] h-[fit-content] px-[10px] py-[5px] rounded-[8px] cursor-pointer" @click="handleOpenEditModal">
                        <Icon icon="ant-design:edit-outlined" />
                        <div class="">Edit</div>
                    </div>
                   

                    <!-- <AppModal headerContent="Edit Team" :openModal="isOpen" @close="handleCloseModal">
                        
                    </AppModal> -->
                </header>

                <main class="">
                    <section class="">
                        <header class="flex items-center justify-between py-[18px] px-[24px] bg-bg-200 border-b-[1px] border-solid border-grey-50 mb-[24px]">
                            <div class="flex items-center gap-[10px] text-grey-200 text-body-2">
                                <div class=""><Icon icon="ant-design:user-outlined" class="w-[24px] h-[24px]" /></div>
                                <div class="">Basic Information</div>
                            </div>
                            <div class="text-grey-200 cursor-pointer" @click="handleShowOrderInformation"><Icon :icon="showOrderInfo ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="w-[18px] h-[18px]" /></div>
                        </header>
                        <main class="">
                            <div v-show="showOrderInfo" class="grid grid-cols-2 gap-[24px] px-[24px] py-[28px]">
                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">First Name</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.firstName }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Last Name</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.lastName }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Email</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.email }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Phone</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.phone }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Role</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.role }} </div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Home Address</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.address == null ? '' : `${userData?.address?.street || ''}, ${userData?.address?.lga || ''} ${userData?.address?.city || ''} ${userData?.address?.country || ''} ` }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Employee Card Number</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.employeeCardNumber || '' }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Employee Pin Code</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.pinCode || '' }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">National ID (NIN)</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.nin || 'N/A' }} </div>
                                </div>
                            </div>

                            <header class="flex items-center justify-between py-[18px] px-[24px] bg-bg-200 border-b-[1px] border-solid border-grey-50 mb-[24px]">
                                <div class="flex items-center gap-[10px] text-grey-200 text-body-2">
                                    <div class=""><Icon icon="ant-design:idcard-outlined" class="w-[24px] h-[24px]" /></div>
                                    <div class="">Finance Information</div>
                                </div>
                                <div class="text-grey-200 cursor-pointer" @click="handleShowFinanceInfo"><Icon :icon="showFinanceInfo ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="w-[18px] h-[18px]" /></div>
                            </header>

                            <div v-show="showFinanceInfo" class="grid grid-cols-2 gap-[24px] px-[24px] py-[28px]">
                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Salary</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.finance?.salary }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Tax</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.finance?.tax }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Account Name</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.finance?.accountName }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Account Number</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.finance?.accountNumber }}</div>
                                </div>

                                <div class="">
                                    <div class="text-grey-200 text-caption font-medium">Bank Name</div>
                                    <div class="font-medium text-body-1 mt-[4px]">{{ userData?.finance?.bankName }}</div>
                                </div>
                                <div class="" v-if="userData?.finance?.paymentCycle == 'monthly'">
                                    <div class="text-grey-200 text-caption font-medium">Payment Day / Cycle</div>
                                    <div class="font-medium text-body-1 mt-[4px]">Every {{ userData?.finance?.paymentDay }} day of the {{ userData?.finance?.paymentCycle }}</div>
                                </div>
                            </div>

                            <header class="flex items-center justify-between py-[18px] px-[24px] bg-bg-200 border-b-[1px] border-solid border-grey-50">
                                <div class="flex items-center gap-[10px] text-grey-200 text-body-2">
                                    <div class=""><Icon icon="ant-design:clock-circle-outlined" class="w-[24px] h-[24px]" /></div>
                                    <div class="">Schedules</div>
                                </div>
                                <div class="text-grey-200 cursor-pointer" @click="handleShowScheduleInformation"><Icon :icon="showScheduleInfo ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="w-[18px] h-[18px]" /></div>
                            </header>
                            
                            <!-- 
                                TODO:
                                - showScheduleInfo add on v-show when ready to implement user schedule
                            -->
                            <div v-show="showScheduleInfo" class="border-t-[1px] border-solid border-grey-50 px-[32px]">
                                <div class="flex gap-[32px] border-b-[1px] border-solid mt-[24px]">
                                    <div
                                        v-for="(tab) in scheduleDayTabs"
                                        :key="tab.value"
                                        :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeScheduleTab.value === tab.value as any }]"
                                        @click="activateDayTab(tab as any)"
                                    > {{ tab.label }} </div>
                                </div>

                                <div class="my-[28px]">
                                    <TableField 
                                        :headers="scheduleHeader" 
                                        :dataItems="scheduleData"
                                        :loading="loading" 
                                    />
                                </div>
                            </div>
                        </main>
                    </section>
                </main>
            </div>
        </div>

        <div v-show="isOpen" class="w-[800px] m-[auto] py-[72px]">
            <div class="">
                <div class="text-heading-3 flex items-center gap-[24px] mb-[28px]">
                    <div class="cursor-pointer" @click="handleOpenEditModal"><Icon icon="ant-design:arrow-left-outlined" /></div>
                    <div class="font-semibold">Edit Member</div>
                </div>
            </div>

            <EditMember :id="id" :handleOpenEditModal="handleOpenEditModal" />
        </div>
    </AppWrapper>
</template>


<script setup lang="ts">
import { useRouter } from 'vue-router';
import { getUserById, useCustomersHelper } from './Teams.helper';
import { defineProps, watchEffect, ref, onMounted } from 'vue'
import { Icon } from '@iconify/vue';
import EditMember from './EditMember.vue'
import TableField from '@/components/shared/table/TableField.vue';
import StatusLabel from '@/components/shared/status-label/StatusLabel.vue';
import { useUserFormHandler } from '@/utility/composables/useUsersFormHandler'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const { AppWrapper, scheduleHeader, scheduleData } = useCustomersHelper()

const router = useRouter()

interface Props {
    id: number | string | any
}

const props = defineProps<Props>()

const showOrderInfo = ref(true)
const showFinanceInfo = ref(true)
const showScheduleInfo = ref(true)
const isOpen = ref(false)
const userData = ref()
const loading = ref<boolean>(true)
const store = useStore()
const route = useRoute();
const activeScheduleTab = ref({
    label: 'Mon',
    value: 'monday'
});
const scheduleDayTabs = ref([{
    label: 'Mon',
    value: 'monday'
},
{
    label: 'Tue',
    value: 'tuesday'
},{
    label: 'Wed',
    value: 'wednesday'
},
{
    label: 'Thurs',
    value: 'thursday'
},
{
    label: 'Fri',
    value: 'friday'
},
{
    label: 'Sat',
    value: 'saturday'
},
{
    label: 'Sun',
    value: 'sunday'
}]);


const activateDayTab = (tab: any) => {
    activeScheduleTab.value = tab;
    scheduleData.value = userData?.value.schedule[tab.value].status ? userData?.value.schedule[tab.value].period : [];
}

const handleShowScheduleInformation = () => {
    showScheduleInfo.value = !showScheduleInfo.value
}

const handleShowOrderInformation = () => {
    showOrderInfo.value = !showOrderInfo.value
}

const handleShowFinanceInfo = () => {
    showFinanceInfo.value = !showFinanceInfo.value
}

const handleOpenEditModal = () => {
    isOpen.value = !isOpen.value;
};

const handleCloseModal = () => {
    isOpen.value = false;
};

const { createUserFormHandler } = useUserFormHandler(props?.id, userData, store, handleCloseModal)

watchEffect(() => {
    getUserById({userId: props.id})
    .then((res: any) => {
        userData.value = res;
        scheduleData.value = res?.schedule?.monday?.period;
        loading.value = false
    })
});

watchEffect(() => {
    createUserFormHandler.setValues(userData.value)
})



function getEditModalValue(search: string) {
    const params = new URLSearchParams(search);
    return params.get('editModal');
}

onMounted(() => {
    const editModal = getEditModalValue(route.query.search as string);
    
    if (editModal === 'true') {
        isOpen.value = true;
    }
});


</script>

<style scoped>
.order-image {
    width: 92px;
    height: 92px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
</style>
