import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]" }
const _hoisted_3 = { class: "grid grid-cols-3 gap-[24px]" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "p-[24px]" }
const _hoisted_17 = { class: "grid grid-cols-2 gap-[20px] my-[8px] mb-[8px]" }
const _hoisted_18 = { class: "mt-[16px]" }
const _hoisted_19 = { class: "flex items-center gap-[10px] mt-[8px]" }
const _hoisted_20 = { class: "" }
const _hoisted_21 = { class: "flex items-center gap-[10px] mt-[8px]" }
const _hoisted_22 = { class: "" }
const _hoisted_23 = { class: "grid grid-cols-2 gap-[20px] mt-[32px]" }
const _hoisted_24 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }
const _hoisted_25 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]" }
const _hoisted_26 = { class: "text-grey-200 mt-[16px]" }
const _hoisted_27 = { class: "py-[32px] px-[28px]" }
const _hoisted_28 = { class: "flex gap-[6px] items-center capitalize" }
const _hoisted_29 = {
  key: 0,
  class: "text-grey-100 text-body-2"
}
const _hoisted_30 = {
  key: 0,
  class: ""
}
const _hoisted_31 = { class: "work-hours-card items-center gap-[20px] mt-[16px]" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }

import TableField from '@/components/shared/table/TableField.vue';
import { getStoreProfile, useSettingsHelper, useStoreDetailsHelper } from './Settings.helper'
import { ref, watch, watchEffect } from 'vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { useSettingsHandler } from '@/utility/composables/useSettingsRequestsHandler';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import ToggleSlider from '@/components/shared/toggle-slider/ToggleSlider.vue';
import { Icon } from '@iconify/vue';
import { addToInputList, deleteFromInputList, formatTimeToAmOrPm } from '@/utility/helper/utils.helper';
import { useStore } from 'vuex';



export default /*@__PURE__*/_defineComponent({
  __name: 'StoreDetails',
  setup(__props) {

const storeProfileData: Record<string | number, any> = ref({});
const loading = ref(true);
const isOpen = ref(false);
const isOpenWorkingHoursModal = ref(false);
const openWorkHourUpdate = ref<boolean[]>([]);
const store = useStore()

const storeAddress = () => {
    let address = ""
    if (storeProfileData.value?.address?.street) {
        let street = storeProfileData.value?.address?.street;
        let city = storeProfileData.value?.address?.city ? `${storeProfileData.value?.address?.city} ` : '';
        let lga = storeProfileData.value?.address?.lga ? `${storeProfileData.value?.address?.lga}. ` : '';
        let state = storeProfileData.value?.address?.state ? `${storeProfileData.value?.address?.state} state ` : '';
        let postalCode = storeProfileData.value?.address?.postalCode ? `${storeProfileData.value?.address?.postalCode} ` : '';
        let country = storeProfileData.value?.address?.country ? `${storeProfileData.value?.address?.country}` : '';
        address = `${street}, ${city}${lga}${state}${postalCode}${country}`; 
    }
    return address;
}

const handleOpenWorkHourUpdateModal = (idx: number) => {
    if (openWorkHourUpdate.value.length <= idx) {
        openWorkHourUpdate.value[idx] = false;
    }
    openWorkHourUpdate.value[idx] = !openWorkHourUpdate.value[idx];
};

const handleOpenEditModal = () => {
    isOpen.value = !isOpen.value;
};

const handleOpenWorkingHoursModal = () => {
    isOpenWorkingHoursModal.value = !isOpenWorkingHoursModal.value;
};

const { businessStatusOptions } = useSettingsHelper()
const { updateProfileFormHandler, updateWorkingHoursFormHandler } = useSettingsHandler(store)
const { workingHoursData, scheduleHeader, scheduleData } = useStoreDetailsHelper();


const handleStoreDetailSubmit = async (event: any) => {
    loading.value = true;
    await updateProfileFormHandler.handleSubmit(event);
    storeProfileData.value = updateProfileFormHandler.values;
    handleOpenEditModal();
    loading.value = false;
}

watchEffect(() => {
    getStoreProfile().then((responseData) => {
        storeProfileData.value = responseData;
        loading.value = false;
    })
})

watch(storeProfileData, (newStoreProfileData) => {
    updateProfileFormHandler.setValues(newStoreProfileData);
});

const handleWorkingHoursChange = (event: any, idx: any) => {
    const { name, value, checked } = event.target;
    const updatedStoreHours = { ...workingHoursData.value.storeHours };
    const updatedDay = { ...(updatedStoreHours[idx] || {}) };

    name === 'status' ? updatedDay[name] = checked : updatedDay[name] = value;

    updatedStoreHours[idx] = updatedDay;
    workingHoursData.value.storeHours = updatedStoreHours; 
    updateWorkingHoursFormHandler.setValues(workingHoursData.value)
};

const clearInputFields = (idx: string | number) => {
    const updatedStoreHours = { ...workingHoursData.value.storeHours };
    const updatedDay = { ...(updatedStoreHours[idx] || {}) }; 

    updatedDay.open = '';
    updatedDay.close = '';

    updatedStoreHours[idx] = updatedDay;
    workingHoursData.value.storeHours = updatedStoreHours; 
    updateWorkingHoursFormHandler.setValues(workingHoursData.value)
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-grey-200" }, "Manage your store details and working hour below.", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("header", { class: "flex items-center justify-between" }, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "font-semibold text-sub-heading" }, "Store Information", -1)),
        _createElementVNode("div", {
          class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] py-[11px] w-[fit-content] text-body-2 cursor-pointer",
          onClick: handleOpenEditModal
        }, "Update")
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Store Name", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[120px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(storeProfileData.value?.name), 3)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Email", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[200px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(storeProfileData.value?.email), 3)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Phone Number", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[140px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(storeProfileData.value?.phone), 3)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Established Date", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[160px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(storeProfileData.value?.establishDate), 3)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Store Address", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[350px] rounded mt-[4px]' : loading.value}])
          }, [
            (!loading.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(storeAddress()), 1))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Business Status", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[160px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(storeProfileData.value?.businessStatus), 3)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Restaurant Type", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["flex gap-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[200px] rounded mt-[4px]' : loading.value}])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(storeProfileData.value.restaurantType, (restaurantType, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "font-medium text-body-1 mt-[4px]",
                key: index
              }, [
                _createTextVNode(_toDisplayString(restaurantType), 1),
                (index !== storeProfileData.value.restaurantType.length - 1)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(",")
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Service Options", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["flex gap-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[150px] rounded mt-[4px]' : loading.value}])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(storeProfileData.value.serviceOptions, (serviceOption, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "font-medium text-body-1 mt-[4px]",
                key: index
              }, [
                _createTextVNode(_toDisplayString(serviceOption), 1),
                (index !== storeProfileData.value.serviceOptions.length - 1)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(",")
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Website", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[300px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(storeProfileData.value.website == '' ? 'N/A' : storeProfileData.value.website), 3)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Device Activation Code", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["font-medium text-body-1 mt-[4px]", {'animate-pulse bg-slate-100 h-[22px] w-[300px] rounded mt-[4px]' : loading.value}])
          }, _toDisplayString(storeProfileData.value.deviceRegistrationCode), 3)
        ])
      ]),
      _createVNode(AppModal, {
        headerContent: "Update Store information",
        openModal: isOpen.value,
        onClose: handleOpenEditModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("form", {
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (handleStoreDetailSubmit($event)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(InputField, {
                    label: "Store Name",
                    type: "text",
                    name: "name",
                    placeholder: "Store Name",
                    value: storeProfileData.value?.name,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "Email",
                    type: "email",
                    placeholder: "Email",
                    name: "email",
                    value: storeProfileData.value?.email,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "Phone Number",
                    type: "tel",
                    placeholder: "080 123 456",
                    name: "phone",
                    value: storeProfileData.value?.phone,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "Established Date",
                    type: "date",
                    name: "establishDate",
                    placeholder: "Established Date",
                    value: storeProfileData.value?.establishDate,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "Street Address",
                    type: "text",
                    placeholder: "Street Address",
                    name: "address.street",
                    value: storeProfileData.value?.address?.street,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "City ",
                    type: "text",
                    placeholder: "City Address",
                    name: "address.city",
                    value: storeProfileData.value?.address?.city,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "LGA",
                    type: "text",
                    placeholder: "",
                    name: "address.lga",
                    value: storeProfileData.value?.address?.lga,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "State",
                    type: "text",
                    name: "address.state",
                    placeholder: "",
                    value: storeProfileData.value?.address?.state,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
                ]),
                _createVNode(InputField, {
                  label: "Country Address",
                  type: "text",
                  placeholder: "Country Address",
                  name: "address.country",
                  value: storeProfileData.value?.address?.country,
                  "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                  "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(DropdownField, {
                    label: "Business Status",
                    name: "businessStatus",
                    value: storeProfileData.value.businessStatus,
                    optionsData: _unref(businessStatusOptions),
                    onChange: _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "optionsData", "onChange"])
                ]),
                _createVNode(InputField, {
                  label: "Restaurant Type",
                  name: "restaurantType",
                  type: "text",
                  placeholder: "Enter Restaurant Type",
                  class: "mt-[16px]",
                  onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers((event) => _unref(addToInputList)(event, storeProfileData.value, _unref(updateProfileFormHandler)), ["prevent"]), ["enter"])),
                  undertext: "Tap \"Enter Key\" to add more restaurant type"
                }),
                _createElementVNode("div", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(storeProfileData.value.restaurantType, (rt, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption",
                      key: idx
                    }, [
                      _createElementVNode("div", _hoisted_20, _toDisplayString(rt), 1),
                      _createVNode(_unref(Icon), {
                        icon: "ant-design:close-outlined",
                        width: "12",
                        height: "12",
                        onClick: () => _unref(deleteFromInputList)('restaurantType', idx, storeProfileData.value, _unref(updateProfileFormHandler))
                      }, null, 8, ["onClick"])
                    ]))
                  }), 128))
                ]),
                _createVNode(InputField, {
                  label: "Service Operations",
                  name: "serviceOptions",
                  type: "text",
                  placeholder: "Enter Service Operations",
                  class: "mt-[32px]",
                  onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers((event) => _unref(addToInputList)(event, storeProfileData.value, _unref(updateProfileFormHandler)), ["prevent"]), ["enter"])),
                  undertext: "Tap \"Enter Key\" to add more service options"
                }),
                _createElementVNode("div", _hoisted_21, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(storeProfileData.value.serviceOptions, (so, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption",
                      key: idx
                    }, [
                      _createElementVNode("div", _hoisted_22, _toDisplayString(so), 1),
                      _createVNode(_unref(Icon), {
                        icon: "ant-design:close-outlined",
                        width: "12",
                        height: "12",
                        onClick: () => _unref(deleteFromInputList)('serviceOptions', idx, storeProfileData.value, _unref(updateProfileFormHandler))
                      }, null, 8, ["onClick"])
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(InputField, {
                    label: "Website",
                    type: "text",
                    placeholder: "Enter website Url",
                    name: "website",
                    value: storeProfileData.value.website,
                    "onUpdate:blur:value": _unref(updateProfileFormHandler).handleBlur,
                    "onUpdate:value": _unref(updateProfileFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
                ])
              ]),
              _createElementVNode("footer", _hoisted_24, [
                _createVNode(ActionButton, {
                  label: "Cancel",
                  theme: "secondary",
                  class: "w-[fit-content]",
                  onClick: handleOpenEditModal
                }),
                _createVNode(ActionButton, {
                  theme: "primary",
                  class: "w-[fit-content]",
                  isSubmit: true,
                  disable: _unref(updateProfileFormHandler).form.loading && true,
                  label: _unref(updateProfileFormHandler).form.loading ? 'Loading...' : 'Save'
                }, null, 8, ["disable", "label"])
              ])
            ], 32)
          ])
        ]),
        _: 1
      }, 8, ["openModal"])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createElementVNode("header", { class: "flex items-center justify-between" }, [
        _cache[15] || (_cache[15] = _createElementVNode("div", { class: "font-semibold text-sub-heading" }, "Working Hours", -1)),
        _createElementVNode("div", {
          class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] py-[11px] w-[fit-content] text-body-2 cursor-pointer",
          onClick: handleOpenWorkingHoursModal
        }, "Update")
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createVNode(TableField, {
          headers: _unref(scheduleHeader),
          dataItems: _unref(scheduleData)
        }, null, 8, ["headers", "dataItems"])
      ]),
      _createVNode(AppModal, {
        headerContent: "Update work Hours",
        openModal: isOpenWorkingHoursModal.value,
        onClose: handleOpenWorkingHoursModal
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_unref(updateWorkingHoursFormHandler).handleSubmit && _unref(updateWorkingHoursFormHandler).handleSubmit(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_27, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(workingHoursData)?.storeHours, (storeHour, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "border-[1px] border-solid border-grey-50 p-[14px] rounded-[8px] mt-[12px]",
                  key: idx
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["flex items-center justify-between", { 'opacity-50': !storeHour.status }])
                  }, [
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(ToggleSlider, {
                        name: "status",
                        isChecked: storeHour.status,
                        "onOn:change": (event) => handleWorkingHoursChange(event, idx),
                        label: idx
                      }, null, 8, ["isChecked", "onOn:change", "label"]),
                      (!openWorkHourUpdate.value[idx])
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_unref(formatTimeToAmOrPm)(storeHour.open)) + " - " + _toDisplayString(_unref(formatTimeToAmOrPm)(storeHour.close)), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode(_unref(Icon), {
                      icon: !openWorkHourUpdate.value[idx] ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                      class: _normalizeClass(["text-primary-300 cursor-pointer", { 'text-primary-300 cursor-not-allowed': !storeHour.status }]),
                      onClick: ($event: any) => (storeHour.status && handleOpenWorkHourUpdateModal(idx))
                    }, null, 8, ["icon", "class", "onClick"])
                  ], 2),
                  (openWorkHourUpdate.value[idx] && storeHour.status)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                          _createVNode(InputField, {
                            label: "Start Time",
                            type: "time",
                            name: "open",
                            prefixIcon: "ant-design:clock-circle-outlined",
                            placeholder: "",
                            value: storeHour.open,
                            ref_for: true,
                            ref: "openInputFields",
                            "onUpdate:blur:value": _unref(updateWorkingHoursFormHandler).handleBlur,
                            "onUpdate:value": ($event: any) => (handleWorkingHoursChange($event, idx))
                          }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                          _createVNode(InputField, {
                            label: "End Time",
                            type: "time",
                            name: "close",
                            prefixIcon: "ant-design:clock-circle-outlined",
                            placeholder: "",
                            value: storeHour.close,
                            ref_for: true,
                            ref: "closeInputFields",
                            "onUpdate:blur:value": _unref(updateWorkingHoursFormHandler).handleBlur,
                            "onUpdate:value": ($event: any) => (handleWorkingHoursChange($event, idx))
                          }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                          _createElementVNode("div", {
                            class: "text-primary-300 bg-bg-200 py-[10px] px-[10px] rounded-[4px] mt-[24px] w-[fit-content]",
                            onClick: ($event: any) => (clearInputFields(idx))
                          }, [
                            _createVNode(_unref(Icon), {
                              icon: "ant-design:clear-outlined",
                              width: "24",
                              height: "24"
                            })
                          ], 8, _hoisted_32)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _createElementVNode("footer", _hoisted_33, [
              _createVNode(ActionButton, {
                label: "Cancel",
                theme: "secondary",
                class: "w-[fit-content]",
                onClick: handleOpenWorkingHoursModal
              }),
              _createVNode(ActionButton, {
                theme: "primary",
                class: "w-[fit-content]",
                isSubmit: true,
                disable: _unref(updateWorkingHoursFormHandler).form.loading && true,
                label: _unref(updateWorkingHoursFormHandler).form.loading ? 'Loading...' : 'Save'
              }, null, 8, ["disable", "label"])
            ])
          ], 32)
        ]),
        _: 1
      }, 8, ["openModal"])
    ])
  ]))
}
}

})