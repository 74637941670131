<template>
  <AuthWrapper>
    <div class="w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" v-if="!resetPasswordHandler.form.res">
      <header class="">
        <div class="text-heading-1 font-semibold text-grey-300">Reset password</div>
        <div class="text-sub-heading text-grey-200">Please enter your email to request a password reset.</div>
      </header>

      <form @submit.prevent="resetPasswordHandler.handleSubmit">
        <InputField 
          class="mt-[50px]" 
          placeholder="Email" 
          type="email" 
          prefixIcon="ant-design:mail-outlined"
          name="email"
          :value="resetPasswordHandler.values.email"
          @update:blur:value="resetPasswordHandler.handleBlur"
          @update:value="resetPasswordHandler.handleChange"
        />
        <div class="text-body-2 text-medium text-error mt-[4px]"> {{ resetPasswordHandler.errors.email }}</div>

        <ActionButton 
          class="mt-[24px]" 
          theme="primary" 
          :disable="resetPasswordHandler.form.loading && true" 
          :label="resetPasswordHandler.form.loading ? 'Loading...' : 'Reset Password'" 
          :isSubmit="true"
        />
        
        <ActionButton
          class="mt-[19px]" 
          label="Back to login" 
          theme="linked" 
          :handleClick="router.back" 
        />
      </form>
    </div>

    <div class="w-[500px] m-[auto] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" v-else>
      <header class="">
        <div class="text-heading-1 font-semibold text-grey-300 flex gap-[20px] items-center">
          <span>Email Sent!</span>
          <div class="bg-[#D5FFD4] p-[2px] w-[fit-content] rounded-[50%]">
            <Icon class="text-success" icon="ant-design:check-circle-filled" width="28" height="28" /> 
          </div>
        </div>
        <div class="text-sub-heading text-grey-200">We have sent you an email with reset instructions</div>
      </header>

      <ActionButton 
        class="mt-[48px]" 
        label="Go to login" 
        theme="primary" 
        :handleClick="() => router.push('/auth/login')" 
      />
    </div>
  </AuthWrapper>

</template>

<script setup lang='ts'>
import AuthWrapper from "@/components/app/auth-wrapper/AuthWrapper.vue"
import ActionButton from "@/components/shared/action-button/ActionButton.vue"
import InputField from "@/components/shared/input-field/InputField.vue"
import { useAuthSession } from "@/utility/composables/useAuthSession";
import { Icon } from '@iconify/vue'
import { useRouter } from "vue-router"

const router = useRouter()
const { resetPasswordHandler } = useAuthSession()

</script>

<style lang="">

</style>