import { ref, onMounted, watchEffect } from "vue";
import { Store } from "vuex";
import { FilterMatchMode } from '@primevue/core/api';

import ReportService from "@/utility/services/report.service";

export const getSalesReportData = async ({ month = 0, year = 2024}) => {
    try {
        const response = await ReportService.getSalesReportRequest(month, year);
        return response
    } catch (error) {
        console.error(error)
    }
}

export const getCustomerReportData = async ({ month = 0, year = 2024}) => {
    try {
        const response = await ReportService.getCustomerReportRequest(month, year);
        return response
    } catch (error) {
        console.error(error)
    }
}

export const useSalesReportHelper = (store?: Store<any>) => {
    const loading = ref(false);
    const sales = ref([]);
    const salesDataTable = ref();
    const expandedRows = ref({});
    const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        customer: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        cashier: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        paymentMethod: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        totalPrice: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const statuses = ref(['Cancelled', 'Completed', 'Failed', 'Pending']);
    onMounted(() => {
        loading.value = true;
        getSalesReportData({month: 0, year: 2024}).then((response: any) => {
            loading.value = false;
            sales.value = response.orders;
        });
    });
    const getSeverity = (status: string) => {
        switch (status) {
            case 'Cancelled':
                return 'danger';
    
            case 'Completed':
                return 'success';
    
            case 'Pending':
                return 'info';
    
            case 'Failed':
                return 'warn';
        }
    }
    const exportCSV = () => {
        salesDataTable.value.exportCSV();
    };
    const onRowExpand = (event: any) => {
        // toast.add({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
    };
    const onRowCollapse = (event: any) => {
        // toast.add({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    };
    const expandAll = () => {
        expandedRows.value = sales.value.reduce((acc: any, p: any) => (acc[p.orderId] = true) && acc, {});
    };
    const collapseAll = () => {
        expandedRows.value = {};
    };
    const columns = ref([
        {field: 'orderChannel', header: 'Channel'},
        {field: 'orderNumber', header: 'Order Number'},
    ]);
    const selectedColumns = ref(columns.value);
    const onToggle = (val: any) => {
        selectedColumns.value = columns.value.filter(col => val.includes(col));
    };
    return  { onToggle, columns, selectedColumns, expandAll, collapseAll, onRowCollapse, onRowExpand, salesDataTable, loading, sales, filters, statuses, expandedRows,  getSeverity, exportCSV };
};

export const useCustomerReportHelper = (store?: Store<any>) => {
    const loading = ref(false);
    const data = ref([]);
    const customerDataTable = ref();
    const expandedRows = ref({});
    const filters = ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        registrationDate: { value: null, matchMode: FilterMatchMode.EQUALS },
        firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        phoneNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
        totalOrders: { value: null, matchMode: FilterMatchMode.EQUALS }
    });
    const statuses = ref(['Cancelled', 'Completed', 'Failed', 'Pending']);
    onMounted(() => {
        loading.value = true;
        getCustomerReportData({month: 0, year: 2024}).then((response: any) => {
            loading.value = false;
            data.value = response.customers;
        });
    });
    const getSeverity = (status: string) => {
        switch (status) {
            case 'Cancelled':
                return 'danger';
    
            case 'Completed':
                return 'success';
    
            case 'Pending':
                return 'info';
    
            case 'Failed':
                return 'warn';
        }
    }
    const exportCSV = () => {
        customerDataTable.value.exportCSV();
    };
    const onRowExpand = (event: any) => {
        // toast.add({ severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000 });
    };
    const onRowCollapse = (event: any) => {
        // toast.add({ severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000 });
    };
    const expandAll = () => {
        expandedRows.value = data.value.reduce((acc: any, p: any) => (acc[p.orderId] = true) && acc, {});
    };
    const collapseAll = () => {
        expandedRows.value = {};
    };
    const columns = ref([
        // {field: 'orderChannel', header: 'Channel'},
        // {field: 'orderNumber', header: 'Order Number'},
    ]);
    const selectedColumns = ref(columns.value);
    const onToggle = (val: any) => {
        selectedColumns.value = columns.value.filter(col => val.includes(col));
    };
    return  { onToggle, columns, selectedColumns, expandAll, collapseAll, onRowCollapse, onRowExpand, customerDataTable, loading, data, filters, statuses, expandedRows,  getSeverity, exportCSV };
};

export const useReportHelper = () => {
    const reportTabs = ref(
        [
            'Sales', 
            'Customers'
        ]
    );
    const activeTab = ref(0);
    const activateTab = (index: number) => {
        activeTab.value = index;
    };
    return { reportTabs, activeTab, activateTab };
};