<template>
    <div class="mt-[24px] mb-[24px]">
        <label class="text-body-2 text-grey-200 mb-[7px] inline-block">Cover Photo</label>
        <div class="relative py-[33.5px] bg-bg-200 text-center rounded-[8px] border-[1px] border-dotted border-grey-50 cursor-pointer h-[150px]">
            <div class="absolute top-0 rounded-[8px]" :style="{
                width: '100%',
                height: '100%',
                backgroundImage: `url(${!previewImageUrl ? imageUrl : previewImageUrl})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }"></div>
            <div class="bg-black absolute top-0 left-0 w-[100%] h-full opacity-50 rounded-[8px]"></div>
            <input 
                type="file" 
                :name="name" 
                :value="value" 
                @change="handleFileChange" 
                accept="image/*" 
                style="display: none;" 
                ref="fileInput"
                @input="$emit('update:value', $event)" 
                @blur="$emit('update:blur:value', $event)"
            />

            <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-white">
                <div class="w-[fit-content] m-[auto] z-[2]" @click="openFilePicker">
                <Icon icon="ant-design:camera-filled" class="w-[48px] h-[48px]"/>
                </div>
                <div class="text-body-2 mt-[4px] centerlize-item" @click="openFilePicker">Select an image from your media gallery.</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue';
import { ref, defineProps, defineEmits, watchEffect } from 'vue';

const fileInput = ref<HTMLInputElement | null>(null);
const previewImageUrl = ref<string | null | any>(null);
const emit = defineEmits(['base64ImgCode', 'update:value', 'update:blur:value']);


interface Props {
    name?: string | number | any
    value?: string | number | any
    imageUrl?: string
}

const props = defineProps<Props>()

const openFilePicker = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

const handleFileChange = (event: Event) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                previewImageUrl.value = reader.result;
                emit('base64ImgCode', previewImageUrl.value);
            }
        };
        reader.readAsDataURL(files[0]);
    }
};

watchEffect(() => {
    previewImageUrl.value
})


// const imageStyle = ref({
//     width: '100%',
//     backgroundImage: `url(${!previewImageUrl ? imageUrl : previewImageUrl})`,
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
// });

</script>

<style scoped>
/* Add your scoped styles here */
</style>
  