<template>
    <div class="mt-[32px]">
        <div class="text-grey-200">Manage your profile and personal information below.</div>
        <div class="mt-[24px] border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]">
            <UploadProfileImage
                :imageUrl="profileData?.avatar"
                name="logo"
                @base64ImgCode="imageDataFromEmitedBase64"
                @update:blur:value="accountProfileFormHandler.handleBlur"
                @update:value="accountProfileFormHandler.handleFileChange"
            />
        </div>

        <div class="relative border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]">
            <header class="flex items-center justify-between">
                <div class="font-semibold text-sub-heading">Personal Information</div>
                <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] text-body-2 py-[11px] w-[fit-content] cursor-pointer" @click="handleOpenProfileUpdatemodal">Update</div>
            </header>

            <div class="grid grid-cols-3 gap-[24px]">
                <div class="">
                    <div class="text-grey-200 text-caption font-medium">First Name</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading}">
                        {{ profileData?.firstName }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Last Name</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading}">
                        {{ profileData?.lastName }}
                    </div>
                    <!-- <div class=""></div> -->
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Email</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[200px] rounded mt-[4px]' : loading}">
                        {{ profileData?.email }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Phone Number</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[120px] rounded mt-[4px]' : loading}">
                        {{ profileData?.phone }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Address</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[160px] rounded mt-[4px]' : loading}">
                        {{ profileData?.address?.street }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">City</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[120px] rounded mt-[4px]' : loading}">
                        {{ profileData?.address?.city }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">LGA</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[120px] rounded mt-[4px]' : loading}">
                        {{ profileData?.address?.lga }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">State</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading}">
                        {{ profileData?.address?.state }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Country</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading}">
                        {{ profileData?.address?.country }}
                    </div>
                </div>

                <div class="">
                    <div class="text-grey-200 text-caption font-medium">Postal Code</div>
                    <div class="font-medium text-body-1 mt-[4px]" :class="{'animate-pulse bg-slate-100 h-[22px] w-[100px] rounded mt-[4px]' : loading}">
                        {{ profileData?.address?.postalCode }}
                    </div>
                </div>
               
            </div>

            <AppModal headerContent="Update Personal information" :openModal="isProfileUpdateModal" @close="handleOpenProfileUpdatemodal" >
                <form @submit.prevent="handleOpenProfileUpdateModalSubmit($event)">
                    <div class="px-[28px] pt-[32px]">
                        <header class="text-body-2 text-grey-200">Please note that you can only update your phone number. For any other changes to your information, please contact us.</header>
                        <div class="grid grid-cols-2 gap-[24px] debit-id items-center gap-[12px] mt-[32px]">
                            <InputField 
                                label="First Name" 
                                type="text" 
                                placeholder="First Name" 
                                name="firstName"
                                :value="profileData?.firstName"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Last Name" 
                                type="text" 
                                placeholder="Last Name" 
                                :value="profileData?.lastName"
                                name="lastName"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Email" 
                                type="text" 
                                placeholder="Email" 
                                :value="profileData?.email"
                                name="email"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Phone Number" 
                                type="text" 
                                placeholder="Phone Number" 
                                :value="profileData?.phone"
                                name="phone"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Address - Street" 
                                type="text" 
                                placeholder="Street" 
                                :value="profileData?.address?.street"
                                name="address.street"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Address - City" 
                                type="text" 
                                placeholder="City" 
                                :value="profileData?.address?.city"
                                name="address.city"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Address - LGA" 
                                type="text" 
                                placeholder="LGA" 
                                :value="profileData?.address?.lga"
                                name="address.lga"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Address - State" 
                                type="text" 
                                placeholder="State" 
                                :value="profileData?.address?.state"
                                name="address.state"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Address - Postal Code" 
                                type="text" 
                                placeholder="Postal Code" 
                                :value="profileData?.address?.postalCode"
                                name="address.postalCode"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />

                            <InputField 
                                label="Address - Country" 
                                type="text" 
                                placeholder="Country" 
                                :value="profileData?.address?.country"
                                name="address.country"
                                @update:blur:value="accountProfileFormHandler.handleBlur"
                                @update:value="accountProfileFormHandler.handleChange"
                            />
                        </div>
                    </div>

                    <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                        <ActionButton theme="secondary" class="w-[fit-content]" label="Cancel" @click="handleOpenProfileUpdatemodal" />
                        <ActionButton 
                            theme="primary" 
                            class="w-[fit-content]" 
                            :isSubmit="true" 
                            :disable="loading && true" 
                            :label="loading ? 'Loading...' : 'Save'" 
                        />
                    </footer>
                </form>
            </AppModal>
        </div>

        <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]">
            <header class="flex items-center justify-between">
                <div class="font-semibold text-sub-heading">Change Passwords</div>
                <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] text-body-2 py-[11px] w-[fit-content] cursor-pointer" @click="handleOpenChangePasswordUpdateModal">Change</div>
            </header>

            <div class="text-grey-200 mt-[10px] text-body-2">Update your password from your old one</div>

            <AppModal headerContent="Change password" :openModal="isChangePasswordModal" @close="handleOpenChangePasswordUpdateModal">
                <form @submit.prevent="handleOpenChangePasswordModalSubmit($event)">
                    <div class="px-[28px]">
                        <div class="grid grid-cols-1 gap-[20px] debit-id items-center gap-[12px] mt-[95px]">
                            <InputField 
                                placeholder="Old password" 
                                prefixIcon="ant-design:lock-outlined"
                                name="currentPassword"
                                :value="changePasswordFormHandler.values.currentPassword"
                                :suffixIcon="showPassword ? 'ant-design:eye-invisible-outlined' : 'ant-design:eye-outlined'"
                                :handleClick="handleShowPassword"
                                :type="showPassword ? 'text' : 'password'"
                                @update:blur:value="changePasswordFormHandler.handleBlur"
                                @update:value="changePasswordFormHandler.handleChange"
                            />
                            <div class="text-body-2 text-medium text-error mt-[4px]" v-if="changePasswordFormHandler.errors.currentPassword"> {{ changePasswordFormHandler.errors.currentPassword }}</div>

                            <InputField 
                                placeholder="New password" 
                                type="password" 
                                name="newPassword"
                                :value="changePasswordFormHandler.values.newPassword"
                                prefixIcon="ant-design:lock-outlined"
                                @update:blur:value="changePasswordFormHandler.handleBlur"
                                @update:value="changePasswordFormHandler.handleChange"
                            />
                            <div class="text-body-2 text-medium text-error mt-[4px]" v-if="changePasswordFormHandler.errors.newPassword"> {{ changePasswordFormHandler.errors.newPassword }}</div>

                            <InputField 
                                placeholder="Confirm password"
                                type="password" 
                                name="confirmPassword"
                                :value="changePasswordFormHandler.values.confirmPassword"
                                prefixIcon="ant-design:lock-outlined"
                                @update:blur:value="changePasswordFormHandler.handleBlur"
                                @update:value="changePasswordFormHandler.handleChange"
                            />
                            <div class="text-body-2 text-medium text-error mt-[4px]" v-if="changePasswordFormHandler.errors.confirmPassword"> {{ changePasswordFormHandler.errors.confirmPassword }}</div>
                        </div>
                    </div>

                    <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                        <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenChangePasswordUpdateModal" />
                        <ActionButton 
                            theme="primary" 
                            class="w-[fit-content]" 
                            :isSubmit="true" 
                            :disable="loading && true" 
                            :label="loading ? 'Loading...' : 'Save'" 
                        />
                    </footer>
                </form>
            </AppModal>
        </div>
    </div>
</template>

<script setup lang="ts">
import { getAccountProfile, updateAccountAvatar } from './Settings.helper';
import { watchEffect, ref, watch } from 'vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import { useSettingsHandler } from '@/utility/composables/useSettingsRequestsHandler';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import UploadProfileImage from '@/components/shared/upload-profile-image/UploadProfileImage.vue';
import { useStore } from 'vuex';

const loading = ref(false);
const isProfileUpdateModal = ref(false)
const isChangePasswordModal = ref(false)
const showPassword = ref(false);
const store = useStore()

const profileData: Record<string | number, any> = ref({});

const handleShowPassword = () => showPassword.value = !showPassword.value;

const imageDataFromEmitedBase64 = async (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    const avatar = imageDataParts?.[1];
    await updateAccountAvatar(avatar, store);
}

const handleOpenChangePasswordUpdateModal = () => {
    isChangePasswordModal.value = !isChangePasswordModal.value;
};

const handleOpenProfileUpdatemodal = () => {
    isProfileUpdateModal.value = !isProfileUpdateModal.value;
};

const { accountProfileFormHandler, changePasswordFormHandler } = useSettingsHandler(store);

const handleOpenProfileUpdateModalSubmit = async (event: any) => {
    loading.value = true;
    await accountProfileFormHandler.handleSubmit(event);
    profileData.value = accountProfileFormHandler.values;
    handleOpenProfileUpdatemodal();
    loading.value = false;
}

const handleOpenChangePasswordModalSubmit = async (event: any) => {
    await changePasswordFormHandler.handleSubmit(event);
    handleOpenChangePasswordUpdateModal();
}

watchEffect(() => {
    getAccountProfile().then((responseData) => {
        profileData.value = responseData;
    })
})

watch(profileData, (newProfileData) => {
    accountProfileFormHandler.setValues(newProfileData);
});

</script>

<style scoped>

</style>