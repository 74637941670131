<template>
    <AppWrapper activeTab="Menu">
        <div class="lg:w-[600px] lg:m-[auto] lg:py-[72px] py-[30px]">
            <div class="mb-[23px]">
                <div class="text-heading-3 flex items-center gap-[24px] mb-[28px]" @click="router.back">
                    <div class=""><Icon icon="ant-design:arrow-left-outlined" /></div>
                    <div class="font-semibold">Update Menu</div>
                </div>
                <div class="text-heading-5 text-primary-300 font-semibold">Menu Information</div>
                <div class="text-body-2 text-grey-200 font-medium mt-[10px]">This information will be displayed to customers on your menu.</div>
            </div>

            <form @submit.prevent="handleCreateMenuSubmit($event)">
                <div class="flex gap-[32px] border-b-[1px] border-solid mt-[24px]">
                    <div
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                        @click="activateTab(index)"
                    > {{ tab }} </div>
                </div>

                <div class="mt-[24px]" v-if="activeTab === 0">
                    <InputField 
                        label="Name" 
                        placeholder="Name" 
                        type="text" 
                        name="name"
                        :value="menuData?.name"
                        @update:blur:value="createMenuFormHandler.handleBlur"
                        @update:value=" createMenuFormHandler.handleChange"
                    />
                    <TextArea 
                        label="Description" 
                        placeholder="Description" 
                        class="mt-[24px]"
                        name="description"
                        :value="menuData?.description"
                        @update:value="createMenuFormHandler.handleChange"
                    />

                    <!-- <div class="mt-[24px] mb-[24px]">
                        <label class="text-body-2 text-grey-200 mb-[7px] inline-block">Image</label>
                        <div class="py-[33.5px] bg-bg-200 text-center rounded-[8px] border-[1px] border-dotted border-grey-50">
                            <div class="w-[fit-content] m-[auto]"><Icon icon="ant-design:camera-filled" class="text-primary-300 w-[48px] h-[48px]"/></div>
                            <div class="text-body-2 text-grey-200 mt-[4px]">Select an image from your media gallery</div>
                        </div>
                    </div> -->

                    <UploadImage
                        name="imageData"
                        :value="menuData?.imageUrl"
                        :imageUrl="menuData?.image"
                        @base64ImgCode="imageDataFromEmitedBase64"
                        @update:blur:value="createMenuFormHandler.handleBlur"
                        @update:value="createMenuFormHandler.handleFileChange"
                    />

                    <DropdownField 
                        :optionsData="categoriesOptions" 
                        default-option-label="Select" 
                        label="Category" 
                        :value="menuData?.categoryId"
                        class="" 
                        @change="handleCategoryChange($event)"
                    />

                    <InputField 
                        label="Add Ingredients" 
                        placeholder="Ingredients Name" 
                        type="text" 
                        name="ingredients"
                        class="mt-[24px]" 
                        @keydown.enter.prevent="(event) => addToInputList(event, menuData, createMenuFormHandler)"
                        undertext='Tap "Enter Key" to add more restaurant type' 
                    />

                    <div class="flex items-center gap-[10px] mt-[8px]">
                        <div class="py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption" v-for="(ingredient, idx) in menuData?.ingredients" :key="idx">
                            <div class="">{{ ingredient }}</div>
                            <Icon icon="ant-design:close-outlined" width="12" height="12" @click="() => deleteFromInputList('ingredients', idx, menuData, createMenuFormHandler)" />
                        </div>
                    </div>

                    <div class="mt-[24px] grid grid-cols-2 gap-[24px]">
                        <InputField 
                            label="Cost Price" 
                            prefixIcon="fa6-solid:naira-sign" 
                            type="number"
                            name="cost" 
                            :value="menuData?.cost == '' ? '0.00' : menuData?.cost"
                            @update:blur:value="createMenuFormHandler.handleBlur"
                            @update:value="createMenuFormHandler.handleChange"
                        />

                        <InputField 
                            label="Selling Price" 
                            prefixIcon="fa6-solid:naira-sign" 
                            type="number" 
                            name="price" 
                            :value="menuData?.price == '' ? '0.00' : menuData?.price"
                            @update:blur:value="createMenuFormHandler.handleBlur"
                            @update:value="createMenuFormHandler.handleChange"
                        />

                        <InputField 
                            label="Quantity" 
                            type="number"
                            name="totalQuantity" 
                            :value="menuData?.totalQuantity"
                            @update:blur:value="createMenuFormHandler.handleBlur"
                            @update:value="createMenuFormHandler.handleChange"
                        />

                        <InputField 
                            label="Remaining Quantity" 
                            type="number" 
                            name="remainingQuantity" 
                            :value="menuData?.remainingQuantity"
                            @update:blur:value="createMenuFormHandler.handleBlur"
                            @update:value="createMenuFormHandler.handleChange"
                        />
                    </div>

                    <div class="mt-[24px]">
                        <label class="text-body-2 text-grey-200 mb-[7px] inline-block">Avalability</label>
                        <div class="flex gap-[32px]">
                            <RadioButton value="true" :isChecked="menuData?.isAvailable === true" name="isAvailable" @on:change="createMenuFormHandler.handleChange" />
                            <RadioButton value="false" :isChecked="menuData?.isAvailable === false" name="isAvailable" @on:change="createMenuFormHandler.handleChange" />
                            <!-- <RadioButton value="Unavailable" name="avail" /> -->
                        </div>
                    </div>
                </div>

                <div v-if="activeTab === 1" >
                    <div class="sizes items-center gap-[12px] mt-[32px]" v-for="(size, idx) in menuData?.sizes" :key="idx">
                        <InputField 
                            label="Name" 
                            type="text" 
                            placeholder="Name" 
                            name="name"
                            :value="size?.name"
                            @update:blur:value="createMenuFormHandler.handleBlur"
                            @update:value="handleChangeMenuTypeValue($event, idx, 'sizes')" 
                        />
                        <InputField 
                            label="Price" 
                            type="number" 
                            placeholder="Price" 
                            name="price"
                            :value="size?.price"
                            @update:blur:value="createMenuFormHandler.handleBlur"
                            @update:value="handleChangeMenuTypeValue($event, idx, 'sizes')" 
                        />
                        <div class="bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content] cursor-pointer" @click="deleteFromInputList('sizes', idx, menuData, createMenuFormHandler)">
                            <Icon icon="ant-design:delete-filled" class="text-primary-300 w-[24px] h-[24px]" />
                        </div>
                    </div>

                    <div class="grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px] border-grey-50">
                        <ActionButton prefixIcon="ant-design:plus-outline" label="Add size" class="w-[fit-content] m-[auto]" theme="outlined" @click="addMenuTypeHandler('sizes')" />
                    </div>
                </div>

                <div v-if="activeTab === 2" >
                    <div class="sizes items-center gap-[12px] mt-[32px]" v-for="(extra, idx) in menuData?.extras" :key="idx">
                        <InputField 
                            label="Name" 
                            type="text" 
                            placeholder="Name" 
                            name="name"
                            :value="extra.name"
                            @update:blur:value="createMenuFormHandler.handleBlur"
                            @update:value="handleChangeMenuTypeValue($event, idx, 'extras')" 
                        />
                        <InputField 
                            label="Price" 
                            type="number" 
                            placeholder="Price" 
                            name="price"
                            :value="extra.price"
                            @update:blur:value="createMenuFormHandler.handleBlur"
                            @update:value="handleChangeMenuTypeValue($event, idx, 'extras')" 
                        />
                        <div class="bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content] cursor-pointer" @click="deleteFromInputList('extras', idx, menuData, createMenuFormHandler)">
                            <Icon icon="ant-design:delete-filled" class="text-primary-300 w-[24px] h-[24px]" />
                        </div>
                    </div>

                    <div class="grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px] border-grey-50">
                        <ActionButton prefixIcon="ant-design:plus-outline" label="Add Extra" class="w-[fit-content] m-[auto]" theme="outlined" @click="addMenuTypeHandler('extras')" />
                    </div>
                </div>

                <div class="border-t-[1px] border-solid border-grey-50 mt-[52px]">
                    <div class="flex w-[fit-content] ml-[auto] gap-[12px]">
                        <ActionButton theme="secondary" label="Clear form" class="w-[fit-content] mt-[22px]" prefixIcon="ant-design:delete-outlined" />
                        <ActionButton theme="primary" label="Save" class="w-[fit-content] mt-[22px]" :isSubmit="true" />
                    </div>
                </div>
            </form>                             
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import InputField from '@/components/shared/input-field/InputField.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
import { useMenuHelper } from './UpdateMenu.helper'
import { Icon } from '@iconify/vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { computed, defineProps, ref, watchEffect } from 'vue';
import UploadImage from '@/components/shared/upload-image/UploadImage.vue';
import { addToInputList, deleteFromInputList, uploadFile } from '@/utility/helper/utils.helper';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

interface Props {
    handleAddMenuItems?: any
    id: any
}

const props = defineProps<Props>();
const router = useRouter()
const imageData = ref();
const store = useStore()

const { activateTab, activeTab, tabs, categoriesData, menuData, createMenuFormHandler } = useMenuHelper(props.id, store);

const imageDataFromEmitedBase64 = (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    imageData.value = imageDataParts?.[1];
}

const categoriesOptions = computed(() => {
    if (!categoriesData.value || !categoriesData.value.categories) {
        return [];
    }

    return categoriesData.value.categories.map((category: any) => ({
        name: category.category,
        value: category.id
    }));
});

const handleCategoryChange = (event: any) => {
    const { value } = event.target
    const categoryData =  categoriesData.value.categories.find((category: any) => value == category.id);

    const newCategoryData = {
        ...menuData.value,
        category: categoryData.category,
        categoryId: categoryData.id
    }

    createMenuFormHandler.setValues(newCategoryData);
}

watchEffect(() => createMenuFormHandler.setValues(menuData.value))

const addMenuTypeHandler = (menuType: string) => {
    const newMenuType = menuData.value?.[menuType] == undefined 
    ? null
    : [...menuData.value?.[menuType], {}]

    const newMenuTypeData = {
        ...menuData.value,
        [menuType]: newMenuType
    }

    console.log({newMenuType, newMenuTypeData})

    menuData.value[menuType] = newMenuType
    createMenuFormHandler.setValues(menuData.value);
}

const handleChangeMenuTypeValue = (event: any, index: any, menuType: string) => {
    const { name, value } = event.target;
    
    const updatedSizesData = [ ...menuData.value?.[menuType] ]
    const updatedSize = {...menuData.value?.[menuType]?.[index]}

    updatedSize[name] = value;

    updatedSizesData[index] = updatedSize;
    menuData.value[menuType] = updatedSizesData;

    createMenuFormHandler.setValues(menuData.value);
}

const handleCreateMenuSubmit = async (event: Event) => {
    event.preventDefault();

    createMenuFormHandler.values.imageData = imageData.value;

    await createMenuFormHandler.handleSubmit(event);
    menuData.value = createMenuFormHandler.values;
    // props.handleAddMenuItems();
};

</script>

<style scoped>
.sizes {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
}
</style>./UpdateMenuForm.helper