import { useAppWrapperHelper } from "@/components/app/app-wrapper/AppWrapper.helper"
import { useStoreFormHadler } from "@/utility/composables/useStoreFormHandler"
import StoreService from "@/utility/services/store.service"
import { ref } from "vue"
import { useRouter } from "vue-router"
import { Store } from "vuex"

export const createStore = async (values: any, store?: Store<any>) => {
    try {
        await StoreService.createStoresRequest(values)
        store?.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Store created', x_message: `Created "${values.name}" store successfully`, duration: 5000 });
    } catch (error: any) {
        console.error('Error creating store', error)
        store?.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error creating store', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
}

export const useStoreManagementHelper = () => {

    const  { storesProfile } = useAppWrapperHelper()
    const { createStoreHandler } = useStoreFormHadler()
    const selectedStoreId = ref()
    const router = useRouter()

    const storesHeader = ref([
        { name: "Store", value: "entityCard" },
        { name: "Setup", value: "setup" },
        { name: "# of Tablets", value: "tablet" },
        { name: "Status", value: "statusCard" },
        { name: "Last updated", value: "lastUpdated" },
    ])

    const storesData = () => {
        return storesProfile?.value?.map((store: Record<string | number, unknown | any>) => {
            return {
                id: store?.id,
                name: `${store?.name}`,
                imageUrl: 'http://localhost:8080/img/location.78a92959.svg',
                description: `Address: ${store?.address?.street} ${store?.address?.lga} ${store?.address?.city} ${store?.address?.country}`,
                subDescription: `Email: ${store?.email}`,
                setup: `${store?.progress}%`,
                tablet: `# ${store?.tabletCount}`,
                status: store?.status,
                lastUpdated: store?.modifiedDate || '________'
            }
        })
    }

    const selectStore = (storeId: string) => {
        selectedStoreId.value = storeId;
        localStorage.setItem('storeId', storeId);
        router.push('/')
    };

    const getStoreText = (store: any) => {
       return store.setup <= 100 ? 'Visit Setup Guide' : 'Visit Store'
    };
    
    return { storesHeader, storesData, createStoreHandler, selectStore }
}
