import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lg:pt-[76px] pt-[30px]" }
const _hoisted_2 = { class: "lg:flex justify-between" }
const _hoisted_3 = { class: "font-semibold lg:text-heading-3 text-heading-5 flex justify-between" }
const _hoisted_4 = { class: "flex gap-[32px] border-b-[1px] border-solid mt-[24px] overflow-auto no-scrollbar" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "my-[28px] overflow-auto" }

import TableField from '@/components/shared/table/TableField.vue';
import { getCashiers, getCategories, getPayments, useOrderHelper } from './Order.helper'
import InputField from '@/components/shared/input-field/InputField.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { Icon } from '@iconify/vue';
import { computed, ref, watchEffect } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'OrdersView',
  setup(__props) {

const showFilterMobile = ref(false);
const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value;

const categoriesData = ref();
const cashiersData = ref();
const paymentData = ref();
const pageSize = ref(10); 
const currentPage = ref(1); 

const handlePageChange = (page: number) => {
    currentPage.value = page;
};

const handlePageSizeChange = (size: number) => {
    pageSize.value = size;
    currentPage.value = 1; 
};

watchEffect(() => {
    getCategories().then((response) => {
        categoriesData.value = response;
    });
});

watchEffect(() => {
    getCashiers().then((response) => {
        cashiersData.value = response;
    });
});

watchEffect(() => {
    getPayments().then((response) => {
        paymentData.value = response;
    });

});

console.log(Object.entries(paymentData.value || {}))

const categoriesOptions = computed(() => {
    if (!categoriesData.value || !categoriesData.value.categories) {
        return [];
    }

    return [{ name: 'All categories', value: '' }, ...categoriesData.value.categories.map((category: any) => ({
        name: category.category,
        value: category.category
    }))];
});

const cashiersOptions = computed(() => {
    if (!cashiersData.value || !cashiersData.value.cashiers) {
        return [];
    }

    return [{ name: 'All cashiers', value: '' }, ...cashiersData.value.cashiers.map((cashier: any) => ({
        name: `${cashier.firstName} ${cashier.lastName}`,
        value: cashier.userId
    }))];
});

const paymentOptions = computed(() => {
    if (!paymentData.value) {
        return [];
    }

    return [{ name: 'All payments', value: '' }, ...Object.entries(paymentData.value).filter(([_, payment]: [string, any]) => payment.status).map(([key]) => ({
        name: key,
        value: key
    }))];
});

const isMobile = ref(window.innerWidth < 768);

const filteredOrderHeaders = computed(() => {
    if (!orderHeaders.value) return [];
    if (isMobile.value) {
        return orderHeaders.value.filter(header => !['paymentMethod', 'customer', 'cashier'].includes(header.value));
    }
    return orderHeaders.value;
});

window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768;
});

const { 
    AppWrapper, 
    orderHeaders, 
    orderSearchDateOptions, 
    activateTab, 
    onChangeData, 
    onSearch, 
    orderData, 
    orderTabs, 
    search,
    activeTab,
    searchOptionsData,
    onChangeCashierData,
    onChangeCategoryData,
    onChangePaymentMethodData,
    totalOrders,
    onChangeStartDate,
    onChangeEndDate,
    loading
} = useOrderHelper(pageSize, currentPage);



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Orders" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", null, "Orders", -1)),
            _createElementVNode("div", {
              class: _normalizeClass(["border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden", showFilterMobile.value && 'bg-primary-300 text-white']),
              onClick: handleShowMobileFilter
            }, [
              _createVNode(_unref(Icon), {
                class: "",
                icon: "fluent:options-20-filled",
                width: "20",
                height: "20"
              })
            ], 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["lg:grid lg:grid-cols-3 lg:border-[1px] lg:border-grey-50 lg:rounded-[8px] lg:p-[16px] grid gap-[10px] items-center mt-[10px] lg:mt-[0]", !showFilterMobile.value ? 'hidden' : 'block' ])
          }, [
            _createVNode(InputField, {
              type: "text",
              placeholder: "Search",
              value: _unref(search),
              "prefix-icon": "ant-design:search-outlined",
              onChange: _unref(onSearch),
              class: ""
            }, null, 8, ["value", "onChange"]),
            _createVNode(InputField, {
              type: "date",
              placeholder: "Start date",
              value: _unref(search),
              "prefix-icon": "ant-design:search-outlined",
              onChange: _unref(onChangeStartDate),
              class: ""
            }, null, 8, ["value", "onChange"]),
            _createVNode(InputField, {
              type: "date",
              placeholder: "End date",
              value: _unref(search),
              "prefix-icon": "ant-design:search-outlined",
              onChange: _unref(onChangeEndDate),
              class: ""
            }, null, 8, ["value", "onChange"]),
            _createVNode(DropdownField, {
              value: _unref(searchOptionsData).cashier,
              label: "",
              optionsData: cashiersOptions.value,
              onChange: _unref(onChangeCashierData)
            }, null, 8, ["value", "optionsData", "onChange"]),
            _createVNode(DropdownField, {
              value: _unref(searchOptionsData).category,
              label: "",
              optionsData: categoriesOptions.value,
              onChange: _unref(onChangeCategoryData)
            }, null, 8, ["value", "optionsData", "onChange"]),
            _createVNode(DropdownField, {
              value: _unref(searchOptionsData).paymentMethod,
              label: "",
              optionsData: paymentOptions.value,
              onChange: _unref(onChangePaymentMethodData)
            }, null, 8, ["value", "optionsData", "onChange"])
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(orderTabs), (tab) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tab?.status,
              class: _normalizeClass(['py-[12px] cursor-pointer lg:text-body-2 text-overline whitespace-nowrap text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab).status === tab.status }]),
              onClick: ($event: any) => (_unref(activateTab)(tab))
            }, _toDisplayString(tab?.status) + " (" + _toDisplayString(tab?.orderCount) + ") ", 11, _hoisted_5))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(TableField, {
            headers: filteredOrderHeaders.value,
            dataItems: _unref(orderData),
            totalItems: _unref(totalOrders),
            isPagination: true,
            viewDetails: true,
            routeData: {name: 'OrderDetails', paramId: 'orderId'},
            onCurrentPage: handlePageChange,
            onPageSize: handlePageSizeChange,
            loading: _unref(loading),
            pagination: "server"
          }, null, 8, ["headers", "dataItems", "totalItems", "loading"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})