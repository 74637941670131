import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lg:py-[72px] py-[20px] lg:w-[800px] lg:m-[auto]" }
const _hoisted_2 = { class: "flex items-center gap-[6px] lg:text-body-1 text-body-2" }
const _hoisted_3 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] lg:mt-[32px] mt-[12px]" }
const _hoisted_4 = { class: "flex items-center gap-[26px] lg:py-[24px] lg:px-[32px] p-[10px]" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "font-semibold lg:text-heading-5 text-body-2 mt-[6px]" }
const _hoisted_9 = { class: "lg:text-body-2 text-caption text-grey-100" }
const _hoisted_10 = {
  key: 0,
  class: "lg:text-body-2 text-caption p-[10px]"
}
const _hoisted_11 = { class: "" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "flex items-center justify-between lg:py-[18px] lg:px-[24px] px-[10px] py-[14px] bg-bg-200 border-b-[1px] border-solid border-grey-50" }
const _hoisted_14 = { class: "flex items-center gap-[10px] text-grey-200" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "grid grid-cols-2 lg:px-[24px] px-[10px] py-[28px]" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = { class: "" }
const _hoisted_20 = { class: "font-medium tlg:ext-body-1 text-caption mt-[4px]" }
const _hoisted_21 = { class: "mt-[24px]" }
const _hoisted_22 = { class: "font-medium lg:text-body-1 text-caption mt-[4px]" }
const _hoisted_23 = { class: "" }
const _hoisted_24 = { class: "font-medium lg:text-body-1 text-caption mt-[4px]" }
const _hoisted_25 = { class: "" }
const _hoisted_26 = { class: "" }
const _hoisted_27 = { class: "font-medium lg:text-body-1 text-caption mt-[4px]" }
const _hoisted_28 = { class: "mt-[24px]" }
const _hoisted_29 = { class: "font-medium lg:text-body-1 text-caption mt-[4px]" }
const _hoisted_30 = { class: "" }
const _hoisted_31 = { class: "font-medium lg:text-body-1 text-caption mt-[4px]" }
const _hoisted_32 = { class: "border-t-[1px] border-solid border-grey-50 lg:px-[32px] px-[10px] pb-[20px]" }
const _hoisted_33 = { class: "flex gap-[32px] border-b-[1px] border-solid lg:mt-[24px]" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { class: "text-grey-200 lg:text-body-2 text-caption font-medium mt-[24px]" }
const _hoisted_37 = { class: "text-grey-300 lg:text-body-1 text-body-2 font-medium flex justify-between items-center" }
const _hoisted_38 = { class: "flex lg:gap-[24px] gap-[12px]" }
const _hoisted_39 = { class: "" }
const _hoisted_40 = { class: "lg:text-body-1 text-caption" }
const _hoisted_41 = { class: "lg:text-body-1 text-caption" }
const _hoisted_42 = { class: "text-grey-200 lg:text-body-2 text-caption font-medium mt-[4px] lg:ml-[41px] ml-[29px]" }
const _hoisted_43 = { class: "" }
const _hoisted_44 = { class: "bg-bg-200 p-[32px] border-t-[3px] border-grey-50 border-dashed" }
const _hoisted_45 = { class: "flex items-center justify-between mt-[10px]" }
const _hoisted_46 = { class: "lg:text-body-1 text-body-2 text-grey-300 font-medium" }
const _hoisted_47 = { class: "lg:text-body-1 text-caption text-grey-200" }
const _hoisted_48 = { class: "text-body-1 text-grey-300 font-medium" }
const _hoisted_49 = { class: "flex items-center justify-between mt-[10px]" }
const _hoisted_50 = { class: "lg:text-body-1 text-body-2 text-grey-300 font-medium" }

import { useRouter } from 'vue-router';
import { useOrderHelper, getOrderById, OrderDetail, orderDetailInitialValues } from './Order.helper';
import { watchEffect, ref } from 'vue'
import { Icon } from '@iconify/vue';
import StatusLabel from '@/components/shared/status-label/StatusLabel.vue'

interface Props {
    id: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrdersDetails',
  props: {
    id: {}
  },
  setup(__props: any) {

const { AppWrapper, orderInformationTabs } = useOrderHelper()
const router = useRouter()

const orderDetailData= ref<OrderDetail>({...orderDetailInitialValues});
const activeTab = ref(0);
const props = __props
const showOrderInfo = ref(true)

const activateTab = (tab: number) => {
    activeTab.value = tab;
};

const handleShowOrderInformation = () => {
    showOrderInfo.value = !showOrderInfo.value
}

watchEffect(() => {
    getOrderById({ orderId: props.id}).then((responseData)=> {
        if (responseData) {
            orderDetailData.value = responseData as OrderDetail;
        }
    });
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Orders" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "flex items-center gap-[6px] text-grey-100 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).back()))
          }, [
            _createVNode(_unref(Icon), { icon: "ant-design:shopping-outlined" }),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, "Orders", -1))
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", null, "/", -1)),
          _createElementVNode("div", null, _toDisplayString(orderDetailData.value.orderNumber), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("header", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "order-image rounded-[8px]",
                style: _normalizeStyle({ backgroundImage: `url(${orderDetailData.value?.imageUrl})`})
              }, null, 4)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(StatusLabel, {
                  status: orderDetailData.value.status
                }, null, 8, ["status"])
              ]),
              _createElementVNode("div", _hoisted_8, "Order " + _toDisplayString(orderDetailData.value.orderNumber), 1),
              _createElementVNode("div", _hoisted_9, "Ordered on " + _toDisplayString(orderDetailData.value.orderDate), 1)
            ])
          ]),
          (orderDetailData.value.status === 'Cancelled')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, " Cancelled reason: " + _toDisplayString(orderDetailData.value?.cancelReason), 1))
            : _createCommentVNode("", true),
          _createElementVNode("main", _hoisted_11, [
            _createElementVNode("section", _hoisted_12, [
              _createElementVNode("header", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_unref(Icon), {
                      icon: "ant-design:shopping-cart-outlined",
                      class: "lg:w-[24px] lg:h-[24px]"
                    })
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "lg:text-body-1 text-body-2" }, "Order Information", -1))
                ]),
                _createElementVNode("div", {
                  class: "text-grey-200 cursor-pointer",
                  onClick: handleShowOrderInformation
                }, [
                  _createVNode(_unref(Icon), {
                    icon: showOrderInfo.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                    class: "lg:w-[24px] lg:h-[24px]"
                  }, null, 8, ["icon"])
                ])
              ]),
              _createElementVNode("main", _hoisted_16, [
                _withDirectives(_createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("div", _hoisted_19, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Order Date", -1)),
                      _createElementVNode("div", _hoisted_20, _toDisplayString(orderDetailData.value.orderDate), 1)
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Payment Option", -1)),
                      _createElementVNode("div", _hoisted_22, _toDisplayString(orderDetailData.value.paymentType), 1)
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium mt-[24px]" }, "Processed By", -1)),
                      _createElementVNode("div", _hoisted_24, _toDisplayString(orderDetailData.value?.cashier?.firstName) + " " + _toDisplayString(orderDetailData.value?.cashier?.lastName), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Total Price", -1)),
                      _createElementVNode("div", _hoisted_27, _toDisplayString(orderDetailData.value.totalPrice), 1)
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Order Channel", -1)),
                      _createElementVNode("div", _hoisted_29, _toDisplayString(orderDetailData.value?.orderChannel), 1)
                    ]),
                    _createElementVNode("div", _hoisted_30, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium mt-[24px]" }, "Customer’s Name", -1)),
                      _createElementVNode("div", _hoisted_31, _toDisplayString(orderDetailData.value?.customerFirstName) + " " + _toDisplayString(orderDetailData.value?.customerLastName) + " (" + _toDisplayString(orderDetailData.value.customerPhone) + ")", 1)
                    ])
                  ])
                ], 512), [
                  [_vShow, showOrderInfo.value]
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("div", _hoisted_33, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(orderInformationTabs), (tab, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass(['py-[12px] cursor-pointer lg:text-body-2 text-caption text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab.value === index }]),
                        onClick: ($event: any) => (activateTab(index))
                      }, _toDisplayString(tab), 11, _hoisted_34))
                    }), 128))
                  ]),
                  (activeTab.value === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                        _createElementVNode("div", _hoisted_36, _toDisplayString(orderDetailData.value?.orderDetails?.length) + " product from Store", 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderDetailData.value?.orderDetails, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item?.menuId,
                            class: "mt-[24px]"
                          }, [
                            _createElementVNode("div", _hoisted_37, [
                              _createElementVNode("div", _hoisted_38, [
                                _createElementVNode("div", _hoisted_39, _toDisplayString(item.quantity) + "x", 1),
                                _createElementVNode("div", _hoisted_40, _toDisplayString(item.menuName), 1)
                              ]),
                              _createElementVNode("div", _hoisted_41, _toDisplayString(item.formatedPrice), 1)
                            ]),
                            _createElementVNode("div", _hoisted_42, _toDisplayString(item.category) + " | " + _toDisplayString(item.name), 1)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("div", _hoisted_44, [
                    _cache[12] || (_cache[12] = _createElementVNode("div", { class: "font-semibold lg:text-heading-4" }, "Summary", -1)),
                    _createElementVNode("div", _hoisted_45, [
                      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "lg:text-body-1 text-body-2 text-grey-200" }, "Product Subtotal", -1)),
                      _createElementVNode("div", _hoisted_46, _toDisplayString(orderDetailData.value.subTotalPrice), 1)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderDetailData.value?.serviceFees, (serviceFee) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: serviceFee?.name,
                        class: "flex items-center justify-between mt-[10px]"
                      }, [
                        _createElementVNode("div", _hoisted_47, _toDisplayString(serviceFee.name), 1),
                        _createElementVNode("div", _hoisted_48, _toDisplayString(serviceFee.formattedValue), 1)
                      ]))
                    }), 128)),
                    _createElementVNode("div", _hoisted_49, [
                      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "lg:text-body-1 text-body-2 text-grey-200" }, "Total:", -1)),
                      _createElementVNode("div", _hoisted_50, _toDisplayString(orderDetailData.value.totalPrice), 1)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})