import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-[72px] w-[800px] m-[auto]" }
const _hoisted_2 = { class: "flex items-center gap-[6px] text-body-1" }
const _hoisted_3 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] mt-[32px]" }
const _hoisted_4 = { class: "py-[24px] px-[32px]" }
const _hoisted_5 = { class: "flex items-center justify-between" }
const _hoisted_6 = { class: "font-semibold text-heading-5 mt-[6px]" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "text-capitalize text-body-1 font-medium" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "px-[32px] py-[28px]" }
const _hoisted_12 = { class: "grid grid-cols-2 gap-[24px]" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_19 = { class: "" }
const _hoisted_20 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_21 = { class: "" }
const _hoisted_22 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_23 = { class: "" }
const _hoisted_24 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_25 = { class: "" }
const _hoisted_26 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_27 = { class: "flex items-center justify-between py-[18px] px-[24px] bg-bg-200 rounded-[8px] mt-[24px]" }
const _hoisted_28 = { class: "" }
const _hoisted_29 = { class: "" }
const _hoisted_30 = { class: "grid grid-cols-2 px-[24px] py-[28px] gap-[24px]" }
const _hoisted_31 = { class: "" }
const _hoisted_32 = { class: "font-medium text-body-1 mt-[4px]" }
const _hoisted_33 = { class: "border-t-[1px] border-solid border-grey-50 px-[32px] pb-[20px]" }

import { useRouter } from 'vue-router';
import { useInvoicesHelper, getInvoiceById } from './Invoices.helper';
import { watchEffect, ref } from 'vue'
import { Icon } from '@iconify/vue';
import StatusLabel from '@/components/shared/status-label/StatusLabel.vue'
import TableField from '@/components/shared/table/TableField.vue';

interface Props {
    id: string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceDetails',
  props: {
    id: {}
  },
  setup(__props: any) {

const { AppWrapper, invoiceDetailsData, detailsHeader } = useInvoicesHelper()
const router = useRouter()

let orders :any = [];
let orderChannel = ""
let customer: any = {};
let cashier: any = {};
const detailsData = ref([])


const props = __props

const showOrderInfo = ref(false)

const handleShowOrderInformation = () => {
    showOrderInfo.value = !showOrderInfo.value
}

watchEffect(() => {
    getInvoiceById({ invoiceId: props.id}).then((responseData)=> {
        if (responseData) {
            invoiceDetailsData.value = responseData;
            orders = responseData.orders;
            orderChannel = orders[0]?.orderChannel;
            customer = orders[0]?.customer;
            cashier = orders[0]?.cashier;
            detailsData.value = orders[0].orderDetails;
        }
    });
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Invoices" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "flex items-center gap-[6px] text-grey-100 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).back()))
          }, [
            _createVNode(_unref(Icon), { icon: "ant-design:shopping-outlined" }),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, "Invoice", -1))
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", null, "/", -1)),
          _createElementVNode("div", null, _toDisplayString(_unref(invoiceDetailsData).invoiceNumber), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("header", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, "Invoice #" + _toDisplayString(_unref(invoiceDetailsData).invoiceNumber), 1),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(StatusLabel, {
                  status: _unref(invoiceDetailsData).status
                }, null, 8, ["status"])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "border-t-[1px] border-solid border-grey-50 flex items-center gap-[10px] py-[20px] px-[32px]",
            style: _normalizeStyle({
                        borderBottom: _unref(invoiceDetailsData).status == 'Cancelled' ? '1px solid #D2452E' : '1px solid #52C41A',
                        backgroundColor: _unref(invoiceDetailsData).status == 'Cancelled' ? '#FFF1F04D' : '#F6FFED4D'
                    })
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_unref(invoiceDetailsData).status == 'Cancelled' ? 'text-error' : 'text-success')
            }, [
              _createVNode(_unref(Icon), {
                icon: _unref(invoiceDetailsData).status == 'Cancelled' ? 'ant-design:close-circle-outlined' : 'ant-design:check-circle-outlined',
                class: "w-[24px] h-[24px]"
              }, null, 8, ["icon"])
            ], 2),
            _createElementVNode("div", _hoisted_8, "Invoice " + _toDisplayString(_unref(invoiceDetailsData).status) + " on " + _toDisplayString(_unref(invoiceDetailsData).invoiceDate), 1)
          ], 4),
          _createElementVNode("main", _hoisted_9, [
            _createElementVNode("section", _hoisted_10, [
              _createElementVNode("main", null, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Order Date", -1)),
                      _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(invoiceDetailsData).invoiceDate), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Order Channel", -1)),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(orderChannel)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "From", -1)),
                      _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(invoiceDetailsData).restaurantStoreName), 1)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "To", -1)),
                      _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(customer)?.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Phone Number", -1)),
                      _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(customer).phone), 1)
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Processed By", -1)),
                      _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(cashier)?.name || "Staff"), 1)
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Invoice Notes", -1)),
                      _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(invoiceDetailsData).invoiceNotes), 1)
                    ])
                  ]),
                  _createElementVNode("header", _hoisted_27, [
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex items-center gap-[10px] text-grey-200" }, [
                      _createElementVNode("div", { class: "text-body-2" }, "Other Charges")
                    ], -1)),
                    _createElementVNode("div", {
                      class: "text-grey-200 cursor-pointer",
                      onClick: handleShowOrderInformation
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: showOrderInfo.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                        class: "w-[24px] h-[24px]"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _withDirectives(_createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, [
                          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Service Fee", -1)),
                          _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(invoiceDetailsData).serviceFee), 1)
                        ])
                      ])
                    ], 512), [
                      [_vShow, showOrderInfo.value]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "pt-[32px] pb-[24px] text-body-2 font-medium" }, "Items", -1)),
                  _createVNode(TableField, {
                    headers: _unref(detailsHeader),
                    dataItems: detailsData.value
                  }, null, 8, ["headers", "dataItems"])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})