<template>
    <div class="mt-[32px]">
        <div class="text-grey-200">Manage your store Delivery options below</div>
        <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]">
            <header class="flex items-center justify-between border-b-[1px] border-grey-50 pb-[12px]">
                <div class="font-semibold text-sub-heading">Delivery Options</div>
                <ToggleSlider />
            </header>

            <div class="grid grid-cols-3 gap-[24px] mt-[28px]">
                <div class="" >
                    <div class="text-grey-200 text-caption font-medium capitalize">State</div>
                    <div class="font-medium text-body-1 mt-[4px]">Lagos</div>
                </div>

                <div class="" >
                    <div class="text-grey-200 text-caption font-medium capitalize">LGA</div>
                    <div class="font-medium text-body-1 mt-[4px]">Oshodi</div>
                </div>

                <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] py-[11px] w-[fit-content] text-body-2 cursor-pointer ml-[auto]" @click="handleOpenEditModal">Update</div>

                <div class="" >
                    <div class="text-grey-200 text-caption font-medium capitalize">Street Address</div>
                    <div class="font-medium text-body-1 mt-[4px]">BLK 15 26 Ayoade olubowale Cres. </div>
                </div>
            </div>

            <div class="mt-[24px]">
                <div class="text-grey-200 text-caption font-medium capitalize">Deliverable States and Areas</div>
                <div class="font-medium text-body-1 mt-[4px]">Table Here...</div>
            </div>

            <div class="mt-[24px]">
                <div class="text-grey-200 text-caption font-medium capitalize">Delivery distances and fees</div>
                <div class="font-medium text-body-1 mt-[4px]">Table Here...</div>
            </div>
        </div>
    </div>

    <AppModal headerContent="Update Store information" :openModal="isOpen" @close="handleOpenEditModal">
        <div class="">
            <form @submit.prevent="handleOpenPayoutUpdateModalSubmit($event)">
                <div class="p-[24px]">
                    <div class="grid grid-cols-2 gap-[20px] mt-[8px]">
                        <div v-for="(serviceFee, idx) in additionalChargesData?.serviceFees" :key="idx">
                            <InputField
                                :label="serviceFee.name" 
                                type="text" 
                                :value="serviceFee.value"
                                :name="serviceFee.name"
                                :placeholder="`Enter ${serviceFee.name}`" 
                                @update:value="(event) => updateAdditionalCharge(serviceFee.name, event.target.value)"
                            />
                        </div>
                    </div>
                </div>

                <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                    <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenEditModal" />
                    <ActionButton
                        theme="primary" 
                        class="w-[fit-content]" 
                        :isSubmit="true" 
                        :disable="loading && true" 
                        :label="loading ? 'Loading...' : 'Save'" 
                    />
                </footer>
            </form>
        </div>
    </AppModal>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import ToggleSlider from '@/components/shared/toggle-slider/ToggleSlider.vue';
import { useAdditionalChargesSettingsHelper } from './Settings.helper';

const { additionalChargesData, handleOpenEditModal, isOpen, loading, handleOpenPayoutUpdateModalSubmit, updateAdditionalCharge } = useAdditionalChargesSettingsHelper()

</script>
