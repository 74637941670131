<template>
    <AppWrapper activeTab="Orders">
        <div class="lg:pt-[76px] pt-[30px]">
            <div class="lg:flex justify-between">
                <div class="font-semibold lg:text-heading-3  text-heading-5 flex justify-between">
                    <div>Orders</div>
                    <div class="border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden" :class="showFilterMobile && 'bg-primary-300 text-white'" @click="handleShowMobileFilter">
                        <Icon class="" icon="fluent:options-20-filled" width="20" height="20" />
                    </div>
                </div>
                <div class="lg:grid lg:grid-cols-3 lg:border-[1px] lg:border-grey-50 lg:rounded-[8px] lg:p-[16px] grid gap-[10px] items-center mt-[10px] lg:mt-[0]" :class="!showFilterMobile ? 'hidden' : 'block' ">
                    <InputField 
                        type="text"
                        placeholder="Search"
                        :value="search"
                        prefix-icon="ant-design:search-outlined"
                        @change="onSearch" 
                        class=""
                    />

                    <InputField 
                        type="date"
                        placeholder="Start date"
                        :value="search"
                        prefix-icon="ant-design:search-outlined"
                        @change="onChangeStartDate" 
                        class=""
                    />

                    <InputField 
                        type="date"
                        placeholder="End date"
                        :value="search"
                        prefix-icon="ant-design:search-outlined"
                        @change="onChangeEndDate" 
                        class=""
                    />
                    <!-- <DropdownField :value="searchOptionsData.since.toString()" label="" :optionsData="orderSearchDateOptions" @change="onChangeData" /> -->
                    <DropdownField :value="searchOptionsData.cashier" label="" :optionsData="cashiersOptions" @change="onChangeCashierData" />
                    <DropdownField :value="searchOptionsData.category" label="" :optionsData="categoriesOptions" @change="onChangeCategoryData" />
                    <DropdownField :value="searchOptionsData.paymentMethod" label="" :optionsData="paymentOptions" @change="onChangePaymentMethodData" />
                </div>
            </div>

            <div class="flex gap-[32px] border-b-[1px] border-solid mt-[24px] overflow-auto no-scrollbar">
                <div
                    v-for="(tab) in orderTabs"
                    :key="tab?.status"
                    :class="['py-[12px] cursor-pointer lg:text-body-2 text-overline whitespace-nowrap text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab.status === tab.status }]"
                    @click="activateTab(tab)"
                > {{ tab?.status }} ({{ tab?.orderCount }}) </div>
            </div>

            <div class="my-[28px] overflow-auto">
                <TableField 
                    :headers="filteredOrderHeaders" 
                    :dataItems="orderData" 
                    :totalItems="totalOrders"
                    :isPagination="true" 
                    :viewDetails="true"
                    :routeData="{name: 'OrderDetails', paramId: 'orderId'}"
                    @currentPage="handlePageChange"
                    @pageSize="handlePageSizeChange"
                    :loading="loading"
                    pagination="server"
                />
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import TableField from '@/components/shared/table/TableField.vue';
import { getCashiers, getCategories, getPayments, useOrderHelper } from './Order.helper'
import InputField from '@/components/shared/input-field/InputField.vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { Icon } from '@iconify/vue';
import { computed, ref, watchEffect } from 'vue';

const showFilterMobile = ref(false);
const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value;

const categoriesData = ref();
const cashiersData = ref();
const paymentData = ref();
const pageSize = ref(10); 
const currentPage = ref(1); 

const handlePageChange = (page: number) => {
    currentPage.value = page;
};

const handlePageSizeChange = (size: number) => {
    pageSize.value = size;
    currentPage.value = 1; 
};

watchEffect(() => {
    getCategories().then((response) => {
        categoriesData.value = response;
    });
});

watchEffect(() => {
    getCashiers().then((response) => {
        cashiersData.value = response;
    });
});

watchEffect(() => {
    getPayments().then((response) => {
        paymentData.value = response;
    });

});

console.log(Object.entries(paymentData.value || {}))

const categoriesOptions = computed(() => {
    if (!categoriesData.value || !categoriesData.value.categories) {
        return [];
    }

    return [{ name: 'All categories', value: '' }, ...categoriesData.value.categories.map((category: any) => ({
        name: category.category,
        value: category.category
    }))];
});

const cashiersOptions = computed(() => {
    if (!cashiersData.value || !cashiersData.value.cashiers) {
        return [];
    }

    return [{ name: 'All cashiers', value: '' }, ...cashiersData.value.cashiers.map((cashier: any) => ({
        name: `${cashier.firstName} ${cashier.lastName}`,
        value: cashier.userId
    }))];
});

const paymentOptions = computed(() => {
    if (!paymentData.value) {
        return [];
    }

    return [{ name: 'All payments', value: '' }, ...Object.entries(paymentData.value).filter(([_, payment]: [string, any]) => payment.status).map(([key]) => ({
        name: key,
        value: key
    }))];
});

const isMobile = ref(window.innerWidth < 768);

const filteredOrderHeaders = computed(() => {
    if (!orderHeaders.value) return [];
    if (isMobile.value) {
        return orderHeaders.value.filter(header => !['paymentMethod', 'customer', 'cashier'].includes(header.value));
    }
    return orderHeaders.value;
});

window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768;
});

const { 
    AppWrapper, 
    orderHeaders, 
    orderSearchDateOptions, 
    activateTab, 
    onChangeData, 
    onSearch, 
    orderData, 
    orderTabs, 
    search,
    activeTab,
    searchOptionsData,
    onChangeCashierData,
    onChangeCategoryData,
    onChangePaymentMethodData,
    totalOrders,
    onChangeStartDate,
    onChangeEndDate,
    loading
} = useOrderHelper(pageSize, currentPage);


</script>

<style scoped>
</style>
