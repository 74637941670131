import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"



interface Props {
    status: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusLabel',
  props: {
    status: {}
  },
  setup(__props: any) {



const getStatusStyle = (status: string) => {
    let backgroundColor = '';
    let color = '';
    let border = ''

    switch (status?.toLowerCase()) {
        case 'pending':
            backgroundColor = '#E6F4FF';
            color = '#1677FF';
            border = '1px solid #91CAFF';
            break;
        case 'disabled':
            backgroundColor = '#FFF1F0';
            color = '#F5222D';
            border = '1px solid #FFA39E';
            break;
        case 'cancelled':
            backgroundColor = '#FFF1F0';
            color = '#F5222D';
            border = '1px solid #FFA39E';
            break;
        case 'failed':
            backgroundColor = '#ffc7c3';
            color = '#F5222D';
            border = '1px solid #F22819';
            break;
        case 'completed':
            backgroundColor = '#F6FFED';
            color = '#52C41A';
            border = '1px solid #B7EB8F';
            break;
        case 'available':
            backgroundColor = '#F6FFED';
            color = '#52C41A';
            border = '1px solid #B7EB8F';
            break;
        case 'active':
            backgroundColor = '#F6FFED';
            color = '#52C41A';
            border = '1px solid #B7EB8F';
            break;
        default:
            break;
    }

    return {
        backgroundColor,
        color,
        border
    };
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "py-[4px] px-[10px] rounded-[4px] w-[fit-content] text-body-2",
    style: _normalizeStyle(getStatusStyle(_ctx.status))
  }, _toDisplayString(_ctx.status), 5))
}
}

})