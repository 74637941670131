<template>
    <div class="mt-[32px]">
        <div class="text-grey-200">Personalize Your Microsite: Make It Yours!</div>
        <div class="mt-[20px] border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]">
            <div class="flex items-center justify-between">
                <div class="font-semibold">Microsite Personalization</div>
                <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] text-body-2 py-[11px] w-[fit-content] cursor-pointer" @click="handleShowMicrositeUpdatemodal">Edit</div>
            </div>

            <div class="relative">
                <div class="h-[250px] overflow-hidden mt-[20px]">
                    <ImageCard width="" height="400" :imageUrl="micrositeData?.cover" />
                </div>

                <div class="h-[120px] w-[120px] rounded-[50%] border-[5px] border-solid border-bg-100 overflow-hidden absolute bottom-[-60px] left-[69px]">
                    <ImageCard width="120" height="120" :imageUrl="micrositeData?.logo" />
                </div>
            </div>

            <div class="mt-[72px]">
                <div class="font-semibold text-heading-4">{{ micrositeData?.name }}</div>
                <div class="mt-[12px] text-grey-200 text-body-2">{{ micrositeData?.description }}</div>
            </div>
        </div>

        <div class="text-link font-medium text-body-2 text-center mt-[44px] mb-[20px]">https://www.foodcart.ng/store/62d9a3aa6d5e0100041f28f8</div>
        <div class="flex items-center gap-[10px] font-medium m-[auto] border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] text-body-2 py-[11px] w-[fit-content] cursor-pointer" @click="() => {}">
            <Icon icon="ant-design:copy-outlined" class="w-[18px] h-[18px]" />
            <div class="">Copy link</div>
        </div>

        <AppModal headerContent="Microsite Personalization" :openModal="isMicrositeUpdateModal" @close="handleShowMicrositeUpdatemodal">
            <form @submit.prevent="handleOpenMicrositeUpdateModalSubmit($event)">
                <div class="px-[28px] pt-[32px]">
                    <UploadProfileImage 
                        :imageUrl="micrositeData?.logo"
                        name="logo"
                        @base64ImgCode="logoImageDataFromEmitedBase64"
                        @update:blur:value="updateMicroAccountFormHandler.handleBlur"
                        @update:value="updateMicroAccountFormHandler.handleFileChange"
                    />
                    
                    <UploadImage
                        :imageUrl="micrositeData?.cover"
                        name="cover"
                        @base64ImgCode="coverImageDataFromEmitedBase64"
                        @update:blur:value="updateMicroAccountFormHandler.handleBlur"
                        @update:value="updateMicroAccountFormHandler.handleFileChange"
                    />

                    <InputField 
                        label="Display Name" 
                        type="text" 
                        :value="micrositeData?.name"
                        name="name"
                        @update:blur:value="updateMicroAccountFormHandler.handleBlur"
                        @update:value="updateMicroAccountFormHandler.handleChange"
                    />
                    
                    <TextArea 
                        label="Store Description" 
                        placeholder="Briefly introduce your store here" 
                        class="mt-[24px]" 
                        :value="micrositeData?.description"
                        name="description"
                        @update:blur:value="updateMicroAccountFormHandler.handleBlur"
                        @update:value="updateMicroAccountFormHandler.handleChange"
                    />
                </div>
                
                <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                    <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleShowMicrositeUpdatemodal" />
                    <ActionButton
                        theme="primary" 
                        class="w-[fit-content]" 
                        :isSubmit="true" 
                        :disable="loading && true" 
                        :label="loading ? 'Loading...' : 'Save'" 
                    />
                </footer>
            </form>
        </AppModal>
    </div>
</template>

<script setup lang="ts">
import ImageCard from '@/components/shared/image/ImageCard.vue';
import { Icon } from '@iconify/vue';
import { useSettingsMicrositeHelper } from './Settings.helper';
import AppModal from '@/components/shared/modal/AppModal.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import UploadImage from '@/components/shared/upload-image/UploadImage.vue';
import UploadProfileImage from '@/components/shared/upload-profile-image/UploadProfileImage.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import { useStore } from 'vuex';


const store = useStore()

const { 
    handleOpenMicrositeUpdateModalSubmit, 
    handleShowMicrositeUpdatemodal, 
    isMicrositeUpdateModal, 
    micrositeData, 
    updateMicroAccountFormHandler, 
    loading 
} = useSettingsMicrositeHelper(store);



const logoImageDataFromEmitedBase64 = (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    updateMicroAccountFormHandler.values.logoImageData =  imageDataParts?.[1];
}
const coverImageDataFromEmitedBase64 = (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    updateMicroAccountFormHandler.values.coverImageData =  imageDataParts?.[1];
}

</script>
