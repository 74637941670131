<template>
    <div class="relative w-full h-1 bg-gray-200 overflow-hidden">
      <div
        class="absolute h-full bg-primary-300 transition-all"
        :class="{ 'loader-bar': loading }"
        :style="{ width: `${progress}%` }"
      ></div>
    </div>
  </template>
  
  <script setup>
  import { ref, watch, onUnmounted, defineProps } from 'vue';
  
  const props = defineProps({
    loading: {
      type: Boolean,
      required: true
    }
  });
  
  const progress = ref(0);
  let intervalId = null;
  
  watch(
    () => props.loading,
    (newLoading) => {
      if (newLoading) {
        progress.value = 0;
        intervalId = setInterval(() => {
          progress.value = Math.min(progress.value + 1, 99);
        }, 1.2);
      } else {
        clearInterval(intervalId);
        progress.value = 100;
      }
    },
    { immediate: true }
  );
  
  onUnmounted(() => {
    if (intervalId) clearInterval(intervalId);
  });
  </script>
  
  <style scoped>
  .loader-bar {
    /* Add any custom loader-bar styles here if needed */
  }
  </style>
  