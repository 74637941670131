import ApiService from './api.service';
import { storeId } from './auth.service';


export default class ReportService {

    static async getSalesReportRequest(month: number, year: number): Promise<unknown> {
        try {
            const response = await ApiService.get(`report/${storeId()}/sales?month=${month}&year=${year}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getCustomerReportRequest(month: number, year: number): Promise<unknown> {
        try {
            const response = await ApiService.get(`report/${storeId()}/customers?month=${month}&year=${year}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getOrdersReportRequest(month: number, year: number): Promise<unknown> {
        try {
            const response = await ApiService.get(`report/${storeId()}/orders?month=${month}&year=${year}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getEmployeeReportRequest(month: number, year: number): Promise<unknown> {
        try {
            const response = await ApiService.get(`report/${storeId()}/employees?month=${month}&year=${year}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }
}