import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center gap-[20px]" }
const _hoisted_2 = { class: "h-[8px] bg-bg-200 rounded-[8px] w-[100%] overflow-hidden" }
const _hoisted_3 = { class: "text-link text-caption font-semibold whitespace-nowrap" }
const _hoisted_4 = { class: "mt-[32px]" }
const _hoisted_5 = { class: "flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px]" }
const _hoisted_6 = { class: "flex items-center lg:gap-[40px] gap-[20px]" }
const _hoisted_7 = { class: "flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]" }
const _hoisted_8 = { class: "flex items-center lg:gap-[40px] gap-[20px]" }
const _hoisted_9 = { class: "flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]" }
const _hoisted_10 = { class: "flex items-center lg:gap-[40px] gap-[20px]" }
const _hoisted_11 = { class: "flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]" }
const _hoisted_12 = { class: "flex items-center lg:gap-[40px] gap-[20px]" }
const _hoisted_13 = { class: "flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]" }
const _hoisted_14 = { class: "flex items-center lg:gap-[40px] gap-[20px]" }
const _hoisted_15 = { class: "flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]" }
const _hoisted_16 = { class: "flex items-center lg:gap-[40px] gap-[20px]" }
const _hoisted_17 = { class: "flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]" }
const _hoisted_18 = { class: "flex items-center lg:gap-[40px] gap-[20px]" }

import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
// import ImageCard from '@/components/shared/image/ImageCard.vue';
import { Icon } from '@iconify/vue';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SetupGuides',
  setup(__props) {

const store = useStore();
const setupCheckListData = computed(() => store.state.setupGuide.setupCheckListData);
const setupChecked = ref(setupCheckListData.value?.setupChecklist);

onMounted(() => {
    store.dispatch('setupGuide/fetchsetupCheckListData');
})

watchEffect(() => {
    setupChecked.value = setupCheckListData.value?.setupChecklist
})


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "Setup Guides" }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _cache[7] || (_cache[7] = _createElementVNode("header", { class: "lg:pt-[72px] pt-[30px] pb-[24px] flex justify-between items-center" }, [
          _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "lg:text-heading-3 text-heading-5 font-semibold text-grey-300" }, "Setup Guide"),
            _createElementVNode("div", { class: "lg:text-body-2 text-caption text-grey-200" }, [
              _createTextVNode(" Complete your store setup with the guide below. "),
              _createElementVNode("span", { class: "font-semibold" }, "Once you reach 100%"),
              _createTextVNode(", your store will be automatically activated, and "),
              _createElementVNode("br"),
              _createTextVNode(" your tablet and microsite link will be ready. Need assistance? "),
              _createElementVNode("span", { class: "font-semibold" }, "We're here to support you.")
            ])
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "h-[8px] bg-bg-200 bg-link transition-width ease-in-out",
              style: _normalizeStyle({width: setupCheckListData.value?.progress+'%'})
            }, null, 4)
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(setupCheckListData.value?.progress) + "% Complete", 1)
        ]),
        _createElementVNode("main", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/settings?q=storeDetails" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "font-semibold lg:text-body-1 text-caption" }, "Add Store details", -1)),
                _createElementVNode("div", _hoisted_6, [
                  (setupChecked.value?.storeDetailsUpdated)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        icon: "ant-design:check-circle-filled",
                        class: "text-[#52C41A] lg:w-[28px] lg:h-[28px]"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(Icon), {
                    icon: "la:long-arrow-alt-right",
                    class: "lg:w-[24px] lg:h-[24px]"
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/menu" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "font-semibold lg:text-body-1 text-caption" }, "Add A Menu", -1)),
                _createElementVNode("div", _hoisted_8, [
                  (setupChecked.value?.menuUpdated)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        icon: "ant-design:check-circle-filled",
                        class: "text-[#52C41A] lg:w-[28px] lg:h-[28px]"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(Icon), {
                    icon: "la:long-arrow-alt-right",
                    class: "lg:w-[24px] lg:h-[24px]"
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/payments" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-semibold lg:text-body-1 text-caption" }, "Setup Payment methods", -1)),
                _createElementVNode("div", _hoisted_10, [
                  (setupChecked.value?.paymentMethodsUpdated)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        icon: "ant-design:check-circle-filled",
                        class: "text-[#52C41A] lg:w-[28px] lg:h-[28px]"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(Icon), {
                    icon: "la:long-arrow-alt-right",
                    class: "lg:w-[24px] lg:h-[24px]"
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/settings?q=deliveryOptions" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_11, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font-semibold lg:text-body-1 text-caption" }, "Setup Delivery options", -1)),
                _createElementVNode("div", _hoisted_12, [
                  (setupChecked.value?.deliveryOptionsUpdated)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        icon: "ant-design:check-circle-filled",
                        class: "text-[#52C41A] lg:w-[28px] lg:h-[28px]"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(Icon), {
                    icon: "la:long-arrow-alt-right",
                    class: "lg:w-[24px] lg:h-[24px]"
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/settings?q=payoutAccount" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "font-semibold lg:text-body-1 text-caption" }, "Add Payout Account", -1)),
                _createElementVNode("div", _hoisted_14, [
                  (setupChecked.value?.payoutUpdated)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        icon: "ant-design:check-circle-filled",
                        class: "text-[#52C41A] lg:w-[28px] lg:h-[28px]"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(Icon), {
                    icon: "la:long-arrow-alt-right",
                    class: "lg:w-[24px] lg:h-[24px]"
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/settings?q=additionalCharges" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_15, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "font-semibold lg:text-body-1 text-caption" }, "Add Additional Charges and fees", -1)),
                _createElementVNode("div", _hoisted_16, [
                  (setupChecked.value?.additionChargesUpdate)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        icon: "ant-design:check-circle-filled",
                        class: "text-[#52C41A] lg:w-[28px] lg:h-[28px]"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(Icon), {
                    icon: "la:long-arrow-alt-right",
                    class: "lg:w-[24px] lg:h-[24px]"
                  })
                ])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: "/teams" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_17, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-semibold lg:text-body-1 text-caption" }, "Setup team roles and permissions", -1)),
                _createElementVNode("div", _hoisted_18, [
                  (setupChecked.value?.teamSetupUpdated)
                    ? (_openBlock(), _createBlock(_unref(Icon), {
                        key: 0,
                        icon: "ant-design:check-circle-filled",
                        class: "text-[#52C41A] lg:w-[28px] lg:h-[28px]"
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(Icon), {
                    icon: "la:long-arrow-alt-right",
                    class: "lg:w-[24px] lg:h-[24px]"
                  })
                ])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})