import AuthService from "@/utility/services/auth.service";
import { Store } from 'vuex'; 

export const handleLogin = async (store: Store<unknown>, email: Record<string | number, unknown> | any, password: Record<string | number, unknown> | any, callback: () => void) => {
    try {
        await AuthService.loginRequest(email, password)
        .then((response) => {
            localStorage.setItem('token', response.data.data.token);
            localStorage.setItem('storeId', response.data.data.user.restaurantStoreGroups?.[0].restaurantStoreId);
        }).finally(callback)
    } catch (error: any) {
        console.error('Error logging in:', error);
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Login error', x_message: `Invalid login credentials. Check password or email and try again`, duration: 5000 })
    }
};

export const handleLogout = async (callback: () => void) => {
    try {   
        await AuthService.logoutRequest().then(() => {
            localStorage.removeItem('token');
            localStorage.removeItem('storeId');
            callback?.();
        })
    } catch (error) {
        console.error('Error logging out:', error);
    }
};

export const handleResetPassword = async (email: string) => {
    try {
        await AuthService.resetPasswordRequest(email)
    } catch (error) {
        console.error(error)
    }
}

export const handleRestNewPassword = async (store: Store<unknown>, newPassword: string, callback?: any) => {
    const currentURL = window.location.href;
    const token = currentURL.split('/').pop();

    try {
        await AuthService.setNewPasswordRequest(newPassword, token as string);
        callback?.()
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Success', x_message: 'Password changed successfully', duration: 5000 });
    } catch (error: any) {
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
};