import ApiService from './api.service';
import { storeId } from './auth.service';


export default class CustomersService {

    static async getCustomerRequest({search = '', page = '', sortBy='', sortDirection = -1, limit=100, since=0,} ): Promise<unknown> {
        try {
            const response = await ApiService.get(`store/${storeId()}/customers?search=${search}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}&limit=${limit}&since=${since}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getCustomerIdRequest({ customerId = '' } ): Promise<unknown> {
        try {
            const response = await ApiService.get(`store/${storeId()}/customers/${customerId}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getCustomerOrdersRequest({customerId = '', search = '', page = '', sortBy='', status="", sortDirection = -1, limit=100, since=0} ): Promise<unknown> {
        try {
            const _status = status === "All" ? "": status;
            const response = await ApiService.get(`store/${storeId()}/customers/${customerId}/orders?search=${search}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}&limit=${limit}&since=${since}&status=${_status}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }
}