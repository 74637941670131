<template>
    <div class="mt-[32px]">
        <div class="text-grey-200">Manage your store additional fees and charges below.</div>
        <div class="border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]">
            
            <!-- <div class="grid grid-cols-3 gap-[24px]">
                <div class="" v-for="(serviceFee, idx) in additionalChargesData?.serviceFees" :key="idx">
                    <div class="text-grey-200 text-caption font-medium capitalize">{{ serviceFee.name }}</div>
                    <div class="font-medium text-body-1 mt-[4px]">{{ serviceFee.type == 'amount' ? 'N' : '%' }} {{ serviceFee.value }}</div>
                </div>
                <div class="font-semibold text-heading-3">Menu</div>
            </div> -->
            <div class="mb-[32px]">
                <div class="flex items-center justify-between gap-[12px]">
                    <div class="font-semibold text-heading-4">Additional Charges and Fees</div>
                    <div class="flex items-center gap-[10px]">
                        <InputField type="text" placeholder="Search" prefix-icon="ant-design:search-outlined" />
                        <ActionButton 
                            :handleClick="handleOpenEditModal" 
                            theme="primary" 
                            label="New Charge" 
                            class="w-[fit-content] m-[auto]" 
                        />
                    </div>
                </div>
            </div>

            <TableField
                :headers="chargesFeesHeader" 
                :dataItems="chargesFeesData()"
                :isPagination="true"
                @openModal="(data: any) => handleOpenEditModal(data)"
            />
        </div>
    </div>

    <AppModal headerContent="New Charges/Fee" :openModal="isOpen" @close="handleOpenEditModal">
        <div class="">
            <form @submit.prevent="handleOpenPayoutUpdateModalSubmit($event)">
                <div class="p-[24px]">
                    <InputField
                        label="Name" 
                        type="text" 
                        name="name"
                        :value="updateAdditionalChargesFormHandler.values.name"
                        placeholder="Enter charge/fee name" 
                        @update:value="updateAdditionalChargesFormHandler.handleChange"
                    />

                    <div class="mt-[24px]">
                        <label class="text-body-2 text-grey-200 mb-[7px] inline-block">Avalability</label>
                        <div class="flex gap-[32px]">
                            <RadioButton value="Amount" name="type" @change="updateAdditionalChargesFormHandler.handleChange" />
                            <RadioButton value="Percentage" name="type" @change="updateAdditionalChargesFormHandler.handleChange" />
                        </div>
                    </div>

                    <InputField
                        label="Value" 
                        type="number" 
                        name="value"
                        class="mt-[24px]"
                        :prefixIcon="updateAdditionalChargesFormHandler.values.type == 'Amount' ? 'fa6-solid:naira-sign' : 'ant-design:percentage-outlined'" 
                        :value="updateAdditionalChargesFormHandler.values.value"
                        :placeholder="updateAdditionalChargesFormHandler.values.type == 'Amount' ? 'Enter Amount' : 'Enter Percentage'" 
                        @update:value="updateAdditionalChargesFormHandler.handleChange"
                    />
                </div>

                <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                    <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenEditModal" />
                    <ActionButton
                        theme="primary" 
                        class="w-[fit-content]" 
                        :isSubmit="true" 
                        :disable="loading && true" 
                        :label="loading ? 'Loading...' : 'Save'" 
                    />
                </footer>
            </form>
        </div>
    </AppModal>
</template>

<script setup lang="ts">
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import { useAdditionalChargesSettingsHelper } from './Settings.helper';
import TableField from '@/components/shared/table/TableField.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';

const { additionalChargesData, handleOpenEditModal, isOpen, loading, handleOpenPayoutUpdateModalSubmit, chargesFeesHeader, chargesFeesData, updateAdditionalChargesFormHandler } = useAdditionalChargesSettingsHelper()

// const handleChargesFeesChange = (event: any, fieldType: string) => {
//     const { name, value } = event.target;

//     // Spread the existing array to avoid mutating the original data
//     const newChargesFeesData = [ ...additionalChargesData.value?.serviceFees ];
//     let foundItem = false;

//     // Iterate over each object in the array
//     newChargesFeesData.forEach((item, index) => {
//         // Check if the current item's name matches the event's name
//         if (item.name === name) {
//             // Update the name of the matched item
//             item.name = value;
//             foundItem = true;
//         }
//         // Check if the current item's value matches the event's value
//         if (item.value === name) {
//             // Update the value of the matched item
//             item.value = value;
//             foundItem = true;
//         }
//     });

//     // If no item with matching name or value was found, add a new item
//     if (!foundItem) {
//         // Push a new object with the provided name and value
//         if (fieldType === 'name') {
//             newChargesFeesData.push({ name: value, value: '' }); // Initialize value as empty string
//         } else if (fieldType === 'value') {
//             newChargesFeesData.push({ name: '', value: value }); // Initialize name as empty string
//         }
//     }

//     console.log(newChargesFeesData);

//     // Update the state with the modified data
//     // additionalChargesData.value[chargesFeesType] = newChargesFeesData;
//     // updateAdditionalChargesFormHandler.setValues(additionalChargesData.value);
// }


</script>
