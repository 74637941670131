<template>
    <div class="flex items-center gap-[16px]">
      <ImageCard :imageUrl="!previewImageUrl ? imageUrl || dummyImage : previewImageUrl" width="86" height="86"/>
      <div class="text-center whitespace-nowrap text-body-2 font-medium" @click="openFilePicker">
        <div class="flex items-center gap-[10px] text-[#1677FF] font-semibold cursor-pointer">
          <div class="w-[fit-content]"><Icon icon="ant-design:picture-outlined" /></div>
          <div class="">Upload profile image</div>
        </div>
        <div class="text-grey-200 mt-[4px]">PNG or JPEG (Max. File Size: 2MB)</div>
      </div>
      <input
        type="file"
        :name="name"
        :value="value"
        @change="handleFileChange"
        accept="image/*"
        style="display: none;"
        ref="fileInput"
        @input="$emit('update:value', $event)"
        @blur="$emit('update:blur:value', $event)"
      />
    </div>
  </template>
  
  <script setup lang="ts">
  import { Icon } from '@iconify/vue';
  import { ref, defineProps, watchEffect, defineEmits } from 'vue';
  import ImageCard from '../image/ImageCard.vue';
  
  const fileInput = ref<HTMLInputElement | null>(null);
  const previewImageUrl = ref<string | null | any>(null);
  const dummyImage = ref('https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fconferenceoeh.com%2Fwp-content%2Fuploads%2Fprofile-pic-dummy.png&f=1&nofb=1&ipt=0240de22da1ceb5dd6f69ef26d82a4bcee544f1349c8f87b51289b4067856e67&ipo=images')
  const emit = defineEmits(['base64ImgCode', 'update:value', 'update:blur:value']);

  interface Props {
    name?: string | number | any
    value?: string | number | any
    imageUrl?: string
  }
  
  defineProps<Props>()
  
  const openFilePicker = () => {
    if (fileInput.value) {
      fileInput.value.click();
    }
  };
  
  const handleFileChange = (event: Event) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          previewImageUrl.value = reader.result;
          emit('base64ImgCode', previewImageUrl.value);
        }
      };
      reader.readAsDataURL(files[0]);
    }
  };

  watchEffect(() => {
    previewImageUrl.value
  })


  
  </script>
  