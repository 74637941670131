import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/images/empty-state.svg'


const _hoisted_1 = { class: "lg:pt-[72px] pt-[30px] pb-[42px] lg:flex justify-between items-center dashboard_responsive_head" }
const _hoisted_2 = { class: "flex justify-between items-center" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "lg:text-heading-3 text-heading-5 font-semibold text-grey-300 dashboard_responsive_header" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "charts" }
const _hoisted_7 = { class: "dashboard flex gap-[20px]" }
const _hoisted_8 = { class: "border-[1px] border-solid border-grey-50 p-[20px] rounded-[8px] w-[fit-content]" }
const _hoisted_9 = {
  key: 0,
  class: "dashboard-bar-chart mt-[31px]"
}
const _hoisted_10 = {
  key: 1,
  class: "dashboard-bar-chart lg:mt-[31px]"
}
const _hoisted_11 = {
  key: 0,
  class: "dashboard-dougnut-chart border-solid border-[1px] border-grey-50 p-[20px] rounded-[8px] max-w-[400px]"
}
const _hoisted_12 = {
  key: 0,
  class: "mt-[50px] w-[300px]"
}
const _hoisted_13 = {
  key: 1,
  class: "legend-list text-caption font-medium"
}
const _hoisted_14 = { class: "grid grid-cols-3 gap-[20px] min-w-[845px]" }
const _hoisted_15 = { class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" }
const _hoisted_16 = { class: "flex gap-[8px] items-center justify-center" }
const _hoisted_17 = { class: "lg:text-body-2 text-caption font-medium text-grey-300" }
const _hoisted_18 = { class: "mt-[16px] font-semibold lg:text-heading-5 text-body-1" }
const _hoisted_19 = { class: "border-[1px] border-solid border-grey-50 h-[8px] mt-[8px] rounded-[8px] w-[114px] m-[auto]" }
const _hoisted_20 = { class: "mt-[42px] responsive_dashboard_best_selling" }
const _hoisted_21 = { class: "flex justify-between items-center" }
const _hoisted_22 = { class: "flex text-grey-200 items-center gap-[10px] lg:py-[11px] lg:px-[16px] py-[5px] px-[6px] border-[1px] border-solid border-grey-50 rounded-[10px] cursor-pointer responsive_dashboard_best_selling_button" }
const _hoisted_23 = {
  key: 0,
  class: "bar-chart-alt flex w-[100%] border-[1px] mt-[30px] h-[12px] bg-bg-200"
}
const _hoisted_24 = { key: 1 }
const _hoisted_25 = {
  key: 2,
  class: "w-[100%] h-[12px] border-[1px] mt-[30px] flex"
}
const _hoisted_26 = {
  key: 3,
  class: "bar-chart-alt flex lg:gap-[90px] w-[100%]"
}
const _hoisted_27 = { class: "mt-[10px] flex gap-[10px] items-center" }
const _hoisted_28 = { class: "lg:text-body-2 text-overline" }
const _hoisted_29 = { class: "ml-[20px] text-grey-200 lg:text-body-2 text-overline" }
const _hoisted_30 = { class: "mt-[42px]" }
const _hoisted_31 = { class: "flex justify-between items-center" }
const _hoisted_32 = { class: "flex text-grey-200 items-center gap-[10px] lg:py-[11px] lg:px-[16px] py-[5px] px-[6px] border-[1px] border-solid border-grey-50 rounded-[10px] cursor-pointer" }
const _hoisted_33 = { class: "my-[28px] text-caption" }

import { Icon } from '@iconify/vue';
import { useDashboardHelper } from './helper/Dashboard.helper'
import TableField from '@/components/shared/table/TableField.vue';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DashBoard',
  setup(__props) {

const { doughnutChartData, filterTabs, activateTab, customersOverview, customersItemsOverview, activeTab, AppWrapper, headers, storeData } = useDashboardHelper()
const store = useStore();
const profileData = computed(() => store.state.profile.profileData);
const showFilterMobile = ref(false);
const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value

const isMobile = ref(window.innerWidth < 768);

const filteredOrderHeaders = computed(() => {
    if (!headers) return [];
    if (isMobile.value) {
        return headers.filter(header => !['orderDate', 'paymentMethod'].includes(header.value));
    }
    return headers;
});

window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768;
});


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Dashboard" }, {
    default: _withCtx(() => [
      _createElementVNode("header", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, "Hi " + _toDisplayString(profileData.value?.firstName) + ",", 1),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-body-2 text-grey-200 dashboard_responsive_sub_header" }, "Welcome to your dashboard", -1))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden", showFilterMobile.value && 'bg-primary-300 text-white']),
            onClick: handleShowMobileFilter
          }, [
            _createVNode(_unref(Icon), {
              class: "",
              icon: "fluent:options-20-filled",
              width: "20",
              height: "20"
            })
          ], 2)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["lg:flex gap-[32px] lg:mt-0 mt-[8px] lg:border-b-[1px] border-solid dashboard_responsive_tab", !showFilterMobile.value ? 'hidden' : 'block' ])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filterTabs), (tab) => {
            return (_openBlock(), _createElementBlock("div", {
              key: tab?.since,
              class: _normalizeClass(['py-[12px] cursor-pointer lg:text-body-2 text-caption text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab).since === tab.since }]),
              onClick: ($event: any) => (_unref(activateTab)(tab))
            }, _toDisplayString(tab?.label), 11, _hoisted_5))
          }), 128))
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-grey-300 lg:text-body-1 text-body-2 font-medium" }, "Store Traffic", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "lg:text-body-2 text-caption text-grey-200 font-regular" }, "Track the number of customer who visited your store.", -1)),
            (_unref(customersOverview)?.[1]?.customersNo != 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[1] || (_cache[1] = [
                  _createElementVNode("canvas", {
                    id: "barChart",
                    class: "bar-chart"
                  }, null, -1)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[2] || (_cache[2] = [
                  _createElementVNode("img", {
                    src: _imports_0,
                    class: "mx-[auto]",
                    width: "185",
                    height: "152"
                  }, null, -1),
                  _createElementVNode("div", { class: "text-center text-grey-100 text-body-2 mt-[40px] px-[82px] pb-[20px]" }, "This space will feature a pie chart showcasing your order distribution once data is available.", -1)
                ])))
          ]),
          (_unref(doughnutChartData))
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "lg:text-body-1 text-body-2 font-medium mb-[30px]" }, " Most used channel ", -1)),
                (!_unref(doughnutChartData)?.labels?.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[5] || (_cache[5] = [
                      _createElementVNode("img", {
                        src: _imports_0,
                        class: "mx-[auto]",
                        width: "185",
                        height: "152"
                      }, null, -1),
                      _createElementVNode("div", { class: "text-center text-grey-100 text-body-2 mt-[40px]" }, "This space will feature a pie chart showcasing your order distribution once data is available.", -1)
                    ])))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(["px-[75px]", _unref(doughnutChartData)?.labels?.length == 0 && 'hidden'])
                }, _cache[6] || (_cache[6] = [
                  _createElementVNode("canvas", {
                    id: "doughnutChart",
                    class: "doughnut-chart"
                  }, null, -1)
                ]), 2),
                (_unref(doughnutChartData)?.labels?.length)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(doughnutChartData).labels, (label, index) => {
                        return (_openBlock(), _createElementBlock("li", { key: index }, [
                          _createElementVNode("span", {
                            style: _normalizeStyle({ backgroundColor: _unref(doughnutChartData).datasets[0].backgroundColor[index] })
                          }, null, 4),
                          _createTextVNode(" " + _toDisplayString(label), 1)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_14, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(customersOverview), (customer, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "text-center py-[23px] whitespace-nowrap border-[1px] border-solid border-grey-50 rounded-[8px] w-[270px] relative"
              }, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_unref(Icon), {
                      style: _normalizeStyle({color: customer.color}),
                      icon: customer.icon,
                      width: "24",
                      height: "24"
                    }, null, 8, ["style", "icon"]),
                    _createElementVNode("div", _hoisted_17, _toDisplayString(customer.type), 1)
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(['text-caption', {'text-success': customer.percAmount > 0, 'text-error': customer.percAmount < 0 }])
                  }, _toDisplayString(customer.percAmount) + "%", 3),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(customer.customersNo), 1),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", {
                      style: _normalizeStyle({background: `${customer.color}`, width: `${customer.progressPerc}%`}),
                      class: "h-[6px] rounded-[8px]"
                    }, null, 4)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "lg:text-heading-5 font-semibold text-grey-300 responsive_dashboard_best_selling_header" }, "Best selling Items"),
            _createElementVNode("div", { class: "lg:text-body-2 text-overline text-grey-200 responsive_dashboard_best_selling_sub_header" }, "Know your best selling items and maintain it.")
          ], -1)),
          _createVNode(_component_router_link, { to: { name: 'BestSelling' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_22, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "font-medium text-grey-300 lg:text-body-2 text-overline responsive_dashboard_best_selling_button_text" }, "All Items", -1)),
                _createVNode(_unref(Icon), {
                  icon: "la:long-arrow-alt-right",
                  width: "24",
                  height: "24",
                  class: "w-[16px] h-[16px]"
                })
              ])
            ]),
            _: 1
          })
        ]),
        (!_unref(customersItemsOverview).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_23))
          : _createCommentVNode("", true),
        (!_unref(customersItemsOverview).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_24, _cache[10] || (_cache[10] = [
              _createElementVNode("div", { class: "mt-[10px] flex gap-[10px] items-center" }, [
                _createElementVNode("div", { class: "w-[10px] h-[10px] bg-bg-300 rounded-[50%]" }),
                _createElementVNode("div", { class: "" }, _toDisplayString(0))
              ], -1),
              _createElementVNode("div", { class: "ml-[20px] text-grey-200 text-body-2" }, "No available data", -1)
            ])))
          : _createCommentVNode("", true),
        (_unref(customersItemsOverview).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(customersItemsOverview), (chartData, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  style: _normalizeStyle({background: `${chartData.color}`, width: `${chartData.progressPerc}%`}),
                  class: "h-[10px]"
                }, null, 4))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_unref(customersItemsOverview).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(customersItemsOverview), (chartData, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", {
                      style: _normalizeStyle({background: `${chartData.color}`}),
                      class: "w-[10px] h-[10px] rounded-[50%]"
                    }, null, 4),
                    _createElementVNode("div", _hoisted_28, _toDisplayString(chartData.count), 1)
                  ]),
                  _createElementVNode("div", _hoisted_29, _toDisplayString(chartData.item), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "lg:text-heading-5 font-semibold text-grey-300" }, "Recent orders"),
            _createElementVNode("div", { class: "lg:text-body-2 text-overline text-grey-200" }, "See your items that are sold recently.")
          ], -1)),
          _createVNode(_component_router_link, { to: { name: 'Orders' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_32, [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "font-medium text-grey-300 lg:text-body-2 text-overline" }, "All orders", -1)),
                _createVNode(_unref(Icon), {
                  icon: "la:long-arrow-alt-right",
                  width: "24",
                  height: "24",
                  class: "w-[16px] h-[16px]"
                })
              ])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createVNode(TableField, {
            headers: filteredOrderHeaders.value,
            dataItems: _unref(storeData),
            isPagination: true,
            viewDetails: true,
            routeData: {name: 'OrderDetails', paramId: 'orderId'}
          }, null, 8, ["headers", "dataItems"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})