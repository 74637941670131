<template>
    <AppWrapper activeTab="Setup Guides">
        <div>
            <header class="lg:pt-[72px] pt-[30px] pb-[24px] flex justify-between items-center">
                <div class="">
                    <div class="lg:text-heading-3 text-heading-5 font-semibold text-grey-300">Setup Guide</div>
                    <div class="lg:text-body-2 text-caption text-grey-200">
                        Complete your store setup with the guide below. <span class="font-semibold">Once you reach 100%</span>, 
                        your store will be automatically activated, and <br /> your tablet and microsite link will be ready. 
                        Need assistance? <span class="font-semibold">We're here to support you.</span>
                    </div>
                </div>
            </header>

            <div class="flex items-center gap-[20px]">
                <div class="h-[8px] bg-bg-200 rounded-[8px] w-[100%] overflow-hidden">
                    <div class="h-[8px] bg-bg-200 bg-link transition-width ease-in-out" :style="{width: setupCheckListData?.progress+'%'}"></div>
                </div>
                <div class="text-link text-caption font-semibold whitespace-nowrap">{{ setupCheckListData?.progress }}% Complete</div>
            </div>

            <main class="mt-[32px]">
                <router-link to="/settings?q=storeDetails">
                    <div class="flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px]">
                        <div class="font-semibold lg:text-body-1 text-caption">Add Store details</div>
                        <div class="flex items-center lg:gap-[40px] gap-[20px]">
                            <Icon icon="ant-design:check-circle-filled" class="text-[#52C41A] lg:w-[28px] lg:h-[28px]" v-if="setupChecked?.storeDetailsUpdated" />
                            <Icon icon="la:long-arrow-alt-right" class="lg:w-[24px] lg:h-[24px]" />
                        </div>
                    </div>
                </router-link>
             
                <router-link to="/menu">
                    <div class="flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]">
                        <div class="font-semibold lg:text-body-1 text-caption">Add A Menu</div>
                        <div class="flex items-center lg:gap-[40px] gap-[20px]">
                            <Icon icon="ant-design:check-circle-filled" class="text-[#52C41A] lg:w-[28px] lg:h-[28px]" v-if="setupChecked?.menuUpdated" />
                            <Icon icon="la:long-arrow-alt-right" class="lg:w-[24px] lg:h-[24px]" />
                        </div>
                    </div>
                </router-link>

                <router-link to="/payments">
                    <div class="flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]">
                        <div class="font-semibold lg:text-body-1 text-caption">Setup Payment methods</div>
                        <div class="flex items-center lg:gap-[40px] gap-[20px]">
                            <Icon icon="ant-design:check-circle-filled" class="text-[#52C41A] lg:w-[28px] lg:h-[28px]" v-if="setupChecked?.paymentMethodsUpdated" />
                            <Icon icon="la:long-arrow-alt-right" class="lg:w-[24px] lg:h-[24px]" />
                        </div>
                    </div>
                </router-link>

                <router-link to="/settings?q=deliveryOptions">
                    <div class="flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]">
                        <div class="font-semibold lg:text-body-1 text-caption">Setup Delivery options</div>
                        <div class="flex items-center lg:gap-[40px] gap-[20px]">
                            <Icon icon="ant-design:check-circle-filled" class="text-[#52C41A] lg:w-[28px] lg:h-[28px]" v-if="setupChecked?.deliveryOptionsUpdated" />
                            <Icon icon="la:long-arrow-alt-right" class="lg:w-[24px] lg:h-[24px]" />
                        </div>
                    </div>
                </router-link>

                <router-link to="/settings?q=payoutAccount">
                    <div class="flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]">
                        <div class="font-semibold lg:text-body-1 text-caption">Add Payout Account</div>
                        <div class="flex items-center lg:gap-[40px] gap-[20px]">
                            <Icon icon="ant-design:check-circle-filled" class="text-[#52C41A] lg:w-[28px] lg:h-[28px]" v-if="setupChecked?.payoutUpdated" />
                            <Icon icon="la:long-arrow-alt-right" class="lg:w-[24px] lg:h-[24px]" />
                        </div>
                    </div>
                </router-link>

                <router-link to="/settings?q=additionalCharges">
                    <div class="flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]">
                        <div class="font-semibold lg:text-body-1 text-caption">Add Additional Charges and fees</div>
                        <div class="flex items-center lg:gap-[40px] gap-[20px]">
                            <Icon icon="ant-design:check-circle-filled" class="text-[#52C41A] lg:w-[28px] lg:h-[28px]" v-if="setupChecked?.additionChargesUpdate" />
                            <Icon icon="la:long-arrow-alt-right" class="lg:w-[24px] lg:h-[24px]" />
                        </div>
                    </div>
                </router-link>

                <router-link to="/teams">
                    <div class="flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]">
                        <div class="font-semibold lg:text-body-1 text-caption">Setup team roles and permissions</div>
                        <div class="flex items-center lg:gap-[40px] gap-[20px]">
                            <Icon icon="ant-design:check-circle-filled" class="text-[#52C41A] lg:w-[28px] lg:h-[28px]" v-if="setupChecked?.teamSetupUpdated" />
                            <Icon icon="la:long-arrow-alt-right" class="lg:w-[24px] lg:h-[24px]" />
                        </div>
                    </div>
                </router-link>
       
                <!-- <router-link to="">
                    <div class="flex items-center justify-between lg:py-[24px] py-[14px] px-[10px] lg:px-[20px] border-[1px] border-grey-50 rounded-[8px] mt-[20px]">
                        <div class="font-semibold lg:text-body-1 text-caption">Lunch your store</div>
                        <div class="flex items-center lg:gap-[40px] gap-[20px]">
                            <Icon icon="ant-design:check-circle-filled" class="text-[#52C41A] lg:w-[28px] lg:h-[28px]" v-if="false" />
                            <Icon icon="la:long-arrow-alt-right" class="lg:w-[24px] lg:h-[24px]" />
                        </div>
                    </div>
                </router-link> -->
            </main> 

            <!-- <div class="absolute bg-primary-300 rounded-[50%] w-[54px] h-[54px] bottom-[40px] right-[40px] shadow-md hover:shadow-lg animate-bounce-slow cursor-pointer">
                <ImageCard :imageUrl="require('@/assets/images/icons/support-team.svg')" width="32" height="32" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]" />
            </div> -->
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
// import ImageCard from '@/components/shared/image/ImageCard.vue';
import { Icon } from '@iconify/vue';
import { useStore } from 'vuex';
import { computed, onMounted, ref, watchEffect } from 'vue';

const store = useStore();
const setupCheckListData = computed(() => store.state.setupGuide.setupCheckListData);
const setupChecked = ref(setupCheckListData.value?.setupChecklist);

onMounted(() => {
    store.dispatch('setupGuide/fetchsetupCheckListData');
})

watchEffect(() => {
    setupChecked.value = setupCheckListData.value?.setupChecklist
})

</script>

<style scoped>
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.animate-bounce-slow {
  animation: bounce 2s infinite ease-in-out;
}

.transition-width {
    transition: width 0.5s ease-in-out; 
}

</style>