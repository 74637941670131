import PaymentService from "../services/payment.service";
import { useForm } from "./useForm";

export const usePaymentFormHandler = (store?: any) => {

    const updatePaymentsHandler = useForm({
        initialValues: {},

        onSubmit: async (values: any, paymentType?: string) => {
            try {
                await PaymentService.updatePaymentsRequest({...values?.[paymentType as string], type: paymentType}, store)
            } catch (error) {
                console.error("update cash payment error:", error);
            }
        }
    })

    return { updatePaymentsHandler }
}