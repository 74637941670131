import { Module, VuexModule, MutationAction } from 'vuex-module-decorators';
import { useAppWrapperHelper } from '@/components/app/app-wrapper/AppWrapper.helper';

export interface StoreProfileState {
  storeProfile: any
  loading: boolean
}

@Module({ namespaced: true })
export default class StoreProfileModule extends VuexModule implements StoreProfileState {
  storeProfile = null
  loading = true;

  @MutationAction
  async fetchStoreDataData() {
    try {
      const { getStores } = useAppWrapperHelper()
      const storesProfile: any = await getStores()
      const currentStoreId = localStorage.getItem('storeId')
      const currentStore = storesProfile?.stores?.find((profile: any) => profile.id === currentStoreId)

      return {
        storeProfile: currentStore,
        loading: false
      };
    } catch (error) {
      console.error('Error fetching store data:', error);
      return {
        storeProfile: null,
        loading: false
      };
    }
  }
}
