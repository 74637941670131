import ApiService from './api.service';
import { storeId } from './auth.service';


export default class OrdersService {

    static async getOrdersRequest({search = '',  sortBy='', sortDirection = 1, status = '', cashier = '', category = '', paymentMethod = '', page = 1, limit=100, startDate='', endDate='', since=0 } ): Promise<any> {
        try {
            const _status = status === "All" ? "": status;
            const response = await ApiService.get(`store/${storeId()}/orders?search=${search}&page=${page}&sortBy=${sortBy}&sortDirection=${sortDirection}&limit=${limit}&status=${_status}&cashier=${cashier}&category=${category}&paymentMethod=${paymentMethod}&startDate=${startDate}&endDate=${endDate}&since=${since}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

    static async getCashiersRequest(): Promise<unknown> {
        const response = await ApiService.get(`store/${storeId()}/cashier`)
        return response.data.data
    }

    static async getOrderByIdRequest({ orderId = '' } ): Promise<any> {
        try {
            const response = await ApiService.get(`store/${storeId()}/orders/${orderId}`)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    }

}