import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]" }
const _hoisted_3 = { class: "flex items-center justify-between border-b-[1px] border-grey-50 pb-[12px]" }
const _hoisted_4 = { class: "grid grid-cols-3 gap-[24px] mt-[28px]" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "p-[24px]" }
const _hoisted_7 = { class: "grid grid-cols-2 gap-[20px] mt-[8px]" }
const _hoisted_8 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }

import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import AppModal from '@/components/shared/modal/AppModal.vue';
import ToggleSlider from '@/components/shared/toggle-slider/ToggleSlider.vue';
import { useAdditionalChargesSettingsHelper } from './Settings.helper';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeliveryOption',
  setup(__props) {

const { additionalChargesData, handleOpenEditModal, isOpen, loading, handleOpenPayoutUpdateModalSubmit, updateAdditionalCharge } = useAdditionalChargesSettingsHelper()


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-grey-200" }, "Manage your store Delivery options below", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("header", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-semibold text-sub-heading" }, "Delivery Options", -1)),
          _createVNode(ToggleSlider)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"\"><div class=\"text-grey-200 text-caption font-medium capitalize\">State</div><div class=\"font-medium text-body-1 mt-[4px]\">Lagos</div></div><div class=\"\"><div class=\"text-grey-200 text-caption font-medium capitalize\">LGA</div><div class=\"font-medium text-body-1 mt-[4px]\">Oshodi</div></div>", 2)),
          _createElementVNode("div", {
            class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] py-[11px] w-[fit-content] text-body-2 cursor-pointer ml-[auto]",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(handleOpenEditModal) && _unref(handleOpenEditModal)(...args)))
          }, "Update"),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "text-grey-200 text-caption font-medium capitalize" }, "Street Address"),
            _createElementVNode("div", { class: "font-medium text-body-1 mt-[4px]" }, "BLK 15 26 Ayoade olubowale Cres. ")
          ], -1))
        ]),
        _cache[5] || (_cache[5] = _createStaticVNode("<div class=\"mt-[24px]\"><div class=\"text-grey-200 text-caption font-medium capitalize\">Deliverable States and Areas</div><div class=\"font-medium text-body-1 mt-[4px]\">Table Here...</div></div><div class=\"mt-[24px]\"><div class=\"text-grey-200 text-caption font-medium capitalize\">Delivery distances and fees</div><div class=\"font-medium text-body-1 mt-[4px]\">Table Here...</div></div>", 2))
      ])
    ]),
    _createVNode(AppModal, {
      headerContent: "Update Store information",
      openModal: _unref(isOpen),
      onClose: _unref(handleOpenEditModal)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("form", {
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(handleOpenPayoutUpdateModalSubmit)($event)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(additionalChargesData)?.serviceFees, (serviceFee, idx) => {
                  return (_openBlock(), _createElementBlock("div", { key: idx }, [
                    _createVNode(InputField, {
                      label: serviceFee.name,
                      type: "text",
                      value: serviceFee.value,
                      name: serviceFee.name,
                      placeholder: `Enter ${serviceFee.name}`,
                      "onUpdate:value": (event) => _unref(updateAdditionalCharge)(serviceFee.name, event.target.value)
                    }, null, 8, ["label", "value", "name", "placeholder", "onUpdate:value"])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("footer", _hoisted_8, [
              _createVNode(ActionButton, {
                label: "Cancel",
                theme: "secondary",
                class: "w-[fit-content]",
                onClick: _unref(handleOpenEditModal)
              }, null, 8, ["onClick"]),
              _createVNode(ActionButton, {
                theme: "primary",
                class: "w-[fit-content]",
                isSubmit: true,
                disable: _unref(loading) && true,
                label: _unref(loading) ? 'Loading...' : 'Save'
              }, null, 8, ["disable", "label"])
            ])
          ], 32)
        ])
      ]),
      _: 1
    }, 8, ["openModal", "onClose"])
  ], 64))
}
}

})