import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "p-[24px]" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-[20px] mt-[8px]" }
const _hoisted_4 = { class: "mt-[32px]" }
const _hoisted_5 = { class: "flex items-center gap-[10px] mt-[8px]" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }

import {  ref } from 'vue'
import { addToInputList, deleteFromInputList } from '@/utility/helper/utils.helper';
import { useSettingsHelper } from '../settings/Settings.helper';
import AppModal from '@/components/shared/modal/AppModal.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import { Icon } from '@iconify/vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { useStore } from 'vuex';

interface Props {
    createStoreFormHandler: any
    isOpen: boolean
    handleOpenEditModal: () => void
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateStoreForm',
  props: {
    createStoreFormHandler: {},
    isOpen: { type: Boolean },
    handleOpenEditModal: { type: Function }
  },
  setup(__props: any) {

const props = __props

const { businessStatusOptions } = useSettingsHelper()
const loading = ref(false)
const store = useStore()

const dataProp = ref({
    callback: props.handleOpenEditModal,
    store: store
})

const  handleSubmitCreateStore = async (event: Event) => {
    loading.value = true
    await props.createStoreFormHandler?.handleSubmit(event, dataProp)
    loading.value = false
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(AppModal, {
      headerContent: "Create New Store",
      openModal: _ctx.isOpen,
      onClose: _ctx.handleOpenEditModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("form", {
            onSubmit: _withModifiers(handleSubmitCreateStore, ["prevent"])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(InputField, {
                  label: "Store Name",
                  type: "text",
                  name: "name",
                  placeholder: "Store Name",
                  value: _ctx.createStoreFormHandler?.values?.name,
                  "onUpdate:blur:value": _ctx.createStoreFormHandler.handleBlur,
                  "onUpdate:value": _ctx.createStoreFormHandler.handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Email",
                  type: "email",
                  placeholder: "Email",
                  name: "email",
                  value: _ctx.createStoreFormHandler?.values?.email,
                  "onUpdate:blur:value": _ctx.createStoreFormHandler.handleBlur,
                  "onUpdate:value": _ctx.createStoreFormHandler.handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Phone Number",
                  type: "tel",
                  placeholder: "080 123 456",
                  name: "phone",
                  value: _ctx.createStoreFormHandler?.values?.phone,
                  "onUpdate:blur:value": _ctx.createStoreFormHandler.handleBlur,
                  "onUpdate:value": _ctx.createStoreFormHandler.handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "Established Date",
                  type: "date",
                  name: "establishDate",
                  placeholder: "Established Date",
                  value: _ctx.createStoreFormHandler?.values?.establishDate,
                  "onUpdate:blur:value": _ctx.createStoreFormHandler.handleBlur,
                  "onUpdate:value": _ctx.createStoreFormHandler.handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "State",
                  type: "text",
                  name: "address.state",
                  placeholder: "",
                  value: _ctx.createStoreFormHandler?.values?.address?.state,
                  "onUpdate:blur:value": _ctx.createStoreFormHandler.handleBlur,
                  "onUpdate:value": _ctx.createStoreFormHandler.handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(InputField, {
                  label: "LGA",
                  type: "text",
                  placeholder: "",
                  name: "address.lga",
                  value: _ctx.createStoreFormHandler?.values?.address?.lga,
                  "onUpdate:blur:value": _ctx.createStoreFormHandler.handleBlur,
                  "onUpdate:value": _ctx.createStoreFormHandler.handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
              ]),
              _createVNode(InputField, {
                label: "Street Address",
                type: "text",
                placeholder: "Street Address",
                name: "address.street",
                class: "mt-[32px]",
                value: _ctx.createStoreFormHandler?.values?.address?.street,
                "onUpdate:blur:value": _ctx.createStoreFormHandler.handleBlur,
                "onUpdate:value": _ctx.createStoreFormHandler.handleChange
              }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(DropdownField, {
                  label: "Business Status",
                  name: "businessStatus",
                  value: _ctx.createStoreFormHandler?.values?.businessStatus,
                  optionsData: _unref(businessStatusOptions),
                  onChange: _ctx.createStoreFormHandler.handleChange
                }, null, 8, ["value", "optionsData", "onChange"])
              ]),
              _createVNode(InputField, {
                label: "Restaurant Type",
                name: "restaurantType",
                type: "text",
                placeholder: "Enter Restaurant Type",
                class: "mt-[32px]",
                onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers((event) => _unref(addToInputList)(event, _ctx.createStoreFormHandler?.values, _ctx.createStoreFormHandler), ["prevent"]), ["enter"])),
                undertext: "Tap \"Enter Key\" to add more restaurant type"
              }),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.createStoreFormHandler?.values?.restaurantType, (rt, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption",
                    key: idx
                  }, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(rt), 1),
                    _createVNode(_unref(Icon), {
                      icon: "ant-design:close-outlined",
                      width: "12",
                      height: "12",
                      onClick: () => _unref(deleteFromInputList)('restaurantType', idx, _ctx.createStoreFormHandler?.values, _ctx.createStoreFormHandler)
                    }, null, 8, ["onClick"])
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("footer", _hoisted_7, [
              _createVNode(ActionButton, {
                label: "Cancel",
                theme: "secondary",
                class: "w-[fit-content]",
                onClick: _ctx.handleOpenEditModal
              }, null, 8, ["onClick"]),
              _createVNode(ActionButton, {
                theme: "primary",
                class: "w-[fit-content]",
                isSubmit: true,
                disable: loading.value && true,
                label: loading.value ? 'Loading...' : 'Save'
              }, null, 8, ["disable", "label"])
            ])
          ], 32)
        ])
      ]),
      _: 1
    }, 8, ["openModal", "onClose"])
  ]))
}
}

})