<template>
    <div class="overflow-auto no-scrollbar border-[1px] border-solid border-grey-50 rounded-[8px] p-[1px] responsive_table">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-bg-200">
                <tr>
                    <th v-for="(header, index) in headers" :key="index" class="px-[20px] py-[17px] text-left lg:text-body-2 text-caption font-medium text-gray-500 capitalize tracking-wider">
                        <div class="flex items-center justify-between">
                            <div>{{ header.name }}</div>
                            <Icon v-if="header?.icon" :icon="header?.icon" class="ml-1" width="24" height="24" />
                        </div>
                    </th>

                    <th class="px-[20px] py-[17px] text-left text-xs font-medium text-gray-400 capitalize tracking-wider" v-if="viewDetails">
                        <div class=""></div>
                    </th>

                    <th class="px-[20px] py-[17px] text-left text-xs font-medium text-gray-400 capitalize tracking-wider" v-if="editDetails">
                        <div class=""></div>
                    </th>
                </tr>

                <tr v-if="loading">
                    <td :colspan="headers.length + (viewDetails ? 1 : 0)">
                        <TableLoader :loading="loading" />
                    </td>
                </tr>
            </thead>

            <tbody v-if="!loading" class="bg-white divide-y divide-gray-200">
                <tr v-for="(data, index) in paginatedDataItems" :key="index" class="hover:bg-gray-50 lg:text-body-2 text-caption">
                    <td v-for="(header, hIndex) in headers" :key="hIndex" class="p-[20px]">
                        <template v-if="header.value === 'entityCard'">
                            <EntityCard 
                                :name="data.name" 
                                :description="data.description" 
                                :image-url= "data.imageUrl"
                                :sub-description="data.subDescription" 
                            />
                        </template>

                        <template v-if="header.value === 'statusCard'">
                            <StatusLabel :status="data.status" />
                        </template>

                        <template v-if="header.value === 'OrderDetails'">
                            <div class="w-fit">
                                <div v-for="(details, idx) in data?.orderDetails" :key="idx" :class="{'border-b border-gray-200 pb-5 mb-3': idx < data.orderDetails.length - 1}">
                                    <div class="">
                                        <span class="text-grey-200 text-caption font-semibold">Menu Name: </span> 
                                        <span class="text-caption text-grey-300">{{ details?.menuName }}</span>
                                    </div> 

                                    <div class="">
                                        <span class="text-grey-200 text-caption font-semibold">Category: </span> 
                                        <span class="text-caption text-grey-300">{{ details?.category }}</span>
                                    </div> 

                                    <div class="">
                                        <span class="text-grey-200 text-caption font-semibold">Quantity: </span> 
                                        <span class="text-caption text-grey-300">{{ details?.quantity }}</span>
                                    </div> 

                                    <div class="">
                                        <span class="text-grey-200 text-caption font-semibold">Price: </span> 
                                        <span class="text-caption text-grey-300">₦{{ details?.price.toLocaleString() }}</span>
                                    </div> 

                                    <div class="" v-if=details?.note>
                                        <span class="text-grey-200 text-caption font-semibold">Note: </span> 
                                        <span class="text-caption text-grey-300">{{ details?.note || 'N/A' }}</span>
                                    </div> 
                                </div>
                                
                                <!-- <div class="border-t border-gray-200 pt-2 mt-2">
                                    <div class="">
                                        <span class="text-grey-200 text-caption font-semibold">Customer name: </span> 
                                        <span class="text-caption text-grey-300">{{ data?.customer || 'N/A' }}</span>
                                    </div> 
                                    <div class="">
                                        <span class="text-grey-200 text-caption font-semibold">Payment Method: </span> 
                                        <span class="text-caption text-grey-300">{{ data?.paymentMethod || 'N/A' }}</span>
                                    </div> 
                                    <div class="">
                                        <span class="text-grey-200 text-caption font-semibold">cashier: </span> 
                                        <span class="text-caption text-grey-300">{{ data?.cashier || 'N/A' }}</span>
                                    </div> 
                                </div> -->
                            </div>
                        </template>

                        <template v-if="header.value === 'available'">
                            <ToggleSlider name="status" :isChecked="data.isAvailable" @on:change="$emit('handleToogleChange', data)" />
                        </template>

                        <template v-if="header.value === 'action'">
                            <router-link :to="{ name: routeData?.name, params: { id: data?.[routeData?.paramId as any] }}">
                                <div class="p-[10px] bg-bg-200 w-[fit-content] rounded-[4px] cursor-pointer">
                                    <Icon icon="ant-design:edit-outlined" class="ml-1 text-grey-200" width="20" height="20" />
                                </div>
                            </router-link>
                        </template>

                        <template v-if="header.value === 'action-callback'">
                            <div class="p-[10px] bg-bg-200 w-[fit-content] rounded-[4px] cursor-pointer" @click="$emit('openModal', data)">
                                <Icon icon="ant-design:edit-outlined" class="ml-1 text-grey-200" width="20" height="20" />
                            </div>
                        </template>
                        
                        <template v-if="header.value === 'orderEntity'">
                            <h5 class="text-body-1">{{ data.orderNumber }}</h5>
                            <p class="text-grey-200 text-body-3 mt-[4px]">{{ data.orderDate}}</p>
                        </template>
                        
                        <template v-else>
                            {{ data?.[header.value as any] }}
                        </template>
                    </td>

                    <td class="p-[20px]" v-if="editDetails">
                        <router-link 
                            :to="{ 
                                name: `${routeData?.name}`, 
                                params: { id: data?.[routeData?.paramId as any] }, 
                                query: { search: uriQuery } 
                            }" 
                            @click="$emit('handleViewDetailsClick', data)"
                        >
                            <div class="text-caption border-[1px] border-solid w-[fit-content] rounded-[8px] px-[10px] py-[5px] m-[auto]">
                                <div v-if="editDetailsText">{{ editDetailsText }}</div>
                                <Icon icon="ant-design:edit-outlined" class="ml-1 text-grey-200" width="20" height="20" v-else />
                            </div>
                        </router-link>
                    </td>

                    <td class="p-[20px]" v-if="viewDetails">
                        <router-link :to="{ name:  routeData?.name, params: { id: data?.[routeData?.paramId as any] }}" @click="$emit('handleViewDetailsClick', data)">
                            <div class="text-caption border-[1px] border-solid w-[fit-content] rounded-[8px] px-[10px] py-[5px] m-[auto]">{{ viewDetailsText || 'View Details' }}</div>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="text-center my-[50px]" v-if="!loading && dataItems?.length == 0">
            <img src="@/assets/images/images/empty-state.svg" class="mx-[auto]" width="185" height="152" />
        </div>
    </div>
  
    <div class="lg:block" v-if="isPagination && dataItems?.length">
        <TablePagination
            :paginationMode="pagination as 'client' as 'server'"
            v-model:currentPage="currentPage"
            :totalItems="pagination === 'server' ? totalItems : dataItems || 0"
            :pageSize="pageSize"
            @pageChange="handlePageChange"
            @pageSizeChange="handlePageSizeChange"
        />
    </div>
  </template>
  
  <script setup lang="ts">
  import { computed, defineProps, ref, defineEmits, watchEffect, watch } from 'vue';
  import { Icon } from '@iconify/vue';
  import { Header, DataItem, RouteData } from './TableField.helper';
  import EntityCard from '@/components/shared/entity-card/EntityCard.vue';
  import TablePagination from '../pagination/TablePagination.vue';
  import StatusLabel from '@/components/shared/status-label/StatusLabel.vue';
  import ToggleSlider from '../toggle-slider/ToggleSlider.vue';
  import TableLoader from '../table-loader/TableLoader.vue';
  
  interface Props {
    headers: Header[]
    dataItems: DataItem[] | any
    isPagination?: boolean
    routeData?: RouteData
    viewDetails?: boolean
    viewDetailsText?: string
    handleViewDetailsClick?: () => void
    currentPage?: number
    totalItems?: number
    loading?: boolean
    editDetails?: boolean
    editDetailsText?: string
    uriQuery?: string
    pagination?: 'client' | 'server'

  }
  
  const props = defineProps<Props>();

  const pagination = ref(props.pagination || 'client');
  
  const currentPage = ref(1);
  const pageSize = ref(10);
  const emit = defineEmits(['currentPage', 'pageSize', 'handleToogleChange', 'openModal', 'handleViewDetailsClick']);
  
  watchEffect(() => {
    emit('currentPage', currentPage.value);
    emit('pageSize', pageSize.value);
    // console.log({ rrI: props.totalItems });
  });
  
  const paginatedDataItems = computed(() => {
    const startIndex = (currentPage.value - 1) * pageSize.value;
    const endIndex = startIndex + pageSize.value;
    return props.dataItems?.slice(startIndex, endIndex);
  });
  
  const handlePageChange = (page: number) => {
    console.log('Page change:', page);
    props.pagination === 'server' ? null : currentPage.value = page;
    emit('currentPage', page);
  };
  
  watch(() => props.currentPage, (newPage) => {
    console.log('Prop currentPage changed:', newPage);
    currentPage.value = newPage || 1;
  });
  
  const handlePageSizeChange = (size: number) => {
    pageSize.value = size;
    currentPage.value = 1;
  };

  </script>
  
  <style lang="scss" scoped>
  /* Add your scoped styles here if needed */
  
  @media (max-width: 1040px) {
    .responsive_table {
      font-size: 14px;
      white-space: nowrap;
    }
  }
  </style>
  