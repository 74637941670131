import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-[12px]" }
const _hoisted_2 = { class: "custom-toggle" }
const _hoisted_3 = ["name", "value", "checked"]
const _hoisted_4 = { class: "text-body-2 text-grey-300 font-medium" }

import { ref, VNode } from 'vue';

interface Props {
    label?: VNode | string | any
    isChecked?: boolean
    name?: string
    value?: any
}

 
export default /*@__PURE__*/_defineComponent({
  __name: 'ToggleSlider',
  props: {
    label: {},
    isChecked: { type: Boolean },
    name: {},
    value: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("input", {
        type: "checkbox",
        name: _ctx.name,
        value: _ctx.value,
        checked: _ctx.isChecked,
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on:change', $event)))
      }, null, 40, _hoisted_3),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "slider" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1)
  ]))
}
}

})