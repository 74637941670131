import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-[32px]" }
const _hoisted_2 = { class: "mt-[20px] border-[1px] border-solid border-grey-50 rounded-[8px] p-[20px]" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = { class: "h-[250px] overflow-hidden mt-[20px]" }
const _hoisted_6 = { class: "h-[120px] w-[120px] rounded-[50%] border-[5px] border-solid border-bg-100 overflow-hidden absolute bottom-[-60px] left-[69px]" }
const _hoisted_7 = { class: "mt-[72px]" }
const _hoisted_8 = { class: "font-semibold text-heading-4" }
const _hoisted_9 = { class: "mt-[12px] text-grey-200 text-body-2" }
const _hoisted_10 = {
  class: "flex items-center gap-[10px] font-medium m-[auto] border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] text-body-2 py-[11px] w-[fit-content] cursor-pointer",
  onClick: () => {}
}
const _hoisted_11 = { class: "px-[28px] pt-[32px]" }
const _hoisted_12 = { class: "flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]" }

import ImageCard from '@/components/shared/image/ImageCard.vue';
import { Icon } from '@iconify/vue';
import { useSettingsMicrositeHelper } from './Settings.helper';
import AppModal from '@/components/shared/modal/AppModal.vue';
import InputField from '@/components/shared/input-field/InputField.vue';
import UploadImage from '@/components/shared/upload-image/UploadImage.vue';
import UploadProfileImage from '@/components/shared/upload-profile-image/UploadProfileImage.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import { useStore } from 'vuex';



export default /*@__PURE__*/_defineComponent({
  __name: 'MicrositePersonalization',
  setup(__props) {

const store = useStore()

const { 
    handleOpenMicrositeUpdateModalSubmit, 
    handleShowMicrositeUpdatemodal, 
    isMicrositeUpdateModal, 
    micrositeData, 
    updateMicroAccountFormHandler, 
    loading 
} = useSettingsMicrositeHelper(store);



const logoImageDataFromEmitedBase64 = (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    updateMicroAccountFormHandler.values.logoImageData =  imageDataParts?.[1];
}
const coverImageDataFromEmitedBase64 = (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    updateMicroAccountFormHandler.values.coverImageData =  imageDataParts?.[1];
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-grey-200" }, "Personalize Your Microsite: Make It Yours!", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "font-semibold" }, "Microsite Personalization", -1)),
        _createElementVNode("div", {
          class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[16px] text-body-2 py-[11px] w-[fit-content] cursor-pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(handleShowMicrositeUpdatemodal) && _unref(handleShowMicrositeUpdatemodal)(...args)))
        }, "Edit")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(ImageCard, {
            width: "",
            height: "400",
            imageUrl: _unref(micrositeData)?.cover
          }, null, 8, ["imageUrl"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(ImageCard, {
            width: "120",
            height: "120",
            imageUrl: _unref(micrositeData)?.logo
          }, null, 8, ["imageUrl"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(micrositeData)?.name), 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(micrositeData)?.description), 1)
      ])
    ]),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-link font-medium text-body-2 text-center mt-[44px] mb-[20px]" }, "https://www.foodcart.ng/store/62d9a3aa6d5e0100041f28f8", -1)),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_unref(Icon), {
        icon: "ant-design:copy-outlined",
        class: "w-[18px] h-[18px]"
      }),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "" }, "Copy link", -1))
    ]),
    _createVNode(AppModal, {
      headerContent: "Microsite Personalization",
      openModal: _unref(isMicrositeUpdateModal),
      onClose: _unref(handleShowMicrositeUpdatemodal)
    }, {
      default: _withCtx(() => [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_unref(handleOpenMicrositeUpdateModalSubmit)($event)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(UploadProfileImage, {
              imageUrl: _unref(micrositeData)?.logo,
              name: "logo",
              onBase64ImgCode: logoImageDataFromEmitedBase64,
              "onUpdate:blur:value": _unref(updateMicroAccountFormHandler).handleBlur,
              "onUpdate:value": _unref(updateMicroAccountFormHandler).handleFileChange
            }, null, 8, ["imageUrl", "onUpdate:blur:value", "onUpdate:value"]),
            _createVNode(UploadImage, {
              imageUrl: _unref(micrositeData)?.cover,
              name: "cover",
              onBase64ImgCode: coverImageDataFromEmitedBase64,
              "onUpdate:blur:value": _unref(updateMicroAccountFormHandler).handleBlur,
              "onUpdate:value": _unref(updateMicroAccountFormHandler).handleFileChange
            }, null, 8, ["imageUrl", "onUpdate:blur:value", "onUpdate:value"]),
            _createVNode(InputField, {
              label: "Display Name",
              type: "text",
              value: _unref(micrositeData)?.name,
              name: "name",
              "onUpdate:blur:value": _unref(updateMicroAccountFormHandler).handleBlur,
              "onUpdate:value": _unref(updateMicroAccountFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
            _createVNode(TextArea, {
              label: "Store Description",
              placeholder: "Briefly introduce your store here",
              class: "mt-[24px]",
              value: _unref(micrositeData)?.description,
              name: "description",
              "onUpdate:blur:value": _unref(updateMicroAccountFormHandler).handleBlur,
              "onUpdate:value": _unref(updateMicroAccountFormHandler).handleChange
            }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
          ]),
          _createElementVNode("footer", _hoisted_12, [
            _createVNode(ActionButton, {
              label: "Cancel",
              theme: "secondary",
              class: "w-[fit-content]",
              onClick: _unref(handleShowMicrositeUpdatemodal)
            }, null, 8, ["onClick"]),
            _createVNode(ActionButton, {
              theme: "primary",
              class: "w-[fit-content]",
              isSubmit: true,
              disable: _unref(loading) && true,
              label: _unref(loading) ? 'Loading...' : 'Save'
            }, null, 8, ["disable", "label"])
          ])
        ], 32)
      ]),
      _: 1
    }, 8, ["openModal", "onClose"])
  ]))
}
}

})