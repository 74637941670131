import { useSettingsHandler } from "@/utility/composables/useSettingsRequestsHandler";
import { formatTimeToAmOrPm, uploadFile } from "@/utility/helper/utils.helper";
import SettingsService, { PayoutAccount, StoreProfile, MicrositeAccount } from "@/utility/services/settings.service";
import { computed, ref, watch, watchEffect } from "vue";
import { Store } from "vuex";

export const getAccountProfile = async () => {
    try {
        const response = await SettingsService.accountProfileRequest();
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const updateAccountProfile = async (firstName: string, lastName: string, email: string, phone: number, address: Record<string | number, any>, store: Store<any>) => {
    try {
        const response = await SettingsService.updateAccountProfileRequest(firstName, lastName, email, phone, address);
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Profile Updated', x_message: `User Profile for "${firstName} " was updated successfully`, duration: 5000 });
        return response;
    } catch (error: any) {
        console.error(error)
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error updating profile', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
}

export const updateAccountAvatar = async (avatar: string, store: Store<any>) => {
    try {
        const response = await SettingsService.updateAccountProfileAvatarRequest(avatar);
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Profile Image Updated', x_message: `Your profile image was updated successfully`, duration: 5000 });
        return response;
    } catch (error: any) {
        console.error(error)
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error updating profile image', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
}

export const changeAccountPassword = async (currentPassword: string, newPassword: string) => {
    try {
        const response = await SettingsService.changeAccountPasswordRequest(currentPassword, newPassword);
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const getStoreProfile = async () => {
    try {
        const response = await SettingsService.storeProfileRequest();
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const updateStoreProfile = async (values: StoreProfile, store: Store<any>) => {
    try {
        const response = await SettingsService.updateStoreProfileRequest(values);
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Store Updated', x_message: `Store "${values.name} " was updated successfully`, duration: 5000 });
        return response;
    } catch (error: any) {
        console.error(error)
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error updating store', x_message: `${error?.response.data.error}`, duration: 5000 })

    }
}


export const updatePayoutAccount = async (values: PayoutAccount) => {
    try {
        const response = await SettingsService.updatePayoutAccountRequest(values);
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const updateMicrositeAccount = async (values: MicrositeAccount, store: Store<any>) => {
    try {
        const response = await SettingsService.updateMicrositePersonalizationRequest(values);
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'success', x_title: 'Store Microsite Updated', x_message: `Microsite "${values.name} " was updated successfully`, duration: 5000 });
        return response;
    } catch (error:any) {
        console.error(error)
        store.dispatch('ToastX/showToastWithTimeout', { theme: 'error', x_title: 'Error updating store microsite', x_message: `${error?.response.data.error}`, duration: 5000 })
    }
}

export const getBankDetails = async () => {
    try {
        const response = await SettingsService.getBankDetailsRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const getPayoutAccountProfile = async () => {
    try {
        const response = await SettingsService.payoutAccountRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}


export const getMicrositeProfile = async () => {
    try {
        const response = await SettingsService.micrositePersonalizationRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const getAdditionalCharges = async () => {
    try {
        const response = await SettingsService.additionalChargesRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const getWorkingHours = async () => {
    try {
        const response = await SettingsService.workingHoursRequest();
        return response
    } catch (error) {
        console.error(error)
    }
}

export const updateAdditionalCharges = async (values: Record<string | number, any> | any) => {
    try {
        const response = await SettingsService.updateAdditionalChargesRequest(values);
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const updateAWorkingHours = async (values: Record<string | number, any> | any) => {
    try {
        const response = await SettingsService.updateWorkingHoursRequest(values);
        return response;
    } catch (error) {
        console.error(error)
    }
}


export const useSettingsHelper = () => {
    const settingsTabs = ref(
        [
            'Profile', 
            'Store Details', 
            'Additional Charges and Fees', 
            'Microsite Personalization', 
            'Delivery Options', 
            'Payout Account'
        ]
    );

    const businessStatusOptions = ref([
        {
            name: 'Operational', 
            value: 'OPERATIONAL'
        },

        {
            name: 'Closed',
            value: 'CLOSED'
        },

        {
            name: 'Temporarily',
            value: 'TEMPORARILY'
        }
    
    ])
        

    const activeTab = ref(0);

    const activateTab = (index: number) => {
        activeTab.value = index;
    };

    const scheduleHeader = ref([
        {name: 'Week', value: 'week'},
        {name: 'Start Time', value: 'startTime'},
        {name: 'End Time', value: 'endTime'},
    ])
    
    const scheduleData = ref([
        {
            week: 'Monday',
            startTime: '12:00 PM',
            endTime: '1:30pm',
        },
    
        {
            week: 'Tuesday',
            startTime: '12:00 PM',
            endTime: '1:30pm',
        },

        {
            week: 'Wednesday',
            startTime: '12:00 PM',
            endTime: '1:30pm',
        },

        {
            week: 'Thursday',
            startTime: '12:00 PM',
            endTime: '1:30pm',
        },
    ]);

    return { settingsTabs, activeTab, activateTab, scheduleHeader, scheduleData, businessStatusOptions }
}

export const useStoreDetailsHelper = () => {
    const workingHoursData = ref();

    const mapWorkingHoursToTableFormat = (workingHoursData: any) => {
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    
        return days.map((day) => {
            const dayData = workingHoursData.storeHours?.[day.toLowerCase()];
    
            return {
                week: day,
                startTime: dayData && dayData.status ? formatTimeToAmOrPm(dayData.open) : 'Closed',
                endTime: dayData && dayData.status ? formatTimeToAmOrPm(dayData.close) : 'Closed'
            };
        });
    };

    const scheduleHeader = [
        { name: 'Week', value: 'week' },
        { name: 'Start Time', value: 'startTime' },
        { name: 'End Time', value: 'endTime' },
    ];

    const scheduleData = computed(() => {
        if (!workingHoursData.value) {
            return [];
        }

        return mapWorkingHoursToTableFormat(workingHoursData.value);
    });

    watchEffect(() => {
        getWorkingHours().then((response) => {
            workingHoursData.value = response;
        })
    });

    return { workingHoursData, scheduleHeader, scheduleData }
}

export const useSettingsMicrositeHelper = (store?: Store<any>) => {

    const micrositeData = ref<MicrositeAccount>();
    const isMicrositeUpdateModal = ref(false);
    const loading = ref(false);

    const { updateMicroAccountFormHandler } = useSettingsHandler(store);

    const handleShowMicrositeUpdatemodal = () => {
        isMicrositeUpdateModal.value = !isMicrositeUpdateModal.value;
    };

    const handleOpenMicrositeUpdateModalSubmit = async (event: Event) => {
        event.preventDefault();
        loading.value = true;
        const newValues = await updateMicroAccountFormHandler.handleSubmit(event);
        micrositeData.value = newValues;
        handleShowMicrositeUpdatemodal();
        loading.value = false;
    };

    watchEffect(() => {
        getMicrositeProfile().then((response: any) => {
            micrositeData.value = response;
        })
    })
    
    watch(micrositeData, (newProfileData) => {
        updateMicroAccountFormHandler.setValues(newProfileData);
    });

    return { handleOpenMicrositeUpdateModalSubmit, micrositeData, handleShowMicrositeUpdatemodal, loading, isMicrositeUpdateModal, updateMicroAccountFormHandler }
}

export const useAdditionalChargesSettingsHelper = () => {
    const additionalChargesData: any = ref();
    const isOpen = ref(false);
    const loading = ref(false);
    const { updateAdditionalChargesFormHandler } = useSettingsHandler()

    const chargesFeesHeader = [
        { name: 'Name', value: 'name' },
        { name: 'Percentage/Amount', value: 'value' },
        { name: 'Last Update', value: 'lastUpdated' },
        { name: 'Action', value: 'action-callback' },
    ];

    const chargesFeesData = () => {
    
        return additionalChargesData.value?.serviceFees?.map((chFee: any) => {
            return {
                name: chFee?.name,
                value: chFee?.value,
                lastUpdated: '--',
                update: '' 
            };
        });
    };

    const handleOpenEditModal = (data?: any) => {
        isOpen.value = !isOpen.value;
        console.log(data)
    };

    const updateAdditionalCharge = (name: string, value: string) => {
        additionalChargesData.value.serviceFees.forEach((item: any) => {
            if (item.name === name) {
                item.value = value;
            }
        });
    };

    watchEffect(() => {
        getAdditionalCharges().then((response) => {
            additionalChargesData.value = response;
        })
    })

    const handleOpenPayoutUpdateModalSubmit = async (event: any) => {
        console.log({form: event.target})
        loading.value = true;
        await updateAdditionalChargesFormHandler.handleSubmit(event);
        handleOpenEditModal();
        loading.value = false;
    }

    return { additionalChargesData, handleOpenEditModal, loading, isOpen, updateAdditionalChargesFormHandler, handleOpenPayoutUpdateModalSubmit, updateAdditionalCharge, chargesFeesHeader, chargesFeesData }
}
