import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[100%] text-center" }

import { useRouter } from 'vue-router';
import AppWrapper from './components/app/app-wrapper/AppWrapper.vue'
import ActionButton from './components/shared/action-button/ActionButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotFound',
  setup(__props) {

const  router = useRouter()

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-[68px] text-black font-bold" }, "404", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-[35px] text-black font-medium my-[13px]" }, "Hmm, page not found", -1)),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-grey-100" }, [
            _createTextVNode("This page doesn't exist or was removed, we suggest you return back to "),
            _createElementVNode("br"),
            _createTextVNode(" dashboard")
          ], -1)),
          _createVNode(ActionButton, {
            handleClick: () => _unref(router).push('/'),
            theme: "primary",
            label: "Go to dashboard",
            class: "w-[fit-content] m-[auto] mt-[22px]",
            prefixIcon: "material-symbols:arrow-left-alt"
          }, null, 8, ["handleClick"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})