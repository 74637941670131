import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lg:py-[72px] py-[30px] lg:w-[800px] lg:m-[auto]" }
const _hoisted_2 = { class: "flex items-center gap-[6px] lg:text-body-1 text-body-2" }
const _hoisted_3 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] lg:mt-[32px] mt-[20px]" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "lg:px-[32px] px-[10px] lg:py-[28px] py-[]" }
const _hoisted_7 = { class: "grid lg:grid-cols-2 gap-[24px]" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "font-medium lg:text-body-1 text-body-2 mt-[4px]" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "font-medium lg:text-body-1 text-body-2 mt-[4px]" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "font-medium lg:text-body-1 text-body-2 mt-[4px]" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "font-medium lg:text-body-1 text-body-2 mt-[4px]" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "font-medium lg:text-body-1 text-body-2 mt-[4px]" }
const _hoisted_18 = { class: "grid lg:grid-cols-2 gap-[24px] lg:px-[32px] px-[10px] pb-[24px] lg:mt-0 mt-[20px]" }
const _hoisted_19 = { class: "flex items-center gap-[16px] px-[20px] py-[15px] bg-[#CFFFE0] rounded-[8px]" }
const _hoisted_20 = { class: "" }
const _hoisted_21 = { class: "lg:text-body-2 text-caption" }
const _hoisted_22 = { class: "text-grey-200" }
const _hoisted_23 = { class: "font-semibold" }
const _hoisted_24 = { class: "font-semibold" }
const _hoisted_25 = { class: "flex items-center gap-[16px] px-[20px] py-[15px] bg-[#FFF4D0] rounded-[8px]" }
const _hoisted_26 = { class: "" }
const _hoisted_27 = { class: "lg:text-body-2 text-caption" }
const _hoisted_28 = { class: "text-grey-200" }
const _hoisted_29 = { class: "font-semibold" }
const _hoisted_30 = { class: "font-semibold" }
const _hoisted_31 = { class: "border-t-[1px] border-solid border-grey-50 lg:px-[32px] px-[10px] pb-[20px]" }
const _hoisted_32 = { class: "flex gap-[32px] border-b-[1px] border-solid mt-[24px] overflow-auto no-scrollbar" }
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { class: "mt-[24px] overflow-auto no-scrollbar" }

import { useRouter } from 'vue-router';
    import { useCustomersHelper, getCustomerById, getCustomerOrders } from './Customers.helper';
    import { watchEffect, ref } from 'vue'
    import { Icon } from '@iconify/vue';
    import { sortBy, sum } from "lodash";

    import TableField from '@/components/shared/table/TableField.vue';
    import ImageCard from '@/components/shared/image/ImageCard.vue';

    import { useOrderHelper, ActiveTab } from '../Orders/Order.helper';

    interface Props {
        id: string
    }

    export interface SearchOptions {
        status: string;
    }

    
export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerDetails',
  props: {
    id: {}
  },
  setup(__props: any) {

    const { AppWrapper, customerDetailsData } = useCustomersHelper()
    const { customOrderHeaders, orderTabsCustomers, customOrderData } = useOrderHelper()

    const router = useRouter()

    const custSearchOptionsData = ref<SearchOptions>({
        status: ''
    });

    const props = __props
    
    const activeCustTab = ref<ActiveTab>({
        status: "All",
        orderCount: 0
    });

    const activateTab = (tab: ActiveTab) => {
        activeCustTab.value = tab;
        custSearchOptionsData.value.status = tab.status;
    };

    watchEffect(() => {
        getCustomerById({customerId: props.id}).then((responseData: any)=> {
            if (responseData) {
                customerDetailsData.value = responseData;
            }
        });
        getCustomerOrders({customerId: props.id, status: custSearchOptionsData.value.status}).then((responseData: any)=> {
            if (responseData) {
                const { orderStatus, orders } = responseData;
                const totalOrderCount = sum(orderStatus.map((item: { orderCount: number; }) => item.orderCount));
                orderStatus.push({ status: "All", orderCount: totalOrderCount });
                orderTabsCustomers.value = sortBy(orderStatus, ['status']);
                customOrderData.value = orders.map((item: any) => {
                    return {
                        ...item,
                        name: item.menuName,
                        description: `Category: ${item.category}`,
                        subDescription: `Qty: ${item.quantity} Channel: ${item.orderChannel}`,
                    }
                });
            }
        });
    });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(AppWrapper), { activeTab: "Customers" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "flex items-center gap-[6px] text-grey-100 cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).back()))
          }, [
            _createVNode(_unref(Icon), { icon: "ant-design:shopping-outlined" }),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, "Customer", -1))
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("div", null, "/", -1)),
          _createElementVNode("div", null, _toDisplayString(_unref(customerDetailsData)?.customerInfo?.firstName) + " " + _toDisplayString(_unref(customerDetailsData)?.customerInfo?.lastName), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[10] || (_cache[10] = _createElementVNode("header", { class: "lg:py-[24px] lg:px-[32px] p-[10px]" }, [
            _createElementVNode("div", { class: "flex items-center justify-between" }, [
              _createElementVNode("div", { class: "font-semibold lg:text-heading-5 lg:mt-[6px]" }, "Customer Details")
            ])
          ], -1)),
          _createElementVNode("main", _hoisted_4, [
            _createElementVNode("section", _hoisted_5, [
              _createElementVNode("main", null, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Name", -1)),
                      _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(customerDetailsData)?.customerInfo?.firstName) + " " + _toDisplayString(_unref(customerDetailsData)?.customerInfo?.lastName), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Email", -1)),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(customerDetailsData)?.customerInfo?.email), 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Phone Number", -1)),
                      _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(customerDetailsData)?.customerInfo?.phone), 1)
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Registered On", -1)),
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(customerDetailsData)?.customerInfo?.registeredDate), 1)
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-grey-200 text-caption font-medium" }, "Address", -1)),
                      _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(customerDetailsData)?.customerInfo?.address?.street), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createVNode(ImageCard, {
                        imageUrl: require('@/assets/images/icons/rocket.svg'),
                        width: "42",
                        height: "42"
                      }, null, 8, ["imageUrl"])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _cache[8] || (_cache[8] = _createTextVNode("Most Ordered Item ")),
                        _createElementVNode("span", _hoisted_23, "· " + _toDisplayString(_unref(customerDetailsData)?.favoriteMenu?.orderCount) + " times", 1)
                      ]),
                      _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(customerDetailsData)?.favoriteMenu?.favoriteFood), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(ImageCard, {
                        imageUrl: require('@/assets/images/icons/beach-restaurant.svg'),
                        width: "42",
                        height: "42"
                      }, null, 8, ["imageUrl"])
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _cache[9] || (_cache[9] = _createTextVNode("Most Used Channel ")),
                        _createElementVNode("span", _hoisted_29, "· " + _toDisplayString(_unref(customerDetailsData)?.favoriteChannel?.orderCount) + " times", 1)
                      ]),
                      _createElementVNode("div", _hoisted_30, _toDisplayString(_unref(customerDetailsData)?.favoriteChannel?.orderChannel), 1)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(orderTabsCustomers), (tab) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: tab?.status,
                        class: _normalizeClass(['py-[12px] cursor-pointer lg:text-body-2 text-caption lg:whitespace-none whitespace-nowrap text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeCustTab.value.status === tab.status }]),
                        onClick: ($event: any) => (activateTab(tab))
                      }, _toDisplayString(tab?.status) + " (" + _toDisplayString(tab?.orderCount) + ") ", 11, _hoisted_33))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createVNode(TableField, {
                      headers: _unref(customOrderHeaders),
                      dataItems: _unref(customOrderData),
                      isPagination: true,
                      pagination: "client"
                    }, null, 8, ["headers", "dataItems"])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})