
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

export interface ToastState {
  showToast: boolean;
  theme: string;
  x_title: string;
  x_message: string;
}

@Module({ namespaced: true })
export default class ToastModule extends VuexModule implements ToastState {
  showToast = false;
  theme = '';
  x_title = '';
  x_message = '';

  @Mutation
  setShowToast(showToast: boolean) {
    this.showToast = showToast;
  }

  @Mutation
  setToastData({ theme, x_title, x_message }: { theme: string; x_title: string; x_message: string }) {
    this.theme = theme;
    this.x_title = x_title;
    this.x_message = x_message;
  }

  @Action
  showToastWithTimeout({ theme, x_title, x_message, duration }: { theme: string; x_title: string; x_message: string; duration: number }) {
    this.context.commit('setToastData', { theme, x_title, x_message });
    this.context.commit('setShowToast', true);
    
    setTimeout(() => {
      this.context.commit('setShowToast', false);
    }, duration);
  }

  hideToast() {
    this.context.commit('setShowToast', false);
  }
}
