import { usePaymentFormHandler } from "@/utility/composables/usePaymentFormHandler";
import PaymentService from "@/utility/services/payment.service";
import { ref, watchEffect } from "vue"
import { getBankDetails } from "../settings/Settings.helper";

export const usePaymentHelper = (store?: any) => {
    const banksDetailsData = ref()
    const payments = ref();
    const  { updatePaymentsHandler } = usePaymentFormHandler(store)
    
    watchEffect(() => {
        getBankDetails().then((response) => {
            banksDetailsData.value = response;
        })
    })

    watchEffect(async () => {
        await PaymentService.getPaymentsRequest()
        .then((response) => {
            payments.value = response
        })
    })

    const updatePaymentStatus = (event: any, paymentType: string, handler: Record<string | number, any>) => {
        event?.preventDefault()

        const updatedPayments = {
            ...payments.value,
            [paymentType]: {
                ...payments.value[paymentType],
                type: paymentType,
                status: event.target.checked 
            }
        };

        payments.value = updatedPayments;

        handler.setValues(updatedPayments);

        return handler.handleSubmit(event, paymentType);
    }

    const addDebitDevice = () => {
        const _addDebitDevice = payments.value?.debit?.devices == undefined 
        ? [{machineName: '', machineId: ''}]
        : [...payments.value?.debit.devices, {machineName: '', machineId: ''}]

        const newPaymentData = {
            ...payments.value,
            debit: {
                ...payments.value.debit,
                devices: _addDebitDevice
            }
        }
    
        payments.value = newPaymentData;

        updatePaymentsHandler.setValues(newPaymentData);
    }

    const handlePaymentValueChange = (event: any, index: any, paymentType: string, resourceType: string) => {
        const { name, value } = event.target;
        console.log({event});
        
        const updatedPaymentDataType = [ ...payments.value?.[paymentType]?.[resourceType] ]
        const updatedSize = {...payments.value?.[paymentType]?.[resourceType]?.[index]}
    
        updatedSize[name] = value;
    
        updatedPaymentDataType[index] = updatedSize;
        payments.value[paymentType][resourceType] = updatedPaymentDataType;

        if (paymentType == 'bankTransfer') {

            const bankAccountData = payments.value.bankTransfer.accounts?.map((account: any) => ({
                ...account,
                accountProviderCode: banksDetailsData.value.find((bankD: any) => account.accountProvider == bankD.name)?.code
            }))

            payments.value.bankTransfer.accounts = bankAccountData
        }

        updatePaymentsHandler.setValues(payments.value);
    }

    const addBankAccount = () => {
        const _addBankAccount = payments.value?.bankTransfer?.accounts == undefined 
        ? [{}]
        : [...payments.value?.bankTransfer.accounts, {}]

        const newPaymentData = {
            ...payments.value,
            bankTransfer: {
                ...payments.value.bankTransfer,
                accounts: _addBankAccount,
            }
        }
    
        payments.value = newPaymentData;

        updatePaymentsHandler.setValues(newPaymentData);
    }

    const deleteFromPaymentInputList = (listType: string, index: string | number, dataValue: any, formHandler: any, resourceType: string) => {
        dataValue[listType]?.[resourceType]?.splice(index, 1);
        formHandler.setValues(dataValue);
    }
      
    

    return {payments, updatePaymentStatus, addDebitDevice, updatePaymentsHandler, handlePaymentValueChange, addBankAccount, deleteFromPaymentInputList, banksDetailsData}
}