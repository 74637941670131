import { Store } from "vuex";
import { useForm } from "./useForm";
import * as yup from 'yup';


import { 
    changeAccountPassword, 
    updateAWorkingHours, 
    updateAccountProfile, 
    updateAdditionalCharges, 
    updateMicrositeAccount, 
    updatePayoutAccount,
    updateStoreProfile
} from "@/views/settings/Settings.helper";

import { PayoutAccount, StoreProfile, MicrositeAccount } from "../services/settings.service";

export const useSettingsHandler = (store?: Store<any> | any) => {

    const accountProfileFormHandler = useForm({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            address: {
                street: "",
                city: "",
                lga: "",
                postalCode: "",
                country: ""
            }
        },

        onSubmit: async (values: any) => {
            try {
                await updateAccountProfile(values.firstName, values.lastName, values.email, values.phone, values.address, store)
            } catch (error) {
                console.error("update account profile error:", error);
            }
        }
    })

    const changePasswordFormHandler = useForm({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        },

        validationSchema: yup.object().shape({
            currentPassword: yup.string().required("Current password is required"),
            newPassword: yup.string().required("New password is required").min(8, "Password must be at least 8 characters"),
            confirmPassword: yup.string()
            .oneOf([yup.ref('newPassword'), undefined], '')
            .required('Password confirmation is required')
        }),

        onSubmit: async (values: any) => {
            try {
                await changeAccountPassword(values.currentPassword, values.newPassword)
            } catch (error) {
                console.error("update account profile error:", error);
            }
        }
    })

    const updateProfileFormHandler = useForm({
        initialValues: {},

        onSubmit: async (values: StoreProfile) => {
            try {
                const { deviceRegistrationCode, ...valueRest } = values;
                await updateStoreProfile({...valueRest}, store);
            } catch (error) {
                console.error("update account profile error:", error);
            }
        }
    })

    const updatePayoutAccountFormHandler = useForm({
        initialValues: {},

        onSubmit: async (values: PayoutAccount) => {
            try {
                await updatePayoutAccount({...values})
            } catch (error) {
                console.error("update account profile error:", error);
            }
        }
    })

    const updateMicroAccountFormHandler = useForm({
        initialValues: {},

        onSubmit: async (values: MicrositeAccount) => {
            try {
                const { logo, cover, ...mircoItem} = values;
                const result = await updateMicrositeAccount({...mircoItem}, store);
                return result as MicrositeAccount
            } catch (error) {
                console.error("update account profile error:", error);
            }
        }
    })

    const updateAdditionalChargesFormHandler = useForm({
        initialValues: {
            name: '',
            value: ''
        },

        onSubmit: async (values: any) => {
            try {
                console.log({values})
                await updateAdditionalCharges(values)
            } catch (error) {
                console.error("update account profile error:", error);
            }
        }
    })

    
    const updateWorkingHoursFormHandler = useForm({
        initialValues: {},

        onSubmit: async (values: any) => {
            try {
                await updateAWorkingHours({...values})
            } catch (error) {
                console.error("update account profile error:", error);
            }
        }
    })

    return { accountProfileFormHandler, changePasswordFormHandler, updateProfileFormHandler, updatePayoutAccountFormHandler, updateMicroAccountFormHandler, updateAdditionalChargesFormHandler, updateWorkingHoursFormHandler,};

}