import ApiService from "./api.service"

export default class StoreService {

    static async getStoresRequest(): Promise<unknown> {
        const response = await ApiService.get('setup/stores')
        return response?.data?.data
    }

    static async createStoresRequest(values: Record<string | number, unknown>): Promise<unknown> {
        const response = await ApiService.post('setup/stores', {...values})
        return response.data.data
    }
}
