<template>
    <div class="">
        <div class="flex gap-[32px] border-b-[1px] border-solid pt-[24px] px-[24px]">
            <div
                v-for="(tab, index) in addMemberTabs"
                :key="index"
                :class="['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': activeTab === index }]"
                @click="activateTab(index)"
            > {{ tab }} </div>
        </div>

        <form class="" @submit.prevent="handleCreateUserSubmit($event)">
            <div class="p-[24px]" v-if="activeTab == 0">
                <div class="grid grid-cols-2 gap-[12px] mt-[8px]">
                    <InputField
                        label="First Name *" 
                        type="text" 
                        placeholder="First Name" 
                        name="firstName"
                        :value="createUserFormHandler.values.firstName"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />
                    <InputField 
                        label="Last Name *" 
                        type="text" 
                        placeholder="Last Name" 
                        name="lastName"
                        :value="createUserFormHandler.values.lastName"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />
                    <InputField 
                        label="Email *" 
                        type="email" 
                        placeholder="Email" 
                        name="email"
                        :value="createUserFormHandler.values.email"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />
                    <InputField 
                        label="Phone *" 
                        type="phone" 
                        placeholder="080 123 456" 
                        name="phone"
                        :value="createUserFormHandler.values.phone"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />

                    <InputField
                        label="Street Address" 
                        type="text" 
                        placeholder="Street Address"
                        name="address.street" 
                        :value="createUserFormHandler?.values?.street"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />

                    <InputField 
                        label="City " 
                        type="text" 
                        placeholder="City Address"
                        name="address.city" 
                        :value="createUserFormHandler?.values?.city"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />

                    <InputField 
                        label="Postal Code" 
                        type="text" 
                        placeholder="" 
                        name="address.postalCode"
                        :value="createUserFormHandler?.values?.postalCode"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />

                    <InputField 
                        label="State" 
                        type="text" 
                        name="address.state"
                        placeholder="" 
                        :value="createUserFormHandler?.values?.state"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />
                </div>

                <InputField
                    label="Country" 
                    type="text" 
                    name="address.country"
                    placeholder="" 
                    class="my-[12px]"
                    :value="createUserFormHandler?.values?.country"
                    @update:blur:value="createUserFormHandler.handleBlur"
                    @update:value="createUserFormHandler.handleChange"
                />

                <InputField 
                    label="National ID (NIN)" 
                    type="number" 
                    name="nin"
                    placeholder="" 
                    :value="createUserFormHandler?.values?.nin"
                    @update:blur:value="createUserFormHandler.handleBlur"
                    @update:value="createUserFormHandler.handleChange"
                />

                <!-- <div class="mt-[12px]">
                    <DropdownField 
                        label="Status *" 
                        name="status"
                        @change="createUserFormHandler.handleChange"
                        :optionsData="[{name: 'Active', id: 'active'}, {name: 'Disabled', id: 'disabled'}]" 
                    />
                </div> -->

                <!-- <InputField 
                    label="Add Roles" 
                    placeholder="Roles" 
                    type="text" 
                    name="roles"
                    class="mt-[24px]" 
                    @keydown.enter.prevent="(event) => addToInputList(event, createUserFormHandler.values, createUserFormHandler)"
                    undertext='Tap "Enter Key" to add user roles' 
                /> -->

                <div class="mt-[12px]">
                    <DropdownField
                        label="Role *" 
                        name="role"
                        defaultOptionLabel="Select" 
                        @change="createUserFormHandler.handleChange"
                        :optionsData="rolesOptions" 
                    />
                </div>

                <div class="flex items-center gap-[10px] mt-[12px]">
                    <div class="py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption" v-for="(role, idx) in createUserFormHandler.values?.roles" :key="idx">
                        <div class="">{{ role }}</div>
                        <Icon icon="ant-design:close-outlined" width="12" height="12" @click="() => deleteFromInputList('roles', idx, createUserFormHandler.values, createUserFormHandler)" />
                    </div>
                </div>
                
                <!-- <InputField label="Home Address *" type="text" placeholder="Home Address" class="mt-[32px]" /> -->

                <div class="mt-[12px] mb-[32px]">
                    <label class="text-body-2 text-grey-200 mb-[7px] inline-block">Gender</label>
                    <div class="flex gap-[32px]">
                        <RadioButton value="Male" name="gender" @on:change="createUserFormHandler.handleChange"  />
                        <RadioButton value="Female" name="gender" @on:change="createUserFormHandler.handleChange" />
                    </div>
                </div>

                <UploadProfileImage
                    :imageUrl="usersData?.avatar"
                    name="imageData"
                    @base64ImgCode="imageDataFromEmitedBase64"
                    @update:blur:value="createUserFormHandler.handleBlur"
                    @update:value="createUserFormHandler.handleFileChange"
                />
            </div>

            <div class="p-[24px]" v-if="activeTab == null">
                <div class="">
                    <DropdownField label="Role *" :optionsData="['Cashier', 'Manager', 'Sale Rep']" />
                </div>
                <div class="mt-[32px]">
                    <label class="text-body-2 text-grey-200 mb-[7px] inline-block">Permissions</label>
                    
                    <div class="grid grid-cols-1 gap-[24px] mt-[17px]">
                        <CheckboxInput label="Manage orders: Create, modify, and delete orders as needed." />
                        <CheckboxInput label="View order history: Access a record of past orders for reference." />
                        <CheckboxInput label="Mark orders as paid: Update the status of orders to indicate payment completion." />
                        <CheckboxInput label="Manage discounts: Apply discounts to orders when applicable." />
                        <CheckboxInput label="Handle refunds: Process refunds for returned or canceled orders." />
                        <CheckboxInput label="Update customer information: Make changes to customer details as necessary." />
                    </div>
                </div>
            </div>

            <div class="p-[24px]" v-if="activeTab == 1">
                <div class="grid grid-cols-2 gap-[20px]">
                    <InputField 
                        label="Salary" 
                        prefixIcon="fa6-solid:naira-sign" 
                        type="number" 
                        name="finance.salary"
                        :value="createUserFormHandler.values.salary"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />
                    <InputField 
                        label="Tax *" 
                        prefixIcon="fa6-solid:naira-sign" 
                        type="number" 
                        name="finance.tax"
                        :value="createUserFormHandler.values.tax"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />
                    <InputField 
                        label="Bank Account Name *" 
                        type="text" 
                        placeholder="Account name" 
                        name="finance.accountName"
                        :value="createUserFormHandler.values.accountName"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />
                    <InputField 
                        label="Bank Account Number *" 
                        type="phone" 
                        placeholder="080 123 456" 
                        name="finance.accountNumber"
                        :value="createUserFormHandler.values.accountNumber"
                        @update:blur:value="createUserFormHandler.handleBlur"
                        @update:value="createUserFormHandler.handleChange"
                    />

                    <DropdownField
                        label="Select Bank" 
                        name="finance.bankName"
                        :value="createUserFormHandler.values.bankName"
                        :optionsData="banksDetailsData"
                        @change="createUserFormHandler.handleChange"
                    />
                    
                    <DropdownField
                        label="Payment cycle"
                        name="finance.paymentCycle"
                        :value="createUserFormHandler.values.paymentCycle"
                        :optionsData="[
                            {name: 'Weekly', value: 'weekly'},
                            {name: 'Bi-Weekly', value: 'bi-weekly'},
                            {name: 'Monthly', value: 'monthly'}
                        ]"
                        @change="createUserFormHandler.handleChange"
                    />

                    <InputField 
                        type="date"
                        label="Payment day"
                        name="finance.paymentDate"
                        placeholder="Start date"
                        :value="createUserFormHandler.values.perDay"
                        @change="createUserFormHandler.handleChange" 
                        :min="minDate" 
                        :max="maxDate"
                    />
                </div>

                <TextArea
                    label="Note" 
                    placeholder="Enter note" 
                    class="mt-[24px] w-full"
                    name="finance.note"
                    :value="createUserFormHandler.values.description"
                    @update:value="createUserFormHandler.handleChange"
                />
            </div>

            <div class="p-[24px] grid grid-cols-1 gap-[12px]" v-if="activeTab == 2">
                <div class="border-[1px] border-solid border-grey-50 p-[14px] rounded-[8px] mt-[12px]" v-for="(storeHour, idx) in workingHoursData?.storeHours" :key="idx">
                    <div class="flex items-center justify-between w-[100%]" :class="{ 'opacity-50': !storeHour.status }">
                        <div class="flex gap-[6px] items-center capitalize">
                            <ToggleSlider name="status" :isChecked="storeHour.status" @on:change="(event) => handleWorkingHoursChange(event, idx)" :label="storeHour?.day" />
                        </div>

                        <Icon :icon="!openWorkHourUpdate[idx] ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined'" class="text-primary-300 cursor-pointer" :class="{ 'text-primary-300 cursor-not-allowed': !storeHour.status }" @click="storeHour.status && handleOpenWorkHourUpdateModal(idx)" />
                    </div>
                    <div class="" v-if="openWorkHourUpdate[idx] && storeHour.status">
                        <div v-for="(timeSlot, timeIdx) in storeHour.period" :key="timeIdx" class="work-hours-card items-center gap-[20px] mt-[16px]">
                            <InputField 
                                label="Start Time" 
                                type="time" 
                                name="open"
                                prefixIcon="ant-design:clock-circle-outlined"
                                :value="timeSlot.open"
                                @update:blur:value="updateWorkingHoursFormHandler.handleBlur"
                                @update:value="(event) => handleTimeSlotChange(event, idx, timeIdx, 'open')"
                                :errors="storeHour.status ? updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].open`] : ''"
                            />

                            <InputField 
                                label="End Time" 
                                type="time" 
                                name="close"
                                prefixIcon="ant-design:clock-circle-outlined"
                                :value="timeSlot.close"
                                @update:blur:value="updateWorkingHoursFormHandler.handleBlur"
                                @update:value="(event) => handleTimeSlotChange(event, idx, timeIdx, 'close')"
                                :errors="storeHour.status ? updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].close`] : ''"
                            />

                            <div class="text-grey-300 bg-bg-200 py-[10px] px-[10px] rounded-[4px] mt-[24px] w-[fit-content]" @click="clearInputFields(idx, timeIdx)">
                                <Icon icon="ant-design:clear-outlined" width="24" height="24" />
                            </div>

                            <div class="text-primary-300 bg-bg-200 py-[10px] px-[10px] rounded-[4px] mt-[24px] w-[fit-content]" @click="deleteTimeSlot(idx, timeIdx)">
                                <Icon icon="ant-design:delete-outlined" width="24" height="24" />
                            </div>
                        </div>

                        <div class="text-primary-300 bg-bg-200 py-[10px] px-[10px] rounded-[4px] mt-[24px] w-[fit-content] mx-auto" @click="addTimeSlot(idx)">
                            <Icon icon="ant-design:plus-outlined" width="24" height="24" />
                        </div>
                    </div>
                    
                </div>
            </div>

            <footer class="flex justify-end gap-[12px] mt-[28px] py-[18px] px-[28px] border-t-[1px]">
                <ActionButton label="Cancel" theme="secondary" class="w-[fit-content]" @click="handleOpenEditModal" />
                <ActionButton
                    theme="primary" 
                    class="w-[fit-content]" 
                    :isSubmit="true" 
                    :disable="loading && true" 
                    :label="loading ? 'Loading...' : 'Save'" 
                />
            </footer>
        </form>
    </div>
</template>

<script setup lang="ts">
    import { useCustomersHelper, getUsers } from './Teams.helper'
    import { ref, watchEffect, defineProps } from 'vue';
    import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
    import InputField from '@/components/shared/input-field/InputField.vue';
    import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
    import CheckboxInput from '@/components/shared/checkbox-input/CheckboxInput.vue';
    import ToggleSlider from '@/components/shared/toggle-slider/ToggleSlider.vue';
    import { SearchOptions } from '../menu/MenuView.vue';
    import { useUserFormHandler } from '@/utility/composables/useUsersFormHandler'
    import UploadProfileImage from '@/components/shared/upload-profile-image/UploadProfileImage.vue';
    import { deleteFromInputList } from '@/utility/helper/utils.helper';
    import ActionButton from '@/components/shared/action-button/ActionButton.vue';
    import { Icon } from '@iconify/vue';
    import { useStore } from 'vuex';
    import { useSettingsHandler } from '@/utility/composables/useSettingsRequestsHandler';
import { getBankDetails } from '../settings/Settings.helper';
import TextArea from '@/components/shared/text-area/TextArea.vue';

    const store = useStore()
    const isOpen = ref(false)
    const loading = ref(false)
    const imageData = ref();
    const teamTabs = ref([`Members (0)`]);
    const { updateWorkingHoursFormHandler } = useSettingsHandler(store)
    const openWorkHourUpdate = ref<boolean[]>([]);
    const banksDetailsData = ref()

    interface Props {
        handleOpenEditModal: any
    }

    const props = defineProps<Props>();

    const handleOpenWorkHourUpdateModal = (idx: number) => {
        if (openWorkHourUpdate.value.length <= idx) {
            openWorkHourUpdate.value[idx] = false;
        }
        openWorkHourUpdate.value[idx] = !openWorkHourUpdate.value[idx];
    };


    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0'); 

    const minDate = `${currentYear}-${currentMonth}-01`; 
    const maxDate = `${currentYear}-${currentMonth}-${new Date(currentYear, currentDate.getMonth() + 1, 0).getDate()}`; 

    const deleteTimeSlot = (dayIdx: number, timeIdx: number) => {
        const updatedStoreHours = { ...workingHoursData.value.storeHours };
        updatedStoreHours[dayIdx].period.splice(timeIdx, 1); // Remove the time slot
        workingHoursData.value.storeHours = updatedStoreHours;
        updateWorkingHoursFormHandler.setValues(workingHoursData.value);
    };

    const handleWorkingHoursChange = (event: any, idx: number) => {
        const { name, value, checked } = event.target;
        const updatedStoreHours = { ...workingHoursData.value.storeHours };
        const updatedDay: any = { ...(updatedStoreHours[idx] || {}) };

        name === 'status' ? updatedDay[name] = checked : updatedDay[name] = value;

        updatedStoreHours[idx] = updatedDay;
        workingHoursData.value.storeHours = updatedStoreHours; 
        updateWorkingHoursFormHandler.setValues(workingHoursData.value)
    };

    const clearInputFields = (dayIdx: number, timeIdx: number) => {
        const updatedStoreHours = { ...workingHoursData.value.storeHours };
        updatedStoreHours[dayIdx].period[timeIdx].open = ''; // Clear start time
        updatedStoreHours[dayIdx].period[timeIdx].close = ''; // Clear end time
        workingHoursData.value.storeHours = updatedStoreHours; 
        updateWorkingHoursFormHandler.setValues(workingHoursData.value);
    };

    const handleOpenEditModal = () => {
        isOpen.value = !isOpen.value;
    };

    const usersData: any = ref([]);
    const searchOptionsData = ref<SearchOptions>({
        search: '',
    });

    const { activateTab, addMemberTabs, activeTab, rolesOptions } = useCustomersHelper()
    const { createUserFormHandler } = useUserFormHandler('', null, store, handleOpenEditModal)

    const workingHoursData = ref({
        storeHours: [
            { day: 'Monday', period: [{ open: '', close: '' }], status: false },
            { day: 'Tuesday', period: [{ open: '', close: '' }], status: false },
            { day: 'Wednesday', period: [{ open: '', close: '' }], status: false },
            { day: 'Thursday', period: [{ open: '', close: '' }], status: false },
            { day: 'Friday', period: [{ open: '', close: '' }], status: false },
            { day: 'Saturday', period: [{ open: '', close: '' }], status: false },
            { day: 'Sunday', period: [{ open: '', close: '' }], status: false },
        ]
    });


    const formatScheduleData = () => {
        const formattedSchedule: any = {};

        Object.values(workingHoursData.value.storeHours).forEach(day => {
            const dayKey = day.day.toLowerCase(); 
            formattedSchedule[dayKey] = {
                status: day.status,
                period: day.period.map(slot => ({
                    start: slot.open,
                    end: slot.close
                }))
            };
        });

        return formattedSchedule;
    };


    const addTimeSlot = (idx: number) => {
        workingHoursData.value.storeHours[idx].period.push({ open: '', close: '' });
        updateWorkingHoursFormHandler.setValues(workingHoursData.value);
    };

    const handleTimeSlotChange = (event: any, dayIdx: number, timeIdx: number, type: 'open' | 'close') => {
        const updatedStoreHours = { ...workingHoursData.value.storeHours };
        updatedStoreHours[dayIdx].period[timeIdx][type] = event.target.value;
        workingHoursData.value.storeHours = updatedStoreHours;
        updateWorkingHoursFormHandler.setValues(workingHoursData.value);
    };

    const imageDataFromEmitedBase64 = (urlCode: string) => {
        const imageDataParts = urlCode.split(',');
        imageData.value = imageDataParts?.[1];
    }

    const handleCreateUserSubmit = async (event: Event) => {
        loading.value = true;

        const storeHoursArray = Array.isArray(workingHoursData.value.storeHours)
            ? workingHoursData.value.storeHours
            : Object.values(workingHoursData.value.storeHours);

        updateWorkingHoursFormHandler.errors = {};

        storeHoursArray.forEach((storeHour: any, idx) => {
            if (storeHour.status) {
                storeHour.period.forEach((timeSlot: any, timeIdx: any) => {
                    if (!timeSlot.open) {
                        updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].open`] = 'Start Time is required';
                    } else {
                        delete updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].open`];
                    }
                    if (!timeSlot.close) {
                        updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].close`] = 'End Time is required';
                    } else {
                        delete updateWorkingHoursFormHandler.errors[`storeHours[${idx}].period[${timeIdx}].close`];
                    }
                });
            }
        });

        if (Object.keys(updateWorkingHoursFormHandler.errors).length > 0) {
            loading.value = false;
            return;
        }

        const formattedSchedule = formatScheduleData();
        createUserFormHandler.values.imageData = imageData.value;
        createUserFormHandler.values.schedule = formattedSchedule;

        try {
            await createUserFormHandler.handleSubmit(event);

            await getUsers({ search: searchOptionsData.value.search }).then((response: any) => {
                usersData.value = response;
                teamTabs.value = [`Members (${response.total})`];
            });

            props.handleOpenEditModal();

        } catch (error) {
            console.error("Form submission failed:", error);
        } finally {
            loading.value = false;
        }
    };

    watchEffect(() => {
        getBankDetails().then((response) => {
            banksDetailsData.value = response;
        })
    })
</script>

<style scoped>
.work-hours-card {
    display: grid;
    grid-template-columns: 1fr 1fr auto auto;
}
</style>