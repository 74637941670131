import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "py-[76px]"
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[30%]" }
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = {
  key: 0,
  class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[100%]"
}
const _hoisted_6 = {
  key: 1,
  class: "w-[600px] m-[auto] py-[72px]"
}
const _hoisted_7 = { class: "mb-[23px]" }
const _hoisted_8 = { class: "text-heading-3 flex items-center gap-[24px] mb-[28px]" }
const _hoisted_9 = {
  key: 2,
  class: "lg:py-[76px] py-[30px]"
}
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "lg:flex items-center justify-between mb-[32px]" }
const _hoisted_12 = { class: "font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between" }
const _hoisted_13 = { class: "overflow-auto" }

import { watchEffect, ref } from 'vue';
    import { Icon } from '@iconify/vue';
    import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
    import ActionButton from '@/components/shared/action-button/ActionButton.vue';
    import ImageCard from '@/components/shared/image/ImageCard.vue'
    import { menueHeaders,  getMenus, getMenuById } from './Menu.helper';

    import MenuForm from './menu-form/MenuForm.vue'

    import TableField from '@/components/shared/table/TableField.vue';
    import InputField from '@/components/shared/input-field/InputField.vue';
    import { updateMenu } from './update-menu/UpdateMenu.helper';
    import { useStore } from 'vuex';

    export interface SearchOptions {
        search: string;
        category?: string;
    }

    
export default /*@__PURE__*/_defineComponent({
  __name: 'MenuView',
  setup(__props) {

    const showFilterMobile = ref(false);
    const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value
    

    const searchOptionsData = ref<SearchOptions>({
        search: '',
        category: ''
    });

    const onSearch = (event: any) => {
        searchOptionsData.value.search = event.target.value;
    };

    const menuItemsData = ref([])
    const loading = ref(true)
    const addMenuItem = ref(true)
    const addMenuItemForm = ref(false)
    const menuItems = ref(false)
    const store = useStore()

    const handleAddMenuItems = () => {
        addMenuItem.value = false;
        addMenuItemForm.value = !addMenuItemForm.value;
        menuItems.value = false;
    }

    const backToMenuTable = () => {
        menuItems.value = true;
        addMenuItemForm.value = false
        addMenuItem.value = true;
    }


    watchEffect(() => {
        getMenus({
            search: searchOptionsData.value.search,
        }).then((responseData) => {
            const { menus } = responseData;
            if (menus?.length) {
                menuItems.value = true;
                addMenuItem.value = false;
                addMenuItemForm.value = false;
                menuItemsData.value = menus?.map((item:any) => {
                    return {
                        ...item,
                        subDescription: `Category: ${item.category}`
                    }
                });
            }
            loading.value = false
        });
    });

    const handleToogleSwitch = async (data: any) => {
        const menu = ref();
        await getMenuById(data.id).then(async (response: any) => {
            menu.value = response
        });

        const { id, _id, status, image, ...menuRest} = menu.value;

        const updatedMenuToggle = {
            ...menuRest,
            isAvailable: !menu.value?.isAvailable
        } 

        updateMenu(updatedMenuToggle, data.id, store)
    }

    
    

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "Menu" }, {
    default: _withCtx(() => [
      (addMenuItem.value && !loading.value && menuItemsData.value.length == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "font-semibold text-heading-3 text-grey-300" }, "Menu", -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(ImageCard, {
                  imageUrl: require('@/assets/images/backgrounds/menu.svg'),
                  width: "340",
                  height: "320",
                  class: "w-[fit-content] m-[auto]"
                }, null, 8, ["imageUrl"]),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "font-semibold text-heading-3 text-primary-300 text-center mt-[22px]" }, "You don't have any menu added yet.", -1)),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-body-1 text-grey-200 text-center mt-[12px]" }, [
                  _createTextVNode("You're just a few clicks away from setting up and running your store. Start by "),
                  _createElementVNode("br"),
                  _createTextVNode(" adding different menus for your products.")
                ], -1)),
                _createVNode(ActionButton, {
                  handleClick: handleAddMenuItems,
                  theme: "primary",
                  label: "Add a Menu",
                  class: "w-[fit-content] m-[auto] mt-[22px]",
                  prefixIcon: "ant-design:plus-outlined"
                })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (loading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(ImageCard, {
                imageUrl: require('@/assets/images/gifs/menu-loading-003.gif'),
                width: "340",
                height: "320",
                class: "w-[fit-content] m-[auto]"
              }, null, 8, ["imageUrl"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (addMenuItemForm.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", {
                  class: "",
                  onClick: backToMenuTable
                }, [
                  _createVNode(_unref(Icon), { icon: "ant-design:arrow-left-outlined" })
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "font-semibold" }, "Create Menu", -1))
              ]),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-heading-5 text-primary-300 font-semibold" }, "Menu Information", -1)),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "text-body-2 text-grey-200 font-medium mt-[10px]" }, "This information will be displayed to customers on your menu.", -1))
            ]),
            _createVNode(MenuForm, { handleAddMenuItems: handleAddMenuItems })
          ]))
        : _createCommentVNode("", true),
      (menuItems.value && menuItemsData.value.length != 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", null, "Menus", -1)),
                  _createElementVNode("div", {
                    class: _normalizeClass(["border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden", showFilterMobile.value && 'bg-primary-300 text-white']),
                    onClick: handleShowMobileFilter
                  }, [
                    _createVNode(_unref(Icon), {
                      class: "",
                      icon: "fluent:options-20-filled",
                      width: "20",
                      height: "20"
                    })
                  ], 2)
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["lg:flex items-center gap-[12px] grid lg:mt-0 mt-[10px]", !showFilterMobile.value ? 'hidden' : 'block' ])
                }, [
                  _createVNode(InputField, {
                    type: "text",
                    placeholder: "Search",
                    "prefix-icon": "ant-design:search-outlined",
                    onChange: onSearch
                  }),
                  _createVNode(ActionButton, {
                    handleClick: handleAddMenuItems,
                    theme: "primary",
                    label: "New Menu",
                    class: "lg:w-[fit-content] m-[auto]",
                    prefixIcon: "ant-design:plus-outlined"
                  })
                ], 2)
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(TableField, {
                  headers: _unref(menueHeaders),
                  dataItems: menuItemsData.value,
                  isPagination: true,
                  routeData: {name: 'UpdateMenu', paramId: 'id'},
                  onHandleToogleChange: _cache[0] || (_cache[0] = (data) => handleToogleSwitch(data))
                }, null, 8, ["headers", "dataItems"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})