import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-[24px] mb-[24px]" }
const _hoisted_2 = { class: "relative py-[33.5px] bg-bg-200 text-center rounded-[8px] border-[1px] border-dotted border-grey-50 cursor-pointer h-[150px]" }
const _hoisted_3 = ["name", "value"]
const _hoisted_4 = { class: "absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-white" }

import { Icon } from '@iconify/vue';
import { ref, watchEffect } from 'vue';

interface Props {
    name?: string | number | any
    value?: string | number | any
    imageUrl?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadImage',
  props: {
    name: {},
    value: {},
    imageUrl: {}
  },
  emits: ['base64ImgCode', 'update:value', 'update:blur:value'],
  setup(__props: any, { emit: __emit }) {

const fileInput = ref<HTMLInputElement | null>(null);
const previewImageUrl = ref<string | null | any>(null);
const emit = __emit;


const props = __props

const openFilePicker = () => {
    if (fileInput.value) {
        fileInput.value.click();
    }
};

const handleFileChange = (event: Event) => {
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
        const reader = new FileReader();
        reader.onload = () => {
            if (typeof reader.result === 'string') {
                previewImageUrl.value = reader.result;
                emit('base64ImgCode', previewImageUrl.value);
            }
        };
        reader.readAsDataURL(files[0]);
    }
};

watchEffect(() => {
    previewImageUrl.value
})


// const imageStyle = ref({
//     width: '100%',
//     backgroundImage: `url(${!previewImageUrl ? imageUrl : previewImageUrl})`,
//     backgroundRepeat: 'no-repeat',
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
// });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("label", { class: "text-body-2 text-grey-200 mb-[7px] inline-block" }, "Cover Photo", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "absolute top-0 rounded-[8px]",
        style: _normalizeStyle({
                width: '100%',
                height: '100%',
                backgroundImage: `url(${!previewImageUrl.value ? _ctx.imageUrl : previewImageUrl.value})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            })
      }, null, 4),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "bg-black absolute top-0 left-0 w-[100%] h-full opacity-50 rounded-[8px]" }, null, -1)),
      _createElementVNode("input", {
        type: "file",
        name: _ctx.name,
        value: _ctx.value,
        onChange: handleFileChange,
        accept: "image/*",
        style: {"display":"none"},
        ref_key: "fileInput",
        ref: fileInput,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:blur:value', $event)))
      }, null, 40, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "w-[fit-content] m-[auto] z-[2]",
          onClick: openFilePicker
        }, [
          _createVNode(_unref(Icon), {
            icon: "ant-design:camera-filled",
            class: "w-[48px] h-[48px]"
          })
        ]),
        _createElementVNode("div", {
          class: "text-body-2 mt-[4px] centerlize-item",
          onClick: openFilePicker
        }, "Select an image from your media gallery.")
      ])
    ])
  ]))
}
}

})