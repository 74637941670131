import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-[8px] items-center" }
const _hoisted_2 = { class: "custom-checkbox" }
const _hoisted_3 = ["checked"]
const _hoisted_4 = { class: "text-body-2 text-grey-300" }

import { ref, VNode } from 'vue';

interface Props {
    label?: VNode | string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckboxInput',
  props: {
    label: {}
  },
  setup(__props: any) {



const isChecked = ref(false);

const toggleCheckbox = () => {
    isChecked.value = !isChecked.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("input", {
        type: "checkbox",
        checked: isChecked.value,
        onChange: toggleCheckbox
      }, null, 40, _hoisted_3),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "checkbox" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1)
  ]))
}
}

})