import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "flex gap-[32px] border-b-[1px] border-solid" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 2,
  class: "mt-[32px]"
}
const _hoisted_6 = { class: "border-[1px] border-solid border-grey-50 rounded-[8px] px-[20px] py-[27px] mt-[20px]" }
const _hoisted_7 = { class: "flex items-center justify-between" }
const _hoisted_8 = { class: "team-properties border-[1px] border-solid border-grey-50 rounded-[8px] mt-[20px]" }
const _hoisted_9 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_10 = { class: "py-[17px] px-[20px] text-center" }
const _hoisted_11 = { class: "flex items-center justify-between py-[18px] px-[20px] bg-bg-200 mt-[12px]" }
const _hoisted_12 = { class: "flex items-center gap-[10px] text-grey-200" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = {
  key: 0,
  class: ""
}
const _hoisted_15 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_16 = { class: "py-[17px] px-[40px]" }
const _hoisted_17 = { class: "py-[17px] px-[40px]" }
const _hoisted_18 = { class: "py-[17px] px-[40px]" }
const _hoisted_19 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_20 = { class: "py-[17px] px-[40px]" }
const _hoisted_21 = { class: "py-[17px] px-[40px]" }
const _hoisted_22 = { class: "py-[17px] px-[40px]" }
const _hoisted_23 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_24 = { class: "py-[17px] px-[40px]" }
const _hoisted_25 = { class: "py-[17px] px-[40px]" }
const _hoisted_26 = { class: "py-[17px] px-[40px]" }
const _hoisted_27 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_28 = { class: "py-[17px] px-[40px]" }
const _hoisted_29 = { class: "py-[17px] px-[40px]" }
const _hoisted_30 = { class: "py-[17px] px-[40px]" }
const _hoisted_31 = { class: "team-properties-table-header" }
const _hoisted_32 = { class: "py-[17px] px-[40px]" }
const _hoisted_33 = { class: "py-[17px] px-[40px]" }
const _hoisted_34 = { class: "py-[17px] px-[40px]" }
const _hoisted_35 = { class: "flex items-center justify-between py-[18px] px-[20px] bg-bg-200 mt-[12px]" }
const _hoisted_36 = { class: "flex items-center gap-[10px] text-grey-200" }
const _hoisted_37 = { class: "" }
const _hoisted_38 = {
  key: 1,
  class: ""
}
const _hoisted_39 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_40 = { class: "py-[17px] px-[40px]" }
const _hoisted_41 = { class: "py-[17px] px-[40px]" }
const _hoisted_42 = { class: "py-[17px] px-[40px]" }
const _hoisted_43 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_44 = { class: "py-[17px] px-[40px]" }
const _hoisted_45 = { class: "py-[17px] px-[40px]" }
const _hoisted_46 = { class: "py-[17px] px-[40px]" }
const _hoisted_47 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_48 = { class: "py-[17px] px-[40px]" }
const _hoisted_49 = { class: "py-[17px] px-[40px]" }
const _hoisted_50 = { class: "py-[17px] px-[40px]" }
const _hoisted_51 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_52 = { class: "py-[17px] px-[40px]" }
const _hoisted_53 = { class: "py-[17px] px-[40px]" }
const _hoisted_54 = { class: "py-[17px] px-[40px]" }
const _hoisted_55 = { class: "team-properties-table-header" }
const _hoisted_56 = { class: "py-[17px] px-[40px]" }
const _hoisted_57 = { class: "py-[17px] px-[40px]" }
const _hoisted_58 = { class: "py-[17px] px-[40px]" }
const _hoisted_59 = { class: "flex items-center justify-between py-[18px] px-[20px] bg-bg-200 mt-[12px]" }
const _hoisted_60 = { class: "flex items-center gap-[10px] text-grey-200" }
const _hoisted_61 = { class: "" }
const _hoisted_62 = {
  key: 2,
  class: ""
}
const _hoisted_63 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_64 = { class: "py-[17px] px-[40px]" }
const _hoisted_65 = { class: "py-[17px] px-[40px]" }
const _hoisted_66 = { class: "py-[17px] px-[40px]" }
const _hoisted_67 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_68 = { class: "py-[17px] px-[40px]" }
const _hoisted_69 = { class: "py-[17px] px-[40px]" }
const _hoisted_70 = { class: "py-[17px] px-[40px]" }
const _hoisted_71 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_72 = { class: "py-[17px] px-[40px]" }
const _hoisted_73 = { class: "py-[17px] px-[40px]" }
const _hoisted_74 = { class: "py-[17px] px-[40px]" }
const _hoisted_75 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_76 = { class: "py-[17px] px-[40px]" }
const _hoisted_77 = { class: "py-[17px] px-[40px]" }
const _hoisted_78 = { class: "py-[17px] px-[40px]" }
const _hoisted_79 = { class: "team-properties-table-header" }
const _hoisted_80 = { class: "py-[17px] px-[40px]" }
const _hoisted_81 = { class: "py-[17px] px-[40px]" }
const _hoisted_82 = { class: "py-[17px] px-[40px]" }
const _hoisted_83 = { class: "flex items-center justify-between py-[18px] px-[20px] bg-bg-200 mt-[12px]" }
const _hoisted_84 = { class: "flex items-center gap-[10px] text-grey-200" }
const _hoisted_85 = { class: "" }
const _hoisted_86 = {
  key: 3,
  class: ""
}
const _hoisted_87 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_88 = { class: "py-[17px] px-[40px]" }
const _hoisted_89 = { class: "py-[17px] px-[40px]" }
const _hoisted_90 = { class: "py-[17px] px-[40px]" }
const _hoisted_91 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_92 = { class: "py-[17px] px-[40px]" }
const _hoisted_93 = { class: "py-[17px] px-[40px]" }
const _hoisted_94 = { class: "py-[17px] px-[40px]" }
const _hoisted_95 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_96 = { class: "py-[17px] px-[40px]" }
const _hoisted_97 = { class: "py-[17px] px-[40px]" }
const _hoisted_98 = { class: "py-[17px] px-[40px]" }
const _hoisted_99 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_100 = { class: "py-[17px] px-[40px]" }
const _hoisted_101 = { class: "py-[17px] px-[40px]" }
const _hoisted_102 = { class: "py-[17px] px-[40px]" }
const _hoisted_103 = { class: "team-properties-table-header border-b-[1px] border-solid border-grey-50" }
const _hoisted_104 = { class: "py-[17px] px-[40px]" }
const _hoisted_105 = { class: "py-[17px] px-[40px]" }
const _hoisted_106 = { class: "py-[17px] px-[40px]" }

import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
import { useSettingsHelper } from './Settings.helper'
import { Icon } from '@iconify/vue';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import CheckboxInput from '@/components/shared/checkbox-input/CheckboxInput.vue';
import { ref, watchEffect } from 'vue';
import StoreDetails from './StoreDetails.vue';
import PayoutAccount from './PayoutAccount.vue';
import ProfileSettings from './ProfileSettings.vue';
import MicrositePersonalization from './MicrositePersonalization.vue';
import AdditionalChargesFees from './AdditionalChargesFees.vue';
import DeliveryOption from './DeliveryOption.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsView',
  setup(__props) {

const { settingsTabs, activateTab, activeTab } = useSettingsHelper()
const isMenuManagement = ref(true)
const isTeamManagement = ref(false)
const isAppSettings = ref(false)
const isInvoiceManagement = ref(false)

const url = window.location.href;
const params = new URLSearchParams(new URL(url).search);
const qValue = params.get('q');

const handleMenuManagement = () => {
    isMenuManagement.value = !isMenuManagement.value
}

const handleTemaManagement = () => {
    isTeamManagement.value = !isTeamManagement.value
}

const handleAppSettings = () => {
    isAppSettings.value = !isAppSettings.value;
};

const handleInvoice = () => {
    isInvoiceManagement.value = !isInvoiceManagement.value;
};

watchEffect(() => {
    switch (qValue) {
        case 'storeDetails':
            return activeTab.value = 1;
        case 'deliveryOptions':
            return activeTab.value = 4;
        case 'payoutAccount':
            return activeTab.value = 5;
        case 'additionalCharges':
            return  activeTab.value = 2
        default:
            return activeTab.value = 0
    }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "Settings" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[50] || (_cache[50] = _createElementVNode("header", { class: "pt-[72px] pb-[24px] flex justify-between items-center" }, [
          _createElementVNode("div", { class: "" }, [
            _createElementVNode("div", { class: "text-heading-3 font-semibold text-grey-300" }, "Settings"),
            _createElementVNode("div", { class: "text-body-2 text-grey-200" }, "View and manage settings related to your store.")
          ])
        ], -1)),
        _createElementVNode("main", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(settingsTabs), (tab, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab) === index }]),
                onClick: ($event: any) => (_unref(activateTab)(index))
              }, _toDisplayString(tab), 11, _hoisted_4))
            }), 128))
          ]),
          (_unref(activeTab) == 0)
            ? (_openBlock(), _createBlock(ProfileSettings, { key: 0 }))
            : _createCommentVNode("", true),
          (_unref(activeTab) == 1)
            ? (_openBlock(), _createBlock(StoreDetails, { key: 1 }))
            : _createCommentVNode("", true),
          (_unref(activeTab) == null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _cache[49] || (_cache[49] = _createElementVNode("div", { class: "text-grey-200" }, "Team Properties", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("header", _hoisted_7, [
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "" }, [
                      _createElementVNode("div", { class: "font-semibold text-sub-heading" }, "Team Properties"),
                      _createElementVNode("div", { class: "text-grey-200 text-caption" }, "Define, manage, and update team roles and permissions.")
                    ], -1)),
                    _createVNode(ActionButton, {
                      label: "Add new role",
                      theme: "primary",
                      class: "w-[fit-content]"
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("header", _hoisted_9, [
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Actions", -1)),
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "py-[17px] px-[40px] text-center" }, "Admin", -1)),
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "py-[17px] px-[40px] text-center" }, "Manager", -1)),
                    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "py-[17px] px-[40px] text-center" }, "Cashier", -1)),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_unref(Icon), {
                        icon: "ant-design:ellipsis-outlined",
                        class: "w-[24px] h-[24px]"
                      })
                    ])
                  ]),
                  _createElementVNode("header", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:profile-outlined",
                          class: "w-[24px] h-[24px]"
                        })
                      ]),
                      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text-grey-300 font-semibold" }, "Menu management", -1))
                    ]),
                    _createElementVNode("div", {
                      class: "text-grey-200 cursor-pointer",
                      onClick: handleMenuManagement
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: isMenuManagement.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                        class: "w-[18px] h-[18px]"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (isMenuManagement.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Add New Menu Item", -1)),
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_18, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Edit Existing Menu Item", -1)),
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Delete Menu Item", -1)),
                          _createElementVNode("div", _hoisted_24, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_27, [
                          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Set Menu Item Availability", -1)),
                          _createElementVNode("div", _hoisted_28, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_31, [
                          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Customization Sizes and Extra", -1)),
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_33, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_34, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("header", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:idcard-outlined",
                          class: "w-[24px] h-[24px]"
                        })
                      ]),
                      _cache[16] || (_cache[16] = _createElementVNode("div", { class: "text-grey-300 font-semibold" }, "Team management", -1))
                    ]),
                    _createElementVNode("div", {
                      class: "text-grey-200 cursor-pointer",
                      onClick: handleTemaManagement
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: isTeamManagement.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                        class: "w-[18px] h-[18px]"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (isTeamManagement.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                        _createElementVNode("div", _hoisted_39, [
                          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Add New Menu Item", -1)),
                          _createElementVNode("div", _hoisted_40, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_41, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_42, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[18] || (_cache[18] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_43, [
                          _cache[19] || (_cache[19] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Edit Existing Menu Item", -1)),
                          _createElementVNode("div", _hoisted_44, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_45, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_46, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[20] || (_cache[20] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_47, [
                          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Delete Menu Item", -1)),
                          _createElementVNode("div", _hoisted_48, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_49, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_50, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_51, [
                          _cache[23] || (_cache[23] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Set Menu Item Availability", -1)),
                          _createElementVNode("div", _hoisted_52, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_53, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_54, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_55, [
                          _cache[25] || (_cache[25] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Customization Sizes and Extra", -1)),
                          _createElementVNode("div", _hoisted_56, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_57, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_58, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[26] || (_cache[26] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("header", _hoisted_59, [
                    _createElementVNode("div", _hoisted_60, [
                      _createElementVNode("div", _hoisted_61, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:setting-outlined",
                          class: "w-[24px] h-[24px]"
                        })
                      ]),
                      _cache[27] || (_cache[27] = _createElementVNode("div", { class: "text-grey-300 font-semibold" }, "App settings", -1))
                    ]),
                    _createElementVNode("div", {
                      class: "text-grey-200 cursor-pointer",
                      onClick: handleAppSettings
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: isAppSettings.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                        class: "w-[18px] h-[18px]"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (isAppSettings.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
                        _createElementVNode("div", _hoisted_63, [
                          _cache[28] || (_cache[28] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Add New Menu Item", -1)),
                          _createElementVNode("div", _hoisted_64, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_65, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_66, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[29] || (_cache[29] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_67, [
                          _cache[30] || (_cache[30] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Edit Existing Menu Item", -1)),
                          _createElementVNode("div", _hoisted_68, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_69, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_70, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[31] || (_cache[31] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_71, [
                          _cache[32] || (_cache[32] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Delete Menu Item", -1)),
                          _createElementVNode("div", _hoisted_72, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_73, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_74, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[33] || (_cache[33] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_75, [
                          _cache[34] || (_cache[34] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Set Menu Item Availability", -1)),
                          _createElementVNode("div", _hoisted_76, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_77, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_78, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[35] || (_cache[35] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_79, [
                          _cache[36] || (_cache[36] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Customization Sizes and Extra", -1)),
                          _createElementVNode("div", _hoisted_80, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_81, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_82, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("header", _hoisted_83, [
                    _createElementVNode("div", _hoisted_84, [
                      _createElementVNode("div", _hoisted_85, [
                        _createVNode(_unref(Icon), {
                          icon: "ant-design:file-outlined",
                          class: "w-[24px] h-[24px]"
                        })
                      ]),
                      _cache[38] || (_cache[38] = _createElementVNode("div", { class: "text-grey-300 font-semibold" }, "Invoice management", -1))
                    ]),
                    _createElementVNode("div", {
                      class: "text-grey-200 cursor-pointer",
                      onClick: handleInvoice
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: isInvoiceManagement.value ? 'ant-design:caret-down-outlined' : 'ant-design:caret-up-outlined',
                        class: "w-[18px] h-[18px]"
                      }, null, 8, ["icon"])
                    ])
                  ]),
                  (isInvoiceManagement.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                        _createElementVNode("div", _hoisted_87, [
                          _cache[39] || (_cache[39] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Add New Menu Item", -1)),
                          _createElementVNode("div", _hoisted_88, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_89, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_90, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[40] || (_cache[40] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_91, [
                          _cache[41] || (_cache[41] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Edit Existing Menu Item", -1)),
                          _createElementVNode("div", _hoisted_92, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_93, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_94, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[42] || (_cache[42] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_95, [
                          _cache[43] || (_cache[43] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Delete Menu Item", -1)),
                          _createElementVNode("div", _hoisted_96, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_97, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_98, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[44] || (_cache[44] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_99, [
                          _cache[45] || (_cache[45] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Set Menu Item Availability", -1)),
                          _createElementVNode("div", _hoisted_100, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_101, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_102, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[46] || (_cache[46] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ]),
                        _createElementVNode("div", _hoisted_103, [
                          _cache[47] || (_cache[47] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, "Customization Sizes and Extra", -1)),
                          _createElementVNode("div", _hoisted_104, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_105, [
                            _createVNode(CheckboxInput)
                          ]),
                          _createElementVNode("div", _hoisted_106, [
                            _createVNode(CheckboxInput)
                          ]),
                          _cache[48] || (_cache[48] = _createElementVNode("div", { class: "py-[17px] px-[20px]" }, null, -1))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(activeTab) == 2)
            ? (_openBlock(), _createBlock(AdditionalChargesFees, { key: 3 }))
            : _createCommentVNode("", true),
          (_unref(activeTab) == 3)
            ? (_openBlock(), _createBlock(MicrositePersonalization, { key: 4 }))
            : _createCommentVNode("", true),
          (_unref(activeTab) == 4)
            ? (_openBlock(), _createBlock(DeliveryOption, { key: 5 }))
            : _createCommentVNode("", true),
          (_unref(activeTab) == 5)
            ? (_openBlock(), _createBlock(PayoutAccount, { key: 6 }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})