import { ref, watchEffect, onMounted } from "vue";
import { barChartrHelper } from "./BarChart.helper";
import { doughnutChartHelper } from "./DoughnutChart.helper";
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue'

import { formatPrice } from '../../../utility/helper/utils.helper'
import DasboardService from "@/utility/services/dashboard.service";

import OrderService from "@/utility/services/orders.service";

export interface FilterOptions {
    since: number;
}

export interface ActiveTab {
    since: number;
}

export const getDashboardStats = async({ since=0}) => {
    try {
        const response = await DasboardService.getDashboardRequest({  since });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const getDashboardBestItems = async({ search= "", since = 0}) => {
    try {
        const response = await DasboardService.getDashboardBestItemsRequest({  search, since });
        return response;
    } catch (error) {
        console.error(error)
    }
}

export const useDashboardHelper = () => {
    const doughnutChartData: any = ref([])
    let doughnutChartObject: any = null;
    let barChartObject: any = null;

    const filterTabs = [{
        label: 'Today',
        since: 0
    },{
        label: 'Yesterday',
        since: 1
    },{
        label: 'Last week',
        since: 7
    },{
        label: 'Last month',
        since: 30
    },
    {
        label: 'This month',
        since: 333
    },
    {
        label: 'Year To Date',
        since: 9999
    }, {
        label: 'Last Year',
        since: 365
    }];

    const activeTab: any = ref<ActiveTab>({
        since: 9999
    });
    const filterOptionsData = ref<FilterOptions>({
        since: 9999
    });
    const activateTab = (tab: ActiveTab) => {
        activeTab.value = tab;
        filterOptionsData.value.since = tab.since;
    };
    const customersOverview = ref([] as any);
    const customersItemsOverview = ref([] as any);
    const BS_COLORS = ['#446CE4', '#13B76B', '#9747FF', '#D3766A'];
    const storeData = ref([]);
    const headers = ref([
        { name: "Order Details", value: "OrderDetails" },
        { name: "Order Date", icon: 'ant-design:sort-ascending-outlined', value: "orderDate" },
        { name: "Customer's name", value: "customer" },
        { name: "Payment Method", value: "paymentMethod" },
        { name: "Cashier", value: "cashier" },
        // { name: "Order Channel", value: "orderChannel" },
        { name: "Total Price", value: "totalPrice" },
        { name: "Status", value: "statusCard" },
    ]);

    watchEffect(() => {
        getDashboardStats({
            since: filterOptionsData.value.since,
        }).then((responseData: any) => {
            customersItemsOverview.value = responseData?.bestSellingItems?.map((item: any, index: number) => {
                return {
                    index,
                    color: BS_COLORS[index],
                    ...item
                }
            });
            if (doughnutChartObject) {
                doughnutChartObject.destroy();
            }
            const { doughnutChart, doughnutChartData: chartData }  = doughnutChartHelper({
                labels: responseData?.channels?.map((dataItem : any) => `${dataItem.channel} (${dataItem.count})`),
                datasets: [{
                    data: responseData?.channels?.map((dataItem : any) => dataItem.count),
                    backgroundColor: [
                        '#F94144',
                        '#F8961E',
                        '#F9C74F',
                        '#F3722C',
                        '#F3722C',
                        '#F3722C',
                        '#F3722C',
                    ],
                    hoverOffset: 4
                }]
            });
            doughnutChartData.value = chartData;
            doughnutChartObject = doughnutChart;
           
            if (barChartObject) {
                barChartObject.destroy();
            }
            const barChartData = {
                labels: responseData?.storeTraffic?.map((vitem: any) => vitem.label),
                datasets: [{
                    label: 'Progress',
                    data: responseData?.storeTraffic?.map((item: any) =>  item.value),
                    backgroundColor: '#FF0000', 
                    borderWidth: 1,
                    borderRadius: 80,
                    barThickness: 30 
                },
                {
                    label: 'Background',
                    data: responseData?.storeTraffic?.map((item: any) => 100 +  100 - item.value), 
                    backgroundColor: '#F5F5F5',
                    borderColor: 'rgb(128, 128, 128)',
                    borderRadius: 60,
                    barThickness: 30
                }]
            };
            const { barChart } = barChartrHelper(barChartData);
            barChartObject = barChart;
            customersOverview.value.length = 0;


            [{
                type: 'Total Sales',
                percAmount:  responseData?.totalOrdersAmount?.change || 0,
                customersNo: formatPrice(responseData?.totalOrdersAmount?.value || 0),
                progressPerc: responseData?.totalOrdersAmount?.progress || 0,
                icon: 'ant-design:shopping-outlined',
                color: '#5075BF'
            }, {
                type: 'Total Orders',
                percAmount:  responseData?.totalOrders?.change || 0,
                customersNo: responseData?.totalOrders?.value || 0,
                progressPerc: responseData?.totalOrders?.progress || 0,
                icon: 'ant-design:shopping-outlined',
                color: '#5075BF'
            },   {
                type: 'New Customers',
                percAmount:  responseData?.newCustomers?.change || 0,
                customersNo: responseData?.newCustomers?.value || 0,
                progressPerc: responseData?.newCustomers?.progress || 0,
                icon: 'ant-design:user-add-outlined',
                color: '#EAB308'
            }, {
                type: 'Total Refunds',
                percAmount:  responseData?.refunds?.change || 0,
                customersNo: responseData?.refunds?.value || 0,
                progressPerc: responseData?.refunds?.progress || 0,
                icon: 'ant-design:rollback-outlined',
                color: '#E132D0'
            },
            {
                type: 'Total discount',
                percAmount:  responseData?.discounts?.change || 0,
                customersNo: responseData?.discounts?.value || 0,
                progressPerc: responseData?.discounts?.progress || 0,
                icon: 'ant-design:percentage-outlined',
                color: '#CF0940'
            },
            {
                type: 'Return Customers',
                percAmount:  responseData?.returnCustomers?.change || 0,
                customersNo: responseData?.returnCustomers?.value || 0,
                progressPerc: responseData?.returnCustomers?.progress || 0,
                icon: 'ant-design:user-add-outlined',
                color: '#EAB308'
            }]
            .forEach((item: any) => {
                customersOverview.value.push({...item})
            })
        })
        OrderService.getOrdersRequest({limit: 10}).then((responseData: any) => {
            const { orders } = responseData;
            storeData.value = orders;
        })
    });

    return {
        onMounted, 
        doughnutChartData, 
        filterTabs,
        activeTab, 
        activateTab, 
        customersOverview: customersOverview.value, 
        customersItemsOverview: customersItemsOverview, 
        AppWrapper,
        storeData: storeData,
        headers: headers.value
    }
}
