<template>
    <AppWrapper activeTab="Menu">
        <div class="py-[76px]" v-if="addMenuItem && !loading && menuItemsData.length == 0">
            <div class="font-semibold text-heading-3 text-grey-300">Menu</div>
            <div class="relative">
                <div class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[30%]">
                    <ImageCard :imageUrl="require('@/assets/images/backgrounds/menu.svg')" width="340" height="320" class="w-[fit-content] m-[auto]" />
                    <div class="font-semibold text-heading-3 text-primary-300 text-center mt-[22px]">You don't have any menu added yet.</div>
                    <div class="text-body-1 text-grey-200 text-center mt-[12px]">You're just a few clicks away from setting up and running your store. Start by <br /> adding different menus for your products.</div>
                    <ActionButton :handleClick="handleAddMenuItems" theme="primary" label="Add a Menu" class="w-[fit-content] m-[auto] mt-[22px]" prefixIcon="ant-design:plus-outlined"/>
                </div>
            </div>
        </div>

        <div class="relative">
            <div v-if="loading" class="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[100%]">
                <ImageCard :imageUrl="require('@/assets/images/gifs/menu-loading-003.gif')" width="340" height="320" class="w-[fit-content] m-[auto]" />
            </div>
        </div>

        <div class="w-[600px] m-[auto] py-[72px]" v-if="addMenuItemForm">
            <div class="mb-[23px]">
                <div class="text-heading-3 flex items-center gap-[24px] mb-[28px]">
                    <div class="" @click="backToMenuTable"><Icon icon="ant-design:arrow-left-outlined" /></div>
                    <div class="font-semibold">Create Menu</div>
                </div>
                <div class="text-heading-5 text-primary-300 font-semibold">Menu Information</div>
                <div class="text-body-2 text-grey-200 font-medium mt-[10px]">This information will be displayed to customers on your menu.</div>
            </div>

            <MenuForm :handleAddMenuItems="handleAddMenuItems" />
        </div>

        <div class="lg:py-[76px] py-[30px]" v-if="menuItems && menuItemsData.length != 0">
            <div class="">
                <div class="lg:flex items-center justify-between mb-[32px]">
                    <div class="font-semibold lg:text-heading-3 text-heading-5 flex items-center justify-between">
                        <div>Menus</div>
                        <div class="border-gray-300 border-[1px] rounded-[4px] p-[4px] lg:hidden" :class="showFilterMobile && 'bg-primary-300 text-white'" @click="handleShowMobileFilter"><Icon class="" icon="fluent:options-20-filled" width="20" height="20" /></div>
                    </div>
                    <div class="lg:flex items-center gap-[12px] grid lg:mt-0 mt-[10px]" :class="!showFilterMobile ? 'hidden' : 'block' ">
                        <InputField type="text" placeholder="Search" prefix-icon="ant-design:search-outlined" @change="onSearch" />
                        <ActionButton :handleClick="handleAddMenuItems" 
                            theme="primary" 
                            label="New Menu" 
                            class="lg:w-[fit-content] m-[auto]" 
                            prefixIcon="ant-design:plus-outlined"/>
                    </div>
                </div>

                <div class="overflow-auto">
                    <TableField 
                        :headers="menueHeaders"
                        :dataItems="menuItemsData"
                        :isPagination="true"
                        :routeData="{name: 'UpdateMenu', paramId: 'id'}" 
                        @handleToogleChange="(data) => handleToogleSwitch(data)"
                    />
                </div>
            </div>
        </div>
    </AppWrapper>
</template>

<script setup lang="ts">
    import { watchEffect, ref } from 'vue';
    import { Icon } from '@iconify/vue';
    import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
    import ActionButton from '@/components/shared/action-button/ActionButton.vue';
    import ImageCard from '@/components/shared/image/ImageCard.vue'
    import { menueHeaders,  getMenus, getMenuById } from './Menu.helper';

    import MenuForm from './menu-form/MenuForm.vue'

    import TableField from '@/components/shared/table/TableField.vue';
    import InputField from '@/components/shared/input-field/InputField.vue';
    import { updateMenu } from './update-menu/UpdateMenu.helper';
    import { useStore } from 'vuex';

    const showFilterMobile = ref(false);
    const handleShowMobileFilter = () => showFilterMobile.value = !showFilterMobile.value
    

    export interface SearchOptions {
        search: string;
        category?: string;
    }

    const searchOptionsData = ref<SearchOptions>({
        search: '',
        category: ''
    });

    const onSearch = (event: any) => {
        searchOptionsData.value.search = event.target.value;
    };

    const menuItemsData = ref([])
    const loading = ref(true)
    const addMenuItem = ref(true)
    const addMenuItemForm = ref(false)
    const menuItems = ref(false)
    const store = useStore()

    const handleAddMenuItems = () => {
        addMenuItem.value = false;
        addMenuItemForm.value = !addMenuItemForm.value;
        menuItems.value = false;
    }

    const backToMenuTable = () => {
        menuItems.value = true;
        addMenuItemForm.value = false
        addMenuItem.value = true;
    }


    watchEffect(() => {
        getMenus({
            search: searchOptionsData.value.search,
        }).then((responseData) => {
            const { menus } = responseData;
            if (menus?.length) {
                menuItems.value = true;
                addMenuItem.value = false;
                addMenuItemForm.value = false;
                menuItemsData.value = menus?.map((item:any) => {
                    return {
                        ...item,
                        subDescription: `Category: ${item.category}`
                    }
                });
            }
            loading.value = false
        });
    });

    const handleToogleSwitch = async (data: any) => {
        const menu = ref();
        await getMenuById(data.id).then(async (response: any) => {
            menu.value = response
        });

        const { id, _id, status, image, ...menuRest} = menu.value;

        const updatedMenuToggle = {
            ...menuRest,
            isAvailable: !menu.value?.isAvailable
        } 

        updateMenu(updatedMenuToggle, data.id, store)
    }

    
    
</script>

<style scoped>
</style>