import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "lg:w-[600px] lg:m-[auto] lg:py-[72px] py-[30px]" }
const _hoisted_2 = { class: "mb-[23px]" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "flex gap-[32px] border-b-[1px] border-solid mt-[24px]" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "mt-[24px]"
}
const _hoisted_7 = { class: "flex items-center gap-[10px] mt-[8px]" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "mt-[24px] grid grid-cols-2 gap-[24px]" }
const _hoisted_10 = { class: "mt-[24px]" }
const _hoisted_11 = { class: "flex gap-[32px]" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px] border-grey-50" }
const _hoisted_15 = { key: 2 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "grid grid-cols-1 mt-[24px] pt-[24px] gap-[32px] border-t-[1px] border-grey-50" }
const _hoisted_18 = { class: "border-t-[1px] border-solid border-grey-50 mt-[52px]" }
const _hoisted_19 = { class: "flex w-[fit-content] ml-[auto] gap-[12px]" }

import InputField from '@/components/shared/input-field/InputField.vue';
import TextArea from '@/components/shared/text-area/TextArea.vue';
import RadioButton from '@/components/shared/radio-button/RadioButton.vue';
import { useMenuHelper } from './UpdateMenu.helper'
import { Icon } from '@iconify/vue';
import DropdownField from '@/components/shared/dropdown/DropdownField.vue';
import { computed, ref, watchEffect } from 'vue';
import UploadImage from '@/components/shared/upload-image/UploadImage.vue';
import { addToInputList, deleteFromInputList, uploadFile } from '@/utility/helper/utils.helper';
import ActionButton from '@/components/shared/action-button/ActionButton.vue';
import AppWrapper from '@/components/app/app-wrapper/AppWrapper.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

interface Props {
    handleAddMenuItems?: any
    id: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateMenu',
  props: {
    handleAddMenuItems: {},
    id: {}
  },
  setup(__props: any) {

const props = __props;
const router = useRouter()
const imageData = ref();
const store = useStore()

const { activateTab, activeTab, tabs, categoriesData, menuData, createMenuFormHandler } = useMenuHelper(props.id, store);

const imageDataFromEmitedBase64 = (urlCode: string) => {
    const imageDataParts = urlCode.split(',');
    imageData.value = imageDataParts?.[1];
}

const categoriesOptions = computed(() => {
    if (!categoriesData.value || !categoriesData.value.categories) {
        return [];
    }

    return categoriesData.value.categories.map((category: any) => ({
        name: category.category,
        value: category.id
    }));
});

const handleCategoryChange = (event: any) => {
    const { value } = event.target
    const categoryData =  categoriesData.value.categories.find((category: any) => value == category.id);

    const newCategoryData = {
        ...menuData.value,
        category: categoryData.category,
        categoryId: categoryData.id
    }

    createMenuFormHandler.setValues(newCategoryData);
}

watchEffect(() => createMenuFormHandler.setValues(menuData.value))

const addMenuTypeHandler = (menuType: string) => {
    const newMenuType = menuData.value?.[menuType] == undefined 
    ? null
    : [...menuData.value?.[menuType], {}]

    const newMenuTypeData = {
        ...menuData.value,
        [menuType]: newMenuType
    }

    console.log({newMenuType, newMenuTypeData})

    menuData.value[menuType] = newMenuType
    createMenuFormHandler.setValues(menuData.value);
}

const handleChangeMenuTypeValue = (event: any, index: any, menuType: string) => {
    const { name, value } = event.target;
    
    const updatedSizesData = [ ...menuData.value?.[menuType] ]
    const updatedSize = {...menuData.value?.[menuType]?.[index]}

    updatedSize[name] = value;

    updatedSizesData[index] = updatedSize;
    menuData.value[menuType] = updatedSizesData;

    createMenuFormHandler.setValues(menuData.value);
}

const handleCreateMenuSubmit = async (event: Event) => {
    event.preventDefault();

    createMenuFormHandler.values.imageData = imageData.value;

    await createMenuFormHandler.handleSubmit(event);
    menuData.value = createMenuFormHandler.values;
    // props.handleAddMenuItems();
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppWrapper, { activeTab: "Menu" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "text-heading-3 flex items-center gap-[24px] mb-[28px]",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(router).back && _unref(router).back(...args)))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(Icon), { icon: "ant-design:arrow-left-outlined" })
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "font-semibold" }, "Update Menu", -1))
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "text-heading-5 text-primary-300 font-semibold" }, "Menu Information", -1)),
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "text-body-2 text-grey-200 font-medium mt-[10px]" }, "This information will be displayed to customers on your menu.", -1))
        ]),
        _createElementVNode("form", {
          onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (handleCreateMenuSubmit($event)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabs), (tab, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(['py-[12px] cursor-pointer text-body-2 text-grey-200', { 'text-primary-300 border-b-[2px] border-solid border-primary-300 font-medium': _unref(activeTab) === index }]),
                onClick: ($event: any) => (_unref(activateTab)(index))
              }, _toDisplayString(tab), 11, _hoisted_5))
            }), 128))
          ]),
          (_unref(activeTab) === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(InputField, {
                  label: "Name",
                  placeholder: "Name",
                  type: "text",
                  name: "name",
                  value: _unref(menuData)?.name,
                  "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                  "onUpdate:value":  _unref(createMenuFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(TextArea, {
                  label: "Description",
                  placeholder: "Description",
                  class: "mt-[24px]",
                  name: "description",
                  value: _unref(menuData)?.description,
                  "onUpdate:value": _unref(createMenuFormHandler).handleChange
                }, null, 8, ["value", "onUpdate:value"]),
                _createVNode(UploadImage, {
                  name: "imageData",
                  value: _unref(menuData)?.imageUrl,
                  imageUrl: _unref(menuData)?.image,
                  onBase64ImgCode: imageDataFromEmitedBase64,
                  "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                  "onUpdate:value": _unref(createMenuFormHandler).handleFileChange
                }, null, 8, ["value", "imageUrl", "onUpdate:blur:value", "onUpdate:value"]),
                _createVNode(DropdownField, {
                  optionsData: categoriesOptions.value,
                  "default-option-label": "Select",
                  label: "Category",
                  value: _unref(menuData)?.categoryId,
                  class: "",
                  onChange: _cache[1] || (_cache[1] = ($event: any) => (handleCategoryChange($event)))
                }, null, 8, ["optionsData", "value"]),
                _createVNode(InputField, {
                  label: "Add Ingredients",
                  placeholder: "Ingredients Name",
                  type: "text",
                  name: "ingredients",
                  class: "mt-[24px]",
                  onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers((event) => _unref(addToInputList)(event, _unref(menuData), _unref(createMenuFormHandler)), ["prevent"]), ["enter"])),
                  undertext: "Tap \"Enter Key\" to add more restaurant type"
                }),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuData)?.ingredients, (ingredient, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "py-[8px] px-[14px] bg-bg-200 rounded-[4px] flex items-center gap-[12px] w-[fit-content] text-caption",
                      key: idx
                    }, [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(ingredient), 1),
                      _createVNode(_unref(Icon), {
                        icon: "ant-design:close-outlined",
                        width: "12",
                        height: "12",
                        onClick: () => _unref(deleteFromInputList)('ingredients', idx, _unref(menuData), _unref(createMenuFormHandler))
                      }, null, 8, ["onClick"])
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(InputField, {
                    label: "Cost Price",
                    prefixIcon: "fa6-solid:naira-sign",
                    type: "number",
                    name: "cost",
                    value: _unref(menuData)?.cost == '' ? '0.00' : _unref(menuData)?.cost,
                    "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                    "onUpdate:value": _unref(createMenuFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "Selling Price",
                    prefixIcon: "fa6-solid:naira-sign",
                    type: "number",
                    name: "price",
                    value: _unref(menuData)?.price == '' ? '0.00' : _unref(menuData)?.price,
                    "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                    "onUpdate:value": _unref(createMenuFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "Quantity",
                    type: "number",
                    name: "totalQuantity",
                    value: _unref(menuData)?.totalQuantity,
                    "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                    "onUpdate:value": _unref(createMenuFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                  _createVNode(InputField, {
                    label: "Remaining Quantity",
                    type: "number",
                    name: "remainingQuantity",
                    value: _unref(menuData)?.remainingQuantity,
                    "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                    "onUpdate:value": _unref(createMenuFormHandler).handleChange
                  }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { class: "text-body-2 text-grey-200 mb-[7px] inline-block" }, "Avalability", -1)),
                  _createElementVNode("div", _hoisted_11, [
                    _createVNode(RadioButton, {
                      value: "true",
                      isChecked: _unref(menuData)?.isAvailable === true,
                      name: "isAvailable",
                      "onOn:change": _unref(createMenuFormHandler).handleChange
                    }, null, 8, ["isChecked", "onOn:change"]),
                    _createVNode(RadioButton, {
                      value: "false",
                      isChecked: _unref(menuData)?.isAvailable === false,
                      name: "isAvailable",
                      "onOn:change": _unref(createMenuFormHandler).handleChange
                    }, null, 8, ["isChecked", "onOn:change"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(activeTab) === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuData)?.sizes, (size, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "sizes items-center gap-[12px] mt-[32px]",
                    key: idx
                  }, [
                    _createVNode(InputField, {
                      label: "Name",
                      type: "text",
                      placeholder: "Name",
                      name: "name",
                      value: size?.name,
                      "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                      "onUpdate:value": ($event: any) => (handleChangeMenuTypeValue($event, idx, 'sizes'))
                    }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                    _createVNode(InputField, {
                      label: "Price",
                      type: "number",
                      placeholder: "Price",
                      name: "price",
                      value: size?.price,
                      "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                      "onUpdate:value": ($event: any) => (handleChangeMenuTypeValue($event, idx, 'sizes'))
                    }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                    _createElementVNode("div", {
                      class: "bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content] cursor-pointer",
                      onClick: ($event: any) => (_unref(deleteFromInputList)('sizes', idx, _unref(menuData), _unref(createMenuFormHandler)))
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: "ant-design:delete-filled",
                        class: "text-primary-300 w-[24px] h-[24px]"
                      })
                    ], 8, _hoisted_13)
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(ActionButton, {
                    prefixIcon: "ant-design:plus-outline",
                    label: "Add size",
                    class: "w-[fit-content] m-[auto]",
                    theme: "outlined",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (addMenuTypeHandler('sizes')))
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(activeTab) === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuData)?.extras, (extra, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "sizes items-center gap-[12px] mt-[32px]",
                    key: idx
                  }, [
                    _createVNode(InputField, {
                      label: "Name",
                      type: "text",
                      placeholder: "Name",
                      name: "name",
                      value: extra.name,
                      "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                      "onUpdate:value": ($event: any) => (handleChangeMenuTypeValue($event, idx, 'extras'))
                    }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                    _createVNode(InputField, {
                      label: "Price",
                      type: "number",
                      placeholder: "Price",
                      name: "price",
                      value: extra.price,
                      "onUpdate:blur:value": _unref(createMenuFormHandler).handleBlur,
                      "onUpdate:value": ($event: any) => (handleChangeMenuTypeValue($event, idx, 'extras'))
                    }, null, 8, ["value", "onUpdate:blur:value", "onUpdate:value"]),
                    _createElementVNode("div", {
                      class: "bg-bg-200 rounded-[4px] mt-[27px] px-[16px] py-[10px] w-[fit-content] cursor-pointer",
                      onClick: ($event: any) => (_unref(deleteFromInputList)('extras', idx, _unref(menuData), _unref(createMenuFormHandler)))
                    }, [
                      _createVNode(_unref(Icon), {
                        icon: "ant-design:delete-filled",
                        class: "text-primary-300 w-[24px] h-[24px]"
                      })
                    ], 8, _hoisted_16)
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(ActionButton, {
                    prefixIcon: "ant-design:plus-outline",
                    label: "Add Extra",
                    class: "w-[fit-content] m-[auto]",
                    theme: "outlined",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (addMenuTypeHandler('extras')))
                  })
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createVNode(ActionButton, {
                theme: "secondary",
                label: "Clear form",
                class: "w-[fit-content] mt-[22px]",
                prefixIcon: "ant-design:delete-outlined"
              }),
              _createVNode(ActionButton, {
                theme: "primary",
                label: "Save",
                class: "w-[fit-content] mt-[22px]",
                isSubmit: true
              })
            ])
          ])
        ], 32)
      ])
    ]),
    _: 1
  }))
}
}

})